import { Box, Text, Image, useColorMode, Progress } from "@chakra-ui/react";
import useGetData from "../../hooks/useGetData";
import Loading from "../EventComponent/Event/Loading";
import { IEvent } from "../../types/interface";
import placeholder from "../../assets/img/imgPlaceholder.png";
import { useSetRecoilState } from "recoil";
import { seatsSoldAtom, selectedEventState } from "../../recoil/atom";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const SummaryCard: React.FC = () => {
  const { colorMode } = useColorMode();
  const userEventsQueryKey = "userEvents";
  const userEventsUrl = `/event/user/all_events`;
  const setSelectedEvent = useSetRecoilState(selectedEventState);
  const [eventCounts, setEventCounts] = useState({});
  const [eventGross, setEventGross] = useState({});
  const setEventCount = useSetRecoilState(seatsSoldAtom);

  console.log("This is event gross: ", eventGross);

  const {
    data: userEvents,
    error: eventsError,
    isLoading: eventsLoading,
    isSuccess: eventsSuccess,
  } = useGetData(userEventsQueryKey, userEventsUrl, 300000);

  useEffect(() => {
    const fetchEventDashboardNumbers = async (eventId) => {
      try {
        const response = await axios.get(
          `${API_URL}/event/dashboard_numbers/${eventId}`,
        );
        const dashboardNumbers = response.data;
        setEventCounts((prevCounts) => ({
          ...prevCounts,
          [eventId]: dashboardNumbers,
        }));
        setEventCount((prevCounts) => ({
          ...prevCounts,
          [eventId]: dashboardNumbers,
        }));
      } catch (error) {
        console.error(
          `Error fetching dashboard numbers for event ${eventId}:`,
          error,
        );
      }
    };

    console.log("User events: ", userEvents);

    const fetchEventGrossRevenue = async (eventId) => {
      try {
        const response = await axios.get(
          `${API_URL}/event/gross-revenue/${eventId}`,
        );
        const grossRevenue = response.data;
        console.log("gross revenue response: ", response.data);
        setEventGross((prevGross) => ({
          ...prevGross,
          [eventId]: grossRevenue,
        }));
        console.log("After setting to sectionRevenueAtom:", grossRevenue);
      } catch (error) {
        console.error(
          `Error fetching gross revenue for event ${eventId}:`,
          error,
        );
      }
    };

    const fetchEventDataForAllEvents = async () => {
      if (userEvents && userEvents.length > 0) {
        const dashboardNumbersPromises = userEvents.map((event) =>
          fetchEventDashboardNumbers(event._id),
        );

        const grossRevenuePromises = userEvents.map((event) =>
          fetchEventGrossRevenue(event._id),
        );

        await Promise.all([
          ...dashboardNumbersPromises,
          ...grossRevenuePromises,
        ]);
      }
    };
    fetchEventDataForAllEvents();
  }, [userEvents]);

  return (
    <>
      {eventsError && <Text color="red.500">Error loading events</Text>}
      {eventsLoading ? (
        <Loading count={2} />
      ) : (
        <>
          {eventsSuccess && userEvents && userEvents.length > 0 ? (
            userEvents.map((event: IEvent) => (
              <Link
                to={"/account/my_events/details"}
                key={event._id}
                onClick={() => {
                  setSelectedEvent(event);
                }}
                className=" w-full items-center justify-start grid md:grid-cols-2 gap-3 lg:gap-6 py-2 pb-4 border-b border-t"
              >
                <Box className=" flex items-center gap-4 w-full">
                  <Image
                    src={event.imgBanner}
                    alt={event.title}
                    loading="lazy"
                    fallbackSrc={placeholder}
                    className=" w-24 object-cover h-24 group-hover:scale-105 duration-500"
                  />
                  <Box>
                    <Text className=" font-bold">{event.title}</Text>
                    <Text
                      className={`${
                        colorMode === "dark" ? "text-gray-200" : "text-gray-600"
                      }`}
                    >
                      {event.locationName}
                    </Text>
                  </Box>
                </Box>
                <Box className=" grid gap-4 grid-cols-3 w-full items-start justify-evenly">
                  <Box>
                    <Text
                      className={"font-semibold"}
                      display={{ md: "none", base: "block" }}
                    >
                      # tickets sold{" "}
                    </Text>
                    <Text>{`${eventCounts[event._id]?.totalBought || 0}/${
                      eventCounts[event._id]?.totalCount || 0
                    }`}</Text>
                    <Progress
                      borderRadius={20}
                      size={"xs"}
                      width={"80%"}
                      value={
                        eventCounts[event._id]
                          ? (eventCounts[event._id].totalBought /
                              eventCounts[event._id].totalCount) *
                            100
                          : 0
                      }
                    />
                  </Box>
                  <Box>
                    <Text
                      className={"font-semibold"}
                      display={{ md: "none", base: "block" }}
                    >
                      Gross revenue
                    </Text>
                    <Text>
                      K{" "}
                      {parseFloat(
                        eventGross[event._id]?.sellerGrossRevenue,
                      ).toFixed(2) || 0}
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      className={"font-semibold"}
                      display={{ md: "none", base: "block" }}
                    >
                      Approval{" "}
                    </Text>
                    <Box>{event.status}</Box>
                  </Box>
                </Box>
              </Link>
            ))
          ) : (
            <Text>No events available.</Text>
          )}
        </>
      )}
    </>
  );
};

export default SummaryCard;
