import { IEvent, PriceAndQuantity } from "../types/interface";

export const helloString = () => "Hello Musha tickets";

export function validateEmail(mail: string) {
  let valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail); // eslint-disable-line
  if (valid) {
    return true;
  }
  return false;
}

export function unStringfy(str: string) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

const phoneNumberRegex = /^(097|096|095|077|076|075)\d{7}$|^260\d{9}$/;

export function isPhoneNumberValid(phoneNumber: string) {
  return phoneNumberRegex.test(phoneNumber);
}

export const truncateString = (str: string, length: number): string => {
  if (str && str.length > length) {
    return str.substring(0, length) + "...";
  }
  return str;
};

export const hasValueGreaterThanThreshold = (
  map: Map<string, number>,
  threshold: number,
): boolean => {
  let hasValueGreaterThanThreshold = false;

  map.forEach((value) => {
    if (value > threshold) {
      hasValueGreaterThanThreshold = true;
      return; // Exit the loop if a value greater than the threshold is found
    }
  });

  return hasValueGreaterThanThreshold;
};

export function sumPricesAndQuantities(
  record: Record<string, PriceAndQuantity>,
): {
  totalPrice: number;
  totalQuantity: number;
  totalFees: number;
  priceToPay: number;
} {
  let totalPrice = 0;
  let totalQuantity = 0;
  let totalFees = 0;
  let priceToPay = 0;

  for (const key in record) {
    if (record.hasOwnProperty(key)) {
      const { price, quantity, fees } = record[key];
      totalPrice += price;
      totalQuantity += quantity;
      totalFees += fees;
    }
  }

  totalFees = Math.ceil(totalFees);
  priceToPay = totalFees + totalPrice;
  return { totalPrice, totalQuantity, totalFees, priceToPay };
}

export function calculateFees(event: IEvent, price: number, quantity: number) {
  // If price is 0, no fees
  if (price === 0) {
    return 0;
  }
  // const buyerCommission = event.buyerCommission || 0; // Set buyer commission to 0 temporarily
  const buyerCommission = 0; // Set buyer commission to 0 temporarily
  const serviceFee = event.serviceFee || 5;
  const fees = price * quantity * buyerCommission + serviceFee * quantity;
  return Math.ceil(fees);
}
