import { Button, StackItem, Text, VStack } from "@chakra-ui/react";
import React from "react";

interface SectionsPurchaseSidebarProps {
  sectionPrice: number;
  navigateToPurchase: () => void;
  numSeatsSelected: number;
  maxNumSeatsToPurchase: number;
}

const SectionsPurchaseSidebar: React.FC<SectionsPurchaseSidebarProps> = (
  props,
) => {
  // Create an array of 50 dummy grid items
  let totalTicketCost = props.numSeatsSelected * props.sectionPrice;

  return (
    <VStack maxW="xs" borderWidth="1px" borderRadius="lg" p={4} flex={1}>
      <StackItem gap={4} minHeight="100px" overflowY="auto" flex={1} w="100%">
        <Text>Number of tickets being bought: {props.numSeatsSelected} </Text>
        <Text>Total cost of tickets: {totalTicketCost} </Text>
      </StackItem>
      <Button
        mt={4}
        colorScheme="blue"
        h="10%"
        onClick={props.navigateToPurchase}
      >
        Purchase Ticket(s)
      </Button>
    </VStack>
  );
};

export default SectionsPurchaseSidebar;
