import { atom } from "recoil";

import {
  CreateEventFormSteps,
  EventSeatTypes,
  IDescribeDiagramFormatForm,
  IEvent,
  IEventDetails1Form,
  IEventDetails2Form,
  ISelectSeatingTypeForm,
  IAddBankDetailsForm,
  IObjType,
  ISeatIdMap,
  CognitoUser,
  SeatGroupingData,
  Section,
  IAddTicketGroupingData,
  DashboardNumbers,
  IPaymentObject,
  IBackendUser,
} from "../types/interface";
import { localPersistEffect } from "./atom-effect";
import { cardInfoProps } from "../components/BuyTicket/ViaCard";

const dangerouslyAllowMutability =
  process.env.REACT_APP_STAGE === "prod" ? false : true;

export const textState = atom<string>({
  key: "textState",
  default: "",
});

export const isAuthenticatedAtom = atom<boolean>({
  key: "isAuthenticatedAtom",
  default: false,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const submitEventAtom = atom<boolean>({
  key: "submitEventAtom",
  default: false,
});

export const userAccessToken = atom<string>({
  key: "userAccessToken",
  default: "",
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const awsUserDetailsAtom = atom<IObjType | CognitoUser>({
  key: "awsUserDetailsAtom",
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const backendUserDetailsAtom = atom<IBackendUser>({
  key: "backendUserDetailsAtom",
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const EventDetails1FormAtom = atom<IEventDetails1Form>({
  key: "EventDetails1FormAtom",
  default: {
    title: "",
    description: "",
    imgBanner: null,
  },
});

export const DescribeDiagramFormatFormAtom = atom<IDescribeDiagramFormatForm>({
  key: "DescribeDiagramFormatFormAtom",
  default: {
    hasSections: false,
    hasRows: false,
    hasTables: false,
    hasAssignedSeats: false,
  },
});

export const EventDetails2FormAtom = atom<IEventDetails2Form>({
  key: "EventDetails2FormAtom",
  default: {
    locationName: "",
    eventStart: Date(),
    eventEnd: Date(),
    categoryId: "",
    isPrivate: false,
    meetingCode: "",
    perTicketUserDataReason: "",
    perTicketUserData: [],
    isFree: false,
  },
});

export const isDataToastActiveAtom = atom<boolean>({
  key: "isDataToastActiveAtom",
  default: false,
});

export const SelectSeatingTypeAtom = atom<ISelectSeatingTypeForm>({
  key: "SelectSeatingTypeAtom",
  default: {
    isFree: false,
    ticketingType: EventSeatTypes.STANDARD_BASED,
    seatingPlanImg: "",
  },
});

export const AddBankDetailsFormAtom = atom<IAddBankDetailsForm | null>({
  key: "AddBankDetailsFormAtom",
  default: null,
});

export const AddSeatsGroupingFormAtom = atom<Record<
  string,
  SeatGroupingData
> | null>({
  key: "AddSeatsGroupingFormAtom",
  default: null,
});

export const AddSeatingChartFormAtom = atom<Record<string, Section>>({
  key: "AddSeatingChartFormAtom",
  default: {},
});

export const formStepAtom = atom<CreateEventFormSteps>({
  key: "formStepAtom",
  default: CreateEventFormSteps.EVENT_DETAILS_1,
});

export const prevCreateEventFormStepAtom = atom<CreateEventFormSteps>({
  key: "prevCreateEventFormStepAtom",
  default: CreateEventFormSteps.EVENT_DETAILS_1,
});

export const eventCreationPathAtom = atom<CreateEventFormSteps>({
  key: "eventCreationPathAtom",
  default: CreateEventFormSteps.ADD_SEATS_GROUPING_FORM,
});

export const ticketStepAtom = atom<number>({
  key: "ticketStepAtom",
  default: 1,
});

export const currentEventAtom = atom<IEvent | null>({
  key: "currentEventAtom",
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const incompleteEventAtom = atom<IEvent | null>({
  key: "incompleteEventAtom",
  default: null,
  dangerouslyAllowMutability,
});

export const selectedDiagramSeatsMapAtom = atom<ISeatIdMap>({
  key: "selectedDiagramSeatsMapAtom",
  default: {},
});

export const numSeatsInSectionToBuyAtom = atom<Record<string, number>>({
  key: "numSeatsInSectionToBuyAtom",
  default: {},
});

export const seatsSoldAtom = atom<DashboardNumbers | null>({
  key: "seatsSold",
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const formProgressAtom = atom<number>({
  key: "formProgressAtom",
  default: 25,
});

export const eventIdState = atom<string>({
  key: "eventIdAtom",
  default: "",
});

export const seatsState = atom<{ [key: string]: string | number }>({
  key: "seatsAtom",
  default: {},
});

export const mobileMoneyNumberState = atom<string>({
  key: "momoNumberAtom",
  default: "",
});

export const visaCardDetailsState = atom<cardInfoProps>({
  key: "cardDetailsAtom",
  default: {
    cardNumber: "",
    cardName: "",
    cardCVV: "",
    cardMonth: "",
    cardYear: "",
  },
});

export const seeMorePageState = atom<boolean>({
  key: "seeMorePageState",
  default: false,
});

export const searchQueryState = atom<string>({
  key: "searchQueryState",
  default: "",
});

export const searchResultsState = atom({
  key: "searchResultsState",
  default: [],
});

export const selectedEventState = atom<IEvent | null>({
  key: "selectedEventState",
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const formikValuesAtom = atom({
  key: "formikValuesAtom",
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const totalAmountAtom = atom({
  key: "totalAmountAtom",
  default: 0,
});

export const privateAuthState = atom({
  key: "privateRouteState",
  default: {},
});

export const buyerEmailAtom = atom({
  key: "buyerEmailAtom",
  default: "",
});

export const pdfBuffersAtom = atom<Uint8Array | null>({
  key: "pdfBuffersAtom",
  default: null,
});

export const formDataAtom = atom<IAddTicketGroupingData>({
  key: "formDataAtom",
  default: {
    id: 1,
    name: "",
    availableQuantity: "",
    price: "",
  },
});

export const allAddTicketsTypeDataAtom = atom<IAddTicketGroupingData[]>({
  key: "allAddTicketsTypeDataAtom",
  default: [],
});

export const paymentObjectAtom = atom<IPaymentObject>({
  key: "paymentObjectState",
  default: null,
  dangerouslyAllowMutability,
  effects_UNSTABLE: [localPersistEffect],
});

export const eventNameAtom = atom<string>({
  key: "eventNameAtom",
  default: "",
});

export const eventDescriptionAtom = atom<string>({
  key: "eventDescriptionAtom",
  default: "",
});

export const refreshAtomState = atom<string>({
  key: "refreshAtom",
  default: "",
});

export const imageAtomState = atom<string | null | File>({
  key: "imageAtom",
  default: "",
});

export const meetingCodeState = atom({
  key: "meetingCodeState",
  default: "",
});
