import { useState } from "react";

import { useMutation } from "react-query";
import { useRecoilValue } from "recoil";

import { userAccessToken } from "../recoil/atom";
import { IMutateData } from "../types/interface";
import { API_URL } from "../utils/constants";

const usePostData = () => {
  const [error, setError] = useState(null);
  const userToken = useRecoilValue(userAccessToken);

  const { mutate, isLoading, isSuccess, data, mutateAsync } = useMutation(
    async (inputData: IMutateData) => {
      //console.log("userToken-->",userToken);
      console.log(JSON.stringify(inputData.inputData));
      const response = await fetch(`${API_URL}${inputData.endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(inputData.inputData),
      });

      /*
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      */

      return response.json();
    },
    {
      onError: (err: Error) => {
        setError(err.message);
      },
    },
  );

  return Boolean(data)
    ? { mutate, error, isLoading, isSuccess, data, mutateAsync }
    : { mutate, error, isLoading, isSuccess, data: [], mutateAsync };
};

export default usePostData;
