import moment from "moment";
import { IEvent } from "../../../types/interface";
import { Box, Image, Text } from "@chakra-ui/react";

interface EventProps {
  event: IEvent;
  onClick: () => void;
}

const EventCard: React.FC<EventProps> = ({ event, onClick }) => {
  return (
    <Box
      key={event._id}
      className="grid grid-cols-2 items-center cursor-pointer w-full gap-4 p-2 rounded-lg md:shadow-none shadow-lg"
      onClick={onClick}
    >
      <Box className="flex md:flex-row flex-col gap-4">
        <Text className="text-lg font-bold">
          {moment(event.eventStart).format("MMM Do")}
        </Text>
        <Image
          src={event.imgBanner}
          alt="img"
          className="w-full h-36 md:h-72 object-cover rounded-md"
        />
      </Box>
      <Box className="text-left gap-2">
        <Text className="text-2xl font-bold">{event.title}</Text>
        <Text>{moment(event.eventStart).format("LLL")}</Text>
        <Text>{event.categoryId}</Text>
      </Box>
    </Box>
  );
};

export default EventCard;
