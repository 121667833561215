import { motion, useAnimation } from "framer-motion";

import {
  AspectRatio,
  Box,
  BoxProps,
  Container,
  forwardRef,
  Heading,
  Image,
  Input,
  Stack,
} from "@chakra-ui/react";
const PreviewImage = forwardRef<BoxProps, typeof Box>((props, ref) => {
  return (
    <Box
      bg="white"
      top="0"
      height="100%"
      width="100%"
      position="absolute"
      borderWidth="1px"
      borderStyle="solid"
      rounded="sm"
      borderColor="gray.400"
      as={motion.div}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundImage={`url("https://image.shutterstock.com/image-photo/paella-traditional-classic-spanish-seafood-600w-1662253543.jpg")`}
      {...props}
      ref={ref}
    />
  );
});

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  imageUploaded: string;
  isInvalid?: boolean;
  headingText: string;
}

const ImageDrop: React.FC<Props> = ({
  onChange,
  isInvalid,
  imageUploaded,
  headingText,
}): JSX.Element => {
  const controls = useAnimation();
  const startAnimation = () => controls.start("hover");
  const stopAnimation = () => controls.stop();
  return (
    <Container display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <AspectRatio width={"40"} ratio={1}>
        <Box
          borderColor={isInvalid ? "red.400" : "gray.300"}
          borderStyle="dashed"
          borderWidth="2px"
          rounded="md"
          shadow="sm"
          role="group"
          transition="all 150ms ease-in-out"
          _hover={{
            shadow: "md",
          }}
          as={motion.div}
          initial="rest"
          animate="rest"
          whileHover="hover"
        >
          {!Boolean(imageUploaded) ? (
            <Box position="relative" height="100%" width="100%">
              <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                width="100%"
                display="flex"
                flexDirection="column"
              >
                <Stack
                  height="100%"
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justify="center"
                  spacing="4"
                >
                  <Box height="16" width="12" position="relative">
                    <PreviewImage
                      //variants={first}
                      backgroundImage="url('https://image.shutterstock.com/image-photo/paella-traditional-classic-spanish-seafood-600w-1662253543.jpg')"
                    />
                    <PreviewImage
                      //variants={second}
                      backgroundImage="url('https://images.unsplash.com/photo-1565299585323-38d6b0865b47?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2628&q=80')"
                    />
                    <PreviewImage
                      //variants={...third}
                      backgroundImage={`url("https://images.unsplash.com/photo-1563612116625-3012372fccce?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2480&q=80")`}
                    />
                  </Box>
                  <Stack p="2" textAlign="center" spacing="1">
                    <Heading
                      fontSize="sm"
                      color="gray.300"
                      fontWeight="semibold"
                    >
                     {headingText}
                    </Heading>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          ) : (
            <Image
              src={imageUploaded}
              objectFit={"cover"}
              width={"98%"}
              height={"98%"}
              alt="Cover Art"
            />
          )}
          <Input
            type="file"
            height="100%"
            width="100%"
            position="absolute"
            top="0"
            left="0"
            opacity="0"
            aria-hidden="true"
            accept="image/*"
            onDragEnter={startAnimation}
            onDragLeave={stopAnimation}
            onChange={(e) => onChange(e)}
          />
        </Box>
      </AspectRatio>
    </Container>
  );
};

export default ImageDrop;
