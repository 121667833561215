import { ChakraProvider, LightMode } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./output.css";

import "@fontsource/raleway/400.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";

import "react-international-phone/style.css";

import reportWebVitals from "./reportWebVitals";
import GlobalErrorHandler from "./components/Errors/GlobalErrorHandler";
import { theme } from "../src/theme";

import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <GlobalErrorHandler>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            {/*<LightMode>*/}
            <App />
            {/*</LightMode>*/}
          </ChakraProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </RecoilRoot>
    </GlobalErrorHandler>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
