import { CognitoUserPool } from "amazon-cognito-identity-js";

export const poolData = {
  UserPoolId: "us-east-1_spapTUiQA",
  ClientId: "4f868k9j14hkmr3hetocp2bi3m",
};

const cognitoUser = new CognitoUserPool(poolData);

export default cognitoUser;
