import { Box, HStack, VStack } from "@chakra-ui/react";

import Skeletons from "../../Loaders";

const LoadingElement = () => {
  return (
    <>
      <HStack width={"full"}>
        <Skeletons
          count={1}
          minWidth={"94px"}
          minHeight={"74px"}
          width={"94px"}
          height={"74px"}
          borderRadius={"8px"}
        />
        <VStack spacing={2} width={"full"} align={"start"}>
          <Skeletons count={1} height={"28px"} borderRadius={"8px"} />
          <Skeletons
            count={1}
            height={"24px"}
            width={"77%"}
            borderRadius={"8px"}
          />
        </VStack>
      </HStack>
      <Box h={"20px"} w={"full"} />
    </>
  );
};

interface Params {
  count: number;
}
const Loading: React.FC<Params> = ({ count = 6 }): JSX.Element => {
  return (
    <>
      {Array(count)
        .fill(null)
        .map((n, i) => (
          <LoadingElement key={i + 1} />
        ))}
    </>
  );
};

export default Loading;
