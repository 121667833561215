import {
  Box,
  Text,
  Image,
  Button,
  Link,
  useColorMode,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import confetti from "../assets/img/confetti.png";
import { useParams } from "react-router-dom";
import Google from "../components/Google";
import AppStore from "../components/AppStore";
import useGetData from "../hooks/useGetData";
import { IEvent } from "../types/interface";

const SuccessEvent = () => {
  const { event_id } = useParams();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { data, isLoading } = useGetData(
    [`event_${event_id}`],
    `/event/${event_id}`,
  );
  const event: IEvent = data;
  const handleCopyLink = () => {
    const eventLink = `https://mushatickets.com/event/${event_id}/details`;
    navigator.clipboard
      .writeText(eventLink)
      .then(() => {
        toast({
          title: "Link copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Failed to copy link",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  return (
    <Box className=" flex flex-col w-full text-center items-center gap-2 py-8 justify-center px-4 md:px-10">
      <Text
        className={`text-2xl text-blue-900 font-extrabold ${
          colorMode === "dark" ? "text-blue-300" : "text-blue-900"
        }`}
      >
        Congrats!
      </Text>
      <Image
        src={confetti}
        alt="confetti"
        className=" w-36 h-36 object-cover"
      />
      <Text
        className={`text-xl font-semibold ${
          colorMode === "dark" ? "text-blue-300" : "text-blue-900"
        }`}
      >
        You have successfully created your event
      </Text>
      <Box
        className={` ${
          colorMode === "dark" ? "text-gray-200" : "text-gray-500"
        }`}
      >
        <Text>Your event will be uploaded within 72hrs</Text>
        <Text>You will receive a confirmation email</Text>
      </Box>
      <Box>
        {isLoading ? (
          <Spinner size={"sm"} />
        ) : (
          <Text>Validator Passphrase: {event.validatorPassphrase}</Text>
        )}
      </Box>
      <Button className=" w-fit" onClick={handleCopyLink}>
        Copy Link
      </Button>
      <Link
        maxW="95%" // Set maximum width to prevent overflow
        whiteSpace="normal" // Allow text to wrap around
        href={`https://mushatickets.com/event/${event_id}/details`}
        className={`underline ${
          colorMode === "dark" ? "text-gray-200" : "text-gray-700"
        }`}
      >
        https://mushatickets.com/event/{event_id}/details
      </Link>
      <Box className=" text-gray-500">
        <Text>
          Your link to share with others will be active once your event is
          uploaded
        </Text>
        <Text>Your will receive a confirmation notification</Text>
      </Box>
      <Text className=" font-bold text-xl mt-2">Ticket Scanning</Text>
      <Text className=" max-w-3xl text-sm">
        You can access the ticket validator by downloading the "MUSHA TICKETS
        SCANNER" application on either Play Store or App Store and log into your
        Account
      </Text>
      <Box className="flex gap-4 items-center mt-2">
        <Google /> <AppStore />
      </Box>
    </Box>
  );
};

export default SuccessEvent;
