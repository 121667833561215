import React, { useEffect, useState } from "react";

import GooglePlacesAutocomplete from "chakra-ui-google-places-autocomplete";
import { useRecoilState } from "recoil";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Fade,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
  useBoolean,
  Text,
} from "@chakra-ui/react";

import { useFormik } from "formik";
import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import { useQuery } from "react-query";
import { getEventCategories } from "../../../api/event";
import {
  formProgressAtom,
  formStepAtom,
  incompleteEventAtom,
  prevCreateEventFormStepAtom,
} from "../../../recoil/atom";
import { getBooleanString } from "../../../utils/constants";
import { form2Schema } from "../../../utils/validation";
import DateTimePicker from "../../DateTimePicker";
import {
  CreateEventFormSteps,
  IEvent,
  PerTicketUserDataTypes,
} from "../../../types/interface";
import { apiClient } from "../../../api";
import { makeToast } from "../../../utils/toast";

const EventDetails2Form: React.FC = (): JSX.Element => {
  const [googleLocaction, setGoogleLocation] = useState(null);

  const [formStep, setFormStep] = useRecoilState(formStepAtom);

  const [incompleteEvent, setIncompleteEvent] =
    useRecoilState(incompleteEventAtom);
  const [formProgress, setFormProgress] = useRecoilState(formProgressAtom);
  const [prevCreateEventFormStep, setPrevCreateEventFormStep] = useRecoilState(
    prevCreateEventFormStepAtom,
  );

  console.log("This is incomplete event: ", incompleteEvent);

  const handleCheckboxChange = (values: string[]) => {
    // setSelectedUserTypes(values);
    formik.setFieldValue("perTicketUserData", values);
  };

  const categoryQuery = useQuery({
    queryFn: getEventCategories,
    queryKey: ["category"],
  });

  useEffect(() => {
    setFormProgress(20);
    setPrevCreateEventFormStep(CreateEventFormSteps.EVENT_DETAILS_1);
  }, [setFormProgress, setPrevCreateEventFormStep]);
  const formik = useFormik({
    initialValues: {
      location: incompleteEvent?.locationName ?? "",
      startDate: incompleteEvent?.eventStart
        ? new Date(incompleteEvent.eventStart)
        : null,
      endDate: incompleteEvent?.eventEnd
        ? new Date(incompleteEvent.eventEnd)
        : null,
      categoryId: incompleteEvent?.categoryId,
      isPrivate: incompleteEvent?.isPrivate,
      isFree: incompleteEvent?.isFree,
      meetingCode: incompleteEvent?.meetingCode,
      perTicketUserDataReason: incompleteEvent?.perTicketUserDataReason,
      perTicketUserData: incompleteEvent?.perTicketUserData,
      collectsUserData: incompleteEvent?.perTicketUserData
        ? incompleteEvent?.perTicketUserData.length !== 0
        : false,
    },
    enableReinitialize: true,
    validationSchema: form2Schema,
    onSubmit: async (values) => {
      const eventUpdates: IEvent = incompleteEvent;
      console.log("This is eventUpdates: ", eventUpdates);
      eventUpdates.locationName = values.location;
      eventUpdates.eventStart = values.startDate;
      eventUpdates.eventEnd = values.endDate;
      eventUpdates.categoryId = values.categoryId;
      eventUpdates.isPrivate = values.isPrivate;
      eventUpdates.meetingCode = values.meetingCode;
      eventUpdates.perTicketUserDataReason = values.perTicketUserDataReason;
      eventUpdates.perTicketUserData = values.perTicketUserData;
      eventUpdates.isFree = values.isFree;

      try {
        console.log("Submitting form 2...", incompleteEvent);
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await apiClient.put(
          `/event/${incompleteEvent._id}`,
          eventUpdates,
          {
            headers,
          },
        );
        console.log("This is response form2: ", response);
        setIncompleteEvent(response.data.event as IEvent);
        setFormStep(CreateEventFormSteps.SELECT_SEATING_TYPE);
      } catch (err) {
        makeToast(
          "error",
          "Error",
          "Could not update event. Please try again later",
        );
      }
    },
  });

  const handleLocationChange = (value) => {
    setGoogleLocation(value);
    console.log("Setting location: ", value);
    formik.setFieldValue("location", value?.value.description);
  };

  const handleIsPrivateChange = (value) => {
    const flag = getBooleanString(value);
    formik.setFieldValue("isPrivate", flag);
  };

  const handleCollectsUserData = (value) => {
    const flag = getBooleanString(value);
    formik.setFieldValue("collectsUserData", flag);
  };

  const handleIsFreeChange = (value) => {
    const flag = getBooleanString(value);
    console.log("Handle is free called: ", flag);
    formik.setFieldValue("isFree", flag);
  };

  return (
    <Box as="form" onSubmit={formik.handleSubmit}>
      <Heading
        fontSize={"lg"}
        fontWeight="bold"
        color="primary.500"
        my={6}
        textAlign={"center"}
      >
        Event Location and Date
      </Heading>
      <FormControl as={GridItem} colSpan={6} isInvalid={true}>
        <FormLabel
          htmlFor="street_address"
          fontWeight="normal"
          fontSize="sm"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Location of Event
        </FormLabel>

        <GooglePlacesAutocomplete
          selectProps={{
            noOptionsMessage: () => null,
            isClearable: true,
            isSearchable: true,
            defaultInputValue: formik.values.location,
            value: googleLocaction,
            onChange: handleLocationChange,
            placeholder: "Lusaka west",
          }}
          apiKey={process.env.REACT_APP_GOOGLE_LOCATION_API_KEY || ""}
          autocompletionRequest={{
            componentRestrictions: {
              country: ["zm"],
            },
          }}
          apiOptions={{ region: "zm" }}
        />

        <FormErrorMessage>{formik.errors.location}</FormErrorMessage>
      </FormControl>

      <Stack className=" w-full" spacing={4} my={4}>
        <FormControl
          isInvalid={
            Boolean(formik.touched.startDate) &&
            Boolean(formik.errors.startDate)
          }
        >
          <FormLabel
            htmlFor="start-date-time"
            fontSize="sm"
            fontWeight="normal"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
          >
            Start Date and Time
          </FormLabel>
          <DateTimePicker
            selectedDate={formik.values.startDate}
            onChange={(date: Date) => formik.setFieldValue("startDate", date)}
          />

          <FormErrorMessage>
            {formik.errors.startDate as string}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={
            Boolean(formik.touched.endDate) && Boolean(formik.errors.endDate)
          }
        >
          <FormLabel
            htmlFor="end-date-time"
            fontSize="sm"
            fontWeight="normal"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
          >
            End Date and Time
          </FormLabel>
          <DateTimePicker
            selectedDate={formik.values.endDate}
            onChange={(date: Date) => formik.setFieldValue("endDate", date)}
          />

          <FormErrorMessage>{formik.errors.endDate as string}</FormErrorMessage>
        </FormControl>
      </Stack>
      <Select
        isInvalid={
          Boolean(formik.touched.categoryId) &&
          Boolean(formik.errors.categoryId)
        }
        disabled={categoryQuery.isLoading}
        my={"4"}
        placeholder="Select Category"
        {...formik.getFieldProps("categoryId")}
        value={formik.values.categoryId ?? ""}
      >
        {categoryQuery.isSuccess &&
          categoryQuery.data?.map(({ _id, name }) => {
            return (
              <option key={_id} value={_id}>
                {name}
              </option>
            );
          })}

        <FormErrorMessage>{formik.errors.categoryId}</FormErrorMessage>
      </Select>

      <FormControl as="fieldset" my={"4"}>
        <FormLabel
          as="legend"
          htmlFor="postal_code"
          fontWeight="md"
          fontSize="sm"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Is your event private? (Private events will only be accessible through
          a passcode you provide. The general public will not be able to see
          your event)
        </FormLabel>
        <RadioGroup
          id="isPrivate"
          defaultValue={incompleteEvent?.isPrivate?.toString() ?? "false"}
          onChange={handleIsPrivateChange}
        >
          <Stack spacing={4} direction="row">
            <Radio value={"false"}>No</Radio>
            <Radio value={"true"}>Yes</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <Fade in={formik.values.isPrivate} unmountOnExit={true}>
        <FormControl
          isInvalid={
            Boolean(formik.touched.meetingCode) &&
            Boolean(formik.errors.meetingCode)
          }
        >
          <FormLabel htmlFor="pass-code" fontSize={"xs"} fontWeight={"normal"}>
            Create a passcode
          </FormLabel>
          <Input
            fontSize={"sm"}
            placeholder="My event passcode"
            {...formik.getFieldProps("meetingCode")}
          />

          <FormErrorMessage>{formik.errors.meetingCode}</FormErrorMessage>
        </FormControl>
      </Fade>

      <FormControl as="fieldset" my={"4"}>
        <FormLabel
          as="legend"
          htmlFor="freeEvent"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Is event free?
        </FormLabel>
        <RadioGroup
          defaultValue={incompleteEvent?.isFree?.toString() ?? "false"}
          id="isFree"
          onChange={handleIsFreeChange}
        >
          <Stack spacing={4} direction="row">
            <Radio value={"false"}>No</Radio>
            <Radio value={"true"}>Yes</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl as="fieldset" my={"4"}>
        <FormLabel
          as="legend"
          htmlFor="postal_code"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Do you need user data to be collected for each ticket?
        </FormLabel>
        <RadioGroup
          id="collectsUserData"
          defaultValue={(incompleteEvent?.perTicketUserData
            ? incompleteEvent?.perTicketUserData.length !== 0
            : "false"
          ).toString()}
          onChange={handleCollectsUserData}
        >
          <Stack spacing={4} direction="row">
            <Radio value={"false"}>No</Radio>
            <Radio value={"true"}>Yes</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <Fade in={formik.values.collectsUserData} unmountOnExit={true}>
        <FormControl
        // isInvalid={
        // Boolean(formik.touched.meetingCode) &&
        // Boolean(formik.errors.meetingCode)
        // }
        >
          <CheckboxGroup
            value={formik.values.perTicketUserData}
            onChange={handleCheckboxChange}
          >
            <Stack direction="column" spacing={4}>
              <FormLabel
                as="legend"
                htmlFor="postal_code"
                fontSize="sm"
                fontWeight="md"
                color="gray.700"
                _dark={{
                  color: "gray.50",
                }}
              >
                Mark which type of data you need to collect per ticket
              </FormLabel>
              <SimpleGrid columns={{ base: 1 }} spacing={2}>
                <Checkbox value={PerTicketUserDataTypes.FULL_NAME}>
                  <Text fontSize={"sm"}>Full Name</Text>
                </Checkbox>
                <Checkbox value={PerTicketUserDataTypes.EMAIL}>
                  <Text fontSize={"sm"}>Email Address</Text>
                </Checkbox>
                <Checkbox value={PerTicketUserDataTypes.GENDER}>
                  <Text fontSize={"sm"}>Gender</Text>
                </Checkbox>
                <Checkbox value={PerTicketUserDataTypes.PHONE_NUMBER}>
                  <Text fontSize={"sm"}>Phone Number </Text>
                </Checkbox>
              </SimpleGrid>
            </Stack>
          </CheckboxGroup>
        </FormControl>
        <FormControl
          my={4}
          isInvalid={
            Boolean(formik.touched.perTicketUserDataReason) &&
            Boolean(formik.errors.perTicketUserDataReason)
          }
        >
          <FormLabel
            htmlFor="perTicketUserDataReason"
            fontWeight={"normal"}
            fontSize={"sm"}
          >
            Explain why collecting this data is necessary for your event
          </FormLabel>
          <Textarea
            id="perTicketUserDataReason"
            placeholder="Enter your justification..."
            {...formik.getFieldProps("perTicketUserDataReason")}
            fontSize={"0.8rem"}
          />
          {formik.errors.perTicketUserDataReason && (
            <FormErrorMessage>
              {formik.errors.perTicketUserDataReason}
            </FormErrorMessage>
          )}
        </FormControl>
      </Fade>

      <HStack justifyContent={"center"} gap={4} mt={10}>
        <Button
          type="button"
          onClick={() => setFormStep(prevCreateEventFormStep)}
          variant="outline"
        >
          <Icon as={MdOutlineArrowBack} />
        </Button>

        <Button
          onClick={() => {
            console.log("Forward button pressed");
            formik.submitForm();
          }}
          variant="solid"
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting}
        >
          <Icon as={MdOutlineArrowForward} />
        </Button>
      </HStack>
    </Box>
  );
};

export default EventDetails2Form;
