import {
  Box,
  useToast,
  Text,
  Flex,
  Popover,
  PopoverTrigger,
  IconButton,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
} from "@chakra-ui/react";
import { selectedEventState } from "../../recoil/atom";
import { useRecoilValue } from "recoil";
import usePostData from "../../hooks/usePostData";
import React, { useEffect, useState } from "react";
import { ITicket } from "../../types/interface";
import Loading from "../EventComponent/Event/Loading";
import { BsThreeDotsVertical, BsTrashFill, BsTrash } from "react-icons/bs";

const DataCard = ({ data, index }) => {
  return (
    <Box key={index} fontFamily={"sans-serif"}>
      {Object.entries(data).map(([key, value], index, array) => (
        <Flex
          key={index}
          className={`mb-2${
            index !== array.length - 1 ? " pb-2 border-b" : ""
          }`}
        >
          <Text className="font-bold w-1/2 mr-4">{key}</Text>
          <Text className="w-1/2 flex items-start">{value as string}</Text>
        </Flex>
      ))}
    </Box>
  );
};

const Attendees = () => {
  const event = useRecoilValue(selectedEventState);
  const [loading, setLoading] = useState<boolean>(false);
  const [seats, setSeats] = useState<ITicket[]>([]);
  const { mutateAsync, error } = usePostData();
  const toast = useToast();

  useEffect(() => {
    const AttendeesList = async () => {
      try {
        setLoading(true);
        const endpoint = `/seat/fetchEventSeats/${event?._id}`;
        const response = await mutateAsync({
          endpoint,
          inputData: {
            validatorPassphrase: event?.validatorPassphrase as string,
            boughtStatus: "PURCHASED",
          },
        });
        if (response) {
          setSeats(response);
          console.log(response);
        } else {
          toast({
            title: "Error",
            description: `Error getting attendees`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error getting scanned seats:", error);
        toast({
          title: "Error",
          description: `Error getting attendees`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
    AttendeesList();
  }, [event?._id, event?.validatorPassphrase, mutateAsync, toast]);

  if (loading) {
    return <Loading count={1} />;
  }

  if (error) {
    return (
      <Text className="font-bold text-center text-lg my-12">
        Error getting attendees
      </Text>
    );
  }

  let hasValidData: boolean = false;
  return (
    <Box className="flex flex-col items-center justify-center min-h-[40vh] w-full">
      {seats.length > 0 && seats !== null ? (
        <Box className="flex flex-col gap-4 items-center justify-center w-full">
          {seats.map((item, i) => {
            if (
              item.seatHolderData?.fullName !== undefined &&
              item.seatHolderData?.email !== undefined
            ) {
              hasValidData = true;
              const dataValues = {};
              if (item?.seatHolderData?.fullName) {
                dataValues["Name "] = item?.seatHolderData?.fullName;
              }
              if (item?.seatHolderData?.email) {
                dataValues["Email "] = item?.seatHolderData?.email;
              }
              if (item?.seatHolderData?.phoneNumber) {
                dataValues["Phone Number "] = item?.seatHolderData?.phoneNumber;
              }
              return (
                <Box
                  width={"100%"}
                  maxW={"560px"}
                  minH={"60px"}
                  className="border p-4 rounded shadow-md mb-4"
                >
                  <DataCard data={dataValues} index={i} />
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        aria-label="menu"
                        icon={<BsTrash />}
                        variant={"ghost"}
                        isRound
                      />
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent className="w-fit">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody className="w-fit flex flex-col mt-6">
                          <Button
                            variant={"ghost"}
                            onClick={
                              () => 2
                              // setFormDataToSpecifiedGroupAndOpenModal(item.id)
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant={"ghost"}
                            onClick={
                              () => 2
                              // handleDeleteTicketTypeFormData(item.id)
                            }
                          >
                            Delete
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Box>
              );
            }
            return null;
          })}
        </Box>
      ) : (
        ""
      )}
      {!hasValidData && (
        <Text className="font-bold text-center text-lg">
          No Attendees Data to show
        </Text>
      )}
    </Box>
  );
};

export default Attendees;
