import { Box, Image, Text } from "@chakra-ui/react";
import { IEvent } from "../../types/interface";

interface OrderSummaryProps {
  totalPrice: number;
  totalQuantity: number;
  totalFees: number;
  priceToPay: number;
  event: IEvent;
}

const CheckoutOrderSummary: React.FC<OrderSummaryProps> = ({
  totalPrice,
  totalQuantity,
  totalFees,
  priceToPay,
  event,
}) => {
  return (
    <Box className="lg:block hidden">
      <Box>
        <Box className="w-full h-32 flex items-center justify-center">
          <Image
            src={event.imgBanner}
            loading="lazy"
            alt={event.title}
            className="w-full object-center h-full rounded-md object-cover"
          />
        </Box>
        <Box className="gap-2 mt-4 grid">
          <Text className="text-lg font-semibold">{event.title}</Text>
          <Text className="text-gray-500">{event.locationName}</Text>
          <Box className="gap-2 grid pt-4">
            <Box className="grid grid-cols-3 gap-6">
              <Text className="col-span-2 font-bold">Ticket Quantity </Text>
              <Text>x {totalQuantity}</Text>
            </Box>
            <Box className="grid grid-cols-3 gap-6">
              <Text className="col-span-2 font-bold">SubTotal </Text>
              <Text className="font-bold">K {totalPrice.toFixed(2)}</Text>
            </Box>
            <Box className="grid grid-cols-3 gap-6">
              <Text className="col-span-2 font-bold">Service fee</Text>
              <Text className="font-bold">K {totalFees.toFixed(2)}</Text>
            </Box>
            <Box className="grid grid-cols-3 gap-6">
              <Text className="col-span-2 font-bold">Total </Text>
              <Text className="font-bold">K {priceToPay.toFixed(2)}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutOrderSummary;
