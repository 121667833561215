import {
  Box,
  Tabs,
  TabPanels,
  TabList,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import MyEventDashBoard from "./MyEventDashBoard";
import EditEvent from "./EditEvent";
import Attendees from "./Attendees";

const MyEventDetails = () => {
  return (
    <Box className=" w-full flex-col flex items-center justify-center px-4 md:px-10">
      <Tabs
        position="relative"
        variant="unstyled"
        className=" w-full justify-center items-center flex flex-col pt-12 pb-8"
      >
        <Box className=" w-fit">
          <TabList>
            <Tab>Dashboard</Tab>
            <Tab>Edit event</Tab>
            <Tab>Attendees</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="orange.500"
            borderRadius="1px"
          />
        </Box>

        <TabPanels>
          <TabPanel>
            <MyEventDashBoard />
          </TabPanel>
          <TabPanel>
            <EditEvent />
          </TabPanel>
          <TabPanel>
            <Attendees/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default MyEventDetails;
