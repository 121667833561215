import {
  Box,
  Text,
  Tabs,
  TabPanels,
  TabList,
  Tab,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import UpcomingEvents from "../../components/Ticket/MyTickets/UpcomingEvents";
import PastEvents from "../../components/Ticket/MyTickets/PastEvents";

const Bought = () => {
  return (
    <Box className=" flex flex-col items-center justify-center w-full">
      <Text className=" font-semibold text-lg py-4">My Tickets</Text>

      <Tabs
        position="relative"
        variant="unstyled"
        className=" w-full justify-center items-center flex flex-col"
      >
        {/* Tab Switch bro */}
        <Box className=" w-fit">
          <TabList>
            <Tab>Upcoming Events</Tab>
            <Tab>Past Events</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="orange.500"
            borderRadius="1px"
          />
        </Box>

        <TabPanels>
          {/* Upcoming */}
          <TabPanel>
            <UpcomingEvents />
          </TabPanel>
          {/* Past events */}
          <TabPanel>
            <PastEvents />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Bought;
