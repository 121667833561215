import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import { API_URL } from "../utils/constants";
import { refreshAccessToken } from "../hooks/refreshAccessToken";

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("userAccessToken");
    if (accessToken) {
      if (config.headers)
        config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config._isRetry
    ) {
      console.log("Token expired, refreshing...");
      try {
        const refreshRes = await refreshAccessToken();
        console.log("This is result from refresh: ", refreshRes);
        if (!refreshRes) {
          throw new Error("Failed to refresh token.");
        }
        console.log("This is config for resending: ", error.config);
        const newConfig: AxiosRequestConfig = {
          ...error.config,
          _isRetry: true, // Mark the request as retried to prevent infinite loop
        };
        const response = await apiClient(newConfig);
        console.log("This is new response: ", response);
        return response;
        return console.log("Access token refreshed successfully");
      } catch (refreshError) {
        console.error("Failed to refresh access token:", refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

export { apiClient };
