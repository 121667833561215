import moment from "moment";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Heading,
  HStack,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";

import { IEvent } from "../../../types/interface";
import { truncateString } from "../../../utils/functions";

interface Props {
  eventCard: IEvent;
}

const EventCard: React.FC<Props> = (props): JSX.Element => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  const cardHeight = "120px";

  return (
    <HStack
      className={` cursor-pointer border ${
        colorMode === "dark" ? "border-gray-700" : "border-gray-300"
      } shadow-sm rounded-md overflow-hidden`}
      // width={{ base: "full", md: "100%" }}
      width={"100%"}
      maxW={"480px"}
      onClick={() => navigate(`/event/${props.eventCard._id}/details`)}
      my={"2"}
    >
      <Image
        //Making height == width to make container a square
        height={cardHeight}
        width={cardHeight}
        objectFit={"cover"}
        src={props.eventCard.imgBanner}
      />
      <VStack alignItems={"start"}>
        <Heading fontSize={"xs"} display={"inline-flex"}>
          <b>
            <p className={"text-pink-500 mr-2"}>
              {moment(props.eventCard.eventStart).format("MMMM Do")}
            </p>
          </b>{" "}
          {moment(props.eventCard.eventStart).format("dddd, HH:mm")}
        </Heading>
        <Text fontSize={"xs"} fontWeight={"bold"}>
          {truncateString(`${props.eventCard.title}`, 60)}
        </Text>
        <Text fontSize={"xs"}>
          {truncateString(`${props.eventCard.locationName}`, 30)}
        </Text>
      </VStack>
    </HStack>
  );
};

export default EventCard;
