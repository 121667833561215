import React from "react";
import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { ISeat, ISection } from "../../types/interface";
interface TooltipProps {
  seatObject?: ISeat;
  sectionObject?: ISection;
  position: { x: number; y: number };
  handleSeatClick?: (id: string, event: React.MouseEvent) => void;
}
export const Tooltip: React.FC<TooltipProps> = (props) => {
  const style: React.CSSProperties = {
    position: "fixed",
    zIndex: 999,
  };

  const availableSpaceNorth = props.position.y;
  const availableSpaceSouth = window.innerHeight - props.position.y;
  const availableSpaceWest = props.position.x;
  const availableSpaceEast = window.innerWidth - props.position.x;

  if (availableSpaceEast >= availableSpaceWest) {
    if (availableSpaceNorth >= availableSpaceSouth) {
      //Northeast
      style.bottom = window.innerHeight - props.position.y + 10;
      style.left = props.position.x + 10;
    } else {
      //Southeast
      style.top = props.position.y + 10;
      style.left = props.position.x + 10;
    }
  } else {
    if (availableSpaceNorth >= availableSpaceSouth) {
      //Northwest
      style.bottom = window.innerHeight - props.position.y + 10;
      style.right = window.innerWidth - props.position.x + 10;
    } else {
      //Southwest
      style.top = props.position.y + 10;
      style.right = window.innerWidth - props.position.x + 10;
    }
  }

  let tooltipToDisplay: React.ReactNode;
  if (props.seatObject !== undefined) {
    tooltipToDisplay = (
      <Box style={style} className="bg-white shadow-md text-black p-4">
        <Flex className="items-center justify-between md:gap-16 gap-12 border-b border-gray-200 w-full">
          <Box className="flex flex-col items-center">
            <Text className="text-gray-500 text-sm md:text-base">SEC</Text>
            <Text className="text-2xl font-bold">
              {props.seatObject?.section}
            </Text>
          </Box>
          <Box className="flex flex-col items-center">
            <Text className="text-gray-500 text-sm md:text-base">ROW</Text>
            <Text className="text-2xl font-bold">{props.seatObject?.row}</Text>
          </Box>
          <Box className="flex flex-col items-center">
            <Text className="text-gray-500 text-sm md:text-base">SEAT</Text>
            <Text className="text-2xl font-bold">
              {props.seatObject?.seatNumber}
            </Text>
          </Box>
        </Flex>
        <Flex className="justify-between text-gray-600 items-center py-2 text-sm md:text-base">
          <Text>Price:</Text>{" "}
          <Text className="font-bold">K{props.seatObject?.price}</Text>
        </Flex>
        <Flex className="border-t md:hidden border-gray-200 pt-2">
          <Button
            size={"sm"}
            rounded={"none"}
            colorScheme="blue"
            className="w-full"
            onClick={(event) => props.handleSeatClick && props.handleSeatClick(props.seatObject?._id, event)}
          >
            Add
          </Button>
        </Flex>
      </Box>
    );
  } else if (props.sectionObject !== undefined) {
    tooltipToDisplay = (
      <Box
        p={4}
        rounded="lg"
        boxShadow="md"
        bg="white"
        border="1px solid"
        borderColor="gray.200"
        style={style}
      >
        <Heading as="h4" size="md">
          Ticket Information
        </Heading>
        <Text>Section: {props.sectionObject?.name}</Text>
        <Text>Price: {props.sectionObject?.price}</Text>
      </Box>
    );
  }

  return <Box>{tooltipToDisplay}</Box>;
};
