import { useState } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";
import debounce from "lodash/debounce";
import { BiSearchAlt } from "react-icons/bi";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  VStack,
  Text,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { IEvent } from "../../types/interface";
import { API_URL } from "../../utils/constants";
import EventSearchedPage from "../EventComponent/Event/EventSearchedPage";
import { searchQueryState, searchResultsState } from "../../recoil/atom";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";

const PAGE_SIZE = 10;

const SearchPage: React.FC = (): JSX.Element => {
  const [isEmptyQuery, setIsEmptyQuery] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const debounceTime: number = 2500;

  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);
  const [searchResults, setSearchResults] = useRecoilState(searchResultsState);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchQuery(newValue);
    setCurrentPage(1);

    if (newValue.length > 0) {
      debouncedFetchData(newValue);
      setIsEmptyQuery(false);
    } else {
      setIsEmptyQuery(true);
      setSearchResults([]);
    }
  };

  const debouncedFetchData = debounce(async (inputValue: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/event/search?searchTerm=${inputValue}&pageNum=${currentPage}&pageSize=${PAGE_SIZE}`
      );
      const data = response.data;
      setSearchResults(data.events);
    } catch (error) {
      console.error(error);
    }
  }, debounceTime);

  const handleNextPage = () => {
    const totalPages = Math.ceil(searchResults.length / PAGE_SIZE);
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      window.scrollTo(0, 0);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    window.scrollTo(0, 0);
  };

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const totalPages = Math.ceil(searchResults.length / PAGE_SIZE);
  const isLastPage = currentPage === totalPages;

  return (
    <Stack
      pos={"relative"}
      w={"full"}
      className={`${
        !isEmptyQuery && searchResults.length > 0
          ? "justify-center"
          : "min-h-screen items-center"
      } w-full py-16 px-4 md:px-10`}
    >
      <Text className="my-4 text-xl text-center font-bold">
        Search for events
      </Text>
      <VStack w={"full"} justifyContent={"center"} alignItems={"center"}>
        <InputGroup w={{ base: "100%", md: "50%" }}>
          <Input
            onChange={handleInputChange}
            placeholder="Search for event"
            value={searchQuery}
          />
          <InputRightElement children={<BiSearchAlt color="green.500" />} />
        </InputGroup>
        {!isEmptyQuery && searchResults.length > 0 && (
          <Flex
            direction={"column"}
            width={{ base: "full", md: "50%" }}
            gap={4}
            className="min-h-screen"
            marginTop={8}
          >
            {/*<Box className="w-full grid md:w-1/2 gap-4 mt-8 min-h-screen">*/}
            {searchResults.slice(startIndex, endIndex).map((event: IEvent) => (
              <EventSearchedPage key={event._id} eventCard={event} />
            ))}
            <Box w={"full"} h={"15px"} />
          </Flex>
        )}
        {searchResults.length > PAGE_SIZE && (
          <Flex className=" items-center gap-4">
            {currentPage > 1 && (
              <IconButton
                aria-label="Previous"
                icon={<ArrowBackIcon />}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                isRound
              />
            )}
            <Text className="font-bold">{currentPage}</Text>
            {!isLastPage && (
              <IconButton
                aria-label="Next"
                icon={<ArrowForwardIcon />}
                onClick={handleNextPage}
                isRound
              >
                Next Page
              </IconButton>
            )}
          </Flex>
        )}
      </VStack>
    </Stack>
  );
};

export default SearchPage;
