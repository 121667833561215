import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function ConfirmPasswordResetForm() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [requestNewCodeSuccess, setRequestNewCodeSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  const handleConfirmPasswordReset = async () => {
    try {
      setIsLoading(true);

      if (newPassword !== confirmPassword) {
        setIsLoading(false);
        toast({
          title: "Password Mismatch",
          description: "Passwords do not match",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const userPool = new CognitoUserPool({
        UserPoolId: "us-east-1_spapTUiQA",
        ClientId: "4f868k9j14hkmr3hetocp2bi3m",
      });

      const userData = {
        Username: email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: () => {
          setIsLoading(false);
          toast({
            title: "Password Reset Success",
            description: "Password reset confirmed successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate("/sign_in");
        },
        onFailure: (err) => {
          setIsLoading(false);
          toast({
            title: "Password Reset Error",
            description: `Error confirming password reset: ${err.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      });
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "An Unexpected Error Occurred",
        description: `Error: ${error.message}`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleRequestNewCode = async () => {
    try {
      setIsLoading(true);

      const userPool = new CognitoUserPool({
        UserPoolId: "us-east-1_spapTUiQA",
        ClientId: "4f868k9j14hkmr3hetocp2bi3m",
      });

      const userData = {
        Username: email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: () => {
          setIsLoading(false);
          setRequestNewCodeSuccess(true);
          toast({
            title: "New Confirmation Code Sent",
            description: "New confirmation code sent successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        },
        onFailure: (err) => {
          setIsLoading(false);
          toast({
            title: "Request Error",
            description: `Error requesting new confirmation code: ${err.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      });
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "An Unexpected Error Occurred",
        description: `Error: ${error.message}`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="min-h-screen items-center justify-center px-4 grid">
      <form className="shadow rounded-lg border p-4 max-w-md grid gap-4">
        <FormControl id="email" isRequired>
          <FormLabel>Email address</FormLabel>
          <Input
            placeholder="your-email@example.com"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
        </FormControl>
        <FormControl id="code" isRequired>
          <FormLabel>Confirmation Code</FormLabel>
          <Input
            placeholder="Enter confirmation code"
            onChange={(e) => setCode(e.target.value)}
            type="text"
          />
        </FormControl>
        <FormControl id="newPassword" isRequired>
          <FormLabel>New Password</FormLabel>
          <InputGroup>
            <Input
              placeholder="Enter new password"
              onChange={(e) => setNewPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
            />
            <InputRightElement width="3rem">
              <Button
                h="1.5rem"
                size="sm"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <IoEyeOff /> : <IoEye />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl id="confirmPassword" isRequired>
          <FormLabel>Confirm New Password</FormLabel>
          <Input
            placeholder="Confirm new password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
          />
        </FormControl>
        <Button
          onClick={handleConfirmPasswordReset}
          isLoading={isLoading}
          loadingText="Confirming..."
        >
          Confirm Reset
        </Button>
        {!requestNewCodeSuccess && (
          <Text
            mt={4}
            color="blue.500"
            cursor="pointer"
            onClick={handleRequestNewCode}
            className="text-center"
          >
            Request a new confirmation code
          </Text>
        )}
      </form>
    </Box>
  );
}

export default ConfirmPasswordResetForm;
