import { useState, useEffect } from "react";
import {
  Box,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  Stack,
  Flex,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { FaPaperPlane } from "react-icons/fa";
import moment from "moment";
import useGetData from "../hooks/useGetData";
import LoadingEventDetails from "../components/EventComponent/Event/LoadingEventDetails";
import usePostData from "../hooks/usePostData";
import { IEvent } from "../types/interface";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  currentEventAtom,
  privateAuthState,
  meetingCodeState,
} from "../recoil/atom";
import { apiClient } from "../api";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import colors from "../utils/colors";

const PrivateEventAuth = () => {
  const { event_id } = useParams();
  const [meetingCode, setMeetingCode] = useRecoilState(meetingCodeState);
  const setPrivateAuthState = useSetRecoilState(privateAuthState);
  const setEventResponse = useSetRecoilState(currentEventAtom);
  const navigation = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { data, isLoading } = useGetData(
    [`event_${event_id}`],
    `/event/${event_id}`,
  );
  const handleMeetingCodeChange = (e) => {
    setMeetingCode(e.target.value);
  };

  const handleMeetingCodeSubmit = async () => {
    try {
      const response = await apiClient.post(`/event/auth/${event_id}`, {
        meetingCode: meetingCode,
      });
      if (response.data.eventDetails) {
        setPrivateAuthState((prev) => ({
          ...prev,
          [event_id]: { isAuthenticated: true },
        }));
        setEventResponse(response.data.eventDetails);
        navigation(`/event/${event_id}/details`);
      } else {
        console.log("Invalid event details returned");
        throw new Error("Invalid event details returned");
      }
    } catch (error) {
      console.log("Meeting auth error");
      setPrivateAuthState((prev) => ({
        ...prev,
        [event_id]: { isAuthenticated: false },
      }));
      alert("Authentication failed.");
      setMeetingCode("");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <LoadingEventDetails />;
  }

  const eventData = data as IEvent;

  return (
    <Box
      className="w-full items-center justify-center flex flex-col"
      paddingX={["4", "6"]}
      paddingY={["6", "10"]}
      width={["full", "lg"]}
    >
      <Box
        paddingX={6}
        paddingY={4}
        borderRadius={6}
        border="2px solid #E2E8F0"
      >
        <Text className="mb-2">
          {moment(eventData.eventStart).format("lll")}
        </Text>
        <Text className="mb-2">{eventData.locationName}</Text>
        <Text className="text-2xl font-bold mb-2">{eventData.title}</Text>
        <Stack spacing={4} direction={["column", "row"]} align="center">
          <Flex flexDirection={"column"} w="full">
            <InputGroup w="full">
              <Input
                placeholder="Enter meeting code"
                type={showPassword ? "text" : "password"}
                value={meetingCode}
                onChange={handleMeetingCodeChange}
              />
              <InputRightElement h={"full"}>
                <Button
                  variant={"ghost"}
                  onClick={() =>
                    setShowPassword((showPassword) => !showPassword)
                  }
                >
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>

            <Button
              loadingText="submitting..."
              isLoading={isLoading}
              size="md"
              marginTop={"16px"}
              bg={colors.primary}
              color={"white"}
              _hover={{
                bg: "blue.800",
              }}
              onClick={handleMeetingCodeSubmit}
            >
              Submit
            </Button>
          </Flex>

          <Text className="text-start">
            Don't have passcode? email {eventData.contactEmail}
          </Text>
        </Stack>

        {/*<IconButton*/}
        {/*  onClick={handleMeetingCodeSubmit}*/}
        {/*  aria-label="send"*/}
        {/*  icon={<FaPaperPlane />}*/}
        {/*/>*/}
      </Box>
    </Box>
  );
};

export default PrivateEventAuth;
