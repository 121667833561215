import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import moment from "moment";
import LoadingEventDetails from "../../components/EventComponent/Event/LoadingEventDetails";
import { EventSeatTypes } from "../../types/interface";
import { useRecoilValue } from "recoil";
import { privateAuthState, currentEventAtom } from "../../recoil/atom";
import parse from "html-react-parser";
import { useMutation } from "react-query";
import { apiClient } from "../../api";
import { API_URL } from "../../utils/constants";
import { AxiosError } from "axios";
import LoginModalBody from "../../components/LoginModalBody";

const EventDetails = () => {
  const navigate = useNavigate();
  const privateEventAuth = useRecoilValue(privateAuthState);
  const currentEvent = useRecoilValue(currentEventAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { event_id } = useParams();
  const { data, isLoading } = useGetData(
    [`event_${event_id}`],
    `/event/${event_id}`,
  );

  const isAuthenticated = privateEventAuth[event_id]?.isAuthenticated;
  const isPrivate = data?.isPrivate;
  console.log("isAuthenticated:", isAuthenticated);

  const eventData = isAuthenticated ? currentEvent : data;

  const { mutate, isLoading: checkAuthLoading } = useMutation(
    async () => {
      const res = await apiClient.get(API_URL + "/users/whoami");
      console.log("Calling whoami brian");
      console.log("Res from whoami: ", res);
      return res.data;
    },
    {
      onSuccess(data) {
        console.log(data);
        navigate(
          eventData.ticketingType === EventSeatTypes.STANDARD_BASED
            ? `/event/${event_id}/choice`
            : `/event/${event_id}/buy_diagram`,
        );
      },
      onError(error) {
        if (error instanceof AxiosError) {
          if (
            (error.response && error.response.status === 401) ||
            error.response.status === 403
          ) {
            onOpen();
          }
        }
        console.log(error);
      },
    },
  );

  const currentDate = moment();
  const eventCreatedDate = moment(eventData?.dateCreated);
  const daysDifference = currentDate.diff(eventCreatedDate, "days");

  let postedMessage;
  if (daysDifference === 0) {
    postedMessage = "Posted today";
  } else if (daysDifference === 1) {
    postedMessage = "Posted 1 day ago";
  } else if (daysDifference <= 6) {
    postedMessage = `Posted ${daysDifference} days ago`;
  } else {
    postedMessage = `Posted on ${eventCreatedDate.format("MMMM Do YYYY")}`;
  }

  if (isPrivate && !isAuthenticated) {
    console.log("Redirecting to private_auth");
    navigate(`/private_auth/${event_id}`);
    return null;
  }

  if (isLoading) {
    return <LoadingEventDetails />;
  }

  return (
    <Box className="w-full flex items-center justify-center px-4 md:px-10 flex-col">
      <Center>
        <Card rounded="10px" w={{ base: "full" }}>
          <Grid templateColumns="repeat(5, 1fr)" alignItems="stretch">
            {/*<GridItem colSpan={{ base: 5, lg: 3 }}>*/}
            <GridItem colSpan={{ base: 5, lg: 2 }}>
              <Image
                src={eventData?.imgBanner}
                objectFit="cover"
                alt="Banner"
                // w="100%"
                w={{ base: "100%", lg: "450px" }}
                // w={"450px"}
                height={{ base: "100%", lg: "450px" }}
              />

              <Badge
                position="absolute"
                top={0}
                px={4}
                py={2}
                colorScheme="secondary"
              >
                {eventData?.isFree ? "Free event" : "Fee Paying Event"}
              </Badge>
            </GridItem>
            <GridItem
              colSpan={{ base: 5, lg: 3 }}
              justifyContent="center"
              alignItems="center"
              bg="gray.50"
            >
              <Box
                display={"flex"}
                h={"100%"}
                p={4}
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
              >
                <Text textAlign="center" color="gray.500">
                  {postedMessage}
                </Text>
                <Stack
                  direction={{ base: "column", md: "column" }}
                  gap={2}
                  my={2}
                >
                  <Text
                    fontSize="2xl"
                    fontWeight="extrabold"
                    color="gray.700"
                    textAlign="center"
                    bgClip="text"
                    textTransform="uppercase"
                    bgGradient="linear(to-l, gray.500, primary.500)"
                  >
                    {eventData?.title}
                  </Text>
                  {eventData?.active ? (
                    <Badge alignSelf={"center"} bg="green.400" color="white">
                      Active
                    </Badge>
                  ) : (
                    <Badge alignSelf={"center"} bg="red.400" color="white">
                      Not Active
                    </Badge>
                  )}
                </Stack>

                <Stack justifyContent="center" alignItems="center" gap={0}>
                  <Text
                    textDecoration="underline"
                    fontSize="lg"
                    fontWeight="semibold"
                    color="gray.700"
                  >
                    Location
                  </Text>
                  <Text
                    fontSize={{ base: "md", md: "lg" }}
                    textAlign={"center"}
                    fontWeight="semibold"
                    color="primary.200"
                    textTransform="capitalize"
                  >
                    {eventData?.locationName}
                  </Text>

                  <Text
                    mt={2}
                    fontWeight="bold"
                    color="red.400"
                    onClick={() => {
                      console.log("This is ticketing: ", data?.ticketingType);
                    }}
                  >
                    Time
                  </Text>

                  <Stack justifyContent="center" alignItems="center">
                    <Text
                      fontWeight="bold"
                      color="gray.700"
                      fontSize={12}
                      colorScheme="primary"
                    >
                      {moment(eventData?.eventStart).format(
                        "MMMM Do YYYY, HH:mm",
                      )}
                    </Text>

                    <Text fontSize={12} color="gray.700">
                      To
                    </Text>

                    <Text fontWeight="bold" color="gray.700" fontSize={12}>
                      {moment(eventData?.eventEnd).format(
                        "MMMM Do YYYY, HH:mm",
                      )}
                    </Text>
                  </Stack>
                </Stack>

                <Stack my={4} justifyContent="center" alignItems="center">
                  <Button
                    size={"lg"}
                    colorScheme="primary"
                    onClick={() => mutate()}
                    isLoading={checkAuthLoading}
                  >
                    Get Ticket
                  </Button>
                </Stack>
              </Box>
            </GridItem>
          </Grid>
        </Card>
      </Center>

      <VStack
        px={4}
        my={10}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        <Text fontWeight="bold" colorScheme="primaary" textAlign="center">
          Event Description
        </Text>
        <Box textAlign="center">{parse(eventData.description)}</Box>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <LoginModalBody data={eventData} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EventDetails;
