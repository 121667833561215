import { useState, useEffect } from "react";
import {
  Heading,
  Container,
  Text,
  Input,
  Button,
  useToast,
  Box,
  FormLabel,
  InputGroup,
  InputRightElement,
  Flex,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { awsUserDetailsAtom } from "../recoil/atom";
import userPool from "../userPool";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { ViewIcon, ViewOffIcon, CheckCircleIcon } from "@chakra-ui/icons";

function UserProfile() {
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState(awsUserDetailsAtom);
  const [loadingPhone, setLoadingPhone] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingName, setLoadingName] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [phone_number, setPhoneNumber] = useState(
    userDetails.phone_number.toString(),
  );
  const [email, setEmail] = useState(userDetails.email.toString());
  const [family_name, setFamilyName] = useState(
    userDetails.family_name.toString(),
  );
  const [given_name, setGivenName] = useState(
    userDetails.given_name.toString(),
  );
  const [password, setPassword] = useState("");
  const [lengthError, setLengthError] = useState(false);
  const [letterError, setLetterError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [spaceError, setSpaceError] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error("Error fetching user session:", err);
        } else {
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              console.error("Error fetching user attributes:", err);
            } else {
              const fetchedUserDetails = {};
              attributes.forEach((attribute) => {
                fetchedUserDetails[attribute.getName()] = attribute.getValue();
              });
              setUserDetails(fetchedUserDetails);
            }
          });
        }
      });
    }
  }, [setUserDetails]);

  const updateAttribute = (
    attributeName: any,
    attributeValue: any,
    setLoadingState: any,
  ) => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      setLoadingState(true);

      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error("Error fetching user session:", err);
          setLoadingState(false);
        } else {
          const attributeList = [
            new CognitoUserAttribute({
              Name: attributeName,
              Value: attributeValue,
            }),
          ];

          cognitoUser.updateAttributes(attributeList, (err, result) => {
            if (err) {
              console.error(`Error updating ${attributeName}:`, err);
              toast({
                title: "Error",
                description: `Error updating ${attributeName}`,
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            } else {
              console.log(`${attributeName} updated successfully`, result);
              cognitoUser.getUserAttributes((err, attributes) => {
                if (err) {
                  console.error("Error fetching user attributes:", err);
                } else {
                  const updatedUserDetails = {};
                  attributes.forEach((attribute) => {
                    updatedUserDetails[attribute.getName()] =
                      attribute.getValue();
                  });
                  setUserDetails(updatedUserDetails);
                  toast({
                    title: "Success",
                    description: `Successfully updated your details`,
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                  });
                }
              });
            }
            setLoadingState(false);
          });
        }
      });
    }
  };

  const updatePhoneNumber = () => {
    updateAttribute("phone_number", phone_number, setLoadingPhone);
  };

  const updateEmail = () => {
    updateAttribute("email", email, setLoadingEmail);
  };

  const updateName = () => {
    updateAttribute("family_name", family_name, setLoadingName);
    updateAttribute("given_name", given_name, setLoadingName);
  };

  const updatePassword = () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      setLoadingPassword(true);

      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error("Error fetching user session:", err);
          setLoadingPassword(false);
        } else {
          const isValidLength = password.length >= 8;
          const hasLetter = /[a-zA-Z]/.test(password);
          const hasNumber = /\d/.test(password);
          const doesNotStartOrEndWithSpace = /^[\S]+.*[\S]+$/.test(password);

          setLengthError(!isValidLength);
          setLetterError(!hasLetter);
          setNumberError(!hasNumber);
          setSpaceError(!doesNotStartOrEndWithSpace);

          if (
            isValidLength &&
            hasLetter &&
            hasNumber &&
            doesNotStartOrEndWithSpace
          ) {
            cognitoUser.changePassword(password, "", (err, result) => {
              if (err) {
                console.error("Error updating password:", err);
                toast({
                  title: "Error",
                  description: `Error updating password`,
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              } else {
                console.log("Password updated successfully", result);
                toast({
                  title: "Success",
                  description: `Successfully updated password`,
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                });
              }
              setLoadingPassword(false);
            });
          } else {
            setLoadingPassword(false);
          }
        }
      });
    }
  };
  return (
    <Container className="w-full flex flex-col gap-8 min-h-screen py-8 items-center justify-center">
      <Box className=" w-full max-w-lg mb-6">
        <Heading className="text-xl text-left w-full mb-6">My Info</Heading>
        <FormLabel>First Name</FormLabel>
        <Input
          type="text"
          value={given_name}
          onChange={(e) => setGivenName(e.target.value)}
          placeholder="Given Name"
          className="mb-4"
        />
        <FormLabel>Last Name</FormLabel>
        <Input
          type="text"
          value={family_name}
          onChange={(e) => setFamilyName(e.target.value)}
          placeholder="Family Name"
          className="mb-4"
        />
        <Button onClick={updateName} isLoading={loadingName}>
          Update Details
        </Button>
      </Box>

      <Box className=" w-full max-w-lg mb-6">
        <Heading className="text-xl text-left w-full mb-6">
          Email Address
        </Heading>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="mb-4"
        />
        <Button onClick={updateEmail} isLoading={loadingEmail}>
          Update Email
        </Button>
      </Box>

      <Box className=" w-full max-w-lg mb-6">
        <Heading className="text-xl text-left w-full mb-6">
          Phone Number
        </Heading>
        <FormLabel>Phone Number</FormLabel>
        <Input
          type="text"
          value={phone_number}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
          className="mb-4"
        />
        <Button onClick={updatePhoneNumber} isLoading={loadingPhone}>
          Update Number
        </Button>
      </Box>

      <Box className=" w-full max-w-lg">
        <Heading className="text-xl text-left w-full mb-6">
          Update Password
        </Heading>
        <FormLabel>New Password</FormLabel>
        <InputGroup>
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setLengthError(false);
              setLetterError(false);
              setNumberError(false);
              setSpaceError(false);
            }}
            placeholder="New Password"
            isInvalid={lengthError || letterError || numberError || spaceError}
            className="mb-4"
          />
          <InputRightElement>
            <Button
              variant={"ghost"}
              onClick={() => setShowPassword((showPassword) => !showPassword)}
            >
              {showPassword ? <ViewOffIcon /> : <ViewIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>

        {spaceError && (
          <Text color="red.500">
            Password must not start or end with a space
          </Text>
        )}

        <Box className="mb-6 grid gap-2">
          <Text>Your password must:</Text>
          <Flex
            className={`items-center ${
              lengthError ? "text-red-500" : ""
            } gap-2`}
          >
            <CheckCircleIcon />
            <Text>Contain at least 8 characters</Text>
          </Flex>
          <Flex
            className={`items-center ${
              letterError ? "text-red-500" : ""
            } gap-2`}
          >
            <CheckCircleIcon />
            <Text>Include a letter</Text>
          </Flex>
          <Flex
            className={`items-center ${
              numberError ? "text-red-500" : ""
            } gap-2`}
          >
            <CheckCircleIcon />
            <Text>Include a number</Text>
          </Flex>
        </Box>

        <Button onClick={updatePassword} isLoading={loadingPassword}>
          Update Password
        </Button>
      </Box>
    </Container>
  );
}

export default UserProfile;
