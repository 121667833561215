import { useEffect, useState } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import WithSubnavigation from "../components/Nav/Navbar";
import usePostData from "../hooks/usePostData";
import {
  backendUserDetailsAtom,
  isAuthenticatedAtom,
  refreshAtomState,
  userAccessToken,
  awsUserDetailsAtom,
} from "../recoil/atom";
import userPool from "../userPool";
import colors from "../utils/colors";
import { validateEmail } from "../utils/functions";
import { IBackendUser, IMutateData } from "../types/interface";
import { apiClient } from "../api";

import { toast } from "react-hot-toast";
import { makeToast } from "../utils/toast";

export default function ResetPasswordForm(): JSX.Element {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedAtom);
  const setUserAccessToken = useSetRecoilState(userAccessToken);
  const setRefreshToken = useSetRecoilState(refreshAtomState);
  const setUserDetails = useSetRecoilState(awsUserDetailsAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isToastActive, setIsToastActive] = useState<boolean>(false);

  const [backendUserDetailsState, setBackendUserDetailsState] = useRecoilState(
    backendUserDetailsAtom,
  );

  const [submitBackendLogin, setSubmitBackendLogin] = useState<boolean>(false);

  const putDBUser = usePostData();

  const toast2 = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Boolean(email) || !Boolean(password)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [email, password]);

  useEffect(() => {
    console.log("Use effect called on accesstoken");

    if (!submitBackendLogin) {
      return;
    }
    const backendLogin = async () => {
      try {
        const res = await apiClient.post("/users");
        setBackendUserDetailsState(res.data as IBackendUser);
        console.log("This is res data from login: ", res.data);
        setIsAuthenticated(true);
        setIsLoading(false);
        navigate("/");
      } catch (error) {
        console.log("This is error again in accessToken useEffect: ", error);
        makeToast("error", "Error", error.response.data.client_message);
        setIsLoading(false);
      }
    };
    backendLogin();
  }, [submitBackendLogin]);

  const submit = async () => {
    try {
      if (invalid) {
        return showToast({
          title: "Please Enter all Fields",
          status: "warning",
        });
      }

      if (!validateEmail(email)) {
        return showToast({
          title: "Email is Invalid",
          description: "Please enter a valid email",
          status: "warning",
        });
      }
      setIsLoading(true);
      const response: any = await loginCognitoUser();
      console.log(
        "AWS:LOGIN:RESPONSE: ",
        response.accessToken.jwtToken,
        "  End",
      );
      console.log("AWS Resp: ", response.accessToken);
      const showErrorToast = () => {
        return showToast({
          title: "There was an error signing in, please try again",
          status: "error",
        });
      };
      if (!response) {
        setIsLoading(false);
        showErrorToast();
        return;
      }
      setRefreshToken(response.refreshToken.token);
      setUserAccessToken(response.accessToken.jwtToken);
      setUserDetails(response.idToken.payload);
      setSubmitBackendLogin(true);
    } catch (error: any) {
      console.log("AWS error: ", error);
      makeToast("error", "Error", "Incorrect username or password");
      setIsLoading(false);
    }
  };

  const showToast = (props) => {
    if (true) {
      // if (!isToastActive) {
      setIsToastActive(true);
      toast2({
        duration: 9000,
        isClosable: true,
        ...props,
        id: "login_toast",
        // onCloseComplete: () => setIsToastActive(false),
      });
    }
  };

  const loginCognitoUser = () => {
    const authenticationData = { Username: email, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const CU = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) =>
      CU.authenticateUser(authenticationDetails, {
        onSuccess: (result: any) => resolve(result),
        onFailure: (err: any) => reject(err),
      }),
    );
  };

  return (
    <Stack
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      width={"full"}
      bg={"gray.50"}
    >
      <WithSubnavigation />
      <Flex
        as={"form"}
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        width={"90%"}
      >
        <Stack
          spacing={4}
          w={"full"}
          maxW={"md"}
          bg={"white"}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
          my={12}
        >
          <Heading
            lineHeight={1.1}
            color={colors.primary}
            fontSize={{ base: "2xl", md: "3xl" }}
          >
            Musha Tickets Login
          </Heading>
          <FormControl color={"gray.700"} id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              placeholder="your-email@example.com"
              _placeholder={{ color: "gray.500" }}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              borderColor={"gray.300"}
            />
          </FormControl>
          <FormControl color={"gray.700"} id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="**********"
                borderColor={"gray.300"}
                _placeholder={{ color: "gray.500" }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement h={"full"}>
                <Button
                  variant={"ghost"}
                  onClick={() =>
                    setShowPassword((showPassword) => !showPassword)
                  }
                >
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Stack spacing={6}>
            <Button
              loadingText="Logging In..."
              isLoading={isLoading}
              size="lg"
              bg={colors.primary}
              color={"white"}
              _hover={{
                bg: "blue.800",
              }}
              onClick={submit}
            >
              Log In
            </Button>
          </Stack>
          <Stack pt={6}>
            <Text align={"center"}>
              Don't have an account yet?{" "}
              <Link to={"/sign_up"}>
                <Text color={colors.primary} fontWeight={"bold"}>
                  Sign Up
                </Text>
              </Link>
            </Text>
          </Stack>
          <Link to="/forgot_password" className=" text-center">
            <Text color={colors.primary} fontWeight={"bold"}>
              Forgot Password?
            </Text>
          </Link>
        </Stack>
      </Flex>
    </Stack>
  );
}
