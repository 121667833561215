import { useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";

import { MdCreate, MdOutlineArrowBack, MdPayment } from "react-icons/md";

import { useFormik } from "formik";
import { addBankDetailsSchema } from "../../../utils/validation";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  eventCreationPathAtom,
  formProgressAtom,
  formStepAtom,
  incompleteEventAtom,
} from "../../../recoil/atom";
import { IEvent } from "../../../types/interface";
import useGetData from "../../../hooks/useGetData";
import { apiClient } from "../../../api";
import { makeToast } from "../../../utils/toast";

interface AddBankDetailsFormProps {
  submitEvent: () => void;
}

type Bank = {
  id: string;
  name: string;
  dateCreated: string;
};

interface TicketsTypeData {
  id: number;
  name: string;
  price: string;
  quantity: string;
}

const AddBankDetailsForm: React.FC<AddBankDetailsFormProps> = (props) => {
  const [formStep, setFormStep] = useRecoilState(formStepAtom);
  const [formProgress, setFormProgress] = useRecoilState(formProgressAtom);
  // const [prevCreateEventFormStep, setPrevCreateEventFormStep] = useRecoilState(prevCreateEventFormStepAtom)
  const [fetchBanks, setFetchBanks] = useState(false);
  const eventCreationPath = useRecoilValue(eventCreationPathAtom);
  const [incompleteEvent, setIncompleteEvent] =
    useRecoilState(incompleteEventAtom);
  const { data: banks, isLoading: banksLoading } = useGetData(
    "fetchBanks",
    "/bank",
    fetchBanks ? undefined : 0,
  );

  useEffect(() => {
    setFormProgress(100);
    // setPrevCreateEventFormStep(CreateEventFormSteps.ADD_SEATS_GROUPING_FORM)
  }, [setFormProgress]);

  const formik = useFormik({
    initialValues: {
      bankName: "",
      bankAccountNumber: "",
      bankAccountOwnerName: "",
    },

    validationSchema: addBankDetailsSchema,
    onSubmit: async (values) => {
      const eventUpdates: IEvent = incompleteEvent;
      eventUpdates.bankName = values.bankName;
      eventUpdates.bankAccountNumber = values.bankAccountNumber;
      eventUpdates.bankAccountOwnerName = values.bankAccountOwnerName;

      try {
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await apiClient.put(
          `/event/${incompleteEvent._id}`,
          eventUpdates,
          {
            headers,
          },
        );
        console.log("This is response SelectSeatingTypeForm: ", response);
        setIncompleteEvent(response.data.event as IEvent);
        await props.submitEvent();
        formik.setSubmitting(false);
      } catch (err) {
        makeToast(
          "error",
          "Error",
          "Could not update event. Please try again later",
        );
      }
    },
  });

  return (
    <Box as="form" onSubmit={formik.handleSubmit}>
      <Heading
        fontSize={{
          base: "sm",
          md: "md",
        }}
        className="mt-6"
      >
        Bank Details
      </Heading>
      <Text color={"gray.600"} className="my-2">
        This is where you will receive the Money in less than 72 hours after the
        event is done.
      </Text>
      <Box>
        <FormControl
          isRequired
          isInvalid={
            Boolean(formik.touched.bankAccountOwnerName) &&
            Boolean(formik.errors.bankAccountOwnerName)
          }
        >
          <FormLabel fontWeight={"normal"}>Account Owner Name</FormLabel>
          <Input
            placeholder="Mapalo Chansa"
            type={"text"}
            {...formik.getFieldProps("bankAccountOwnerName")}
          />

          <FormErrorMessage>
            {formik.errors.bankAccountOwnerName}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={
            Boolean(formik.touched.bankAccountNumber) &&
            Boolean(formik.errors.bankAccountNumber)
          }
          className="mt-2"
        >
          <FormLabel htmlFor="bank_name">Account Number</FormLabel>

          <InputGroup>
            <Input
              placeholder="237000000"
              type={"number"}
              {...formik.getFieldProps("bankAccountNumber")}
            />

            <InputRightElement color="gray.400">
              <MdPayment />
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage>{formik.errors.bankAccountNumber}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={
            Boolean(formik.touched.bankName) && Boolean(formik.errors.bankName)
          }
          className="mt-2"
        >
          <FormLabel htmlFor="bank_name">Bank Name</FormLabel>
          <Select
            {...formik.getFieldProps("bankName")}
            className="form-select"
            disabled={banksLoading || banks.error}
            onClick={() => setFetchBanks(true)}
            placeholder="Select a bank"
          >
            {banks
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((bank: Bank) => (
                <option key={bank.id} value={bank.name}>
                  {bank.name}
                </option>
              ))}
          </Select>

          <FormErrorMessage>{formik.errors.bankName}</FormErrorMessage>
        </FormControl>
      </Box>
      <HStack spacing={4} justifyContent="center" mt={10}>
        <Button
          type="button"
          variant="outline"
          onClick={() => setFormStep(eventCreationPath)}
        >
          <Icon as={MdOutlineArrowBack} />
        </Button>
        <Button
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          loadingText="Creating..."
          onClick={formik.submitForm}
          leftIcon={<Icon as={MdCreate} />}
        >
          Create
        </Button>
      </HStack>
    </Box>
  );
};

export default AddBankDetailsForm;
