import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  EventSeatTypes,
  IEvent,
  IGroupTickets,
  ISeat,
  ISeatIdMap,
  ISectionIdDetailsMap,
  SideBarState,
} from "../../types/interface";

import DiagramComponent from "../../components/BuyTicket/DiagramComponent";
import GroupTicketsList from "../../components/BuyTicket/GroupTicketsList";
import BoughtTicketsList from "../../components/BuyTicket/BoughtTicketsList";
import { useNavigate, useParams } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import { API_URL } from "../../utils/constants";
import { useRecoilState } from "recoil";
import {
  currentEventAtom,
  selectedDiagramSeatsMapAtom,
  numSeatsInSectionToBuyAtom,
} from "../../recoil/atom";
import GroupTicketsPurchaseScreen from "../../components/BuyTicket/GroupTicketsPurchaseScreen";
import SectionsPurchaseSidebar from "../../components/BuyTicket/SectionsPurchaseSidebar";
import SectionsListSidebar from "../../components/BuyTicket/SectionsListSidebar";
// import usePostData from "../../hooks/usePostData";
import { apiClient } from "../../api";
import { useQuery } from "react-query";
import { rtDiagramDSContainer } from "../../utils/buildRtDiagramDataStructure";
import Loading from "../../components/EventComponent/Event/Loading";

const BuyTicketsDiagram = () => {
  // window.localStorage.removeItem("chakra-ui-color-mode");
  // console.log('deleted "chakra-ui-color-mode" from local storage');
  // console.log("You can now refresh to see how a first visit looks like.");
  const [selectedSeats, setSelectedSeats] = useState(new Set<string>());
  const [prevUnselectedSeat, setPrevUnselectedSeat] = useState<string>("");

  const [sideBarState, setSideBarState] = useState<SideBarState>(
    SideBarState.GroupTicketsList
  );
  const { event_id } = useParams();
  const [numSeatsTobuy, setNumSeatsToBuy] = useState(1);

  const [rtGroupDS, setRtGroupDS] = useState(new rtDiagramDSContainer());

  const [, setCurrentEvent] = useRecoilState(currentEventAtom);

  const [, setSelectedDiagramSeatsMapAtom] = useRecoilState(
    selectedDiagramSeatsMapAtom
  );

  const [, setNumSeatsInSectionToBuyAtom] = useRecoilState(
    numSeatsInSectionToBuyAtom
  );

  const [numGroupTickets, setNumGroupTickets] = useState<number>(1);

  const [selectedRtIdentifer, setSelectedRtIdentifer] = useState("");

  const [selectedSection, setSelectedSection] = useState<string>("");

  const [maxNumSeatsToBuy, setMaxNumSeatsToBuy] = useState(5);

  const navigate = useNavigate();
  const navigateToBuyDiagramBasedSeat = () => {
    setSelectedDiagramSeatsMapAtom(getUpdatedSelectedSeatMap());
    navigate(`/event/${event_id}/payment`);
  };

  // const { mutate } = usePostData();

  const navigateToBuySectionBasedSeat = () => {
    let numSeatsInSectionToBuy: Record<string, number> = {};
    // numSeatsInSectionToBuy[sectionIdDetailsMap[selectedSection].name] = FILL in;
    setNumSeatsInSectionToBuyAtom(numSeatsInSectionToBuy);
    navigate(`/event/${event_id}/payment`);
  };

  const selectSectionAndShowOnSidebar = (id: string) => {
    setSelectedSection(id);
    setSideBarState(SideBarState.SectionsPurchaseSidebar);
  };

  useEffect(() => {
    // Apply the "no-scroll" class when the component mounts
    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";
    // Remove the "no-scroll" class when the component unmounts
    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (selectedSeats.size < 1) {
      setSideBarState(SideBarState.GroupTicketsList);
    }
  }, [selectedSeats, prevUnselectedSeat]);

  let endpointUrl = `/seat/fetchEventSeats/${event_id}`;
  const allSeatsQuery = useQuery("allDiagramSeats", async () => {
    const response = await apiClient.post(endpointUrl, {});

    console.log("This is response: ", response);
    return response.data;
  });

  useEffect(() => {
    console.log("Datas building useeffect");
    if (allSeatsQuery.isLoading) {
      setRtGroupDS(new rtDiagramDSContainer());
    }
    if (allSeatsQuery.isSuccess) {
      console.log("Datas building success");
      //TODO: count how many times this is called
      const seatsArray = allSeatsQuery.data as ISeat[];
      const newRtGroupDs = new rtDiagramDSContainer();
      newRtGroupDs.insertAllSeatObjects(seatsArray);
      setRtGroupDS(newRtGroupDs);
    }
  }, [allSeatsQuery.data, allSeatsQuery.isLoading, allSeatsQuery.isSuccess]);
  // console.log("These are all seats: ", allSeats)
  const eventDetails = useGetData(
    "event_details",
    `${API_URL}/event/${event_id}`
  );
  if (eventDetails.isSuccess) {
    setCurrentEvent(eventDetails.data as IEvent);
  }

  if (eventDetails.isLoading || allSeatsQuery.isLoading) {
    return (
      <Box className="min-h-screen items-center justify-center flex">
        <Loading count={3} />
      </Box>
    );
  } else {
  }
  if (eventDetails.error || allSeatsQuery.error) {
    console.log("Fetch error: ", eventDetails.error, allSeatsQuery.error);
    return <Box>Error Fetching seats information...</Box>;
  }

  const sectionIdDetailsMap: ISectionIdDetailsMap = {};
  let eventObject = eventDetails.data as IEvent;
  console.log("This is received event: ", eventObject);
  console.log("This is section object: ", eventObject.seatGroupingMap);
  console.log("This is TITLE: ", eventObject.title);
  for (const sectionName in eventObject.seatGroupingMap) {
    let sectionDetails = {
      price: eventObject.seatGroupingMap[sectionName].price,
      name: sectionName,
      numSeatsLeft: 900000,
    };
    sectionIdDetailsMap[`section-${sectionName}`] = sectionDetails;
    sectionIdDetailsMap[`section-${sectionName}`].numSeatsLeft =
      eventObject.seatGroupingMap[sectionName].numInitialSeats -
      eventObject.seatGroupingMap[sectionName].numSeatsSold;
  }

  console.log("These are event details: ", eventDetails);

  const seatIdDetailsMap: ISeatIdMap = {};

  const getTicketId = (seat: ISeat): string => {
    return `seat-${seat.section}-${seat.row}-${seat.seatNumber}`;
  };
  for (const seatInfo of allSeatsQuery?.data) {
    seatIdDetailsMap[getTicketId(seatInfo as ISeat)] = seatInfo as ISeat;
  }
  console.log("This is seatID details map: ", seatIdDetailsMap);
  const addDiagramSelectedSeat = (seatId: string) => {
    console.log("ADD called in parent. ID: ", seatId);
    setSideBarState(SideBarState.BoughtTickets);
    setSelectedSeats((prevSelectedSeats) => {
      const newSelectedSeats = new Set(prevSelectedSeats);
      newSelectedSeats.add(seatId);
      console.log("This is new PARENT ADDED: ", newSelectedSeats);
      return newSelectedSeats;
    });
    setPrevUnselectedSeat("");
  };

  const selectGroupSeats = (rtIdentifer: string) => {
    // setSideBarComponent(SideBarState.BoughtTickets)
    setSelectedSeats((prevSelectedSeats) => {
      const newSelectedSeats = new Set<string>();
      rtGroupDS
        .getConsecutiveSeatNumbers(rtIdentifer, numSeatsTobuy)
        .forEach((seatId) => newSelectedSeats.add(seatId));
      console.log("This is new selected seats: ", newSelectedSeats);
      return newSelectedSeats;
    });
    setSideBarState(SideBarState.GroupTicketsPurchase);
  };

  const removeGroupSeats = (group: IGroupTickets) => {
    setSelectedSeats((prevSelectedSeats) => {
      const newSelectedSeats = new Set(prevSelectedSeats);
      group.ticketIds.forEach((ticketId) => {
        newSelectedSeats.delete(ticketId);
      });
      return newSelectedSeats;
    });
  };

  const removeDiagramSelectedSeat = (seatId: string) => {
    setSelectedSeats((prevSelectedSeats) => {
      const newSelectedSeats = new Set(prevSelectedSeats);
      newSelectedSeats.delete(seatId);
      return newSelectedSeats;
    });
    setPrevUnselectedSeat((oldSeatId) => {
      // console.log("This is prev unselected: ", oldSeatId)
      return seatId;
    });
  };

  const getUpdatedSelectedSeatMap = (): ISeatIdMap => {
    const selectedSeatsMap: ISeatIdMap = {};
    console.log("thIS IS all selected seats MAP: ", selectedSeatsMap);
    selectedSeats.forEach((seatId) => {
      selectedSeatsMap[seatId] = seatIdDetailsMap[seatId];
    });
    return selectedSeatsMap;
  };

  let sideBarToDisplay: React.ReactNode;

  switch (sideBarState) {
    case SideBarState.BoughtTickets:
      sideBarToDisplay = (
        <BoughtTicketsList
          seats={getUpdatedSelectedSeatMap()}
          removeSelectedSeat={removeDiagramSelectedSeat}
          navigateToPurchase={navigateToBuyDiagramBasedSeat}
        />
      );
      break;
    case SideBarState.GroupTicketsList || SideBarState.SectionsListSidebar:
      if (eventObject.ticketingType === EventSeatTypes.SECTIONS_BASED) {
        sideBarToDisplay = (
          <SectionsListSidebar
            maxNumSeats={maxNumSeatsToBuy}
            sectionIdDetailsMap={sectionIdDetailsMap}
            selectSection={selectSectionAndShowOnSidebar}
            setNumSeatsToBuy={setNumGroupTickets}
          />
        );
      } else {
        console.log("Entered group tickets sidebar to display");
        sideBarToDisplay = (
          <GroupTicketsList
            maxNumSeats={10}
            selectGroupTickets={selectGroupSeats}
            rtDSContainer={rtGroupDS}
            numSeatsToBuy={numSeatsTobuy}
            setNumSeatsToBuy={setNumSeatsToBuy}
          />
        );
      }
      break;
    case SideBarState.GroupTicketsPurchase:
      sideBarToDisplay = (
        <GroupTicketsPurchaseScreen
          navigateToPurchase={navigateToBuyDiagramBasedSeat}
          seatMap={getUpdatedSelectedSeatMap()}
          removeSelectedSeat={removeDiagramSelectedSeat}
        />
      );
      break;
    case SideBarState.SectionsPurchaseSidebar:
      const sectionName = selectedSection.match(/section-(.+)/)[1];
      sideBarToDisplay = (
        <SectionsPurchaseSidebar
          sectionPrice={
            (eventDetails.data as IEvent).seatGroupingMap[sectionName].price
          }
          navigateToPurchase={navigateToBuySectionBasedSeat}
          numSeatsSelected={numGroupTickets}
          maxNumSeatsToPurchase={maxNumSeatsToBuy}
        />
      );
      break;

    default:
      sideBarToDisplay = <div>gkgk</div>;
  }

  // if (allSeats.isLoading || eventDetails.isLoading){
  //     return <></>
  // }

  return (
    <Flex
      h={{ base: "100%", md: "100vh" }}
      width={"100%"}
      flex={1}
      flexDir={{ base: "column", md: "row" }}
      align="center"
      gap={4}
    >
      <DiagramComponent
        addSelectedSeat={addDiagramSelectedSeat}
        removeSelectedSeat={removeDiagramSelectedSeat}
        seatIdMap={seatIdDetailsMap}
        selectedSeats={selectedSeats}
        prevUnselectedSeat={prevUnselectedSeat}
        setSelectedSection={selectSectionAndShowOnSidebar}
        // eventSeatType={eventObject.ticketingType}
        sectionIdDetailsMap={sectionIdDetailsMap}
        currentEvent={eventObject}
      />
      <Box h={{ base: "100%", md: "100%" }} className="max-w-sm w-full">
        {sideBarToDisplay}
      </Box>
    </Flex>
  );
};

export default BuyTicketsDiagram;
