import React, { useEffect, useRef, useState } from "react";
import DiagramSVG from "./DiagramSVG";
import { Box, Text } from "@chakra-ui/react";

import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { Tooltip } from "./Tooltip";
import {
  EventSeatTypes,
  IEvent,
  ISeat,
  ISection,
  ISectionIdDetailsMap,
  SVGSeatTypes,
} from "../../types/interface";
import { useQuery } from "react-query";
import Loading from "../EventComponent/Event/Loading";

interface DiagramComponentProps {
  selectedSeats: Set<string>;
  prevUnselectedSeat: string;
  addSelectedSeat: (string) => void;
  removeSelectedSeat: (string) => void;
  seatIdMap: Object;
  setSelectedSection: (string) => void;
  sectionIdDetailsMap: ISectionIdDetailsMap;
  currentEvent: IEvent;
}

const ReactZoom: React.FC<DiagramComponentProps> = (props) => {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const reactZoomRef = useRef<ReactZoomPanPinchRef>(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipSeatObject, setTooltipSeatObject] = useState<ISeat>(undefined);
  const [tooltipSectionObject, setTooltipSectionObject] =
    useState<ISection>(undefined);
  // const [svgContent, setSvgContent] = useState("");
  // const [sectionView, setSectionView] = useState(
  //   props.currentEvent.diagramData.zoomOnSectionClick ?? false,
  // );
  const [sectionView, setSectionView] = useState(false);
  const maxZoomLevel = 1.5;
  const sectionViewThreshold = 1.2;
  const minZoomLevel = 1;

  // useEffect(() => {
  //   const fetchSVG = async () => {
  //     try {
  //       const response = await fetch(props.currentEvent.diagramData.diagramSVG);
  //       const svgText = await response.text();
  //       setSvgContent(svgText);
  //       console.log("This is svg text: ", svgText);
  //     } catch (error) {
  //       console.error("Error fetching SVG:", error);
  //     }
  //   };
  //
  //   fetchSVG();
  // }, []);

  const diagramSVGQuery = useQuery(
    "diagramSVGQuery",
    async () => {
      const response = await fetch(props.currentEvent.diagramData.diagramSVG);
      console.log("This is response: ", response);
      return response.text();
    },
    {
      onSuccess: (data) => {
        console.log("Calling on success..");
        // setSectionView(false);
        //Hacky solution to call useEffect
        // setZoomLevel(1 + 0.000001)
      },
    },
  );

  // const createHandleClick = (): (id: string, event : MouseEvent ) => void => {
  //     return (id: string, event : MouseEvent) => {
  //         reactZoomRef.current?.zoomToElement(id, 8)
  //         console.log("ThIS IS ID: ", id)
  //         if (clickedSeats.includes(id)){
  //             const updatedSeats = clickedSeats.filter((i) => i !== id);
  //             console.log("Clicked agsain");
  //             (event.target as Element).setAttribute("fill", "green");
  //             setClickedSeats(updatedSeats)
  //         } else {
  //             (event.target as Element).setAttribute("fill", "red");
  //             setClickedSeats([...clickedSeats, id])
  //             console.log("This is clicked seats: ", clickedSeats)
  //         }
  //     }
  // }
  useEffect(() => {
    // console.log("Zoom level changed. New: ", zoomLevel);
    if (
      props.currentEvent.ticketingType === EventSeatTypes.SEATS_BASED &&
      (props.currentEvent.diagramData?.zoomOnSectionClick ?? false) &&
      diagramSVGQuery.isSuccess
    ) {
      console.log(
        "use efect: sECTION VIEW: ",
        sectionView,
        "Zoom level: ",
        zoomLevel,
      );
      if (sectionView && zoomLevel > sectionViewThreshold) {
        let allSeatGroups = document.querySelectorAll(
          '[data-element-type="seats-group"]',
        );
        allSeatGroups.forEach((seatGroup) => {
          seatGroup.setAttribute("opacity", "1");
        });
        let allSectionGroups = document.querySelectorAll(
          '[data-element-type="sections-group"]',
        );
        allSectionGroups.forEach((sectionGroup) => {
          sectionGroup.setAttribute("fill", "#D8D8D8");
          sectionGroup.setAttribute("style", 'transition: "fill 5s ease"');
        });
        setSectionView(false);
      }
      if (!sectionView && zoomLevel < sectionViewThreshold) {
        console.log("Entered correct case");
        let allSeatGroups = document.querySelectorAll(
          '[data-element-type="seats-group"]',
        );
        console.log("This is all seats group: ", allSeatGroups);
        allSeatGroups.forEach((seatGroup) => {
          seatGroup.setAttribute("opacity", "0");
        });
        let allSectionGroups = document.querySelectorAll(
          '[data-element-type="sections-group"]',
        );
        allSectionGroups.forEach((sectionGroup) => {
          sectionGroup.setAttribute("fill", "green");
        });
        setSectionView(true);
      }
    }
  }, [zoomLevel, diagramSVGQuery.isFetched, props.currentEvent.ticketingType, props.currentEvent.diagramData?.zoomOnSectionClick, diagramSVGQuery.isSuccess, sectionView]);

  useEffect(() => {
    // console.log("This is clicked seats: ", clickedSeats)
    props.selectedSeats.forEach((seatId) => {
      swapDocumentElements(
        seatId,
        "icon-selected",
        SVGSeatTypes.Circle,
        SVGSeatTypes.Group,
      );
    });
    if (props.prevUnselectedSeat !== "") {
      swapDocumentElements(
        props.prevUnselectedSeat,
        "icon-not-selected",
        SVGSeatTypes.Group,
        SVGSeatTypes.Circle,
      );
    }
  }, [props]);

  if (diagramSVGQuery.isLoading) {
    return <Loading count={3} />;
  }
  if (diagramSVGQuery.error) {
    return <Text>Error displaying seat map</Text>;
  }

  const swapDocumentElements = (
    id1: string,
    id2: string,
    el1Type: SVGSeatTypes,
    el2Type: SVGSeatTypes,
  ) => {
    console.log("ID2 : ", id2);
    const replacementIcon = document
      .getElementById(id2)
      .cloneNode(true) as Element;
    let targetElement = document.getElementById(id1) as Element;
    console.log("This is target element children: ", targetElement.children);
    console.log("This is target element TYPE: ", targetElement.nodeName);
    console.log("This is target element parent: ", targetElement.parentNode);
    if (targetElement.parentNode === null) {
      return;
    }
    // const initialIcon = document.getElementById(id1)
    let newX = "0";
    let newY = "0";

    let realTargetSeat = targetElement;
    while (!realTargetSeat.id.includes("seat")) {
      //TODO: Add depth check to avoid hitting root
      realTargetSeat = realTargetSeat.parentNode as Element;
    }
    console.log("This is real target found: ", realTargetSeat);

    if (realTargetSeat.nodeName === SVGSeatTypes.Circle) {
      newX = targetElement.getAttribute("cx");
      newY = targetElement.getAttribute("cy");
      console.log("This is new z new y: ", newX, newY);
    } else if (realTargetSeat.nodeName === SVGSeatTypes.Group) {
      console.log("This is inside regex");
      console.log(
        "This is target att: ",
        realTargetSeat.getAttribute("transform"),
      );
      const regex = /translate\(([^,]+),([^)]+)\)/;
      const match = realTargetSeat.getAttribute("transform").match(regex);
      newX = match[1].trim();
      newY = match[2].trim();
    }
    if (el2Type === SVGSeatTypes.Group) {
      const translationString = `translate(${newX}, ${newY})`;
      console.log("Found group translation string: ", translationString);
      replacementIcon.setAttribute("transform", translationString);
    } else if (el2Type === SVGSeatTypes.Circle) {
      replacementIcon.setAttribute("cx", newX);
      replacementIcon.setAttribute("cy", newY);
    }
    replacementIcon.id = realTargetSeat.id;
    realTargetSeat.parentNode?.replaceChild(replacementIcon, realTargetSeat);
    // if (!replacementIcon.hasAttribute('attached_listener')){
    //
    // }
    replacementIcon.addEventListener("click", (event) =>
      handleSeatClickLargeScreen(replacementIcon.id, event as MouseEvent),
    );
    replacementIcon.addEventListener("mouseenter", (event) =>
      createHandleSeatHover()(replacementIcon.id, event as MouseEvent),
    );
    replacementIcon.addEventListener("mouseleave", (event) =>
      createHandleSeatEndHover()(replacementIcon.id, event as MouseEvent),
    );
    console.log("This is replacement parent: ", replacementIcon.parentNode);
    replacementIcon.setAttribute("attached_listener", "true");
  };
  const handleSeatClickLargeScreen = (id: string, event: MouseEvent) => {
    //Hacky solution to access state variables is by accessing state inside the functional setter functions
    // with prev state values. Regulate setState functions don't work because function only has access
    // to initial state.
    setZoomLevel((prevZoomLevel) => {
      console.log("Prev zoom level: ", prevZoomLevel);
      if (prevZoomLevel < maxZoomLevel) {
        reactZoomRef.current?.zoomToElement(id, maxZoomLevel);
        return maxZoomLevel;
      } else {
        return prevZoomLevel;
      }
    });

    if (props.currentEvent.diagramData?.zoomOnSectionClick ?? false) {
      setSectionView((prevSectionView) => {
        if (!prevSectionView) {
          if (props.selectedSeats.has(id)) {
            props.removeSelectedSeat(id);
          } else {
            props.addSelectedSeat(id);
          }
        }
        return prevSectionView;
      });
    } else {
      if (props.selectedSeats.has(id)) {
        props.removeSelectedSeat(id);
      } else {
        props.addSelectedSeat(id);
      }
    }
  };

  const handleSectionClickLargeScreen = (id: string, event: MouseEvent) => {
    // props.setSelectedSection(id)
    if (props.currentEvent.diagramData?.zoomOnSectionClick ?? false) {
      setSectionView((prevSectionView) => {
        if (prevSectionView) {
          reactZoomRef.current?.zoomToElement(id, maxZoomLevel);
          setZoomLevel((prevZoomLevel) => {
            return maxZoomLevel;
          });
        }
        console.log("New section click: ", prevSectionView);
        return prevSectionView;
      });
      // if (changeZoomToMax){
      //     console.log("Changing zoom to max")
      //     setZoomLevel((prevZoomLevel) => {
      //         return maxZoomLevel
      //     })
      // }
    }
  };

  const createHandleSectionHover = (): ((
    id: string,
    event: MouseEvent,
  ) => void) => {
    if (props.currentEvent.ticketingType === EventSeatTypes.SECTIONS_BASED) {
      return (id: string, event: MouseEvent) => {
        console.log("Handle section hover called: ", id);
        setTooltipPosition({ x: event.pageX, y: event.pageY });
        setShowTooltip(true);
        setTooltipSectionObject(props.sectionIdDetailsMap[id]);
        console.log("This is map: ", props.sectionIdDetailsMap);
        console.log(
          "This is tooltip section details: ",
          props.sectionIdDetailsMap[id],
        );
      };
    }
    return (id: string, event: MouseEvent) => {
      console.log("Fake hover");
      // setTooltipPosition({x: event.pageX, y: event.pageY});
      // setShowTooltip(true);
      // setTooltipObject(props.seatIdMap[id])
    };
  };

  const createHandleSectionEndHover = (): ((
    id: string,
    event: MouseEvent,
  ) => void) => {
    return (id: string, event: MouseEvent) => {
      // setTooltipPosition({x: event.pageX, y: event.pageY});
      setShowTooltip(false);
    };
  };

  let toolTipComponent: React.ReactNode;
  switch (props.currentEvent.ticketingType) {
    case EventSeatTypes.SEATS_BASED:
      toolTipComponent = (
        <Tooltip seatObject={tooltipSeatObject} position={tooltipPosition} />
      );
      break;
    case EventSeatTypes.SECTIONS_BASED:
      toolTipComponent = (
        <Tooltip
          sectionObject={tooltipSectionObject}
          position={tooltipPosition}
        />
      );
      break;
  }

  const createHandleSeatHover = (): ((
    id: string,
    event: MouseEvent,
  ) => void) => {
    return (id: string, event: MouseEvent) => {
      setTooltipPosition({ x: event.pageX, y: event.pageY });
      setShowTooltip(true);
      setTooltipSeatObject(props.seatIdMap[id]);
    };
  };

  const createHandleSeatEndHover = (): ((
    id: string,
    event: MouseEvent,
  ) => void) => {
    return (id: string, event: MouseEvent) => {
      // setTooltipPosition({x: event.pageX, y: event.pageY});
      setShowTooltip(false);
    };
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="start"
      h={"100%"}
      overflowY="auto"
      width={"100%"}
      className="h-full max-h-[60vh] md:min-h-screen w-full"
    >
      {showTooltip && toolTipComponent}
      <TransformWrapper
        initialScale={1}
        // initialPositionX={-300}
        // initialPositionY={20}
        centerOnInit={true}
        pinch={{ step: 100 }}
        wheel={{ step: 300, smoothStep: 0.005 }}
        minScale={minZoomLevel}
        maxScale={maxZoomLevel}
        ref={reactZoomRef}
        onZoom={(event) => {
          setZoomLevel(event.state.scale);
        }}
      >
        <TransformComponent
          wrapperStyle={{ width: "100%", height: "86%" }}
          contentStyle={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DiagramSVG
            handleSeatClick={handleSeatClickLargeScreen}
            handleSeatHover={createHandleSeatHover()}
            handleSeatEndHover={createHandleSeatEndHover()}
            handleSectionClick={handleSectionClickLargeScreen}
            handleSectionEndHover={createHandleSectionEndHover()}
            handleSectionHover={createHandleSectionHover()}
            svgText={diagramSVGQuery.data}
          />
        </TransformComponent>
      </TransformWrapper>
    </Box>
  );
};
export default ReactZoom;
