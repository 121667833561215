import React, { useEffect, useState } from "react";

import {
  Button,
  Fade,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";

import { useFormik } from "formik";
import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import {
  CreateEventFormSteps,
  EventSeatTypes,
  IEvent,
} from "../../../types/interface";
import { TICKET_PLANS } from "../../../utils/constants";
import { selectSeatingTypeSchema } from "../../../utils/validation";
import RadioCard from "../../RadioCard";
import ImageDrop from "../ImageDrop";
import { useRecoilState } from "recoil";
import {
  formProgressAtom,
  formStepAtom,
  incompleteEventAtom,
  prevCreateEventFormStepAtom,
  SelectSeatingTypeAtom,
} from "../../../recoil/atom";
import { apiClient } from "../../../api";
import { makeToast } from "../../../utils/toast";
import { uploadImageToAws } from "../../../utils/aws";

const SelectSeatingTypeForm: React.FC = () => {
  const [formStep, setFormStep] = useRecoilState(formStepAtom);
  const [formProgress, setFormProgress] = useRecoilState(formProgressAtom);
  const [prevCreateEventFormStep, setPrevCreateEventFormStep] = useRecoilState(
    prevCreateEventFormStepAtom,
  );
  const [incompleteEvent, setIncompleteEvent] =
    useRecoilState(incompleteEventAtom);

  const [formState, setFormState] = useRecoilState(SelectSeatingTypeAtom);

  const [imageChanged, setImageChanged] = useState(false);
  const formik = useFormik({
    initialValues: {
      ticketingType:
        incompleteEvent?.ticketingType ?? EventSeatTypes.STANDARD_BASED,
      seatingPlanImg: incompleteEvent?.ticketingPlanStructure ?? "",
      seatingPlanDescription: incompleteEvent?.seatingPlanDescription ?? "",
    },
    enableReinitialize: true,
    validationSchema: selectSeatingTypeSchema,

    onSubmit: async ({
      ticketingType,
      seatingPlanImg,
      seatingPlanDescription,
    }) => {
      console.log("On submit called in selectSeatingType");
      let seatingPlanImgUrl = incompleteEvent?.ticketingPlanStructure;
      if (ticketingType !== EventSeatTypes.STANDARD_BASED && imageChanged) {
        seatingPlanImgUrl = await uploadImageToAws(seatingPlanImg);
      }

      const eventUpdates: IEvent = incompleteEvent;
      eventUpdates.ticketingType = ticketingType;
      eventUpdates.seatingPlanDescription = seatingPlanDescription;
      eventUpdates.ticketingPlanStructure = seatingPlanImgUrl;

      try {
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await apiClient.put(
          `/event/${incompleteEvent._id}`,
          eventUpdates,
          {
            headers,
          },
        );
        console.log("This is response SelectSeatingTypeForm: ", response);
        setIncompleteEvent(response.data.event as IEvent);
        if (ticketingType === EventSeatTypes.STANDARD_BASED) {
          setFormStep(CreateEventFormSteps.ADD_SEATS_GROUPING_FORM);
        } else {
          setFormStep(CreateEventFormSteps.ADD_BANK_DETAILS);
        }
      } catch (err) {
        makeToast(
          "error",
          "Error",
          "Could not update event. Please try again later",
        );
      }
    },
  });

  useEffect(() => {
    setFormProgress(50);
    setPrevCreateEventFormStep(CreateEventFormSteps.EVENT_DETAILS_2);
  }, [setFormProgress, setPrevCreateEventFormStep]);

  const { getRadioProps } = useRadioGroup({
    name: "seats-type",
    defaultValue: formik.values.ticketingType,
    onChange: (value) => formik.setFieldValue("ticketingType", value),
  });

  const handleCoverImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("This is new: ", e.target.files);
    if (e.target?.files.length === 0) {
      return;
    }
    setImageChanged(true);
    formik.setFieldValue(
      "seatingPlanImg",
      URL.createObjectURL(e.target.files![0]),
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Heading
          textAlign={"center"}
          fontSize={"lg"}
          fontWeight="semibold"
          my={8}
        >
          Choice Of Ticketing Plan?
        </Heading>
        <SimpleGrid columns={1} spacing={6}>
          <RadioGroup>
            <Stack spacing={8} direction="column">
              {TICKET_PLANS.map(({ id, value, label, color }) => {
                const radio = getRadioProps({ value });
                return (
                  <RadioCard key={id} {...radio} color={color}>
                    <VStack textAlign="center">
                      <Text
                        fontWeight="semibold"
                        color="gray.600"
                        fontSize="md"
                      >
                        {label.title}
                      </Text>
                      <Text
                        fontSize="sm"
                        textAlign="center"
                        color="gray.500"
                        as={"p"}
                      >
                        {label.subTitle}
                      </Text>
                    </VStack>
                  </RadioCard>
                );
              })}
            </Stack>
          </RadioGroup>

          <Fade
            in={formik.values.ticketingType !== EventSeatTypes.STANDARD_BASED}
            unmountOnExit={true}
          >
            <VStack spacing={6}>
              <ImageDrop
                headingText="Upload drawing or picture of venue"
                imageUploaded={formik.values.seatingPlanImg}
                onChange={(e) => handleCoverImgChange(e)}
                isInvalid={
                  Boolean(formik.errors.seatingPlanImg) &&
                  Boolean(formik.touched.seatingPlanImg)
                }
              />
            </VStack>
          </Fade>
          <Fade
            in={formik.values.ticketingType !== EventSeatTypes.STANDARD_BASED}
            unmountOnExit={true}
          >
            <FormControl
              my={4}
              isInvalid={
                Boolean(formik.touched.seatingPlanDescription) &&
                Boolean(formik.errors.seatingPlanDescription)
              }
            >
              <FormLabel
                htmlFor="seatingPlanDescription"
                fontWeight={"normal"}
                fontSize={"sm"}
              >
                Please explain in detail the seating pricing structure of the
                venue. We will use this description to create the seating plan
                for you.
              </FormLabel>
              <Textarea
                id="seatingPlanDescription"
                placeholder="Enter your justification..."
                {...formik.getFieldProps("seatingPlanDescription")}
                minHeight="200px"
                fontSize={"0.8rem"}
              />
              {formik.errors.seatingPlanDescription && (
                <FormErrorMessage>
                  {formik.errors.seatingPlanDescription}
                </FormErrorMessage>
              )}
            </FormControl>
          </Fade>
        </SimpleGrid>

        <HStack justifyContent={"center"} gap={4} mt={10}>
          <Button
            type="button"
            onClick={() => setFormStep(prevCreateEventFormStep)}
            variant="outline"
          >
            <Icon as={MdOutlineArrowBack} />
          </Button>

          <Button
            type="submit"
            onClick={formik.submitForm}
            variant="solid"
            isLoading={formik.isSubmitting}
            disabled={formik.isSubmitting}
          >
            <Icon as={MdOutlineArrowForward} />
          </Button>
        </HStack>
      </form>
    </>
  );
};

export default SelectSeatingTypeForm;
