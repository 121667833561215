import React from "react";

import { Heading, Stack, Text, VStack } from "@chakra-ui/react";

import BackButton from "../components/Buttons/BackButton";
import Footer from "../components/Footer";
import WithSubnavigation from "../components/Nav/Navbar";

const Privacy: React.FC = (): JSX.Element => {
  return (
    <VStack w={"full"}>
      <Stack
        w={{
          base: "full",
          md: "70%",
        }}
        p={4}
      >
        <BackButton />
        <Heading>PRIVACY POLICY</Heading>
        <Text>
          Musha Tickets (hereafter referred to as "the Platform") is committed
          to protecting your privacy. This privacy policy explains how we
          collect, use, and disclose personal information.
        </Text>
        <Heading pt={1} pl={3} size={"sm"}>
          1. Information Collection
        </Heading>
        <Text>
          We collect personal information when you create an account on our
          Platform, purchase tickets, or communicate with us via email or chat.
          The personal information we collect may include your name, email
          address, phone number, billing address, and payment information.
        </Text>
        <Heading pt={1} pl={3} size={"sm"}>
          2. Information Use
        </Heading>
        <Text>
          We use your personal information to process your ticket purchases,
          communicate with you about events and services, and improve our
          Platform's performance. We may also use your personal information for
          marketing purposes, but you can opt-out of marketing emails at any
          time.
        </Text>
        <Heading pt={1} pl={3} size={"sm"}>
          3. Information Disclosure
        </Heading>
        <Text>
          We may share your personal information with third-party service
          providers to help us process your ticket purchases and communicate
          with you. We may also disclose your personal information if required
          by law or to protect our legal rights.
        </Text>
        <Heading pt={1} pl={3} size={"sm"}>
          4. Information Security
        </Heading>
        <Text>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no data transmission
          over the internet can be guaranteed to be 100% secure. Therefore, we
          cannot guarantee the security of any information you transmit to us,
          and you do so at your own risk.
        </Text>
        <Heading pt={1} pl={3} size={"sm"}>
          5. Cookies
        </Heading>
        <Text>
          We use cookies on our Platform to improve your browsing experience and
          personalize content. Cookies are small files that are stored on your
          device's hard drive. You can disable cookies in your web browser, but
          this may limit your access to certain features of the Platform.
        </Text>
        <Heading pt={1} pl={3} size={"sm"}>
          6. Third-Party Links
        </Heading>
        <Text>
          Our Platform may contain links to third-party websites. We are not
          responsible for the privacy practices or content of these websites. We
          encourage you to read the privacy policies of these websites before
          providing any personal information.
        </Text>
        <Heading pt={1} pl={3} size={"sm"}>
          7. Children's Privacy
        </Heading>
        <Text>
          Our Platform is not intended for use by children under the age of 13.
          We do not knowingly collect personal information from children under
          the age of 13. If we learn that we have collected personal information
          from a child under the age of 13, we will delete that information
          immediately.
        </Text>
        <Heading pt={1} pl={3} size={"sm"}>
          8. Changes to Privacy Policy
        </Heading>
        <Text>
          We reserve the right to modify this privacy policy at any time without
          prior notice. Any changes to the privacy policy will be effective
          immediately upon posting on the Platform.
        </Text>
        <Text>
          If you have any questions or concerns about our privacy policy, please
          contact us at{" "}
          <a href="mailto:mushatickets@gmail.com">mushatickets@gmail.com</a>
        </Text>
      </Stack>
    </VStack>
  );
};

export default Privacy;
