import React from "react";

import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { HStack, IconButton } from "@chakra-ui/react";

const BackButton: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <HStack w={"full"} h={"auto"} paddingBottom={4}>
        <IconButton
          aria-label="go to previous page"
          icon={<IoArrowBack fontWeight={"bold"} />}
          onClick={() => navigate(-1)}
        />
      </HStack>
    </>
  );
};

export default BackButton;
