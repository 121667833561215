import { Box, HStack, Heading, SimpleGrid, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomNumberInput from "../components/AddEventDiagram/CustomNumberInput";
import { Rnd } from "react-rnd";
import { MdChair } from "react-icons/md";

const AddEventDiagramScreen: React.FC = (): JSX.Element => {
  const [topLeft, setTopLeft] = useState<string | number>(0);
  const [topRight, setTopRight] = useState<string | number>(0);
  const [bottomLeft, setBottomLeft] = useState<string | number>(0);
  const [bottomRight, setBottomRight] = useState<string | number>(0);
  const [width, setWidth] = useState<string | number>(300);
  const [height, setHeight] = useState<string | number>(430);
  const [seats, setSeats] = useState<string | number>(2);
  let seatsArray = Array.from({ length: seats as number });

  useEffect(() => {
    seatsArray = Array.from({ length: seats as number });
  }, [seats]);

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "green",
  } as const;

  return (
    <HStack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        w={"75%"}
        h={"100vh"}
        bg={"gray.400"}
      >
        <Stack
          borderBottomRightRadius={bottomRight}
          borderBottomLeftRadius={bottomLeft}
          borderTopLeftRadius={topLeft}
          borderTopRightRadius={topRight}
          w={`${width}px`}
          height={height}
          bg={"aliceblue"}
        >
          {seatsArray.map((seat, i) => (
            <Rnd
              style={style}
              default={{
                x: i + 2,
                y: i + 2,
                width: 25,
                height: 35,
              }}
            >
              <MdChair size={15} />
            </Rnd>
          ))}
        </Stack>
      </Stack>
      <Stack w={"25%"} h={"100vh"}>
        <Heading
          mt={"4"}
          fontSize={"lg"}
          fontWeight={"light"}
          textAlign={"center"}
        >
          Border Radius
        </Heading>
        <SimpleGrid
          w={"full"}
          justifyContent={"space-between"}
          columns={2}
          spacing={1}
          mb={8}
        >
          <CustomNumberInput
            value={topLeft}
            callback={(v) => setTopLeft(v)}
            title="top-left"
          />
          <CustomNumberInput
            value={topRight}
            callback={(v) => setTopRight(v)}
            title="top-right"
          />
          <CustomNumberInput
            value={bottomLeft}
            callback={(v) => setBottomLeft(v)}
            title="bottom-left"
          />
          <CustomNumberInput
            value={bottomRight}
            callback={(v) => setBottomRight(v)}
            title="bottom-left"
          />
        </SimpleGrid>
        <Box width={"full"} h={"30px"} />
        <Heading
          mt={"12"}
          fontSize={"lg"}
          fontWeight={"light"}
          textAlign={"center"}
        >
          Dimensions
        </Heading>
        <SimpleGrid
          w={"full"}
          justifyContent={"space-between"}
          columns={2}
          spacing={1}
        >
          <CustomNumberInput
            value={width}
            callback={(v) => setWidth(v)}
            title="Width"
          />
          <CustomNumberInput
            value={height}
            callback={(v) => setHeight(v)}
            title="Height"
          />
          <CustomNumberInput
            value={seats}
            callback={(v) => setSeats(v)}
            title="Number of Chairs"
          />
        </SimpleGrid>
        <Box width={"full"} h={"30px"} />
      </Stack>
    </HStack>
  );
};

export default AddEventDiagramScreen;
