import React, { useState, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";
import debounce from "lodash/debounce";
import { BiSearchAlt } from "react-icons/bi";
import {
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  VStack,
  Button,
  useColorMode,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { IEvent } from "../../types/interface";
import { API_URL } from "../../utils/constants";
import EventSearched from "../EventComponent/Event/EventSearched";
import { searchQueryState, searchResultsState } from "../../recoil/atom";
import { useNavigate } from "react-router-dom";

const SearchComponent: React.FC = (): JSX.Element => {
  const [noResultsFromApi, setNoResultsFromApi] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { colorMode } = useColorMode();
  const navigation = useNavigate();

  const debounceTime: number = 2500;

  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);
  const [searchResults, setSearchResults] = useRecoilState(searchResultsState);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    console.log("Input Value:", newValue);
    setSearchQuery(newValue);

    if (newValue.length > 0) {
      debouncedFetchData(newValue);
      // setIsEmptyQuery(false);
    } else {
      // setIsEmptyQuery(true);
      setSearchResults([]);
    }
  };

  const debouncedFetchData = debounce(async (inputValue: string) => {
    console.log("Debounced Fetch Data:", inputValue);
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${API_URL}/event/search?searchTerm=${inputValue}&pageNum=${0}`
      );
      const data = response.data;
      console.log(data);
      setSearchResults(data.events.slice(0, 5)); // Limit results to 5
      if (data.events.length === 0) {
        setNoResultsFromApi(true);
      } else {
        setNoResultsFromApi(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, debounceTime);

  const searchComponentRef = useRef(null);
  useEffect(() => {
    const handleCloseSearchResults = (event: MouseEvent) => {
      if (
        searchComponentRef.current &&
        !searchComponentRef.current.contains(event.target as Node)
      ) {
        setSearchResults([]);
        setNoResultsFromApi(false);
      }
    };

    document.addEventListener("click", handleCloseSearchResults);

    return () => {
      document.removeEventListener("click", handleCloseSearchResults);
    };
  }, [setSearchResults]);

  return (
    <Stack pos={"relative"} w={"full"} ref={searchComponentRef}>
      <VStack
        w={"full"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <InputGroup w={{ base: "90%", md: "50%" }} borderColor={"gray.400"}>
          <Input
            onChange={handleInputChange}
            placeholder="Search for event"
            value={searchQuery}
          />
          <InputRightElement>
            {isLoading ? (
              <Spinner size="sm" color="gray.500" />
            ) : (
              <BiSearchAlt color="green.500" />
            )}
          </InputRightElement>
        </InputGroup>
        {searchResults.length > 0 && (
          <SimpleGrid
            p={2}
            w={{ base: "90%", md: "50%" }}
            justifyContent={"space-around"}
            columns={1}
            shadow={"md"}
            rounded={"md"}
            className={`gap-2 absolute top-[100%] border z-50 ${
              colorMode === "dark" ? "bg-[#1e232f]" : " bg-white"
            }`}
          >
            {searchResults.map((event: IEvent) => (
              <EventSearched key={event._id} eventCard={event} />
            ))}
            <Button
              onClick={() => {
                navigation(`/search/${searchQuery}`);
              }}
              variant="outline"
            >
              See all results
            </Button>
          </SimpleGrid>
        )}

        {noResultsFromApi && searchQuery.length !== 0 && (
          <SimpleGrid
            p={2}
            w={{ base: "90%", md: "50%" }}
            justifyContent={"space-around"}
            columns={1}
            shadow={"md"}
            rounded={"md"}
            className={`gap-2 absolute top-[100%] border z-50 ${
              colorMode === "dark" ? "bg-[#1e232f]" : " bg-white"
            }`}
          >
            <Text textAlign={"center"}>No search results found.</Text>
          </SimpleGrid>
        )}
      </VStack>
    </Stack>
  );
};

export default SearchComponent;
