import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useEffect, useState } from "react";

import { CognitoUserAttribute } from "amazon-cognito-identity-js";
// import PhoneInput from "react-phone-number-input";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import WithSubnavigation from "../components/Nav/Navbar";
import userPool from "../userPool";
import colors from "../utils/colors";
import { validateEmail } from "../utils/functions";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import termsAndConditions from "./TermsOfUseContent";
import TermsOfUseContent from "./TermsOfUseContent";
import { apiClient } from "../api";
import { useMutation } from "react-query";
import { API_URL } from "../utils/constants";
import { useSetRecoilState } from "recoil";
import { userAccessToken } from "../recoil/atom";

export default function SignupCard() {
  const [showPassword, setShowPassword] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [renter_password, setRenterPassword] = useState<string>("");
  const [first_Name, setFirst_Name] = useState<string>("");
  const [last_Name, setLast_Name] = useState<string>("");
  const [phone_Number, setPhone_Number] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validPassword, setValidPassword] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isToastActive, setIsToastActive] = useState(false);
  // const setUserAccessToken = useSetRecoilState(userAccessToken);

  const toast = useToast();
  const navigate = useNavigate();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  /*
    if (
        1+1 === 3
      ) {
        return toast({
          title: "Invalid Phone Number",
          description:
            "Phone number must start with 09 or 07 and must be equal to 10 characters",
          status: "warning",
          duration: 15000,
          isClosable: true,
        });
      }
  */

  useEffect(() => {
    if (
      !Boolean(email) ||
      !Boolean(password) ||
      !Boolean(renter_password) ||
      !Boolean(first_Name) ||
      !Boolean(last_Name) ||
      !Boolean(phone_Number)
    ) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [email, password, first_Name, last_Name, phone_Number, renter_password]);

  const validatePassword = (password) => {
    // const passwordRegex =
    //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*.]).{6,}$/;
    const passwordRegex = /^.{6,}$/;
    console.log("Password:", password);
    const isValidPassword = passwordRegex.test(password);
    console.log("Is Valid Password:", isValidPassword);
    setValidPassword(isValidPassword);
  };

  const submit = async () => {
    try {
      if (invalid) {
        return showToast({
          title: "Please Enter all Fields",
          status: "warning",
        });
      }

      if (password !== renter_password) {
        return showToast({
          title: "Passwords are different",
          status: "warning",
        });
      }

      if (!validPassword) {
        return showToast({
          title: "Password must be at least 6 characters long.",
          status: "warning",
        });
      }

      if (!validateEmail(email)) {
        return showToast({
          title: "Email is Invalid",
          description: "Please enter a valid email",
          status: "warning",
        });
      }

      setIsLoading(true);
      const response: any = await signupCognitoUser();

      console.log("AWS::RESPONSE", response);
      if (!response) {
        setIsLoading(false);
        return showToast({
          title: "There was an error signing up, please try again",
          status: "error",
        });
      }
      // addUserToDatabaseMutation(response?.userSub);
      showToast({
        title: "Account created.",
        description: "Your Account has been Successfully Created.",
        status: "success",
      });
      setIsLoading(false);
      navigate("/sign_in");
    } catch (error: any) {
      console.log(error);
      showToast({
        title: "Error",
        description: `${error.message as string}`,
        status: "error",
      });
      setIsLoading(false);
    }
  };

  const showToast = (props) => {
    if (!isToastActive) {
      setIsToastActive(true);
      toast({
        duration: 9000,
        isClosable: true,
        ...props,
        onCloseComplete: () => setIsToastActive(false),
      });
    }
  };

  const signupCognitoUser = () => {
    let attributeList: any[] = [];

    attributeList.push(
      new CognitoUserAttribute({
        Name: "given_name",
        Value: first_Name,
      }),
    );

    attributeList.push(
      new CognitoUserAttribute({
        Name: "family_name",
        Value: last_Name,
      }),
    );

    attributeList.push(
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: phone_Number,
      }),
    );

    return new Promise((resolve, reject) =>
      userPool.signUp(email, password, attributeList, [], (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      }),
    );
  };

  const { mutate: addUserToDatabaseMutation, isLoading: addUserToDbLoading } =
    useMutation(
      async (aws_id) => {
        const res = await apiClient.post(`/users/${aws_id}`);
        console.log("Sending user to DB mutation");
        return res.data;
      },
      {
        onSuccess(data) {
          console.log("On success called sign in");
          showToast({
            title: "Account created.",
            description: "Your Account has been Successfully Created.",
            status: "success",
          });
          setIsLoading(false);
          navigate("/sign_in");
        },
        onError(error) {
          console.log("On error called");
          showToast({
            title: "Error creating account.",
            description: "Please try again.",
            status: "error",
          });
          setIsLoading(false);
        },
      },
    );

  console.log(phone_Number);
  console.log(password);

  const handlePhoneNumberChange = (value) => {
    // Check if the value already starts with a plus sign
    if (!value.startsWith("+")) {
      // If not, prepend the plus sign
      value = "+" + value;
    }
    // Update the phone number state
    setPhone_Number(value);
  };

  // Inside the PhoneInput component:
  <PhoneInput
    country="zm"
    regions={"africa"}
    value={phone_Number}
    onChange={handlePhoneNumberChange}
    inputStyle={{
      backgroundColor: "white",
      fontSize: "16px",
      height: "40px",
      width: "100%",
    }}
  />;

  /*
<InputGroup>
                <InputLeftAddon children="+260" />
                <Input
                  type="number"
                  name="phone_number"
                  placeholder="9/7 0000000"
                  _placeholder={{ color: "gray.500" }}
                  onChange={(e) => setPhone_Number(e.target.value)}
                />
              </InputGroup>
  */

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={"gray.50"}
      flexDirection={"column"}
    >
      <WithSubnavigation />
      <Stack as={"form"} spacing={8} mx={"auto"} maxW={"lg"} py={6} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} color={colors.primary} textAlign={"center"}>
            Sign up
          </Heading>
          <Text
            fontSize={"lg"}
            fontWeight={"medium"}
            letterSpacing={"wider"}
            color={"orange.500"}
          >
            Reliable e-Ticketing Service
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={"white"}
          border={"2"}
          borderColor={"gray.400"}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4} color={"gray.700"}>
            <HStack>
              <Box>
                <FormControl id="firstName" isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    name="first_name"
                    placeholder="John"
                    borderColor={"gray.300"}
                    _placeholder={{ color: "gray.500" }}
                    onChange={(e) => setFirst_Name(e.target.value)}
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl id="lastName" isRequired>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    name="last_name"
                    placeholder="Banda"
                    borderColor={"gray.300"}
                    _placeholder={{ color: "gray.500" }}
                    onChange={(e) => setLast_Name(e.target.value)}
                  />
                </FormControl>
              </Box>
            </HStack>
            <FormControl id="phone_number" isRequired>
              <FormLabel>Phone Number</FormLabel>

              <PhoneInput
                country="zm"
                regions={"africa"}
                value={phone_Number}
                onChange={handlePhoneNumberChange}
                inputStyle={{
                  backgroundColor: "white",
                  fontSize: "16px",
                  height: "40px",
                  width: "100%",
                }}
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                name="email"
                placeholder="your-email@example.com"
                borderColor={"gray.300"}
                _placeholder={{ color: "gray.500" }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="**********"
                  borderColor={"gray.300"}
                  _placeholder={{ color: "gray.500" }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword(e.target.value);
                  }}
                />
                <InputRightElement h={"full"}>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="renter-password" isRequired>
              <FormLabel>Re-enter Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="**********"
                  borderColor={"gray.300"}
                  _placeholder={{ color: "gray.500" }}
                  onChange={(e) => setRenterPassword(e.target.value)}
                />
                <InputRightElement h={"full"}>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <p className="mt-10 text-center text-sm font-semibold text-gray-500">
              By Signing up you agree to the <br />
              <a
                className="mt-4 cursor-pointer text-green-700 underline"
                onClick={openModal}
              >
                Terms And Conditions
              </a>
              <TermsAndConditionsModal isOpen={isOpen} onClose={closeModal}>
                <TermsOfUseContent />
              </TermsAndConditionsModal>
            </p>
            <Stack spacing={10} pt={2}>
              <Button
                loadingText="Submitting"
                isLoading={isLoading}
                size="lg"
                bg={colors.primary}
                color={"white"}
                _hover={{
                  bg: "blue.800",
                }}
                onClick={() => submit()}
              >
                Sign up
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={"center"}>
                Already have an account with us?
                <Link to={"/sign_in"}>
                  <Text color={colors.primary} fontWeight={"bold"}>
                    Login
                  </Text>
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
