import React from "react";

import { Heading, Stack, Text, VStack } from "@chakra-ui/react";

import BackButton from "../components/Buttons/BackButton";
import Footer from "../components/Footer";
import WithSubnavigation from "../components/Nav/Navbar";

const Refund: React.FC = (): JSX.Element => {
  return (
    <VStack w={"full"}>
      <Stack
        w={{
          base: "full",
          md: "70%",
        }}
        p={4}
      >
        <Heading>REFUND POLICY</Heading>
        <Heading size={"md"}>Introduction</Heading>
        <Text>
          When purchasing tickets for any event, you are entering into a
          contract with the organizer, not with Musha Tickets. We do not verify
          the authenticity of events, fundraisers, or subscription offers, nor
          do we guarantee the accuracy of any statements or descriptions made by
          the organizers.
        </Text>
        <Text>
          Events may be subject to specific terms and conditions set by the
          event organizer. It is important to read and understand these terms
          before purchasing a ticket on Musha Tickets. We are not responsible
          for any cancellations, postponements, limitations, or restrictions of
          an event.
        </Text>
        <Text>
          Any disputes or complaints you have about an event, campaign, or
          subscription should be addressed directly with the organizer. Musha
          Tickets is not liable for any such disputes or complaints.
        </Text>
        <Text>
          Occasionally, events may be canceled, postponed, rescheduled to a
          different date or time, or moved to a different venue:
        </Text>
        <Text>
          <b>If the event is canceled:</b>No action is required to obtain a
          refund from us unless directed by the Event Organizers, who handle
          refunds directly with us. Once we receive funds from the Event
          Organizer, we will issue a refund to the original method of payment
          used at the time of purchase. Please note that this process may take
          up to 2 weeks to complete.
        </Text>
        <Text>
          <b>If the event is postponed, rescheduled, or moved:</b>Your
          ticket(s), including any additional add-ons or upgrades, will still be
          valid, and no further action is required. In this case, you will have
          the option to either receive a refund (excluding tax and service fees
          paid for the ticket) or keep your ticket(s) for the postponed,
          rescheduled, or moved event.
        </Text>
        <Text>
          If your event is canceled, postponed, rescheduled, or moved, we will
          try to contact you to provide updates on the event's status and inform
          you of any refund procedures. For specific instructions regarding any
          particular canceled, postponed, rescheduled, or moved event, please
          check the event information online and in your account (which will
          include the most up-to-date information) or contact us.
        </Text>
        <Text>
          Please note that we will not be responsible for any travel or other
          expenses incurred by you or anyone else in connection with a canceled,
          postponed, rescheduled, or moved event.
        </Text>
        <Heading size={"md"}>Refunds:</Heading>
        <Text>
          All sales are final, and refunds are only allowed in limited
          circumstances, as explained in this section and the "Canceled,
          Postponed, Rescheduled, and Moved Events" section above. Before
          purchasing tickets, carefully review the event and seat selection.
          Tickets cannot be replaced if they are lost, stolen, or damaged.
        </Text>
        <Text>
          For cancellations, postponements, rescheduling, or moved dates,
          refunds will be issued for the ticket price paid, excluding tax and
          service fees, within approximately one week. If a refund is issued, it
          will be processed back to the original method of payment used at the
          time of purchase. Refunds cannot be issued to a different mobile money
          number, credit card, or debit card. If your phone number, credit card,
          or debit card number has changed, the refund will still be made to the
          specific phone number or card used for purchasing the ticket.
        </Text>
        <Text>
          Please note that seating maps represent the venue's layout, but they
          are subject to change at any time. Refunds will not be allowed if the
          seating map is updated, additional seats/rows are added, or seats
          change due to a venue change after purchase.
        </Text>
        <Text>
          Occasionally, we may offer tickets at a discount after the original
          on-sale date, and we will not refund the difference between the
          original price and the sale price. Additionally, there are no refunds
          or returns for adjusted prices set by the Event Organizers.
        </Text>
        <Heading size={"md"}>Pricing and Other Errors:</Heading>
        <Text>
          If the amount you pay for a ticket is incorrect (regardless of whether
          it is incorrect due to an error in a price posted on the Site or
          communicated to you otherwise), or if you are able to order a ticket
          that was not supposed to be released for sale, we reserve the right to
          cancel that ticket (or the order for that ticket) and refund you the
          amount you paid. This policy applies regardless of whether the error
          occurred due to human error or a transactional malfunction of the Site
          or other Musha Tickets operated system. We will not be liable for any
          travel or other expenses incurred by you or anyone else in connection
          with errors of this nature.
        </Text>
      </Stack>
    </VStack>
  );
};

export default Refund;
