import { useEffect, useState } from "react";

import moment from "moment";
import { useParams } from "react-router-dom";

import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";

import WithSubnavigation from "../components/Nav/Navbar";
import useGetData from "../hooks/useGetData";
import { IEvent } from "../types/interface";
import { API_URL } from "../utils/constants";
import parse from "html-react-parser";

const UserEvent: React.FC = (): JSX.Element => {
  const [event, setEvent] = useState<IEvent | null>(null);
  const { event_id } = useParams();

  const eventResult = useGetData(
    `user_events_details_${event_id}`,
    `${API_URL}/event/${event_id}`,
  );

  useEffect(() => {
    if (eventResult.isSuccess && eventResult.data !== null) {
      setEvent(eventResult.data);
    }
  }, [eventResult.data, eventResult.isSuccess]);

  return (
    <>
      <WithSubnavigation />
      <Container maxW={"7xl"}>
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 18, md: 24 }}
        >
          {!eventResult.isLoading &&
            eventResult.isSuccess &&
            event !== null && (
              <>
                <Flex>
                  <Image
                    rounded={"md"}
                    alt={"event image"}
                    src={event.imgBanner}
                    fit={"cover"}
                    align={"center"}
                    w={"100%"}
                    h={{ base: "100%", sm: "400px", lg: "500px" }}
                  />
                </Flex>
                <Stack spacing={{ base: 6, md: 10 }}>
                  <Box as={"header"}>
                    <VStack w={"75%"} alignItems={"start"}>
                      <Heading
                        pb={4}
                        fontWeight={"medium"}
                        fontSize={"md"}
                        display={"inline-flex"}
                      >
                        <b>
                          <p className={"text-pink-500 mr-2"}>
                            {moment(event.eventStart).format("MMMM Do")}
                          </p>
                        </b>{" "}
                        {moment(event.eventStart).format("dddd, h:mm a")}
                      </Heading>
                    </VStack>
                    <Heading
                      lineHeight={1.1}
                      fontWeight={600}
                      fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                    >
                      {event.title}
                    </Heading>
                    <Text color={"gray.900"} fontWeight={300} fontSize={"md"}>
                      {event.locationName}
                    </Text>
                  </Box>

                  <Stack
                    spacing={{ base: 4, sm: 6 }}
                    direction={"column"}
                    divider={<StackDivider borderColor={"gray.200"} />}
                  >
                    <VStack spacing={{ base: 4, sm: 6 }}>
                      <Box
                        color={"gray.500"}
                        fontSize={"lg"}
                        fontWeight={"300"}
                      >
                        {parse(event.description)}
                      </Box>
                    </VStack>
                    <Box>
                      <Text
                        fontSize={{ base: "16px", lg: "18px" }}
                        color={"yellow.500"}
                        fontWeight={"500"}
                        textTransform={"uppercase"}
                        mb={"4"}
                      >
                        User and Bank Details
                      </Text>

                      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                        <List spacing={2}>
                          <ListItem>{event.contactEmail}</ListItem>
                          <ListItem>{event.contactPhone}</ListItem>
                          <ListItem>{event.meetingCode}</ListItem>
                        </List>
                        <List spacing={2}>
                          <ListItem>{event.bankName}</ListItem>
                          <ListItem>{event.bankAccountNumber}</ListItem>{" "}
                          <ListItem>{event.bankBranch}</ListItem>
                          <ListItem>{event.bankCode}</ListItem>
                        </List>
                      </SimpleGrid>
                    </Box>
                  </Stack>
                </Stack>
              </>
            )}
        </SimpleGrid>
      </Container>
    </>
  );
};

export default UserEvent;
