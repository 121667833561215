// row or table diagram data structure
import { ISeat, RowOrTableType, rtDisplayData } from "../types/interface";
import cloneDeep from "lodash/cloneDeep";

type KeyValue = {
  key: string;
  value: number;
};
type RtDiagramData = {
  seatIds: string[];
  availableSeats: number;
  maxConsecutiveSeats: number;
  price: number;
  rowOrTable: RowOrTableType;
  name: string;
};

export class rtDiagramDS {
  private seatNumberArray: number[] = [];

  private maxConsecutiveSeats: number = 0;

  private price: number = 0;
  private section = "";
  private name = "";
  private rowOrTable: RowOrTableType = RowOrTableType.ROW;

  private doneBuilding = false;

  insertSortedSeatnumber(numberToInsert: number): void {
    const indexToInsert = this.binarySearch(
      numberToInsert,
      0,
      this.seatNumberArray.length - 1,
    );
    this.seatNumberArray.splice(indexToInsert, 0, numberToInsert);
  }

  private binarySearch(target: number, start: number, end: number): number {
    while (start <= end) {
      const mid = Math.floor((start + end) / 2);

      if (this.seatNumberArray[mid] === target) {
        return mid; // Found exact match
      } else if (this.seatNumberArray[mid] < target) {
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }
    return start; // If not found, return the index where it should be inserted
  }

  insertSeat(seat: ISeat) {
    this.insertSortedSeatnumber(parseInt(seat.seatNumber, 10));
    this.price = parseFloat(seat.price);
    this.section = seat.section;
    this.name = seat.row;
  }

  getPrice() {
    return this.price;
  }

  getName() {
    return this.name;
  }

  getSection() {
    return this.section;
  }

  getRowOrTableType() {
    return this.rowOrTable;
  }

  private getLongestConsecutiveSequenceLength(): number {
    let maxSequenceLength = 1;
    let currentSequenceLength = 1;

    for (let i = 1; i < this.seatNumberArray.length; i++) {
      if (this.seatNumberArray[i] === this.seatNumberArray[i - 1] + 1) {
        currentSequenceLength++;
      } else {
        maxSequenceLength = Math.max(maxSequenceLength, currentSequenceLength);
        currentSequenceLength = 1;
      }
    }

    return Math.max(maxSequenceLength, currentSequenceLength);
  }

  setDoneBuilding() {
    this.maxConsecutiveSeats = this.getLongestConsecutiveSequenceLength();
    this.doneBuilding = true;
  }

  checkDoneBuilding() {
    if (!this.doneBuilding) {
      throw Error("Cannot access values before finishing build");
    }
  }

  getMaxConsecutiveSeats() {
    this.checkDoneBuilding();
    return this.maxConsecutiveSeats;
  }

  getConsecutiveSeatNumbers(count: number): number[] {
    this.checkDoneBuilding();
    console.log("Get consecutive seat numbers called. Count: ", count);
    let currentSequence: number[] = [];
    let resultSequence: number[] | null = null;
    console.log("This is local seat array: ", this.seatNumberArray);

    for (let i = 0; i < this.seatNumberArray.length; i++) {
      if (currentSequence.length === 0) {
        currentSequence.push(this.seatNumberArray[i]);
      } else if (this.seatNumberArray[i] === this.seatNumberArray[i - 1]) {
        currentSequence.push(this.seatNumberArray[i]);
      } else {
        currentSequence = [];
      }
      if (
        currentSequence.length >= count &&
        (resultSequence === null ||
          currentSequence.length < resultSequence.length)
      ) {
        resultSequence = currentSequence;
      }
    }

    // Check the last sequence
    if (
      currentSequence.length >= count &&
      currentSequence.length < resultSequence.length
    ) {
      resultSequence = currentSequence.slice();
    }

    console.log("thIS IS RESULTsequence: ", resultSequence);
    return resultSequence;
  }
}

export class rtDiagramDSContainer {
  private sortedRtIdentifierArray: string[] = [];
  private rtIdToDataMap: Record<string, rtDiagramDS> = {};

  // constructor(existingKeys: string[]) {
  //   // Initialize sortedRtIdentifierArray with existing keys
  //   this.sortedRtIdentifierArray = [...existingKeys];
  //
  //   // Populate keyToValueMap with initial values (implement as needed)
  //   // existingKeys.forEach((key) => {
  //   //   this.keyToValueMap.set(key, this.keyToValueMap.get(key));
  //   // });
  // }

  private binarySearchInsertionIndex(key: string, value: number): number {
    let low = 0;
    let high = this.sortedRtIdentifierArray.length - 1;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const midKey = this.sortedRtIdentifierArray[mid];
      const midValue = this.rtIdToDataMap[midKey].getPrice();

      if (midValue < value) {
        low = mid + 1;
      } else if (midValue > value) {
        high = mid - 1;
      } else {
        return mid; // Handle the case where values are equal (if needed)
      }
    }

    return low; // Return the insertion index
  }

  insertRtObjectInSortedArr(newPair: KeyValue): void {
    const { key, value } = newPair;

    // Find the insertion index using binary search
    const insertionIndex = this.binarySearchInsertionIndex(key, value);

    // Insert the key at the appropriate index
    this.sortedRtIdentifierArray.splice(insertionIndex, 0, key);

    // Update keyToValueMap (implement as needed)
  }

  private getRtIdentifier(seat: ISeat): string {
    return `${seat.section}-${seat.row}`;
  }

  getConsecutiveSeatNumbers(rtIdentifier: string, count: number): string[] {
    const rtObject = this.rtIdToDataMap[rtIdentifier];
    const consecutiveSeats = rtObject.getConsecutiveSeatNumbers(count);
    let result: string[] = [];
    consecutiveSeats.forEach((seatNumber) => {
      result.push(`seat-${rtIdentifier}-${seatNumber}`);
    });
    return result;
  }

  getMaxConsecutiveSeatNumbers(rtIdentifier: string) {
    return this.rtIdToDataMap[rtIdentifier].getMaxConsecutiveSeats();
  }

  getRtDisplayData(rtIdentifier: string): rtDisplayData {
    const rtObject = this.rtIdToDataMap[rtIdentifier];
    return {
      section: rtObject.getSection(),
      row: rtObject.getName(),
      rowOrTable: rtObject.getRowOrTableType(),
      price: rtObject.getPrice(),
    };
  }

  getLowestToHighestPriceRtIdentifiers(minConsecutiveSeatCount: number) {
    return this.sortedRtIdentifierArray.filter(
      (rtIdentifier) =>
        this.rtIdToDataMap[rtIdentifier].getMaxConsecutiveSeats() >=
        minConsecutiveSeatCount,
    );
  }

  getSortedBestRtIdentifiers(minConsecutiveSeatCount: number) {
    const filteredIdentifiers = this.getLowestToHighestPriceRtIdentifiers(
      minConsecutiveSeatCount,
    );
    filteredIdentifiers.reverse();
    return filteredIdentifiers;
  }

  insertSeatObject(seat: ISeat): void {
    const rtIdentifier = this.getRtIdentifier(seat);
    if (this.rtIdToDataMap[rtIdentifier] === undefined) {
      this.rtIdToDataMap[rtIdentifier] = new rtDiagramDS();
      this.insertRtObjectInSortedArr({
        key: rtIdentifier,
        value: parseFloat(seat.price),
      });
    }
    this.rtIdToDataMap[rtIdentifier].insertSeat(seat);
  }

  insertAllSeatObjects(seats: ISeat[]): void {
    console.log("Inserting into ds container. Seats: ", seats);
    for (const seat of seats) {
      this.insertSeatObject(seat);
    }
    Object.values(this.rtIdToDataMap).forEach((diagramDS) => {
      diagramDS.setDoneBuilding();
    });
    console.log("This is new ds: ", this.rtIdToDataMap);
  }

  getPrice(rtIdentifier: string): number {
    return this.rtIdToDataMap[rtIdentifier].getPrice();
  }

  getRowCount(): number {
    return this.sortedRtIdentifierArray.length;
  }

  clone(): rtDiagramDSContainer {
    return cloneDeep(this);
  }
}
