import { SortedNumericMap } from "../utils/sortedNumericMap";

export interface IAwsUser {
  name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface IBackendUser {
  _id: string; // MongoDB assigns each object this ID on creation
  awsId: string;
  dateJoined: Date;
}

export interface ICategory {
  _id: string;
  name: string;
  img?: string;
  value: string;
}

export interface discoverResult {
  events: IEvent[];
}

export enum EventSeatTypes {
  STANDARD_BASED = "STANDARD_BASED",
  SECTIONS_BASED = "SECTIONS_BASED",
  SEATS_BASED = "SEATS_BASED",
}

// Type definition for event on the backend
export type IEvent = {
  _id: string; // MongoDB assigns each object this ID on creation
  title: string;
  description: string;
  dateCreated: Date;
  dateModified: Date;
  eventStart: Date;
  eventEnd: Date;
  imgBanner: string;
  creatorId: string;
  locationName: string;
  contactEmail: string;
  contactPhone: string;
  categoryId: string;
  isPrivate: boolean;
  meetingCode: string;
  bankName: string;
  bankAccountNumber: string;
  bankAccountOwnerName: string;
  bankBranch: string;
  bankCode: string;
  isFree: boolean;
  ticketingType: EventSeatTypes;
  pricingDescription: string;
  seatingPlanDescription: string;
  ticketingPlanStructure: string;
  active: boolean;
  perTicketUserData: PerTicketUserDataTypes[];
  perTicketUserDataReason: string;
  validatorPassphrase: string;
  seatGroupingMap: Record<string, SeatGroupingData>;
  diagramData: DiagramData;
  status: string;
  buyerCommission: number;
  sellerCommission: number;
  serviceFee: number;
  maxNumTicketsInSectionPerPurchase: number;
};
export type DiagramData = {
  hasSections: boolean;
  hasRows: boolean;
  hasTables: boolean;
  diagramSVG: string;
  zoomOnSectionClick: boolean;
  defaultZoomLevel: number;
};

export interface CognitoUser {
  sub: string;
  "cognito:groups": string[];
  email_verified: boolean;
  iss: string;
  phone_number_verified: boolean;
  "cognito:username": string;
  given_name: string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  phone_number: string;
  exp: number;
  iat: number;
  family_name: string;
  jti: string;
  email: string;
}

export interface rtDisplayData {
  section: string;
  row: string;
  rowOrTable: RowOrTableType;
  price: number;
}

export interface IObjType {
  [key: string]:
    | string
    | boolean
    | number
    | IObjType
    | string[]
    | number[]
    | IObjType[]
    | Date
    | Map<string, any>;
}

export interface IMutateData {
  inputData: IObjType;
  endpoint: string;
}

export interface IEventDetails2Form {
  locationName: string;
  eventStart: string;
  eventEnd: string;
  categoryId: string;
  isPrivate: boolean;
  isFree: boolean;
  perTicketUserData: string[];
  perTicketUserDataReason: string;
  meetingCode?: string;
}

export interface IEventDetails1Form {
  title: string;
  description: string;
  imgBanner: string;
}

export interface ISelectSeatingTypeForm {
  isFree: boolean;
  ticketingType: EventSeatTypes;
  seatingPlanImg: string;
}

export interface IAddBankDetailsForm {
  bankAccountOwnerName?: string;
  bankAccountNumber?: string;
  bankName?: string;
}

export interface IAddSeatsGroupingForm {
  saleStartTime: Date;
  saleEndTime: Date;
  numInitialSeats: number;
  numSeatsSold: number;
  price: number;
  groupName: string;
}

export interface ISeat {
  _id: string;
  buyerAwsId: string;
  buyerEmail: string;
  buyerFullName: string;
  dateCreated: Date;
  eventId: string;
  seatNumber: string;
  row: string;
  section: string; // For diagram purposes
  dateBought: Date;
  price: string;
  boughtStatus: boolean;
  pricingType: string; // Subtype of ticket
  isScanned: boolean;
  qrCodeId: string;
  seatHolderData: SeatHolderData;
}

export interface ISection {
  price: number;
  name: string;
  numSeatsLeft: number;
}

export interface ISeatIdMap {
  [key: string]: ISeat;
}

export interface ISectionIdDetailsMap {
  [key: string]: ISection;
}

export interface IGroupTickets {
  titleText: string;
  section: string;

  ticketIds: Set<string>;
}

export enum PaymentModes {
  card = "card",
  mobile = "mobile",
}

export interface IDescribeDiagramFormatForm {
  hasSections: boolean;
  hasRows: boolean;
  hasTables: boolean;
  hasAssignedSeats: boolean;
}

export enum CreateEventFormSteps {
  EVENT_DETAILS_1,
  EVENT_DETAILS_2,
  SELECT_SEATING_TYPE,
  ADD_SECTIONS_NO_DIAGRAM,
  ADD_SECTIONS_DIAGRAM_SECTIONS_ONLY,
  ADD_SEATING_CHART,
  ADD_SEATS_GROUPING_FORM,
  ADD_BANK_DETAILS,
  DESCRIBE_DIAGRAM_FORMAT,
}

export interface IMomoPaymentDetails {
  phoneNumber: string;
}

export interface ICardPaymentDetails {
  cardNumber: string;
  securityCode: number;
  cardOwnerName: string;
}

export type SeatGroupingData = {
  salesStartDate?: Date | null | string;
  salesStartTime?: Date | null | string;
  salesEndDate?: Date | null | string;
  salesEndTime?: Date | null | string;
  numInitialSeats: number;
  numSeatsSold: number;
  price: number;
  groupName: string;
  maxTicketsPerOrder?: number;
};

export interface PriceAndQuantity {
  price: number;
  quantity: number;
  fees?: number;
}

export enum SideBarState {
  BoughtTickets,
  GroupTicketsList,
  GroupTicketsPurchase,
  SectionsPurchaseSidebar,
  SectionsListSidebar,
}

export enum SVGSeatTypes {
  Group = "g",
  Circle = "circle",
}

export enum PerTicketUserDataTypes {
  FULL_NAME = "FULL_NAME",
  EMAIL = "EMAIL",
  GENDER = "GENDER",
  PHONE_NUMBER = "PHONE_NUMBER",
  AGE = "AGE",
}

export type SeatHolderData = {
  email?: string;
  fullName?: string;
  age?: number;
  phoneNumber?: string;
  gender?: string;
};

export interface ITicket {
  _id: string;
  buyerAwsId: string;
  dateCreated: string;
  eventId: string;
  dateBought: string;
  boughtStatus: boolean;
  pricingType: string;
  isScanned: boolean;
  qrCodeId: string;
  seatHolderData: {
    email: string;
    fullName: string;
    age: number;
    phoneNumber: string;
    _id: string;
  };
}

export interface ITransaction {
  _id: string;
  userAwsId: string;
  amount: number;
  paymentDate: Date;
  mobileNumber?: string;
  cardNumber?: string;
  eventId: string;
  pendingPaymentDataId?: string;
  firstName: string;
  lastName: string;
  seatsPaidFor: string[];
}

export enum RowOrTableType {
  ROW = "row",
  TABLE = "table",
}

export interface Section {
  name: string;
  rowOrTableMap: Record<string, Row>;
}

export interface Row {
  name: string;
  seats: SortedNumericMap;
}

export interface GroupTicket {
  groupName: string;
  numInitialSeats: number;
  numSeatsSold: number;
  price: number;
}

export interface GroupTickets {
  [key: string]: GroupTicket;
}

export interface IAddTicketGroupingData {
  id?: number;
  name: string;
  availableQuantity: string;
  price: string;
  salesStartDate?: Date;
  salesStartTime?: Date;
  salesEndDate?: Date;
  salesEndTime?: Date;
  maxTicketsPerOrder?: string;
}

export interface DashboardNumbers {
  sections: {
    [sectionName: string]: {
      numBought: number;
      numScanned: number;
      numTotal: number;
    };
  };
  totalCount: number;
  totalScanned: number;
  totalBought: number;
}

export interface IPaymentObject {
  _id: string;
  userAwsId: string;
  merchantReference: string;
  amount: number;
  customerName: string;
  customerEmail: string;
  customerPhone: number;
  groupAndNumber: string;
  seatIds: string[];
  paymentDate: Date;
  eventId: string;
  __v: number;
}

export type GrossRevenue = {
  sellerGrossRevenue: number;
  mushaStoreCommission: number;
  paymentPlatformCommission: number;
  numSeats: number;
  numSoldSeats: number;
  sections: { [sectionName: string]: SectionRevenue };
};

export type SectionRevenue = {
  sectionNumSeats: number;
  sectionNumSoldSeats: number;
  sectionPaymentPlatformCommission: number;
  sectionMushaStoreCommission: number;
  sectionSellerGrossRevenue: number;
};
