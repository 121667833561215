import { Box, VStack } from "@chakra-ui/react";

import Skeletons from "../../Loaders";

const LoadingEventDetails = () => {
  return (
    <>
      <VStack
        w={{
          base: "90%",
          md: "40%",
        }}
        minH={"400px"}
        padding={2}
      >
        <Skeletons
          count={1}
          minWidth={"full"}
          minHeight={"180px"}
          borderRadius={"8px"}
        />
        <VStack spacing={2} width={"full"} align={"start"}>
          <Skeletons count={1} height={"16px"} borderRadius={"8px"} />
          <Skeletons
            count={1}
            width={"80%"}
            height={"16px"}
            borderRadius={"8px"}
          />
          <Skeletons
            count={1}
            width={"70%"}
            height={"16px"}
            borderRadius={"8px"}
          />
          <Skeletons
            count={1}
            height={"16px"}
            width={"60%"}
            borderRadius={"8px"}
          />
          <Box h={"20px"} w={"full"} />
          <Skeletons
            count={1}
            height={"40px"}
            width={"70%"}
            borderRadius={"8px"}
            alignSelf={"center"}
            mt={12}
          />
        </VStack>
      </VStack>
    </>
  );
};

export default LoadingEventDetails;
