import { useState } from "react";
import {
  Box,
  IconButton,
  Container,
  Heading,
  Flex,
  Button,
} from "@chakra-ui/react";
import useGetData from "../../../hooks/useGetData";
import { IEvent } from "../../../types/interface";
import { API_URL } from "../../../utils/constants";
import EventCard from "../Cards";
import Loading from "./Loading";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";

const PAGE_SIZE = 10;

const EventList = (): JSX.Element => {
  const navigate = useNavigate();
  const { detailEventId, detailEventName } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const eventsResult = useGetData(
    `eventsResult_${detailEventId}`,
    `${API_URL}/event?categoryId=${detailEventId}`
  );

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const totalPages = Math.ceil(eventsResult.data?.length / PAGE_SIZE);
  const isLastPage = currentPage === totalPages;

  return (
    <Box className=" w-full px-4 md:px-10">
      <IconButton
        aria-label="Back"
        isRound
        icon={<ArrowBackIcon />}
        onClick={() => navigate("/")}
        className="mt-8 ml-5"
      />
      <Container maxW="container.lg" mt="4">
        <Heading as="h2" fontSize="2xl" mb="4" className="capitalize">
          {detailEventName}
        </Heading>

        {eventsResult.isLoading && <Loading count={10} />}

        {eventsResult.data && eventsResult.data.length > 0 ? (
          <>
            {eventsResult.data
              .slice(startIndex, endIndex)
              .map((event: IEvent) => (
                <EventCard key={event._id} eventCard={event} />
              ))}
            <Flex className="items-center justify-center mt-16">
              {currentPage > 1 && (
                <IconButton
                  aria-label="Previous"
                  icon={<ArrowBackIcon />}
                  onClick={handlePrevPage}
                  size={{ md: "sm", base: "xs" }}
                  rounded={"none"}
                />
              )}
              {Array.from({ length: totalPages }).map((_, index) => (
                <Button
                  key={index}
                  className={`font-bold ${
                    currentPage === index + 1 ? "text-orange-500 border-2" : ""
                  }`}
                  onClick={() => handlePageClick(index + 1)}
                  variant={"outline"}
                  size={{ md: "sm", base: "xs" }}
                  rounded={"none"}
                >
                  {index + 1}
                </Button>
              ))}
              {!isLastPage && (
                <IconButton
                  aria-label="Next"
                  icon={<ArrowForwardIcon />}
                  onClick={handleNextPage}
                  size={{ md: "sm", base: "xs" }}
                  rounded={"none"}
                />
              )}
            </Flex>
          </>
        ) : (
          <Heading as="h3" fontSize="md" textAlign="center">
            No events in this category yet...
          </Heading>
        )}
      </Container>
    </Box>
  );
};

export default EventList;
