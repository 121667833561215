import {
  Box,
  Button,
  Flex,
  IconButton,
  StackItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ISeat, ISeatIdMap } from "../../types/interface";
import { AddIcon, CloseIcon, MinusIcon } from "@chakra-ui/icons";
import { useRecoilValue } from "recoil";
import { currentEventAtom } from "../../recoil/atom";
import { useState } from "react";

interface GroupTicketsPurchaseScreenProps {
  navigateToPurchase: () => void;
  seatMap: ISeatIdMap;
  removeSelectedSeat: (string) => void;
}

const GroupTicketsPurchaseScreen: React.FC<GroupTicketsPurchaseScreenProps> = (
  props
) => {
  const event = useRecoilValue(currentEventAtom);
  const [fakeCount, setCount] = useState<number>(0);
  let totalTicketCost = 0;
  let quantity = 0;
  let priceToPay = 0;
  let totalFees = 0;
  Object.entries(props.seatMap).forEach(([id, seat]: [string, ISeat]) => {
    const buyerCommission = event.buyerCommission || 0;
    const serviceFee = event.serviceFee || 0;
    quantity = Object.keys(props.seatMap).length;
    totalTicketCost += Number(seat.price);
    const amount = quantity * totalTicketCost;
    const fees = amount * buyerCommission + serviceFee;
    totalFees += fees;
    priceToPay = totalFees + totalTicketCost;
  });

  console.log(quantity);

  const handleRemoveSelectedSeat = (seatId: string) => {
    props.removeSelectedSeat(seatId);
  };

  return (
    <VStack
      className="flex flex-col w-full shadow max-w-md max-h-[40vh] md:min-h-[80vh] lg:min-h-[86vh]"
      borderWidth="1px"
      borderRadius="lg"
      flex={1}
    >
      <StackItem className=" w-full border-b py-2">
        {Object.entries(props.seatMap).map(
          ([seatId, seat]: [string, ISeat]) => (
            <Flex key={seatId} className=" justify-between items-center w-full">
              <span></span>
              <Text className="font-bold">
                Sec {seat.section}, Row {seat.row}
              </Text>
              <IconButton
                aria-label="close"
                onClick={() => handleRemoveSelectedSeat(seatId)}
                icon={<CloseIcon />}
                variant={"ghost"}
              />
            </Flex>
          )
        )}
      </StackItem>

      <StackItem className="flex-grow overflow-y-auto w-full">
        <Box className=" w-full border-y py-2 px-2">
          <Flex className=" w-full justify-between">
            <Box>
              <Text className="font-bold">Ticket</Text>
              <Text>K{totalTicketCost} + Fees</Text>
            </Box>
            <Flex className=" items-center gap-2">
              <IconButton
                aria-label="sub"
                icon={<MinusIcon />}
                isRound
                size={"sm"}
                disabled={fakeCount < 0 ? true : false}
                onClick={() => setCount(fakeCount - 1)}
              />
              {fakeCount}
              <IconButton
                aria-label="add"
                icon={<AddIcon />}
                isRound
                size={"sm"}
                onClick={() => setCount(fakeCount + 1)}
              />
            </Flex>
          </Flex>

          <Text className="text-xs mt-2 text-gray-500">
            Event ticket limit: {event.maxNumTicketsInSectionPerPurchase}
          </Text>
        </Box>
      </StackItem>

      <Box className=" flex flex-col w-full border-t py-2 px-2">
        <Flex className=" w-full justify-between">
          <Box>
            <Text className=" font-bold text-lg">Subtotal</Text>
            <Text className="text-sm">{quantity} Ticket(s)</Text>
          </Box>
          <Text className=" font-bold text-lg">K{priceToPay}</Text>
        </Flex>
        <Button
          mt={4}
          colorScheme="blue"
          onClick={props.navigateToPurchase}
          className="w-full"
        >
          Purchase Ticket(s)
        </Button>
      </Box>
    </VStack>
  );
};

export default GroupTicketsPurchaseScreen;
