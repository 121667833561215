import { Box, Text, useColorMode } from "@chakra-ui/react";
import { SummaryCard } from "../../components/dashboard";

const Dashboard = () => {
  const { colorMode } = useColorMode();
  return (
    <Box className="flex w-full flex-col items-center justify-center px-4 md:px-10">
      <Text className=" my-8 text-lg font-bold">My Events</Text>
      <Box
        className={`w-full items-center justify-center hidden md:grid grid-cols-2 pl-4 font-bold py-4 ${
          colorMode === "dark" ? "bg-gray-800" : "bg-gray-300"
        }`}
      >
        <Box>Event</Box>
        <Box className=" grid grid-cols-3 items-center justify-center">
          <Text># tickets sold</Text>
          <Text>Gross revenue</Text>
          <Text>Approval status</Text>
        </Box>
      </Box>
      <SummaryCard />
    </Box>
  );
};

export default Dashboard;
