import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import WithSubnavigation from "../components/Nav/Navbar";
import { Container } from "@chakra-ui/react";

interface RootLayoutProps {
  maxWidth: string;
}

const RootLayout: React.FC<RootLayoutProps> = ({ maxWidth }) => {
  return (
    <Container px={0} maxW={"1700px"}>
      <Container px={0} maxW={"1340px"}>
        <WithSubnavigation />
      </Container>
      <Container px={0} maxW={maxWidth}>
        <Outlet />
      </Container>
      <Container px={0} maxW={"1340px"}>
        <Footer />
      </Container>
    </Container>
  );
};

export default RootLayout;
