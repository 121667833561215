import { boolean, date, object, string } from "yup";
import * as Yup from "yup";
import moment from "moment";
import { EventSeatTypes } from "../types/interface";

const description = string()
  .required("Required")
  .trim()
  .test("word-count", "Field must contain between 5 and 250 words", (value) => {
    if (!value) return false; // Handle empty input
    const wordCount = value.trim().split(/\s+/).length;
    return wordCount >= 5 && wordCount <= 250;
  });
export const form1Shema = object({
  eventName: string()
    .required("Required")
    .trim()
    .test("word-count", "must contain between 1 and 10 words", (value) => {
      if (!value) return false; // Handle empty input
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount >= 1 && wordCount <= 10;
    }),

  eventDescription: description,
  eventCoverImage: string().required("required"),
});

const timeLimit = Yup.date()
  .typeError("Datetime is required")
  .test("datetime", "Datetime is required", function (value) {
    return value !== undefined;
  })
  .min(new Date(), "Datetime cannot be in the past")
  .max(new Date("2030-12-31"), "Datetime cannot be in the future");

export const form2Schema = Yup.object({
  location: Yup.string().required("Location is required").trim(),
  startDate: timeLimit,
  endDate: timeLimit.test({
    name: "datetime",
    message: "End date & time must be after start date",
    test: function (endDate) {
      const startDate = this.parent.startDate;
      if (!startDate || !endDate) return true;
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);
      return endMoment.isAfter(startMoment);
    },
  }),
  categoryId: string().required("Category is required"),
  isPrivate: boolean().nullable(),
  meetingCode: string().when("isPrivate", {
    is: true,
    then: (schema) =>
      schema
        .required("Meeting password is required")
        .min(4, "must contain atleast 4 characters")
        .max(10, "not more than 10 characters"),
  }),
});

export const chooseDiagramTypeFormSchema = object({
  hasSection: boolean(),
  hasRows: boolean(),
  hasTables: boolean(),
  hasAssignedSeats: boolean(),
});

export const selectSeatingTypeSchema = object({
  ticketingType: string().required("required"),
  seatingPlanImg: string().when(["ticketingType"], {
    is: (ticketingType: EventSeatTypes) =>
      ticketingType !== EventSeatTypes.STANDARD_BASED,
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.nullable(),
  }),
  seatingPlanDescription: string().when(["ticketingType"], {
    is: (ticketingType: EventSeatTypes) =>
      ticketingType !== EventSeatTypes.STANDARD_BASED,
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const addBankDetailsSchema = object({
  //TODO: Validate name price and quantity stuff
  bankName: string().required("Required").trim(),
  bankAccountNumber: string().required("Required"),
  bankAccountOwnerName: string().required("Required").trim(),
});

export const addSeatsGroupingSchema = object({
  //TODO: Validate name price and quantity stuff
});

export const checkoutFormSchema = object({
  //TODO: Validate name price and quantity stuff
  // buyerName: string().required("Required").trim(),
  // buyerEmail: string().email('Invalid email format').required('Email is required'),
  // buyerName: string().required("Required").trim(),
  buyerEmail: string()
    .email("Invalid email format")
    .required("Email is required"),
  // momoNumber: string()
  //   .optional()
  //   .length(10, "Phone number must have 10 characters"),
});
export const mobileMoneyPaymentSchema = object({
  phoneNumber: string()
    .required("required")
    .matches(/^\+\d{1,4}\s\d{1,15}$/, "Phone Number Not Valid"),
});

export const visaFormSchema = object({
  cardNumber: string()
    .required("Card number is required")
    .matches(
      /^(?!0)([0-9]{13,16}|[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4})$/,
      "Invalid credit card number",
    )
    .test("luhn-algorithm", "Invalid credit card number", (value) => {
      const cardNumber = value.replace(/\s/g, "");

      // Apply the Luhn algorithm (Modulus 10)
      let total = 0;
      let reverseDigits = cardNumber.split("").reverse();
      let isSecondDigit = false;

      for (let i = 0; i < reverseDigits.length; i++) {
        let digit = parseInt(reverseDigits[i], 10);

        if (isSecondDigit) {
          digit *= 2;
          if (digit > 9) {
            digit -= 9;
          }
        }

        total += digit;
        isSecondDigit = !isSecondDigit;
      }

      return total % 10 === 0;
    }),
  expirationDate: string()
    .required("Expiration date is required")
    .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, "Invalid expiration date")
    .test("future-expiration", "Expiration date has passed", (value) => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100;
      const currentMonth = currentDate.getMonth() + 1;

      const [inputMonth, inputYear] = value
        .split("/")
        .map((v) => parseInt(v, 10));

      if (currentYear < inputYear) {
        return true;
      } else if (currentYear === inputYear) {
        return inputMonth >= currentMonth;
      }

      return false;
    }),

  securityCode: string()
    .required("Security code is required")
    .matches(/^[0-9]{3,4}$/, "Invalid security code"),

  cardOwnerName: string()
    .required("Cardholder name is required")
    .matches(/^[a-zA-Z\s]+$/, "Invalid cardholder name")
    .min(2, "Cardholder name is too short")
    .max(100, "Cardholder name is too long"),
});
