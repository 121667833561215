import { Box, Text } from "@chakra-ui/react";
import useGetData from "../../../hooks/useGetData";
import Loading from "../../EventComponent/Event/Loading";
import { IEvent } from "../../../types/interface";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { selectedEventState } from "../../../recoil/atom";
import EventCard from "./EventCard";

const UpcomingEvents = () => {
  const selectedEvent = useSetRecoilState(selectedEventState);
  const navigation = useNavigate();
  const queryKey = "eventsWithUserTickets";
  const baseUrl = "/event/events_with_user_tickets?fetchPastEvents=false";

  const endpointUrl = `${baseUrl}`;

  const { data, error, isLoading, isSuccess } = useGetData(
    queryKey,
    endpointUrl,
    0,
  );

  const handleEventClick = (event: IEvent) => {
    selectedEvent(event);
    navigation(`/account/my_tickets/${event._id}`);
  };

  if (isLoading) {
    return <Loading count={1} />;
  }

  if (error) {
    return <Text className="text-center text-red-400 my-8">Error</Text>;
  }

  return (
    <Box className="w-full py-8 items-center flex-col flex justify-center px-4 md:px-10">
      {isSuccess &&
      data &&
      Array.isArray(data.events) &&
      data.events.length > 0 ? (
        <Box className="w-full items-center flex-col gap-4 flex justify-center">
          {data.events.map((event: IEvent) => (
            <EventCard onClick={() => handleEventClick(event)} event={event} />
          ))}
        </Box>
      ) : (
        <Text className="pb-32">No Upcoming Events found</Text>
      )}
    </Box>
  );
};

export default UpcomingEvents;
