import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  ChakraProvider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Divider,
  InputGroup,
  InputLeftAddon,
  Link,
  Radio,
  RadioGroup,
  Select,
  Text,
  useToast,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { Form, Formik, useFormik } from "formik";
import {
  EventSeatTypes,
  PaymentModes,
  PerTicketUserDataTypes,
  PriceAndQuantity,
  SeatHolderData,
} from "../../types/interface";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  buyerEmailAtom,
  currentEventAtom,
  isAuthenticatedAtom,
  numSeatsInSectionToBuyAtom,
  pdfBuffersAtom,
  selectedDiagramSeatsMapAtom,
  awsUserDetailsAtom,
} from "../../recoil/atom";
import cloneDeep from "lodash/cloneDeep";
import { checkoutFormSchema } from "../../utils/validation";
import { apiClient } from "../../api";
import CheckoutOrderSummary from "../../components/Order/CheckoutOrderSummary";
import CheckoutFormSummaryMobile from "./CheckoutFormSummaryMobile";
import OrderSummaryMobile from "../../components/Order/OrderSummaryMobile";
import OrderSummary from "../../components/Order/OrderSummary";
import TermsAndConditionsModal from "../TermsAndConditionsModal";
import TermsOfUseContent from "../TermsOfUseContent";
import { RotatingLines } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import colors from "../../utils/colors";
import { getBooleanString } from "../../utils/constants";
import { calculateFees } from "../../utils/functions";

type FormikValues = {
  [key: string]: {
    quantity: number;
    amount: number;
  };
};

const extractQuantitiesAndAmounts = (
  formikValues: FormikValues,
): Record<string, number> => {
  const result: Record<string, number> = {};
  for (const key in formikValues) {
    if (formikValues.hasOwnProperty(key)) {
      result[key] = formikValues[key].quantity;
    }
  }
  return result;
};

let initialUserSeatData: SeatHolderData = {
  fullName: "",
  age: 0,
  email: "",
  phoneNumber: "",
};

const CheckoutForm: React.FC = () => {
  const [currPaymentMode, setCurrPaymentMode] = useState<PaymentModes>(
    PaymentModes.card,
  );
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const currentEvent = useRecoilValue(currentEventAtom);
  const selectedDiagramSeatsMap = useRecoilValue(selectedDiagramSeatsMapAtom);
  const numSeatsInSectionToBuy = useRecoilValue(numSeatsInSectionToBuyAtom);
  const userDetails = useRecoilValue(awsUserDetailsAtom);
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
  const [, setBuyerEmail] = useRecoilState(buyerEmailAtom);
  const [finalPrices, setFinalPrices] = useState({
    totalPrice: 0,
    totalQuantity: 0,
    totalFees: 0,
    priceToPay: 0,
  });

  const [transactionToken, setTransactionToken] = useState("");

  enum ModalData {
    loadingScreen = "loadingScreen",
    retryVerificationPrompt = "retryVerificationPrompt",
    retryVerificationLoading = "retryVerificationLoading",
  }

  const [modalData, setModalData] = useState(ModalData.loadingScreen);

  const { event_id } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [isOpend, setIsOpend] = useState(false);
  const openModal = () => setIsOpend(true);
  const closeModal = () => setIsOpend(false);

  useEffect(() => {
    const calculateFinalPrices = () => {
      let totalPrice = 0;
      let totalQuantity = 0;
      let totalFees = 0;
      let priceToPay = 0;

      Object.entries(selectedDiagramSeatsMap).forEach(
        ([seatId, seatObject]) => {
          const quantity = Object.keys(selectedDiagramSeatsMap).length;
          const price = parseInt(seatObject.price) || 0;
          const buyerCommission = currentEvent.buyerCommission || 0;
          const serviceFee = currentEvent.serviceFee || 0;

          const amount = quantity * price;
          const fees = amount * buyerCommission + serviceFee;

          totalPrice += price;
          totalQuantity = quantity;
          totalFees += fees;
          priceToPay = totalFees + totalPrice;
        },
      );
      return { totalPrice, totalQuantity, totalFees, priceToPay };
    };

    const { totalPrice, totalQuantity, totalFees, priceToPay } =
      calculateFinalPrices();
    setFinalPrices({
      totalPrice: totalPrice,
      totalQuantity: totalQuantity,
      totalFees: totalFees,
      priceToPay: priceToPay,
    });
  }, [selectedDiagramSeatsMap, currentEvent]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem(
        "reloadInfo",
        "Page reloaded at: " + new Date().toString(),
      );
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const reloadInfo = localStorage.getItem("reloadInfo");
    if (reloadInfo) {
      console.log(reloadInfo);
      navigate(-2);
      localStorage.removeItem("reloadInfo");
    }
  }, [navigate]);

  const [sectionToUserDataMap, setSectionToUserDataMap] = useState<Record<
    string,
    SeatHolderData[]
  > | null>(null);
  const [idToUserDataMapLocal, setIdToUserDataMapLocal] = useState<Record<
    string,
    SeatHolderData
  > | null>(null);
  const handleSectionUserDataChange = (
    section: string,
    index: number,
    field: string,
    data: string | number,
  ) => {
    let sectionToUserDataMapCopy = cloneDeep(sectionToUserDataMap);
    sectionToUserDataMapCopy[section][index][field] = data;
    setSectionToUserDataMap(sectionToUserDataMapCopy);
    console.log("This is new data ", sectionToUserDataMapCopy);
  };

  const handleSeatUserDataChange = (
    seatId: string,
    field: string,
    data: string | number,
  ) => {
    let idToUserDataMapLocalCopy = cloneDeep(idToUserDataMapLocal);
    idToUserDataMapLocalCopy[seatId][field] = data;
    setIdToUserDataMapLocal(idToUserDataMapLocalCopy);
  };

  useEffect(() => {
    if (
      currentEvent.ticketingType === EventSeatTypes.STANDARD_BASED &&
      currentEvent.perTicketUserData.length > 0 &&
      sectionToUserDataMap === null
    ) {
      let sectionToUserDataMapCopy = {};
      Object.entries(numSeatsInSectionToBuy).forEach(
        ([section, count], index) => {
          sectionToUserDataMapCopy[section] = [];
          for (let i = 0; i < count; i++) {
            console.log("Section push: ", section);
            sectionToUserDataMapCopy[section].push({
              ...initialUserSeatData,
            });
          }
        },
      );
      console.log("This is map: ", sectionToUserDataMapCopy);
      setSectionToUserDataMap(sectionToUserDataMapCopy);
    }

    if (
      currentEvent.ticketingType === EventSeatTypes.SEATS_BASED &&
      currentEvent.perTicketUserData.length > 0 &&
      sectionToUserDataMap === null
    ) {
      let idToUserDataMapCopy = {};
      Object.entries(selectedDiagramSeatsMap).forEach(
        ([fakeSeatId, seat], index) => {
          idToUserDataMapCopy[seat._id] = {
            ...initialUserSeatData,
          };
        },
      );
      setIdToUserDataMapLocal(idToUserDataMapCopy);
    }
  }, [
    currentEvent.perTicketUserData.length,
    currentEvent.ticketingType,
    numSeatsInSectionToBuy,
    sectionToUserDataMap,
    selectedDiagramSeatsMap,
  ]);

  const ticketStrings: string[] = [];
  if (currentEvent.ticketingType === EventSeatTypes.STANDARD_BASED) {
    let numTickets = 0;
    Object.entries(numSeatsInSectionToBuy).forEach(
      ([section, count], index) => {
        for (let i = 0; i < count; i++) {
          ticketStrings.push(`Ticket ${numTickets} - ${section}`);
          numTickets++;
        }
      },
    );
  } else if (currentEvent.ticketingType === EventSeatTypes.SEATS_BASED) {
    let numTickets = 0;
    Object.entries(selectedDiagramSeatsMap).forEach(
      ([seatId, seatObject], index) => {
        ticketStrings.push(
          `Ticket ${numTickets} - Section ${seatObject.section}, Row: ${seatObject.row}, Number: ${seatObject.seatNumber}`,
        );
        numTickets++;
      },
    );
  }

  let userDataComponents: React.ReactNode[] = [];
  let informationHeader = (
    <Heading size={"md"} mb={"16px"}>
      Enter information about each ticket holder
    </Heading>
  );
  if (
    currentEvent.ticketingType === EventSeatTypes.STANDARD_BASED &&
    currentEvent.perTicketUserData.length > 0 &&
    sectionToUserDataMap !== null
  ) {
    userDataComponents.push(informationHeader);
    let numTickets = 0;
    Object.entries(numSeatsInSectionToBuy).forEach(([section, count]) => {
      for (let i = 0; i < count; i++) {
        numTickets++;
        let subComponents: React.ReactNode[] = [];
        for (let userDataType of currentEvent.perTicketUserData) {
          let labelName = "";
          switch (userDataType) {
            case PerTicketUserDataTypes.FULL_NAME:
              labelName = `fullName_${section}_${i}`;
              subComponents.push(
                <FormControl mb={4} key={labelName}>
                  <Input
                    type="text"
                    value={sectionToUserDataMap[section][i]?.fullName || ""}
                    onChange={(e) =>
                      handleSectionUserDataChange(
                        section,
                        i,
                        "fullName",
                        e.target.value,
                      )
                    }
                    placeholder={"Full Name"}
                    className=" md:col-span-2"
                  />
                </FormControl>,
              );
              break;
            case PerTicketUserDataTypes.EMAIL:
              labelName = `email_${section}_${i}`;
              subComponents.push(
                <FormControl mb={4} key={labelName}>
                  <Input
                    type="text"
                    value={sectionToUserDataMap[section][i]?.email || ""}
                    onChange={(e) =>
                      handleSectionUserDataChange(
                        section,
                        i,
                        "email",
                        e.target.value,
                      )
                    }
                    placeholder={"Email"}
                  />
                </FormControl>,
              );
              break;
            case PerTicketUserDataTypes.GENDER:
              labelName = `gender_${section}_${i}`;
              subComponents.push(
                <FormControl key={labelName} mb={4}>
                  <Select
                    onChange={(e) =>
                      handleSectionUserDataChange(
                        section,
                        i,
                        "gender",
                        e.target.value,
                      )
                    }
                    value={sectionToUserDataMap[section][i]?.gender || ""}
                    placeholder="Select Gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Select>
                </FormControl>,
              );
              break;
            case PerTicketUserDataTypes.PHONE_NUMBER:
              labelName = `phoneNumber_${section}_${i}`;
              subComponents.push(
                <InputGroup key={labelName} className="my-4">
                  <InputLeftAddon children="+260" />
                  <Input
                    type="tel"
                    placeholder="phone number"
                    required
                    onChange={(e) =>
                      handleSectionUserDataChange(
                        section,
                        i,
                        "phoneNumber",
                        e.target.value,
                      )
                    }
                    value={sectionToUserDataMap[section][i]?.phoneNumber || ""}
                  />
                </InputGroup>,
              );
              break;
            case PerTicketUserDataTypes.AGE:
              labelName = `age_${section}_${i}`;
              subComponents.push(
                <FormControl mb={4} key={labelName}>
                  <Input
                    type="number"
                    value={sectionToUserDataMap[section][i]?.age || ""}
                    onChange={(e) =>
                      handleSectionUserDataChange(
                        section,
                        i,
                        "age",
                        e.target.value,
                      )
                    }
                    placeholder={"Age"}
                  />
                </FormControl>,
              );
              break;
            default:
              throw Error("Unimplemented user data type");
          }
        }

        let userDataFormComponent = (
          <Box key={numTickets}>
            <Heading size="xs" mb={2}>
              {` Section ${section}, Ticket ${i + 1}`}
            </Heading>
            {subComponents}
          </Box>
        );

        userDataComponents.push(userDataFormComponent);
      }
    });
  }

  if (
    currentEvent.ticketingType === EventSeatTypes.SEATS_BASED &&
    currentEvent.perTicketUserData.length > 0 &&
    sectionToUserDataMap !== null
  ) {
    userDataComponents.push(informationHeader);
    let numTickets = 0;
    Object.entries(selectedDiagramSeatsMap).forEach(
      ([seatId, seatObject], index) => {
        numTickets++;
        let subComponents: React.ReactNode[] = [];
        let labelName = "";
        for (let userDataType of currentEvent.perTicketUserData) {
          switch (userDataType) {
            case PerTicketUserDataTypes.FULL_NAME:
              labelName = `fullName_${seatObject.section}_${seatObject.row}_${seatObject.seatNumber}`;
              subComponents.push(
                <FormControl mb={4} key={labelName}>
                  <Input
                    type="text"
                    value={idToUserDataMapLocal[seatObject._id]?.fullName || ""}
                    onChange={(e) =>
                      handleSeatUserDataChange(
                        seatObject._id,
                        "fullName",
                        e.target.value,
                      )
                    }
                    placeholder={"Name"}
                  />
                </FormControl>,
              );
              break;
            case PerTicketUserDataTypes.EMAIL:
              labelName = `email_${seatObject.section}_${seatObject.row}_${seatObject.seatNumber}`;
              subComponents.push(
                <FormControl mb={4} key={labelName}>
                  <Input
                    type="text"
                    value={idToUserDataMapLocal[seatObject._id]?.email || ""}
                    onChange={(e) =>
                      handleSeatUserDataChange(
                        seatObject._id,
                        "email",
                        e.target.value,
                      )
                    }
                    placeholder={"Email"}
                  />
                </FormControl>,
              );
              break;
            case PerTicketUserDataTypes.GENDER:
              labelName = `gender_${seatObject.section}_${seatObject.row}_${seatObject.seatNumber}`;
              subComponents.push(
                <FormControl key={labelName}>
                  <Select
                    onChange={(e) =>
                      handleSeatUserDataChange(
                        seatObject._id,
                        "gender",
                        e.target.value,
                      )
                    }
                    value={idToUserDataMapLocal[seatObject._id]?.gender || ""}
                    placeholder="Select Gender"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Select>
                </FormControl>,
              );
              break;
            case PerTicketUserDataTypes.PHONE_NUMBER:
              subComponents.push(
                <InputGroup key={labelName} className="mt-4">
                  <InputLeftAddon children="+260" />
                  <Input
                    type="tel"
                    placeholder="phone number"
                    required
                    onChange={(e) =>
                      handleSeatUserDataChange(
                        seatObject._id,
                        "phoneNumber",
                        e.target.value,
                      )
                    }
                    value={
                      idToUserDataMapLocal[seatObject._id]?.phoneNumber || ""
                    }
                  />
                </InputGroup>,
              );
              break;
            case PerTicketUserDataTypes.AGE:
              labelName = `age_${seatObject.section}_${seatObject.row}_${seatObject.seatNumber}`;
              subComponents.push(
                <FormControl mb={4} key={labelName}>
                  <Input
                    type="submit"
                    value={idToUserDataMapLocal[seatObject._id]?.age || ""}
                    onChange={(e) =>
                      handleSeatUserDataChange(
                        seatObject._id,
                        "age",
                        e.target.value,
                      )
                    }
                    placeholder={"Age"}
                  />
                </FormControl>,
              );
              break;
            default:
              throw Error("Unimplemented user data type");
          }
        }
        let userDataFormComponent = (
          <Box key={numTickets}>
            <Heading size="md" mb={2}>
              {`${numTickets}. Section ${seatObject.section}, Row: ${seatObject.row}, Seat Number ${seatObject.seatNumber}`}
            </Heading>
            {subComponents}
          </Box>
        );

        userDataComponents.push(userDataFormComponent);
      },
    );
  }

  const verifyTransactionWithRetry = async (
    transactionToken: string,
    maxDuration: number,
    retryInterval: number,
  ): Promise<boolean> => {
    const startTime = Date.now();
    while (Date.now() - startTime < maxDuration) {
      // eslint-disable-next-line no-await-in-loop
      try {
        const result = await apiClient.get(
          `/payment/verifyTransaction/${transactionToken}`,
          {},
        );
        console.log("This is verification response: ", result);
        if (result.data && result.data.success) {
          // The function returned true, stop retrying
          return true;
        }
      } catch (error) {
        console.log("Verification error: ", error);
      }

      // Retry after the specified interval
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => {
        setTimeout(resolve, retryInterval);
      });
    }

    // Max duration reached, return false
    return false;
  };

  const formik = useFormik({
    initialValues: {
      buyerFirstName: `${userDetails?.given_name ?? ""}`,
      buyerLastName: `${userDetails?.family_name ?? ""}`,
      buyerEmail: `${userDetails?.email ?? ""}`,
      momoNumber: "",
      mobileNumber: "",
    },

    validationSchema: checkoutFormSchema, 

    onSubmit: async ({
      buyerEmail,
      momoNumber,
      buyerFirstName,
      buyerLastName,
      mobileNumber,
    }) => {
      console.log("On submit called");
      let paymentBody: any = {
        paymentMode: currPaymentMode,
        buyerEmail,
        momoNumber,
        buyerFirstName,
        buyerLastName,
        mobileNumber,
        isBuyerSignedIn: isAuthenticated,
      };
      //TODO: handle diagram seat submission
      console.log(
        "This is formik submit inputs: ",
        buyerEmail,
        momoNumber,
        buyerFirstName,
        buyerLastName,
        mobileNumber,
      );
      let endpointUrl = "/seat/buy/";
      if (currPaymentMode === PaymentModes.card) {
        if (currentEvent.ticketingType === EventSeatTypes.SEATS_BASED) {
          endpointUrl += "processCardPayment/";
          let seatIds = Object.values(selectedDiagramSeatsMap).map(
            (seatObject) => seatObject._id,
          );
          paymentBody = {
            ...paymentBody,
            seatIds,
            buyerFirstName,
            buyerLastName,
            mobileNumber,
          };
        } else {
          endpointUrl += "processCardPayment/";
          paymentBody = {
            ...paymentBody,
            groupAndNumber: numSeatsInSectionToBuy,
            sectionToUserDataMap: sectionToUserDataMap,
          };
        }
      }

      console.log("This is payment body: ", paymentBody);
      endpointUrl += currentEvent._id;

      setBuyerEmail(buyerEmail);

      console.log("Before making payment");
      const headers = {
        "Content-Type": "application/json",
      };

      try {
        const response = await apiClient.post(endpointUrl, paymentBody, {
          headers,
        });
        console.log("This is response: ", response);

        if (response.data.isFreeTransaction) {
          navigate(
            `/success?TransactionToken=${response.data.transactionToken}`,
          );
        } else {
          window.open(response.data.paymentUrl, "_self");
        }
      } catch (error) {
        console.log("Payment error: ", error);
        toast({
          title: "Error",
          description: error.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        onClose();
      }
    },
  });

  const submitForm = () => {
    onOpen();
    console.log("Submit form called");
    formik.submitForm();
  };

  const buildPriceAndQuantityMap = (): Record<string, PriceAndQuantity> => {
    const priceAndQuantityMap: Record<string, PriceAndQuantity> = {};

    if (currentEvent.ticketingType === EventSeatTypes.STANDARD_BASED) {
      Object.entries(numSeatsInSectionToBuy).forEach(([group, quantity]) => {
        const seatGroupingData = currentEvent.seatGroupingMap[group];
        const fees = calculateFees(
          currentEvent,
          seatGroupingData.price,
          quantity,
        );
        console.log("Fees for Brian: ", fees);
        priceAndQuantityMap[group] = {
          price: seatGroupingData.price,
          quantity,
          fees,
        };
      });
    } else {
      Object.entries(selectedDiagramSeatsMap).forEach(([localId, seatObj]) => {
        const fees = calculateFees(currentEvent, parseFloat(seatObj.price), 1);
        priceAndQuantityMap[localId] = {
          price: parseFloat(seatObj.price),
          quantity: 1,
          fees,
        };
      });
    }

    return priceAndQuantityMap;
  };

  const modalBodyContent = () => {
    if (currPaymentMode === PaymentModes.mobile) {
      return loadingModalContainer(`Follow the prompt that shows up on your screen. 
      Once you put in the pin, we will automatically verify your payments. Do not close this screen. 
      This process may take up to 2 minutes. Thank you for your patience.`);
    } else {
      return loadingModalContainer(
        `Redirecting you to payment portal. Do not close this page.`,
      );
    }
  };

  const loadingModalContainer = (textData) => {
    return (
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        paddingTop={"1.5rem"}
        paddingBottom={"1.5rem"}
      >
        <Text mb={1} marginBottom={"2rem"}>
          {textData}
        </Text>
        <RotatingLines
          visible={true}
          strokeWidth="5"
          animationDuration="0.9"
          strokeColor={"#2C4971"}
          width="4rem"
          ariaLabel="rotating-lines-loading"
        />
      </Flex>
    );
  };

  const retryVerificationFunction = async () => {
    console.log("This is token in reverify: ", transactionToken);
    setModalData(ModalData.retryVerificationLoading);
    const verificationResult = await verifyTransactionWithRetry(
      transactionToken,
      120000,
      5000,
    );
    if (verificationResult) {
      console.log("This is verification response: ");
      navigate(`/success?TransactionToken=${transactionToken}`);
      return;
    } else {
      const error = "Payment failed";
      toast({
        title: "Error",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      onClose();
      setModalData(ModalData.loadingScreen);
      return;
    }
  };

  const retryVerificationModal = () => {
    let modalText;
    if (currPaymentMode === PaymentModes.mobile) {
      modalText = (
        <Text mb={1} marginBottom={"2rem"} textAlign={"center"}>
          Verification timed out. Did you make a mobile money payment?
        </Text>
      );
    } else {
      modalText = (
        <Text mb={1} marginBottom={"2rem"}>
          Redirecting you to payment portal. Do not close this page.
        </Text>
      );
    }

    return (
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        paddingTop={"1.5rem"}
        paddingBottom={"1.5rem"}
      >
        {modalText}
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          paddingTop={"1.5rem"}
          paddingBottom={"1.5rem"}
          width={"80%"}
        >
          <Button
            size="md"
            bg={colors.orange}
            color={"white"}
            _hover={{
              bg: colors.orange,
            }}
            onClick={() => {
              onClose();
              setModalData(ModalData.loadingScreen);
            }}
            width={"100px"}
          >
            No
          </Button>
          <Button
            size="md"
            bg={colors.primary}
            color={"white"}
            _hover={{
              bg: colors.orange,
            }}
            width={"100px"}
            onClick={() => retryVerificationFunction()}
          >
            Yes
          </Button>
        </Flex>
      </Flex>
    );
  };

  let displayModalData: React.ReactNode;
  const retryText =
    "Retrying payment verification. This process may take up to 2 minutes. Do not close this screen. Thank you for your patience.";
  switch (modalData) {
    case ModalData.loadingScreen:
      displayModalData = modalBodyContent();
      break;
    case ModalData.retryVerificationPrompt:
      displayModalData = retryVerificationModal();
      break;
    case ModalData.retryVerificationLoading:
      displayModalData = loadingModalContainer(retryText);
      break;
  }

  return (
    <ChakraProvider>
      <Card className=" w-full grid lg:grid-cols-2 gap-6 lg:p-4 p-2 rounded-xl">
        <Box className="grid">
          {currentEvent.ticketingType === EventSeatTypes.STANDARD_BASED && (
            <OrderSummaryMobile
              openSummary={openSummary}
              setOpenSummary={setOpenSummary}
              priceAndQuantityMap={buildPriceAndQuantityMap()}
            />
          )}
          {currentEvent.ticketingType === EventSeatTypes.SEATS_BASED && (
            <CheckoutFormSummaryMobile
              openSummary={openSummary}
              setOpenSummary={setOpenSummary}
              totalFees={finalPrices.totalFees}
              priceToPay={finalPrices.priceToPay}
              totalPrice={finalPrices.totalPrice}
              totalQuantity={finalPrices.totalQuantity}
            />
          )}
          <Heading mb={4}>Checkout Form</Heading>
          <Formik onSubmit={submitForm} initialValues={formik.initialValues}>
            <Form>
              <Heading size="md" mb={2}>
                Billing Information
              </Heading>
              <Box className="grid lg:grid-cols-2 lg:gap-4">
                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="buyerFirstName">First Name</FormLabel>
                  <Input
                    name="buyerFirstName"
                    placeholder="John"
                    {...formik.getFieldProps("buyerFirstName")}
                  />
                  <FormErrorMessage>
                    {formik.errors.buyerFirstName}
                  </FormErrorMessage>
                </FormControl>

                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="buyerLastName">Last Name</FormLabel>
                  <Input
                    name="buyerLastName"
                    placeholder="Doe"
                    {...formik.getFieldProps("buyerLastName")}
                  />
                  <FormErrorMessage>
                    {formik.errors.buyerLastName}
                  </FormErrorMessage>
                </FormControl>

                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="buyerEmail">Email</FormLabel>
                  <Input
                    name="buyerEmail"
                    placeholder="Email"
                    {...formik.getFieldProps("buyerEmail")}
                  />
                </FormControl>
                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="mobileNumber">Contact Number</FormLabel>
                  <Input
                    name="mobile number"
                    placeholder="0979054417"
                    {...formik.getFieldProps("mobileNumber")}
                  />
                  <FormErrorMessage>
                    {formik.errors.mobileNumber}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              {userDataComponents}

              {/*<FormControl mb={4}>*/}
              {/*  <RadioGroup*/}
              {/*    name="paymentMethod"*/}
              {/*    defaultValue={PaymentModes.mobile}*/}
              {/*    onChange={(value) =>*/}
              {/*      setCurrPaymentMode(value as PaymentModes)*/}
              {/*    }*/}
              {/*  >*/}
              {/*    <Box className="md:grid flex justify-between grid-cols-2">*/}
              {/*      <Radio value={PaymentModes.mobile}>Mobile Money</Radio>*/}
              {/*      <Radio value={PaymentModes.card}>Card Payment</Radio>*/}
              {/*    </Box>*/}
              {/*  </RadioGroup>*/}
              {/*</FormControl>*/}
              {currPaymentMode === PaymentModes.mobile ? (
                <FormControl mb={4} isRequired>
                  <FormLabel htmlFor="momoNumber">
                    Mobile Money Number
                  </FormLabel>
                  <InputGroup key="momoNumber">
                    {/*<InputLeftAddon children="+260" />*/}
                    <Input
                      name="momoNumber"
                      placeholder="0975444311"
                      {...formik.getFieldProps("momoNumber")}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {formik.errors.momoNumber}
                  </FormErrorMessage>
                </FormControl>
              ) : null}
              {currPaymentMode === PaymentModes.card ? (
                <>
                  <Box className="grid"></Box>
                </>
              ) : null}
              <Text>
                <Divider
                  borderBottomWidth="2px"
                  borderBottomStyle="solid"
                  borderColor="black"
                  my="2"
                  fontWeight="bold"
                />
                <Heading size="md" mb={2}>
                  Payment Information
                </Heading>
                <Text> Mobile money and card payments accepted.</Text>

                <span>
                  By selecting place order, I agree to the Musha Tickets{" "}
                </span>
                <a
                  className="mt-4 cursor-pointer text-orange-700"
                  onClick={openModal}
                >
                  terms and conditions
                </a>
                <TermsAndConditionsModal isOpen={isOpend} onClose={closeModal}>
                  <TermsOfUseContent />
                </TermsAndConditionsModal>
              </Text>
              {/* modal for loading state */}
              <Modal
                closeOnOverlayClick={false}
                isOpen={isOpen}
                onClose={onClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalBody>{displayModalData}</ModalBody>
                </ModalContent>
              </Modal>
              <Button type="submit" mt={4} className=" w-full">
                Place order
              </Button>
            </Form>
          </Formik>
        </Box>
        <Box className="">
          {currentEvent.ticketingType === EventSeatTypes.STANDARD_BASED && (
            <OrderSummary
              event={currentEvent}
              priceAndQuantityMap={buildPriceAndQuantityMap()}
            />
          )}
          {currentEvent.ticketingType === EventSeatTypes.SEATS_BASED && (
            <CheckoutOrderSummary
              event={currentEvent}
              totalFees={finalPrices.totalFees}
              priceToPay={finalPrices.priceToPay}
              totalPrice={finalPrices.totalPrice}
              totalQuantity={finalPrices.totalQuantity}
            />
          )}
        </Box>
      </Card>
    </ChakraProvider>
  );
};

export default CheckoutForm;
