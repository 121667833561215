import { Card, CardBody, useRadio } from "@chakra-ui/react";
import React from "react";

function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Card as="label" className=" h-fit">
      <input {...input} />
      <CardBody
        {...checkbox}
        cursor="pointer"
        borderRadius="md"
        boxShadow="md"
        borderLeftWidth="5px"
        borderColor="white"
        _checked={{
          bg: "gray.100",
          borderLeftColor: props.color,
          borderLeftWidth: "5px",
        }}
        _focus={{
          boxShadow: "none",
        }}
      >
        {props.children}
      </CardBody>
    </Card>
  );
}
export default RadioCard;
