import {
  Box,
  Text,
  Flex,
  IconButton,
  useToast,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { seatsSoldAtom, selectedEventState } from "../../recoil/atom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  IoLogoFacebook,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoWhatsapp,
  IoMail,
  IoReceiptOutline,
} from "react-icons/io5";
import { CopyIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import {
  GrossRevenue,
  ITransaction,
  SectionRevenue,
} from "../../types/interface";
import moment from "moment";
import Loading from "../EventComponent/Event/Loading";
import { useEffect, useState } from "react";
import { API_URL } from "../../utils/constants";

const DataCard = ({ data, index }) => {
  return (
    <Box
      className="border p-4 rounded shadow-md mb-4"
      key={index}
      fontFamily={"sans-serif"}
    >
      {Object.entries(data).map(([key, value], index, array) => (
        <Flex
          key={index}
          className={`mb-2${
            index !== array.length - 1 ? " pb-2 border-b" : ""
          }`}
        >
          <Text className="font-bold w-1/2 mr-4">{key}</Text>
          <Text className="w-1/2 flex items-start">{value as string}</Text>
        </Flex>
      ))}
    </Box>
  );
};

const MyEventDashBoard = () => {
  const selectedEvent = useRecoilValue(selectedEventState);
  const [eventRevenueData, setEventRevenueData] = useState(null);

  useEffect(() => {
    const fetchRevenueData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/event/gross-revenue/${selectedEvent?._id}`,
        );
        const data = await response.json();
        console.log(data);

        setEventRevenueData(data);
      } catch (error) {
        console.error("Error fetching revenue data:", error);
        setEventRevenueData(null);
      }
    };

    if (selectedEvent) {
      fetchRevenueData();
    }
  }, [selectedEvent]);

  const eventUrl = selectedEvent?.isPrivate
    ? `https://mushatickets.com/private_auth/${selectedEvent._id}`
    : `https://mushatickets.com/event/${selectedEvent._id}`;

  const {
    data: transactions,
    error,
    isLoading,
    isSuccess,
  } = useGetData(
    ["getEventData", selectedEvent?._id],
    `/transaction/${selectedEvent?._id}`,
    300000,
  );
  const transactionsData: ITransaction[] = transactions?.transactions;
  const toast = useToast();
  if (!selectedEvent) {
    return (
      <Box>
        <Text textAlign={"center"} className="my-12">
          No event selected
        </Text>
      </Box>
    );
  }

  const formatNumberAsPrice = (value: number) => {
    return `K ${value.toFixed(2)}`;
  };

  console.log("This is transaction data: ", transactionsData);
  const handleCopyLink = () => {
    const eventLink = `https://mushatickets.com/event/${selectedEvent._id}/details`;

    navigator.clipboard
      .writeText(eventLink)
      .then(() => {
        toast({
          title: "Link copied to clipboard",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Failed to copy link",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  return (
    <Box className="w-full items-center justify-center flex flex-col">
      <Box className=" flex flex-col gap-2 pt-8 w-full">
        <Box>
          <Text className=" my-4">
            <span className=" font-bold">Validator Passphrase:</span>{" "}
            {selectedEvent?.validatorPassphrase}
          </Text>
          {selectedEvent?.isPrivate ? (
            <Text className=" my-4">
              <span className=" font-bold">Meeting code:</span>{" "}
              {selectedEvent?.meetingCode}
            </Text>
          ) : null}
        </Box>

        <Text className=" text-2xl font-bold mb-6">Share</Text>
        <Text>Event URL</Text>
        <Flex className=" items-center gap-4 flex-wrap">
          <Link
            to={eventUrl}
            className="whitespace-normal break-all overflow-auto text-blue-500 hover:text-blue-700"
          >
            {eventUrl}
          </Link>
          <IconButton
            aria-label="copy"
            icon={<CopyIcon />}
            onClick={handleCopyLink}
          />
        </Flex>

        <Text className=" pt-8">Share on</Text>
        <Flex className=" gap-4 md:justify-start justify-evenly pb-4">
          <FacebookShareButton
            openShareDialogOnClick
            children={<IoLogoFacebook size={32} />}
            url={`https://mushatickets.com/event/${selectedEvent._id}/details`}
          />
          <TwitterShareButton
            openShareDialogOnClick
            children={<IoLogoTwitter size={32} />}
            url={`https://mushatickets.com/event/${selectedEvent._id}/details`}
          />
          <EmailShareButton
            openShareDialogOnClick
            children={<IoMail size={32} />}
            url={`https://mushatickets.com/event/${selectedEvent._id}/details`}
          />
          <LinkedinShareButton
            openShareDialogOnClick
            children={<IoLogoLinkedin size={32} />}
            url={`https://mushatickets.com/event/${selectedEvent._id}/details`}
          />
          <WhatsappShareButton
            openShareDialogOnClick
            children={<IoLogoWhatsapp size={32} />}
            url={`https://mushatickets.com/event/${selectedEvent._id}/details`}
          />
        </Flex>

        <Text className="text-2xl font-bold my-6">Total Sales</Text>

        {eventRevenueData ? (
          <DataCard
            data={{
              "# of tickets sold": `${eventRevenueData.numSoldSeats} /
                          ${eventRevenueData.numSeats}`,
              "Gross revenue": formatNumberAsPrice(
                eventRevenueData.sellerGrossRevenue,
              ),
              "Payment platform commission": formatNumberAsPrice(
                eventRevenueData.paymentPlatformCommission,
              ),
              "Musha tickets commission": formatNumberAsPrice(
                eventRevenueData.mushaStoreCommission,
              ),
            }}
            index={0}
          />
        ) : null}

        <Text className="text-2xl font-bold my-6">Sales by Section</Text>
        <Box className=" block lg:hidden">
          {eventRevenueData?.sections
            ? Object.entries(eventRevenueData?.sections).map(
                (
                  [sectionName, sectionRevData]: [string, SectionRevenue],
                  index,
                ) => {
                  return (
                    <DataCard
                      data={{
                        section: sectionName,
                        "# of tickets sold": `${sectionRevData.sectionNumSoldSeats} /
                          ${sectionRevData.sectionNumSeats}`,
                        "Gross revenue": formatNumberAsPrice(
                          sectionRevData.sectionSellerGrossRevenue,
                        ),
                        "Payment platform commission": formatNumberAsPrice(
                          sectionRevData.sectionPaymentPlatformCommission,
                        ),
                        "Musha tickets commission": formatNumberAsPrice(
                          sectionRevData.sectionMushaStoreCommission,
                        ),
                      }}
                      index={index}
                    />
                  );
                },
              )
            : ""}
        </Box>

        <Table variant="simple" className="hidden lg:block">
          <Thead>
            <Tr
              className="md:grid hidden grid-cols-5 gap-4 items-center justify-center font-roboto text-black"
              fontFamily={"sans-serif"}
            >
              <Th
                className="text-black font-bold"
                fontFamily={"sans-serif"}
                fontSize={"1rem"}
              >
                Section
              </Th>
              <Th
                className="text-black font-bold"
                fontFamily={"sans-serif"}
                fontSize={"1rem"}
              >
                Gross revenue
              </Th>
              {/*<Th>Number</Th>*/}
              <Th
                className="text-black font-bold "
                fontFamily={"sans-serif"}
                fontSize={"1rem"}
              >
                # of tickets sold
              </Th>
              <Th
                className="text-black font-bold "
                fontFamily={"sans-serif"}
                fontSize={"1rem"}
              >
                Payment platform commission
              </Th>
              <Th
                className="text-black font-bold"
                fontFamily={"sans-serif"}
                fontSize={"1rem"}
              >
                Musha Tickets commission
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {eventRevenueData?.sections
              ? Object.entries(eventRevenueData?.sections).map(
                  (
                    [sectionName, sectionRevData]: [string, SectionRevenue],
                    index,
                  ) => {
                    return (
                      <Tr
                        key={index}
                        className="grid md:grid-cols-5 gap-4 border-b-2 items-center justify-center"
                      >
                        <Td>{sectionName}</Td>
                        <Td>
                          {`${formatNumberAsPrice(
                            sectionRevData?.sectionSellerGrossRevenue,
                          )}`}
                        </Td>
                        <Td>
                          {`${sectionRevData?.sectionNumSoldSeats}/${sectionRevData?.sectionNumSeats}`}
                        </Td>
                        <Td>
                          {formatNumberAsPrice(
                            sectionRevData.sectionPaymentPlatformCommission,
                          )}
                        </Td>
                        <Td>
                          {formatNumberAsPrice(
                            sectionRevData.sectionMushaStoreCommission,
                          )}
                        </Td>
                      </Tr>
                    );
                  },
                )
              : ""}
          </Tbody>
        </Table>

        <Box className="flex flex-col gap-2 pt-4">
          <Text className="text-2xl font-bold my-6">Transactions</Text>

          <Box className="block lg:hidden">
            {isSuccess && transactionsData.length > 0
              ? transactionsData.map((singleTransaction, i) => (
                  <DataCard
                    index={i}
                    data={{
                      "Transaction ID": singleTransaction._id.slice(0, 10),
                      "Customer name":
                        singleTransaction.firstName +
                        " " +
                        singleTransaction.lastName,
                      "# of tickets bought":
                        singleTransaction.seatsPaidFor.length,
                      Date: moment(singleTransaction.paymentDate).format("lll"),
                    }}
                  />
                ))
              : null}
          </Box>

          <Table variant="simple" className="hidden lg:block">
            <Thead>
              <Tr className="md:grid hidden grid-cols-4 gap-4 items-center justify-center text-md font-roboto text-black">
                <Th className="text-black font-bold " fontSize={"1rem"}>
                  Transaction ID
                </Th>
                <Th className="text-black font-bold" fontSize={"1rem"}>
                  Customer name
                </Th>
                {/*<Th>Number</Th>*/}
                <Th className="text-black font-bold " fontSize={"1rem"}>
                  # of tickets bought
                </Th>
                <Th className="text-black font-bold " fontSize={"1rem"}>
                  Date
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isSuccess && transactionsData.length > 0 ? (
                transactionsData.map((singleTransaction, i) => (
                  <Tr
                    key={i}
                    className="grid md:grid-cols-4 gap-4 border-b-2 items-center justify-center"
                  >
                    <Td className="border-b-0">{singleTransaction._id}</Td>
                    <Td className="border-b-0 capitalize">
                      {singleTransaction.firstName +
                        " " +
                        singleTransaction.lastName}
                    </Td>
                    {/*<Td className="border-b-0">*/}
                    {/*  {singleTransaction.mobileNumber}*/}
                    {/*</Td>*/}
                    <Td className="border-b-0">
                      {singleTransaction.seatsPaidFor.length}
                    </Td>
                    <Td className="border-b-0">
                      {moment(singleTransaction.paymentDate).format("lll")}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr></Tr>
              )}
            </Tbody>
          </Table>
          {!transactionsData || transactionsData?.length < 0 ? (
            <Box className="flex w-full py-6 flex-col gap-10 items-center justify-center">
              <IoReceiptOutline size={120} />
              <Text>No transactions for this event yet</Text>
            </Box>
          ) : null}
          {isLoading && <Loading count={1} />}
          {error && (
            <Text className=" text-red-400 my-4 text-center">
              An error Ocurred
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MyEventDashBoard;
