import { useRecoilValue } from "recoil";
import { selectedEventState, awsUserDetailsAtom } from "../../../recoil/atom";
import EventDetails from "./EventDetails";

const PastEventsInfo = () => {
  const selectedEvent = useRecoilValue(selectedEventState);

  return <EventDetails selectedEvent={selectedEvent} />;
};

export default PastEventsInfo;
