import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import React, { Children, ReactNode } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useQueryErrorResetBoundary } from "react-query";

const ErrorRender = ({ resetErrorBoundary, error }: FallbackProps) => (
  <Stack direction="column" gap={2} justifyContent="center" py={20}>
    <Heading> Something Went Wrong</Heading>
    <Text as="code" fontSize="xs">
      {error.message}
    </Text>
    <Button onClick={() => resetErrorBoundary()}>Try again</Button>
  </Stack>
);

interface Props {
  children: ReactNode;
}
const GlobalErrorHandler = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary onReset={reset} fallbackRender={ErrorRender}>
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorHandler;
