import { Box, Button, Flex, IconButton, Link, Text } from "@chakra-ui/react";
import Loading from "../../EventComponent/Event/Loading";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";
import moment from "moment";
import BackButton from "../../Buttons/BackButton";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { pdfBuffersAtom } from "../../../recoil/atom";
import axios from "axios";
import { API_URL } from "../../../utils/constants";

interface EventInfoProps {
  selectedEvent: any;
  data?: any;
  isLoading?: boolean;
  isSuccess?: boolean;
  error?: any;
}

const EventDetails = ({
  selectedEvent,
  data,
  isLoading,
  isSuccess,
  error,
}: EventInfoProps) => {
  const [pdfBuffersObject, setPdfBuffersObject] =
    useRecoilState(pdfBuffersAtom);
  const [postLoading, setPostLoading] = useState(false);
  useEffect(() => {
    const postData = async () => {
      try {
        setPostLoading(true);
        const response = await axios.post(API_URL + "/seat/generate-pdf", {
          tickets: data,
          event: selectedEvent,
        });
        console.log("PDF generated successfully:", response.data);
        
        const ticketsReturned = response.data?.pdfBuffers;

        const pdfBufferResponse = new Uint8Array(atob(ticketsReturned).split('').map(char => char.charCodeAt(0)));
        setPdfBuffersObject(pdfBufferResponse);
      } catch (error) {
        console.error("Error generating PDF:", error.message);
      } finally {
        setPostLoading(false);
      }
    };
    postData();
  }, [data, selectedEvent, setPdfBuffersObject]);

  const handleDownload = () => {
    try {

      const blob = new Blob([pdfBuffersObject], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "MushaTickets.pdf";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
    } catch (error) {
      console.error("Error handling compressed PDF buffer:", error);
    }
  };

  if (isLoading) {
    return <Loading count={1} />;
  }

  if (error && !isSuccess) {
    return (
      <Text className="text-center text-red-400 my-16">
        Error getting tickets
      </Text>
    );
  }

  return (
    <Box className=" py-12 md:px-10 px-4 grid gap-6 w-full">
      <BackButton />
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Text className=" text-black-500 font-bold text-3xl mb-4 md:text-left text-center">
          Your tickets for{" "}
          <span className=" text-orange-500">{selectedEvent.title}</span>
        </Text>

        <Button
          isLoading={postLoading}
          onClick={handleDownload}
          mt={4}
          colorScheme="orange"
          className=" w-full max-w-sm"
        >
          Download Tickets
        </Button>

        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          mt={"2em"}
        >
          <Box mr={"8em"}>
            <Text fontWeight="700" mt="1em" mb="1em" textDecoration="underline">
              Event Information
            </Text>
            <Box className="flex flex-col gap-2">
              <Text>
                <span className=" font-semibold">From:</span>{" "}
                {moment(selectedEvent.eventStart).format("lll")}
              </Text>
              <Text>
                <span className=" font-semibold">To:</span>{" "}
                {moment(selectedEvent.eventStart).format("lll")}
              </Text>

              <Text>
                <span className=" font-semibold">Location:</span>{" "}
                {selectedEvent.locationName}
              </Text>
            </Box>
          </Box>
          <Box>
            <Box className="mt-4">
              <Text className=" font-bold mb-2 underline">
                Organizer contact
              </Text>
              <Flex className="gap-6 items-center">
                <Text>Tel: {selectedEvent.contactPhone}</Text>
                <Link href={`tel:${selectedEvent.contactPhone}`}>
                  <IconButton
                    aria-label="phone"
                    icon={<AiFillPhone />}
                    isRound
                  />
                </Link>
              </Flex>
              <Flex className="gap-6 items-center">
                <Text>Mail: {selectedEvent.contactEmail}</Text>
                <Link href={`mailto:${selectedEvent.contactEmail}`}>
                  <IconButton
                    aria-label="phone"
                    icon={<AiOutlineMail />}
                    isRound
                  />
                </Link>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EventDetails;
