import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { IoMdClose } from "react-icons/io";
import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import { CreateEventFormSteps, Section } from "../../../types/interface";
import { useRecoilState } from "recoil";
import {
  AddSeatingChartFormAtom,
  eventCreationPathAtom,
  formProgressAtom,
  formStepAtom,
  prevCreateEventFormStepAtom,
} from "../../../recoil/atom";
import { useFormik } from "formik";
import {
  generateStringsFromMap,
  SortedNumericMap,
} from "../../../utils/sortedNumericMap";
import cloneDeep from "lodash/cloneDeep";
import { AiOutlineClose } from "react-icons/ai";

const AddSeatingChartForm: React.FC = () => {
  const [sections, setSections] = useRecoilState(AddSeatingChartFormAtom);
  const [newSectionName, setNewSectionName] = useState("");
  const [formStep, setFormStep] = useRecoilState(formStepAtom);
  const [formProgress, setFormProgress] = useRecoilState(formProgressAtom);
  const [, setEventCreationPath] = useRecoilState(eventCreationPathAtom);
  const [prevCreateEventFormStep, setPrevCreateEventFormStep] = useRecoilState(
    prevCreateEventFormStepAtom
  );

  useEffect(() => {
    setFormProgress(70);
    setPrevCreateEventFormStep(CreateEventFormSteps.DESCRIBE_DIAGRAM_FORMAT);
    setEventCreationPath(CreateEventFormSteps.ADD_SEATING_CHART);
  }, [setEventCreationPath, setFormProgress, setPrevCreateEventFormStep]);

  const handleAddSection = () => {
    const newSection: Section = {
      name: newSectionName,
      rowOrTableMap: {},
    };

    setSections((prevSections) => {
      const newSections = { ...prevSections };
      newSections[newSectionName] = newSection;
      return newSections;
    });

    setNewSectionName("");
  };

  const handleRemoveSection = (sectionName: string) => {
    setSections((prevSections) => {
      const newSections = { ...prevSections };
      delete newSections[sectionName];
      return newSections;
    });
  };

  const handleAddRow = (sectionName: string) => {
    const newRowName = formik.values[`row-in-section-${sectionName}`];
    setSections((prevSections) => {
      const newSections = cloneDeep(prevSections);
      newSections[sectionName].rowOrTableMap[newRowName] = {
        name: newRowName,
        seats: new SortedNumericMap(),
      };

      return newSections;
    });
    formik.setFieldValue(`row-in-section-${sectionName}`, "");
  };

  const handleRemoveRow = (sectionName: string, rowName: string) => {
    setSections((prevSections) => {
      const newSections = { ...prevSections };
      delete newSections[sectionName].rowOrTableMap[rowName];
      return newSections;
    });
  };

  const handleAddSeat = (sectionName: string, rowName: string) => {
    const newSeatName = formik.values[`seat-number-${sectionName}-${rowName}`];
    const newSeatPrice = formik.values[`seat-price-${sectionName}-${rowName}`];
    setSections((oldSections) => {
      const newSections = cloneDeep(oldSections);
      const newSeats =
        newSections[sectionName].rowOrTableMap[rowName].seats.clone();
      newSeats.updateMap(newSeatName, parseFloat(newSeatPrice));
      newSections[sectionName].rowOrTableMap[rowName].seats = newSeats;
      return newSections;
    });
  };

  const handleRemoveSeat = (
    sectionName: string,
    rowName: string,
    seatString: string
  ) => {
    setSections((oldSections) => {
      const newSections = cloneDeep(oldSections);
      const newSeats =
        newSections[sectionName].rowOrTableMap[rowName].seats.clone();
      newSeats.removeKey(seatString);
      newSections[sectionName].rowOrTableMap[rowName].seats = newSeats;
      return newSections;
    });
  };

  const formik = useFormik({
    initialValues: {
      input: "",
    },
    onSubmit: async (values) => {},
  });

  const handleFormSubmit = (): any => {
    setFormStep(CreateEventFormSteps.ADD_BANK_DETAILS);
  };

  return (
    <Stack align="middle" maxW={1000} mt={2}>
      <Heading className="text-xl text-center">Seating Chart</Heading>

      <FormControl>
        <FormLabel>Section Name</FormLabel>
        <Flex className="md:gap-4 gap-2">
          <Input
            type="text"
            value={newSectionName}
            onChange={(e) => setNewSectionName(e.target.value)}
            placeholder="Section name. E.g VIP"
            className=" border border-gray-300"
          />
          <IconButton
            aria-label="add"
            icon={<AddIcon />}
            onClick={handleAddSection}
          />
        </Flex>
      </FormControl>

      {Object.entries(sections).map(([sectionName, sectionObject]) => (
        <Box key={sectionObject.name} className="border-2 p-2 rounded-lg mt-2">
          <Flex justifyContent={"space-between"} className="items-center">
            <Text className=" font-bold">
              Section: {sectionObject.name}
            </Text>
            <IconButton
              aria-label={`Delete icon ${sectionObject.name}`}
              variant="unstyled"
            >
              <IconButton
                aria-label="delete"
                icon={<AiOutlineClose />}
                onClick={() => handleRemoveSection(sectionObject.name)}
                isRound={true}
                size={"xs"}
                variant={"outline"}
              />
            </IconButton>
          </Flex>

          <FormControl>
            <FormLabel>Row Name</FormLabel>
            <Flex className="md:gap-4 gap-2">
              <Input
                type="text"
                value={formik.values[`row-in-section-${sectionObject.name}`]}
                onChange={(e) =>
                  formik.setFieldValue(
                    `row-in-section-${sectionObject.name}`,
                    e.target.value
                  )
                }
                className=" border border-gray-300"
              />
              <IconButton
                icon={<AddIcon />}
                aria-label="add"
                onClick={() => handleAddRow(sectionObject.name)}
              />
            </Flex>
          </FormControl>

          {Object.entries(sectionObject.rowOrTableMap).map(
            ([rowName, rowObject]) => (
              <Box
                key={`${sectionObject.name}-${rowObject.name}`}
                className="mt-5 border p-2 rounded"
              >
                <Flex justifyContent={"space-between"} className=" mb-2 items-center">
                  <Heading as="h4" size="sm" className="font-bold">
                    Row: {rowObject.name}
                  </Heading>
                  <IconButton
                    aria-label="delete"
                    icon={<AiOutlineClose />}
                    onClick={() =>
                      handleRemoveRow(sectionObject.name, rowObject.name)
                    }
                    isRound={true}
                    size={"xs"}
                    variant={"outline"}
                  />
                </Flex>

                <Flex className="md:gap-4 gap-2">
                  <Input
                    type="text"
                    value={
                      formik.values[
                        `seat-number-${sectionObject.name}-${rowObject.name}`
                      ]
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        `seat-number-${sectionObject.name}-${rowObject.name}`,
                        e.target.value
                      )
                    }
                    placeholder="Seat number or range. E.g 25 or 40-50"
                    className=" border border-gray-300"
                  />
                  <Input
                    type="number"
                    value={
                      formik.values[
                        `seat-price-${sectionObject.name}-${rowObject.name}`
                      ]
                    }
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const numericValue = parseFloat(inputValue);
                      if (inputValue === "" || !isNaN(numericValue)) {
                        formik.setFieldValue(
                          `seat-price-${sectionObject.name}-${rowObject.name}`,
                          inputValue
                        );
                        // setNewSeatPrice(inputValue);
                      }
                    }}
                    placeholder="Seat price. E.g 50"
                    className=" border border-gray-300"
                  />

                  <IconButton
                    aria-label="add"
                    icon={<AddIcon />}
                    onClick={() =>
                      handleAddSeat(sectionObject.name, rowObject.name)
                    }
                  />
                </Flex>

                <Box className="grid lg:grid-cols-3 md:grid-cols-2 w-full gap-2 mt-4">
                  {generateStringsFromMap(rowObject.seats).map(
                    (seat, seatIndex) => (
                      <Flex
                        key={seatIndex}
                        borderWidth="2px"
                        borderRadius="lg"
                        borderColor={"orange"}
                        className="justify-between md:p-4 p-2"
                      >
                        <Text>{seat}</Text>
                        <IconButton
                          aria-label={`Remove Seat ${seatIndex + 1}`}
                          variant="outline"
                          icon={<IoMdClose />}
                          onClick={() =>
                            handleRemoveSeat(
                              sectionObject.name,
                              rowObject.name,
                              seat
                            )
                          }
                          size={"xs"}
                        />
                      </Flex>
                    )
                  )}
                </Box>
              </Box>
            )
          )}
        </Box>
      ))}
      <HStack justifyContent={"center"} gap={4} className="mt-8">
        <Button
          type="button"
          onClick={() => setFormStep(prevCreateEventFormStep)}
          variant="outline"
        >
          <Icon as={MdOutlineArrowBack} />
        </Button>

        <Button onClick={handleFormSubmit} variant="solid">
          <Icon as={MdOutlineArrowForward} />
        </Button>
      </HStack>
    </Stack>
  );
};

export default AddSeatingChartForm;
