import { Route, Routes } from "react-router-dom";

import { RequireAuth } from "./HOCs";
import WithoutAuthentication from "./HOCs/withOutAuthentication";
import {
  BuyTicketLayout,
  RootLayoutStandard,
  TicketsLayout,
  RootLayoutWide,
} from "./layouts";
import About from "./screens/About";
import AddEventDiagramScreen from "./screens/AddEventDiagram";
import CommunityGuidelines from "./screens/CommunityGuidelines";
import CreateEvent from "./screens/CreateEvent";
import Discover from "./screens/Discover";
import NotFound from "./screens/NotFound";
import Privacy from "./screens/Privacy";
import Refund from "./screens/Refund";
import Signup from "./screens/Signup";
import Terms from "./screens/Terms";
import Ticket from "./screens/Ticket";
import UserEvent from "./screens/UserEvent";
import { Tickets } from "./screens/account";
import {
  TicketsOptions,
  BuyTicketsDiagram,
  CheckoutForm,
} from "./screens/buyTicket";
import EventDetails from "./screens/buyTicket/EventDetails";
import Login from "./screens/login";
import { Bought } from "./screens/tickets";
import EventList from "./components/EventComponent/Event/EventList";
import SearchPage from "./components/Search/SearchPage";
import ForgotPasswordForm from "./screens/Password/ForgotPasswordForm";
import ConfirmPasswordResetForm from "./screens/Password/ConfirmPasswordResetForm";
import UserProfile from "./components/UserProfile";
import MyEventDetails from "./components/dashboard/MyEventDetails";
import SuccessEvent from "./screens/SuccessEvent";
import UpcomingEventInfo from "./components/Ticket/MyTickets/UpcomingEventInfo";
import PrivateEventAuth from "./screens/PrivateEventAuth";
import PastEventsInfo from "./components/Ticket/MyTickets/PastEventsInfo";
import FAQ from "./screens/FAQ/FAQ";
import SuccessPage from "./screens/buyTicket/SuccessPage";
import Pricing from "./screens/Pricing/Pricing";
import TransactionSuccessPage from "./screens/buyTicket/TransactionSuccessPage";

const Index = () => {
  return (
    <Routes>
      <Route element={<WithoutAuthentication />}>
        <Route path="/sign_up" element={<Signup />} />
        <Route path="/sign_in" element={<Login />} />
        <Route path="/forgot_password" element={<ForgotPasswordForm />} />
        <Route
          path="/confirm_password_reset"
          element={<ConfirmPasswordResetForm />}
        />
      </Route>

      <Route path="/" element={<RootLayoutWide />}>
        <Route path={"/pricing"} element={<Pricing />} />
        <Route
          path="event/:event_id/buy_diagram"
          element={<BuyTicketsDiagram />}
        />
      </Route>

      <Route path="/" element={<RootLayoutStandard />}>
        <Route index element={<Discover />} />
        <Route path="/search/:searchText" element={<SearchPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/guidelines" element={<CommunityGuidelines />} />

        <Route path="event/:event_id/success" element={<SuccessPage />} />
        <Route path="/success" element={<TransactionSuccessPage />} />
        <Route path="event/:event_id" element={<BuyTicketLayout />}>
          <Route path="details" element={<EventDetails />} />
          <Route path="choice" element={<TicketsOptions />} />
          <Route path="payment" element={<CheckoutForm />} />
        </Route>

        <Route
          path="/account/my_tickets"
          element={
            <RequireAuth loginPath="/sign_in">
              <Bought />
            </RequireAuth>
          }
        />
        <Route
          path="/account/my_tickets/:id"
          element={
            <RequireAuth loginPath="/sign_in">
              <UpcomingEventInfo />
            </RequireAuth>
          }
        />
        <Route
          path="/account/my_tickets/past/:id"
          element={
            <RequireAuth loginPath="/sign_in">
              <PastEventsInfo />
            </RequireAuth>
          }
        />
        <Route
          path="/account/my_events"
          element={
            <RequireAuth loginPath="/sign_in">
              <Tickets />
            </RequireAuth>
          }
        />
        <Route
          path="/account/my_events/details"
          element={
            <RequireAuth loginPath="/sign_in">
              <MyEventDetails />
            </RequireAuth>
          }
        />
        <Route
          path="/account/profile"
          element={
            <RequireAuth loginPath="/sign_in">
              <UserProfile />
            </RequireAuth>
          }
        />

        <Route
          path="/add_event"
          element={
            <RequireAuth loginPath="/sign_in">
              <CreateEvent />
            </RequireAuth>
          }
        />
        <Route
          path="tickets"
          element={
            <RequireAuth loginPath="/sign_in">
              <TicketsLayout />
            </RequireAuth>
          }
        >
          <Route path="bought" element={<Bought />} />
        </Route>

        <Route path="/private_auth/:event_id" element={<PrivateEventAuth />} />
        <Route
          path="/create_event"
          element={
            <RequireAuth loginPath="/sign_in">
              <CreateEvent />
            </RequireAuth>
          }
        />
        <Route path="/ticket/:ticket_id" element={<Ticket />} />
        <Route path="/user_event/:event_id" element={<UserEvent />} />
        <Route path="/event/add-diagram" element={<AddEventDiagramScreen />} />
        <Route path="/:detailEventId" element={<EventList />} />
        <Route
          path="/:detailEventId/:detailEventName"
          element={<EventList />}
        />
        <Route
          path="/create_event/success/:event_id"
          element={<SuccessEvent />}
        />
        <Route path="/faq" Component={FAQ} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Index;
