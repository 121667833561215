import { useState } from "react";
import {
  Input,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { bucket, getS3 } from "../../utils/aws";

const EditEventModal = ({ isOpen, onClose, selectedEvent, onSave }) => {
  const [editedEvent, setEditedEvent] = useState({
    priceTypes: selectedEvent.priceTypes,
    ticketingType: selectedEvent.ticketingType,
    description: selectedEvent.description,
    imgBanner: selectedEvent.imgBanner,
  });

  const handleInputChange = async (e) => {
    const { name, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      const imageUrl = await putImage(file);
      setEditedEvent((prevEvent) => ({
        ...prevEvent,
        [name]: imageUrl,
      }));
    } else {
      const { value } = e.target;
      setEditedEvent((prevEvent) => ({
        ...prevEvent,
        [name]: value,
      }));
    }
  };

  const handleSave = () => {
    onSave(editedEvent);
    onClose();
  };

  const putImage = async (img) => {
    try {
      const response = await uploadImg(img);
      return response.Location;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw new Error("Failed to upload image");
    }
  };

  const uploadImg = async (img) => {
    const s3 = getS3();

    try {
      const response = await s3
        .upload({
          Bucket: bucket,
          Key: `musha${Date.now() + Date.now()}img.jpg`,
          Body: img,
          ContentType: "image/*",
        })
        .promise();
      console.log("Successful upload:", response);
      return response;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Event</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Cover image upload</FormLabel>
            <Input type="file" name="imgBanner" onChange={handleInputChange} />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Description</FormLabel>
            <Textarea
              name="description"
              value={editedEvent.description}
              onChange={handleInputChange}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditEventModal;
