import { Box, Text } from "@chakra-ui/react";
import useGetData from "../../../hooks/useGetData";
import { IEvent } from "../../../types/interface";
import Loading from "../../EventComponent/Event/Loading";
import { useSetRecoilState } from "recoil";
import { selectedEventState } from "../../../recoil/atom";
import { useNavigate } from "react-router-dom";
import EventCard from "./EventCard";

const PastEvents = () => {
  const queryKey = "pastEventsWithUserTickets";
  const baseUrl = "/event/events_with_user_tickets?fetchPastEvents=true";
  const selectedEvent = useSetRecoilState(selectedEventState);
  const navigation = useNavigate();

  const endpointUrl = `${baseUrl}`;

  const { data, error, isLoading, isSuccess } = useGetData(
    queryKey,
    endpointUrl,
  );

  if (isLoading) {
    return <Loading count={1} />;
  }

  if (error) {
    return <Text className=" text-center text-red-400 my-8">Error</Text>;
  }

  const handleEventClick = (event: IEvent) => {
    selectedEvent(event);
    navigation(`/account/my_tickets/past/${event._id}`);
  };

  return (
    <Box className=" w-full py-8 items-center flex-col flex justify-center px-4 md:px-10 ">
      {isSuccess &&
      data &&
      Array.isArray(data.events) &&
      data.events.length > 0 ? (
        <Box className=" w-full items-center flex-col gap-4 flex justify-center">
          {data.events.map((event: IEvent) => (
            <EventCard event={event} onClick={() => handleEventClick(event)} />
          ))}
        </Box>
      ) : (
        <Text className="pb-32">No Past Events found</Text>
      )}
    </Box>
  );
};

export default PastEvents;
