import { Link, NavLink, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

import { IoPersonOutline, IoTicketOutline } from "react-icons/io5";
import { HiUser, HiSun, HiMoon } from "react-icons/hi";
import {
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { Avatar } from "@chakra-ui/react";
import { isAuthenticatedAtom, awsUserDetailsAtom } from "../../recoil/atom";
import colors from "../../utils/colors";
import { IoCalendarOutline, IoLogOutOutline } from "react-icons/io5";
import React, { ReactNode } from "react";

export default function WithSubnavigation() {
  const { onToggle, isOpen, onClose } = useDisclosure();
  const {
    onToggle: onToggleMobileNav,
    isOpen: isOpenMobileNav,
    onClose: onCloseMobileNav,
  } = useDisclosure();
  const [isAuthenticated, setIsAuthenticated] =
    useRecoilState(isAuthenticatedAtom);
  const setUserDetails = useSetRecoilState(awsUserDetailsAtom);
  const userDetails = useRecoilValue(awsUserDetailsAtom);

  const navigate = useNavigate();

  const logout = () => {
    const confirm = window.confirm("Are you sure you want to Log out?");
    if (confirm) {
      navigate("/");
      setUserDetails(null);
      setIsAuthenticated(false);
      localStorage.clear();
    }
  };

  return (
    <Box width={"full"} className=" relative">
      <HStack
        bg={"white"}
        color={"gray.600"}
        py={{ base: 4 }}
        px={{ base: 2, md: 10 }}
        borderBottom={1}
        justifyContent={"space-between"}
        borderStyle={"solid"}
        borderColor={"gray.200"}
        align={"center"}
      >
        <IconButton
          display={{ base: "inline", lg: "none" }}
          onClick={() => {
            onToggleMobileNav();
          }}
          icon={
            isOpenMobileNav ? (
              <CloseIcon w={3} h={3} />
            ) : (
              <HamburgerIcon w={5} h={5} />
            )
          }
          variant={"ghost"}
          aria-label={"Toggle Navigation"}
        />

        <Link to="/" onClick={() => onClose()}>
          <Image
            src={"/LOGO.png"}
            alt={"logo"}
            // className=" w-full object-cover object-center md:h-10 h-6"
            className="w-full object-center object-contain md:h-10 h-10"
            // src={"/LOGO.png"}
            // alt={"logo"}
            // className="w-full h-auto md:h-10 h-6"
            // style={{ maxWidth: "100%", height: "auto" }}
          />
        </Link>

        <Flex display={{ base: "none", lg: "block" }} px={4} flex={1}>
          <DesktopNav />
        </Flex>

        <HStack spacing={6}>
          {isAuthenticated ? (
            <HStack spacing={2}>
              <Flex
                alignItems={"center"}
                gap={1}
                display={{ lg: "flex", base: "none" }}
              ></Flex>
              <Spacer />
              <Box
                className="cursor-pointer"
                alignItems={"center"}
                flexDirection={"row"}
                display={"flex"}
                justifyContent={"space-between"}
                onClick={() => onToggle()}
              >
                <Text
                  fontSize="md"
                  mr={"8px"}
                  display={{ base: "none", lg: "block" }}
                  fontWeight={"semibold"}
                >
                  My Account
                </Text>
                <Avatar
                  color="white"
                  icon={<Icon as={HiUser} fontSize="1.3rem" />}
                  src=""
                />
              </Box>

              <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                size={"md"}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton size="lg" />
                  <DrawerHeader py={12} textAlign={"center"}>
                    <Text>Hello {userDetails?.given_name as string}!</Text>
                  </DrawerHeader>
                  {SideBarItems.map((item, i) => (
                    <Box
                      key={i}
                      borderTop="1px"
                      borderBottom="1px"
                      borderColor="gray.200"
                      alignItems={"center"}
                      justifyContent={"center"}
                      className={"group "}
                      transition="background 0.3s"
                      _hover={{
                        background: "gray.100",
                        cursor: "pointer",
                      }}
                      textAlign="center"
                    >
                      <NavLink
                        to={item.href}
                        className={
                          "flex-1 w-full flex flex-col items-center justify-center py-6"
                        }
                        onClick={() => onClose()}
                      >
                        {item.icon}
                        <Text>{item.label}</Text>
                      </NavLink>
                    </Box>
                  ))}
                  {isAuthenticated && (
                    <Box
                      borderTop="1px"
                      borderBottom="1px"
                      borderColor="gray.200"
                      py={6}
                      alignItems={"center"}
                      justifyContent={"center"}
                      transition="background 0.3s"
                      _hover={{
                        background: "gray.100",
                        cursor: "pointer",
                      }}
                      textAlign="center"
                      onClick={logout}
                      // className=" hover:bg-red-400 duration-300 hover:text-white"
                    >
                      <Icon as={IoLogOutOutline} boxSize="2em" />
                      <Text>Log out</Text>
                    </Box>
                  )}
                </DrawerContent>
              </Drawer>
            </HStack>
          ) : (
            <>
              <Button
                size={"xs"}
                as={Link}
                to={"/sign_in"}
                fontSize={"sm"}
                fontWeight={700}
                variant={"link"}
                color={colors.primary}
              >
                Sign In
              </Button>
              <Button
                as={Link}
                to={"/sign_up"}
                display={{ base: "none", lg: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={600}
                color={"white"}
                bg={colors.primary}
                _hover={{
                  bg: "blue.600",
                }}
              >
                Sign Up
              </Button>
            </>
          )}
        </HStack>
      </HStack>

      <MobileNav onClose={onCloseMobileNav} isOpen={isOpenMobileNav} />
    </Box>
  );
}

const DesktopNav = () => {
  return (
    <HStack spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <NavLink key={navItem.id} to={navItem?.href}>
          {({ isActive, isPending }) => (
            <Text
              fontSize="md"
              fontWeight={"semibold"}
              color={isActive ? "secondary.500" : ""}
            >
              {navItem.label}
            </Text>
          )}
        </NavLink>
      ))}
    </HStack>
  );
};

interface MobileNavProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SideBarItemProps {
  text: string;
  icon?: ReactNode;
  href: string;
  onClose: () => void;
}

const SideBarItem: React.FC<SideBarItemProps> = (props: SideBarItemProps) => {
  return (
    <>
      <Box
        borderTop="1px"
        borderBottom="1px"
        borderColor="gray.200"
        py={6}
        alignItems={"center"}
        justifyContent={"center"}
        className={"group "}
        transition="background 0.3s"
        _hover={{
          background: "gray.100",
          cursor: "pointer",
        }}
        textAlign="center"
        as={NavLink}
        onClick={props.onClose}
        to={props.href}
      >
        {props.icon ? props.icon : null}
        <Text>{props.text}</Text>
      </Box>
    </>
  );
};
const MobileNav: React.FC<MobileNavProps> = (props: MobileNavProps) => {
  return (
    <Drawer
      isOpen={props.isOpen}
      placement="left"
      onClose={props.onClose}
      size={"sm"}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader textAlign={"center"}>Menu</DrawerHeader>
        <DrawerCloseButton size={"lg"} />
        {NAV_ITEMS.map((navItem) => (
          <SideBarItem
            key={navItem.id}
            onClose={props.onClose}
            href={navItem.href}
            text={navItem.label}
          />
        ))}
      </DrawerContent>
    </Drawer>
  );
};

interface NavItem {
  id: string | number;
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

interface SideItem {
  label: string;
  href: string;
  icon: any;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    id: "link-1",
    label: "Home",
    href: "/",
  },
  {
    id: "link-2",
    label: "Create Event",
    href: "/create_event",
  },
  {
    id: "link-5",
    label: "Pricing",
    href: "/pricing",
  },
  {
    id: "link-6",
    label: "FAQ",
    href: "/faq",
  },
  {
    id: "link-7",
    label: "About",
    href: "/about",
  },
];

const SideBarItems: Array<SideItem> = [
  {
    label: "My tickets",
    // icon: <IoTicket size="2em" color={"blue"}/>,
    icon: <Icon as={IoTicketOutline} boxSize="2em" />,
    href: "/account/my_tickets",
  },
  {
    label: "My Events",
    icon: <Icon as={IoCalendarOutline} boxSize="2em" />,
    href: "/account/my_events",
  },
  {
    label: "Profile",
    icon: <Icon as={IoPersonOutline} boxSize="2em" />,
    href: "/account/profile",
  },
];
