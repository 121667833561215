import { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  VStack,
  Card,
  CardBody,
  Box,
  Flex,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import useGetData from "../../hooks/useGetData";
import LoadingEventDetails from "../../components/EventComponent/Event/LoadingEventDetails";
import { IEvent, PriceAndQuantity } from "../../types/interface";
import get from "lodash/get";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentEventAtom,
  formikValuesAtom,
  meetingCodeState,
  numSeatsInSectionToBuyAtom,
  totalAmountAtom,
} from "../../recoil/atom";
import { API_URL } from "../../utils/constants";
import OrderSummary from "../../components/Order/OrderSummary";
import OrderSummaryMobile from "../../components/Order/OrderSummaryMobile";
import moment from "moment";
import { calculateFees } from "../../utils/functions";

const TicketsOptions = () => {
  const { event_id } = useParams();
  const navigate = useNavigate();
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [soldOut, setSoldOut] = useState<boolean>(false);

  const meetingCode = useRecoilValue(meetingCodeState);
  // console.log("This is meeting CODE state: ", meetingCode);
  const { colorMode } = useColorMode();

  const [, setNumSeatsInSectionToBuy] = useRecoilState(
    numSeatsInSectionToBuyAtom,
  );

  const [, setCurrentEvent] = useRecoilState(currentEventAtom);
  const setFormikValues = useSetRecoilState(formikValuesAtom);
  const setTotalAmount = useSetRecoilState(totalAmountAtom);

  interface FormValues {
    [key: string]: {
      quantity: number;
      amount: number;
      fees: number;
    };
  }

  const initialValues: FormValues = {};

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      let currentSeatsInSection: Record<string, number> = {};
      let totalAmount = 0;
      Object.entries(formik.values).forEach(([type, { quantity, amount }]) => {
        console.log("These are values: ", type);
        if (quantity > 0) {
          currentSeatsInSection[type] = quantity;
          totalAmount += amount;
        }
      });
      setFormikValues(values);
      setNumSeatsInSectionToBuy(currentSeatsInSection);

      setTotalAmount(totalAmount);
      navigate(`/event/${event_id}/payment`);
    },
  });

  const eventDetails = useGetData(
    "event_details",
    `${API_URL}/event/${event_id}?meetingCode=${meetingCode}`,
  );
  useEffect(() => {
    if (eventDetails.isSuccess) {
      setCurrentEvent(eventDetails.data as IEvent);
      return;
    }
  }, [eventDetails.data, eventDetails.isSuccess, setCurrentEvent]);

  // useEffect(() => {
  //   if (eventDetails.data !== undefined) {
  //     const seatGroupObject = (eventDetails.data as IEvent).seatGroupingMap;
  //     if (seatGroupObject !== undefined) {
  //       const seatGroupKeys = Object.keys(seatGroupObject);
  //       const groupName = seatGroupKeys.length > 0 ? seatGroupKeys[0] : "";
  //       const groupNameData = seatGroupObject[groupName];
  //       const isSoldOut =
  //         groupNameData.numSeatsSold >= groupNameData.numInitialSeats;
  //       if (isSoldOut) {
  //         setSoldOut(true);
  //       } else {
  //         setSoldOut(false);
  //       }
  //     }
  //   }
  // }, [eventDetails.data]);

  useEffect(() => {
    // Check if any field in the form is dirty (i.e., has a value)
    const isDirty = Object.values(formik.values).some((field) =>
      Object.values(field).some((value) => value > 0),
    );
    setDisabled(!isDirty); // Disable button if no field is dirty
  }, [formik.values]);

  if (eventDetails.isLoading) {
    return <LoadingEventDetails />;
  }

  const currentEvent = eventDetails.data as IEvent;

  if (eventDetails.error) {
    return <Text>No data found or an error occurred.</Text>;
  }

  if (eventDetails.data?.isTruncated) {
    navigate(`/private_auth/${event_id}`);
  }
  const seatGroupObject = (eventDetails.data as IEvent).seatGroupingMap;

  // console.log("This is event data grouping map: ", eventDetails.data as IEvent);

  const handleQuantityChange = (seatGroup: string, quantity: number) => {
    formik.setFieldValue(`${seatGroup}.quantity`, quantity);
    if (quantity !== 0) {
      setDisabled(false);
      const ticketPrice = seatGroupObject[seatGroup].price || 0;
      const amount = quantity * ticketPrice;
      const fees = calculateFees(currentEvent, ticketPrice, quantity);
      console.log("These are new fees for Order summary Brian: ", fees);
      console.log(
        "These are new fees for Order summary Brian: ",
        currentEvent,
        ticketPrice,
        quantity,
      );
      formik.setFieldValue(`${seatGroup}.amount`, amount);
      formik.setFieldValue(`${seatGroup}.fees`, fees);
    } else {
      setDisabled(true);
      formik.setFieldValue(`${seatGroup}.amount`, 0);
      formik.setFieldValue(`${seatGroup}.fees`, 0);
    }
    const isDisabled = Object.values(formik.values).every(
      ({ quantity }) => quantity <= 0,
    );
    setDisabled(isDisabled);
  };

  const buildPriceAndQuantityMap = (): Record<string, PriceAndQuantity> => {
    const priceAndQuantityMap: Record<string, PriceAndQuantity> = {};
    Object.entries(formik.values).forEach(
      ([group, { quantity, amount, fees }]) => {
        priceAndQuantityMap[group] = {
          price: amount,
          quantity: quantity,
          fees: fees,
        };
      },
    );
    return priceAndQuantityMap;
  };

  const handleCancel = () => {
    const confirm = window.confirm(
      "Are you sure you want to cancel your Order?",
    );
    if (confirm) {
      navigate(-1);
    }
  };

  return (
    <VStack justifyContent="center" gap={6}>
      <Card className="w-full">
        <CardBody className=" w-full">
          <Box className="grid lg:grid-cols-2 gap-6">
            <Box className=" lg:hidden block">
              <OrderSummaryMobile
                openSummary={openSummary}
                setOpenSummary={setOpenSummary}
                priceAndQuantityMap={buildPriceAndQuantityMap()}
              />
            </Box>
            {soldOut ? (
              <Box className="flex w-full items-center justify-center">
                <Text className=" text-2xl font-bold py-32 md:py-0">
                  Sold Out😪
                </Text>
              </Box>
            ) : (
              <Box className="flex flex-col w-full">
                <form onSubmit={formik.handleSubmit} className="w-full">
                  <Text className="text-xl" fontWeight="bold" mb={6}>
                    Choose your tickets
                  </Text>
                  <Text>
                    Select which ticket types you wish to purchase below.
                  </Text>
                  <Box className="items-center w-full grid gap-4">
                    {seatGroupObject !== undefined ? (
                      Object.entries(seatGroupObject)
                        .sort(([, a], [, b]) => a.price - b.price)
                        .map(([type, seatGroupingData]) => {
                          const isNowAfterSalesEnd: boolean = moment().isAfter(
                            seatGroupingData.salesEndDate,
                          );
                          const isNowBeforeSalesStart: boolean =
                            moment().isBefore(seatGroupingData.salesStartDate);
                          return (
                            <Flex
                              key={type}
                              className={`items-center mt-4 justify-between gap-4 p-2 border ${
                                seatGroupingData.numSeatsSold >=
                                seatGroupingData.numInitialSeats
                                  ? "border-red-500"
                                  : ""
                              } rounded-md flex w-full`}
                            >
                              <Box>
                                <Text className="font-bold">
                                  {type}{" "}
                                  {seatGroupingData.numSeatsSold >=
                                    seatGroupingData.numInitialSeats && (
                                    <span className=" text-red-500">
                                      Sold Out
                                    </span>
                                  )}
                                  {isNowAfterSalesEnd && (
                                    <span className="text-red-500">
                                      - Sales have ended
                                    </span>
                                  )}
                                  {isNowBeforeSalesStart && (
                                    <span className="text-red-500">
                                      Sales start{" "}
                                      {moment(
                                        seatGroupingData.salesStartDate,
                                      ).format("LL")}{" "}
                                      {moment(
                                        seatGroupingData.salesStartDate,
                                      ).format("LT")}
                                    </span>
                                  )}
                                </Text>
                                <Flex
                                  className={`${
                                    colorMode === "dark"
                                      ? "text-gray-200"
                                      : "text-gray-600"
                                  } mt-1`}
                                >
                                  <Text
                                    className={`${
                                      colorMode === "dark"
                                        ? "text-gray-100"
                                        : "text-gray-500"
                                    }`}
                                  >
                                    {`K${seatGroupingData.price || 0} `}
                                  </Text>
                                  <Text> + Fees</Text>
                                </Flex>
                              </Box>
                              <NumberInput
                                value={
                                  get(
                                    formik.values,
                                    `${type}.quantity`,
                                    0,
                                  ) as number
                                }
                                onChange={(valueString) =>
                                  handleQuantityChange(
                                    type,
                                    parseInt(valueString),
                                  )
                                }
                                defaultValue={0}
                                min={0}
                                max={
                                  seatGroupingData.numInitialSeats -
                                    seatGroupingData.numSeatsSold >
                                  5
                                    ? 5
                                    : seatGroupingData.numInitialSeats -
                                      seatGroupingData.numSeatsSold
                                }
                                className="w-24"
                              >
                                <NumberInputField
                                  disabled={
                                    seatGroupingData.numSeatsSold >=
                                      seatGroupingData.numInitialSeats ||
                                    isNowBeforeSalesStart ||
                                    isNowAfterSalesEnd
                                  }
                                />
                                {seatGroupingData.numSeatsSold >=
                                  seatGroupingData.numInitialSeats ||
                                isNowBeforeSalesStart ||
                                isNowAfterSalesEnd ? null : (
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                )}
                              </NumberInput>
                            </Flex>
                          );
                        })
                    ) : (
                      <Text>No prices available</Text>
                    )}
                  </Box>
                </form>
              </Box>
            )}

            <OrderSummary
              event={currentEvent}
              priceAndQuantityMap={buildPriceAndQuantityMap()}
            />
          </Box>

          <Flex className="items-center justify-between pt-6 lg:w-1/2 w-full md:pr-3">
            <Button
              onClick={handleCancel}
              variant={"outline"}
              size={"lg"}
              className="w-fit"
            >
              Cancel
            </Button>
            <Button
              isDisabled={disabled}
              onClick={formik.submitForm}
              size={"lg"}
              className="w-fit"
            >
              Payment
            </Button>
          </Flex>
        </CardBody>
      </Card>
    </VStack>
  );
};

export default TicketsOptions;
