import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { faq } from "./questions";

const FAQ = () => {
  return (
    <Box className=" flex flex-col w-full px-4 md:px-10 items-center justify-center py-12">
      <Text className=" text-2xl text-center font-bold mb-4">
        Frequently Asked Questions
      </Text>
      <Text className="text-center mb-6">
        Welcome to our FAQ section! Here, you'll find answers to common
        questions about our services and events. If you can't find the
        information you're looking for, feel free to contact us for further
        assistance.
      </Text>
      {faq.map((item, i) => (
        <Accordion defaultIndex={[i]} key={i} allowToggle className="w-full">
          <AccordionItem className="w-full">
            <h2 className="w-full py-2">
              <AccordionButton className="w-full">
                <Box as="span" flex="1" textAlign="left">
                  {item.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} style={{ whiteSpace: "pre-line" }}>
              <div dangerouslySetInnerHTML={{ __html: item.answer }} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
