import React, { useEffect, useState } from "react";

import { Navigate } from "react-router-dom";

import { Box, SimpleGrid, Text, VStack } from "@chakra-ui/react";

import HeaderCategoryCard from "../components/Category/HeaderCategory";
import ErrorHeading from "../components/Errors/ErrorHeading";
import EventCard from "../components/EventComponent/Cards";
import EventCata from "../components/EventComponent/Event/EventCata";
import Loading from "../components/EventComponent/Event/Loading";
import Skeletons from "../components/Loaders";
import SearchComponent from "../components/Search";
import useGetData from "../hooks/useGetData";
import { ICategory, IEvent } from "../types/interface";
import {
  API_URL,
  CategoryImages,
  getCategoryByName,
  ICategoryImages,
} from "../utils/constants";
import colors from "../utils/colors";

const Discover: React.FC = (): JSX.Element => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [seeMore, setSeeMore] = useState<string>("");
  const [detailEventId, setDetailEventId] = useState<string>("");
  const [seeMorePage, setSeeMorePage] = useState<boolean>(false);
  const [detailEventName, setDetailEventName] = useState<string>("");

  // const navigate = useNavigate();
  // const toast = useToast();
  // const concertsResult = useGetData(
  //   "concert_and_parties",
  //   `${API_URL}/event?categoryName=Concerts_And_Parties&numEvents=${2}`
  // );

  const concertsResult = useGetData(
    "concert_and_parties",
    `${API_URL}/event?categoryName=${encodeURIComponent(
      "Concerts and Parties",
    )}&numEvents=${2}`,
  );

  const sportsResult = useGetData(
    "sport_events",
    `${API_URL}/event?categoryName=Sports&numEvents=${2}`,
  );

  const meetingsResult = useGetData(
    "meeting_and_conferences",
    `${API_URL}/event?categoryName=${encodeURIComponent(
      "Meetings and Conferences",
    )}&numEvents=${2}`,
  );

  const otheresult = useGetData(
    "other_events",
    `${API_URL}/event?categoryName=${encodeURIComponent(
      "Other Events",
    )}&numEvents=${2}`,
  );
  const categoryResult = useGetData("category", `${API_URL}/category`);

  const seeMoreEvents = () => {
    setSeeMorePage(true);
  };

  useEffect(() => {
    function appendImgUrlToItems(
      items: ICategory[],
      imgUrls: ICategoryImages,
    ): ICategory[] {
      // create a new array of items with the `img_url` property appended to each object
      return items.map((item) => ({
        ...item,
        img: imgUrls[item.name.replace("& ", "").replace(/ /g, "_")],
      }));
    }

    if (categoryResult.data && categoryResult.data.length > 1) {
      const categories: ICategory[] = appendImgUrlToItems(
        categoryResult.data,
        CategoryImages,
      );
      setCategories(categories);
      //console.log(categories);
    }
  }, [categoryResult.data]);

  const ERROR_MSG: string =
    "There was an error getting events, try again later.";
  const NO_RESULT_MSG: string =
    "It seems there are no events in this category yet...";

  const openEvents = (val: string, name: string) => {
    const category = getCategoryByName(categories, name);
    if (Boolean(category)) {
      setSeeMore(val);
      seeMoreEvents();
      setDetailEventId(category._id);
      setDetailEventName(category.name);
    }
  };

  return (
    <VStack h={"full"} width={"full"}>
      <Box borderBottom={1} w={"full"} py={10}>
        {seeMorePage && (
          <Navigate to={`/${detailEventId}/${detailEventName}`} />
        )}

        <Text
          mb={4}
          textAlign={"center"}
          fontSize={{ base: "xs", md: "sm", lg: "2xl" }}
          fontWeight="semibold"
          color={colors.primary}
        >
          Experience the Next Generation of
          <span className={"text-orange-500 "}> Reliable e-Ticketing</span>
        </Text>
        <SearchComponent />
      </Box>
      <SimpleGrid
        py={2}
        w={{ base: "full" }}
        px={{ base: 2, md: 10 }}
        justifyContent={"space-around"}
        columns={{ base: 2, md: 4 }}
        spacing={{ base: 2, md: 8 }}
      >
        {
          /* Skeletons */
          categoryResult.isLoading && (
            <Skeletons
              height={{ base: "90px", md: "170px" }}
              rounded={"lg"}
              count={4}
            />
          )
        }
        {categories.length > 0 &&
          categories.map((cat) => (
            <HeaderCategoryCard
              key={cat._id}
              _id={cat._id}
              value={cat.name}
              img={cat.img}
              name={cat.name}
              callback={(val) => {
                setSeeMore(val);
                const category = getCategoryByName(
                  categories,
                  val.trim().split(" ")[0].toLowerCase(),
                );
                //console.log(val);
                //setDetailEventId(category._id);
                //setSeeMorePage(true);
                if (Boolean(category)) {
                  setDetailEventId(category._id);
                  setSeeMorePage(true);
                }
              }}
            />
          ))}
      </SimpleGrid>
      <Box width={"full"} height={"15px"} />
      <Box width={"full"} height={"30px"} />

      <EventCata
        title="Sports"
        callback={(val) => {
          openEvents(val, "sports");
        }}
        showMore={
          (!sportsResult.error || sportsResult.isSuccess) &&
          !sportsResult.isLoading
        }
      >
        {sportsResult.isLoading ? (
          <Loading count={2} />
        ) : (
          <>
            {sportsResult &&
              sportsResult.data.length > 0 &&
              sportsResult.data.map((event: IEvent, i: number) => (
                <EventCard key={event._id} eventCard={event} />
              ))}
          </>
        )}
        {(sportsResult.error ||
          (!sportsResult.isSuccess && !sportsResult.isLoading)) && (
          <ErrorHeading msg={ERROR_MSG} />
        )}
        {sportsResult.isSuccess &&
          !sportsResult.isLoading &&
          sportsResult &&
          sportsResult.data.length < 1 && (
            <ErrorHeading
              padding={2}
              border={"0px"}
              fontSize={"xs"}
              msg={NO_RESULT_MSG}
            />
          )}
      </EventCata>

      <EventCata
        title="Concerts and Parties"
        callback={(val) => {
          openEvents(val, "concerts");
        }}
        showMore={
          (!concertsResult.error || concertsResult.isSuccess) &&
          !concertsResult.isLoading
        }
      >
        {concertsResult.isLoading ? (
          <Loading count={2} />
        ) : (
          <>
            {concertsResult &&
              concertsResult.data.length > 0 &&
              concertsResult.data.map((event: IEvent, i: number) => (
                <EventCard key={event._id} eventCard={event} />
              ))}
          </>
        )}
        {(concertsResult.error ||
          (!concertsResult.isSuccess && !concertsResult.isLoading)) && (
          <ErrorHeading msg={ERROR_MSG} />
        )}
        {concertsResult.isSuccess &&
          !concertsResult.isLoading &&
          concertsResult &&
          concertsResult.data.length < 1 && (
            <ErrorHeading
              padding={2}
              border={"0px"}
              fontSize={"xs"}
              msg={NO_RESULT_MSG}
            />
          )}
      </EventCata>

      <EventCata
        title="Meetings and Conferences"
        callback={(val) => {
          openEvents(val, "meetings");
        }}
        showMore={
          (!meetingsResult.error || meetingsResult.isSuccess) &&
          !meetingsResult.isLoading
        }
      >
        {meetingsResult.isLoading ? (
          <Loading count={2} />
        ) : (
          <>
            {meetingsResult &&
              meetingsResult.data.length > 0 &&
              meetingsResult.data.map((event: IEvent, i: number) => (
                <EventCard key={event._id} eventCard={event} />
              ))}
          </>
        )}

        {(meetingsResult.error ||
          (!meetingsResult.isSuccess && !meetingsResult.isLoading)) && (
          <ErrorHeading msg={ERROR_MSG} />
        )}
        {meetingsResult.isSuccess &&
          !meetingsResult.isLoading &&
          meetingsResult &&
          meetingsResult.data.length < 1 && (
            <ErrorHeading
              padding={2}
              border={"0px"}
              fontSize={"xs"}
              msg={NO_RESULT_MSG}
            />
          )}
      </EventCata>

      <EventCata
        title="Other Events"
        callback={(val) => {
          openEvents(val, "other");
        }}
        showMore={
          (!otheresult.error || otheresult.isSuccess) && !otheresult.isLoading
        }
      >
        {otheresult.isLoading ? (
          <Loading count={2} />
        ) : (
          <>
            {otheresult &&
              otheresult.data.length > 0 &&
              otheresult.data.map((event: IEvent, i: number) => (
                <EventCard key={event._id} eventCard={event} />
              ))}
          </>
        )}
        {(otheresult.error ||
          (!otheresult.isSuccess && !otheresult.isLoading)) && (
          <ErrorHeading msg={ERROR_MSG} />
        )}
        {otheresult.isSuccess &&
          !otheresult.isLoading &&
          otheresult &&
          otheresult.data.length < 1 && (
            <ErrorHeading
              padding={2}
              border={"0px"}
              fontSize={"xs"}
              msg={NO_RESULT_MSG}
            />
          )}
      </EventCata>
    </VStack>
  );
};

export default Discover;
