import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Box,
  HStack,
  Image,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";

import { IEvent } from "../../../types/interface";
import { truncateString } from "../../../utils/functions";

interface Props {
  eventCard: IEvent;
}

const EventSearched: React.FC<Props> = (props): JSX.Element => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  console.log("This is colorMode: ", colorMode);

  return (
    <HStack
      overflow={"hidden"}
      w={"full"}
      className={`cursor-pointer border ${
        colorMode === "dark" ? "bg-[#1e232f]" : ""
      } rounded-md shadow-sm flex items-center`}
      bg={colorMode === "dark" ? "transparent" : "gray.50"}
      onClick={() => navigate(`/event/${props.eventCard._id}/details`)}
    >
      <Box className=" w-16 h-16">
        <Image
          className=" object-cover w-full h-full"
          src={props.eventCard.imgBanner}
        />
      </Box>
      <VStack w={"75%"} alignItems={"start"} className=" py-1">
        <Text className=" text-sm">
          {truncateString(`${props.eventCard.title}`, 90)}
        </Text>
        <Text
          className={`text-xs mt-2 ${
            colorMode === "dark" ? "text-gray-300" : "text-gray-600"
          }`}
        >
          {moment(props.eventCard.eventStart).format("MMMM Do YYYY")}
        </Text>
      </VStack>
    </HStack>
  );
};

export default EventSearched;
