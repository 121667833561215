import React from "react";

import { Heading, Stack, Text, VStack } from "@chakra-ui/react";

import BackButton from "../components/Buttons/BackButton";

const About: React.FC = (): JSX.Element => {
  return (
    <VStack w={"full"}>
      <Stack
        w={{
          base: "full",
          md: "70%",
        }}
        p={4}
      >
        <Heading>ABOUT US</Heading>
        <Text>
          Welcome to our electronic ticketing platform! We are a team of
          dedicated professionals committed to providing a seamless ticketing
          experience for event organizers and attendees.
        </Text>
        <Text>
          Musha is a group of companies and Musha Tickets is an electronic
          ticketing platform that gives its users the best ticketing and event
          experience in the land.
        </Text>
        <Text>
          Our mission is to simplify the event ticketing process, making it easy
          for event organizers to sell tickets and attendees to purchase them.
          We believe that everyone should have access to exciting events, and we
          work hard to make this possible.
        </Text>
        <Text>
          Our platform offers a wide range of features to make ticketing simple
          and stress-free. We provide customizable ticketing options, secure
          payment processing, and real-time analytics to help event organizers
          manage their events and maximize attendance.
        </Text>
        <Text>
          We pride ourselves on our commitment to customer service. Our
          knowledgeable and friendly support team is available to answer any
          questions and resolve any issues quickly and efficiently. We believe
          that communication is key to a successful event, and we work closely
          with our clients to ensure that their events run smoothly.
        </Text>
        <Text>
          At our electronic ticketing platform, we are passionate about events
          and creating memorable experiences for our clients and attendees. We
          strive to be a trusted partner for event organizers and a reliable
          source of event tickets for attendees.
        </Text>
        <Text>
          Thank you for choosing our platform for your event ticketing needs. We
          look forward to helping you make your next event a success!
        </Text>
        <Text>
          We value your feedback and questions. If you need help or have a
          question about our electronic ticketing company, please do not
          hesitate to contact us. Our knowledgeable and friendly customer
          service representatives are available to assist you.
        </Text>
        <Text>
          Here's how you can contact us:
          <br />
          1. Email: You can email us at mushatickets@gmail.com. We strive to
          respond to all inquiries within 24 hours.
          <br />
          2. Phone: You can call us at +260 979 958 895. Our customer service
          representatives are available from 9:00 am to 5:00 pm CAT, Monday
          through Saturday.
          <br />
          3. Social Media: You can reach out to us on our social media
          platforms, including Facebook, ‘MUSHA TICKETS’ and Instagram,
          @mushatickets.
        </Text>
        <Text>
          Thank you for choosing our electronic ticketing company for your
          ticketing needs. We look forward to hearing from you!
        </Text>
      </Stack>
    </VStack>
  );
};

export default About;
