import { Box, Text } from "@chakra-ui/react";
import colors from "../../utils/colors";

const Header = () => {
  return (
    <Box className="relative font-bold min-h-screen flex items-center w-full px-4 overflow-hidden justify-center flex-col">
      <Box
        className=" w-56 h-40 blur-xl opacity-70 -z-0 rounded-full absolute bottom-10 -left-2"
        bgColor={colors.primary}
      />
      <Box className=" w-24 h-24 blur-xl opacity-70 -z-0 rounded-full bg-orange-500 absolute top-10 -left-10" />
      <Box className=" w-64 h-64 blur-xl opacity-70 -z-0 rounded-full bg-orange-500 absolute -top-10 -right-10" />
      <Box className=" w-full flex-col flex items-center justify-center">
        <Text className="md:text-7xl text-3xl text-center">
          AMPLIFY YOUR EVENT'S REACH WITH MUSHA TICKETS
        </Text>
        <Text className=" max-w-lg font-medium mt-8">
          No hassle, just impact. Build your event, manage it effortlessly, and
          connect with thousands - all in one intuitive platform.
        </Text>
      </Box>
    </Box>
  );
};

export default Header;
