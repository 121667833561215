import axios from "axios";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { API_URL } from "../../utils/constants";
import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useRecoilState } from "recoil";
import {
  currentEventAtom,
  paymentObjectAtom,
  pdfBuffersAtom,
} from "../../recoil/atom";
import moment from "moment";
import pako from "pako";
import Loading from "../../components/EventComponent/Event/Loading";
import { useState } from "react";

const TransactionSuccessPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const TransactionToken = queryParams.get("TransactionToken");
  const [currentEvent, setCurrentEvent] = useRecoilState(currentEventAtom);
  const [buyerEmail, setBuyerEmail] = useState("");
  const [pdfBuffersObject, setPdfBuffersObject] =
    useRecoilState(pdfBuffersAtom);

  console.log("Entered success page");
  const { isError, isLoading } = useQuery(
    "getTransactionDetails",
    async () => {
      const res = await axios.get(
        API_URL + `/seat/transaction/verify/${TransactionToken}`,
      );
      return res.data;
    },
    {
      onSuccess(data) {
        console.log(data);
        setCurrentEvent(data?.eventDetails);
        setBuyerEmail(data?.buyerEmail);

        const ticketsReturned = data?.pdfBuffers;


      

        const pdfBufferResponse = new Uint8Array(atob(ticketsReturned).split('').map(char => char.charCodeAt(0)));
        setPdfBuffersObject(pdfBufferResponse);
      },
    },
  );

  const handleDownload = () => {
    try {
      const blob = new Blob([pdfBuffersObject], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "MushaTickets.pdf";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
    } catch (error) {
      console.error("Error handling compressed PDF buffer:", error);
    }
  };

  if (isLoading) {
    return (
      <Box className=" min-h-screen flex items-center justify-center px-4 md:px-12">
        <Loading count={2} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box>
        <Text>Something went wrong upon getting transaction details</Text>
      </Box>
    );
  }

  return (
    <Box className=" w-full px-4 md:px-12 flex items-center justify-center flex-col">
      <Text mt={"2rem"} fontWeight={{ base: "normal", md: "semibold" }}>
        {" "}
        Experienced any difficulties? Email support@mushatickets.com and we'll
        get back to you within 24 hours
      </Text>

      <Box
        p={6}
        borderWidth={1}
        borderRadius="md"
        boxShadow="md"
        maxW="100%"
        mx="auto"
        my={12}
      >
        <Flex
          borderBottom="1px solid gray"
          className="w-full flex md:flex-row items-center pb-6 justify-between flex-col gap-4"
        >
          <Flex className="items-center">
            <Icon as={CheckCircleIcon} color="orange" boxSize={6} mr={2} />
            <Text fontWeight="bold" className="md:text-2xl text-xl">
              Thanks for your order!
            </Text>
          </Flex>
          <Button
            colorScheme="orange"
            onClick={handleDownload}
            className=" md:block hidden"
          >
            Download Tickets
          </Button>
        </Flex>
        <Flex flexDirection="column" pt="4">
          <Text fontSize="14px">YOU'RE GOING TO</Text>
          <Text fontSize="20px" fontWeight="bold" pt="2">
            {currentEvent?.title}
          </Text>
        </Flex>
        <Flex flexDirection="column" pt="4">
          <Text fontSize="14px" fontWeight="bold">
            ORGANISER MESSAGE
          </Text>
          <Text pt="1">
            Thanks for registering for {currentEvent?.title}! We can't wait to
            see you there for what we believe will be a very special event! Keep
            your ticket(s) handy and we shall see you soon
          </Text>
        </Flex>
        <Flex
          pt="5"
          justifyContent="space-between"
          gap="4"
          className="w-full flex md:flex-row flex-col"
        >
          <Flex flexDirection="column">
            <Text pt="1" fontWeight="bold">{`TICKET(S) SENT TO`}</Text>
            <Text>{buyerEmail}</Text>
          </Flex>
          <Flex flexDirection="column">
            <Flex fontWeight="bold">DATE</Flex>
            <Text>
              {moment(currentEvent?.eventStart).format("MMMM Do YYYY, h:mm a")}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Flex fontWeight="bold">LOCATION</Flex>
            <Text>{currentEvent?.locationName}</Text>
          </Flex>
        </Flex>
        <Button
          colorScheme="orange"
          onClick={handleDownload}
          className=" md:hidden block w-full mt-6"
        >
          Download Tickets
        </Button>
      </Box>
    </Box>
  );
};

export default TransactionSuccessPage;
