import { Skeleton, useColorModeValue } from "@chakra-ui/react";

export default function Skeletons({ count = 5, ...rest }) {
  const startColor = useColorModeValue("white", "gray.600");
  const endColor = useColorModeValue("gray.200", "gray.900");

  return (
    <>
      {Array(count)
        .fill(null)
        .map((n, i) => {
          return (
            <Skeleton
              key={i}
              height={"100px"}
              width={"full"}
              startColor={startColor}
              endColor={endColor}
              children={n}
              {...rest}
            />
          );
        })}
    </>
  );
}
