import {
  Box,
  Code,
  Flex,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import QRCode from "react-qr-code";
import { API_URL } from "../../utils/constants";
import useGetData from "../../hooks/useGetData";
import { ISeat } from "../../types/interface";
import Loading from "../EventComponent/Event/Loading";

const TicketContainer: React.FC = (): JSX.Element => {
  const {
    data: allSeats,
    isLoading,
    error,
  } = useGetData("seats", `${API_URL}/seat/userSeats`);
  console.log(allSeats);

  if (isLoading) {
    return <Loading count={3} />;
  }

  if (error || !allSeats || allSeats.length === 0) {
    return <Text>No data found or an error occurred.</Text>;
  }

  return (
    <>
      {allSeats.map((seat: ISeat) => (
        <Stack
          key={seat._id}
          py={4}
          w="full"
          border={"1px"}
          borderColor={"gray.200"}
          shadow={"sm"}
          h={"560px"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          id="pdf"
        >
          <Stack
            mt={"1"}
            w={"full"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Flex>
              <Image
                w={"100%"}
                h={"50px"}
                src={"/LOGO.png"}
                alt={"musha logo"}
                objectFit={"cover"}
              />
            </Flex>
          </Stack>
          <VStack
            borderY={"1px"}
            borderStyle={"dashed"}
            py={"4"}
            w={"full"}
            alignItems={"center"}
          >
            <Heading textAlign={"center"} fontSize={"sm"}>
              Macky 2 Album lunch
            </Heading>
            <Text fontSize={"xs"}>
              sat Jan, 2015 08:00hrs, Woodlans Stadium
            </Text>
          </VStack>
          <SimpleGrid
            w={"full"}
            justifyContent={"space-around"}
            columns={3}
            spacing={1}
          >
            <VStack py={"2"}>
              <Heading fontSize={"md"}>Section</Heading>
              <Text fontSize={"sm"}>{seat.section}</Text>
            </VStack>
            <VStack py={"2"}>
              <Heading fontSize={"sm"}>Row</Heading>
              <Text fontSize={"sm"}>{seat.row}</Text>
            </VStack>
            <VStack py={"2"}>
              <Heading fontSize={"sm"}>Seat</Heading>
              <Text fontSize={"sm"}>{seat.seatNumber}</Text>
            </VStack>
          </SimpleGrid>
          <HStack
            py={"4"}
            borderY={"1px"}
            borderColor={"gray.200"}
            my={14}
            px={"6"}
            w={"full"}
            justifyContent={"space-between"}
          >
            <Heading fontSize={"md"}>K {seat.price}</Heading>
            <Text fontSize={"sm"}>Admit 1</Text>
          </HStack>
          <Box w={"full"} h={"3px"} />
          <Code py={"1"} px={"4"}>
            MHDYYGDD
          </Code>
          <Box w={"full"} h={"3px"} />
          <QRCode
            size={256}
            style={{ height: "160px", maxWidth: "100%", width: "100%" }}
            value={seat._id}
            viewBox={`0 0 256 256`}
          />
        </Stack>
      ))}
    </>
  );
};

export default TicketContainer;
