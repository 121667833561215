import React, { ReactNode } from "react";

import {
  Heading,
  HStack,
  SimpleGrid,
  Text,
  useColorMode,
} from "@chakra-ui/react";

interface Params {
  title: string;
  children: ReactNode;
  callback?: (val: string) => void;
  showMore: boolean;
}

const EventCata: React.FC<Params> = ({
  callback,
  title,
  children,
  showMore,
}): JSX.Element => {
  const { colorMode } = useColorMode();
  return (
    <>
      <HStack
        pr={{
          base: 6,
          md: 24,
        }}
        justifyContent={"space-between"}
        w={"full"}
        alignItems={"center"}
      >
        <Heading
          pl={{
            base: "3",
            md: "20",
          }}
          textAlign={"left"}
          fontSize={{
            base: "sm",
            md: "lg",
          }}
          fontWeight={"medium"}
        >
          {title}
        </Heading>
        {showMore && (
          <Text
            className={`${
              colorMode === "dark" ? "text-gray-300" : "text-blue-950"
            } hover:underline`}
            cursor={"pointer"}
            onClick={() => {
              if (Boolean(callback)) {
                callback(title);
              }
            }}
          >
            See All &rarr;
          </Text>
        )}
      </HStack>
      <SimpleGrid
        p={"2"}
        w={{ base: "full", md: "90%" }}
        justifyContent={"space-around"}
        columns={{ base: 1, md: 2 }}
        spacing={4}
      >
        {children}
      </SimpleGrid>
    </>
  );
};

export default EventCata;
