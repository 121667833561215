class SortedMap extends Map<number, number> {
  set(key: number, value: number): this {
    super.set(key, value);
    // Sort the keys after insertion
    this.sortKeys();
    return this;
  }

  private sortKeys(): void {
    const sortedKeys = Array.from(this.keys()).sort((a, b) => a - b);
    const sortedMap = new Map<number, number>();
    sortedKeys.forEach((key) => {
      console.log("This is key value: ", key, this.get(key));
      sortedMap.set(key, this.get(key)!);
    });
    // Clear the original map and set the sorted key-value pairs
    this.clear();
    sortedMap.forEach((value, key) => {
      console.log("Key value again: ", key, value);
      super.set(key, value);
    });
  }
}

class SortedNumericMap extends SortedMap {
  setValueForRange(start: number, end: number, value: number): void {
    for (let i = start; i <= end; i++) {
      this.set(i, value);
    }
  }

  updateMap(input: string, value: number): void {
    console.log("Input, Value for update map: ", input, value);
    const rangeRegex = /^(\d+)-(\d+)$/;

    const singleMatch = input.match(/^\d+$/);
    const rangeMatch = input.match(rangeRegex);

    if (singleMatch) {
      const key = parseInt(singleMatch[0], 10);
      console.log("Setting key: ", key);
      this.set(key, value);
    } else if (rangeMatch) {
      const start = parseInt(rangeMatch[1], 10);
      const end = parseInt(rangeMatch[2], 10);
      this.setValueForRange(start, end, value);
    } else {
      console.error("Invalid input format");
    }
  }

  removeKey(input: string): void {
    const rangeRegex = /^(\d+)-(\d+)$/;

    const singleMatch = input.match(/^\d+$/);
    const rangeMatch = input.match(rangeRegex);

    if (singleMatch) {
      const key = parseInt(singleMatch[0], 10);
      this.delete(key);
    } else if (rangeMatch) {
      const start = parseInt(rangeMatch[1], 10);
      const end = parseInt(rangeMatch[2], 10);
      for (let i = start; i <= end; i++) {
        this.delete(i);
      }
    } else {
      console.error("Invalid input format");
    }
  }

  clone(): SortedNumericMap {
    const newMap = new SortedNumericMap();
    this.forEach((value, key) => {
      newMap.set(key, value);
    });
    return newMap;
  }
}

function generateStringsFromMap(sortedNumericMap: SortedNumericMap): string[] {
  const result: string[] = [];
  let currentKey: number | null = null;
  let startKey: number | null = null;
  let endKey: number | null = null;
  let nextKey: number | null = null;

  sortedNumericMap.forEach((value, key) => {
    if (currentKey === null) {
      // First iteration
      currentKey = key;
      startKey = key;
      endKey = key;
      nextKey = key + 1;
    } else if (value !== sortedNumericMap.get(currentKey) || nextKey !== key) {
      // Value changed, create string
      if (startKey === endKey) {
        result.push(`${startKey}:${sortedNumericMap.get(currentKey)}`);
      } else {
        result.push(
          `${startKey}-${endKey}:${sortedNumericMap.get(currentKey)}`,
        );
      }
      currentKey = key;
      startKey = key;
      endKey = key;
      nextKey = key + 1;
    } else {
      // Consecutive keys with the same value
      endKey = key;
      nextKey = key + 1;
    }
  });

  // Handle the last group of consecutive keys
  if (currentKey !== null) {
    if (startKey === endKey) {
      result.push(`${startKey}:${sortedNumericMap.get(currentKey)}`);
    } else {
      result.push(`${startKey}-${endKey}:${sortedNumericMap.get(currentKey)}`);
    }
  }

  return result;
}

export { SortedMap, generateStringsFromMap, SortedNumericMap };
