import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Text,
  AspectRatio,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { MdOutlineArrowForward } from "react-icons/md";
import { CloseIcon } from "@chakra-ui/icons";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  EventDetails1FormAtom,
  eventDescriptionAtom,
  eventNameAtom,
  formProgressAtom,
  formStepAtom,
  prevCreateEventFormStepAtom,
  incompleteEventAtom,
} from "../../../recoil/atom";
import { form1Shema } from "../../../utils/validation";
import {
  CreateEventFormSteps,
  IAddBankDetailsForm,
  IEvent,
} from "../../../types/interface";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { apiClient } from "../../../api";
import { uploadImageToAws } from "../../../utils/aws";
import { makeToast } from "../../../utils/toast";

interface EventDetails1FormProps {
  isUserEventInitialized: boolean;
}

const EventDetails1Form: React.FC<EventDetails1FormProps> = (props) => {
  const [formState, setFormState] = useRecoilState(EventDetails1FormAtom);
  const setFormStep = useSetRecoilState(formStepAtom);
  const setFormProgress = useSetRecoilState(formProgressAtom);
  const [, setPrevCreateEventFormStep] = useRecoilState(
    prevCreateEventFormStepAtom,
  );
  const [showCropper, setShowCropper] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [image, setImage] = useState<string | null>(null);
  const [eventNameRecoil, setEventNameRecoil] = useRecoilState(eventNameAtom);
  const [eventDescriptionRecoil, setEventDescriptionRecoil] =
    useRecoilState(eventDescriptionAtom);
  const [incompleteEvent, setIncompleteEvent] =
    useRecoilState(incompleteEventAtom);

  console.log("This is incomplete event: ", incompleteEvent);
  const formik = useFormik({
    initialValues: {
      eventName: incompleteEvent?.title ?? "",
      eventDescription: incompleteEvent?.description ?? "",
      eventCoverImage: incompleteEvent?.imgBanner ?? "",
    },

    enableReinitialize: true,
    validationSchema: form1Shema,
    onSubmit: async ({ eventName, eventDescription, eventCoverImage }) => {
      console.log("Uploading image to AWS");
      let eventUpdates: IEvent = incompleteEvent;
      if (!props.isUserEventInitialized) {
        const response = await apiClient.post("/event/create_new_user_event");
        eventUpdates = response.data as IEvent;
      }

      let coverImageUrl = incompleteEvent?.imgBanner;
      if (image !== null) {
        coverImageUrl = await uploadImageToAws(eventCoverImage);
      }

      console.log("Uploaded to AWS. Here is URL ", coverImageUrl);
      eventUpdates.title = eventName;
      eventUpdates.description = eventDescription;
      eventUpdates.imgBanner = coverImageUrl;

      try {
        // Event is not initialized, so initialize here
        console.log("Submitting form 1...");
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await apiClient.put(
          `/event/${eventUpdates._id}`,
          eventUpdates,
          {
            headers,
          },
        );
        console.log("This is response form1: ", response);
        setIncompleteEvent(response.data.event as IEvent);
        setFormStep(CreateEventFormSteps.EVENT_DETAILS_2);
        setPrevCreateEventFormStep(CreateEventFormSteps.EVENT_DETAILS_1);
      } catch (err) {
        //TODO: show error feedback
        console.log("This is error: ", err);
        makeToast(
          "error",
          "Error",
          "Could not update event. Please try again later",
        );
      }
    },
  });

  useEffect(() => {
    setEventNameRecoil(formik.values.eventName);
  }, [formik.values.eventName, setEventNameRecoil]);

  useEffect(() => {
    setEventDescriptionRecoil(formik.values.eventDescription);
  }, [formik.values.eventDescription, setEventDescriptionRecoil]);

  useEffect(() => {
    setFormProgress(5);
  }, [setFormProgress]);

  console.log("Function rendered");
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Handle image change called: ");
    setImage(URL.createObjectURL(e.target.files![0]));
    setShowCropper(true);
    formik.setFieldValue(
      "eventCoverImage",
      URL.createObjectURL(e.target.files![0]),
    );
  };

  const onCancelImage = () => {
    formik.setFieldValue("eventCoverImage", "");
  };

  const getCroppedData = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas();
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const file = new File([blob], "croppedImage.jpg", {
            type: "image/jpeg",
          });
          const dataUrl = URL.createObjectURL(file);
          formik.setFieldValue("eventCoverImage", dataUrl);
        }
        setShowCropper(false);
      }, "image/jpeg");
    }
  };

  return (
    <Box as="form" onSubmit={formik.handleSubmit}>
      <Heading textAlign={"center"} fontSize={"lg"} fontWeight="bold" my={6}>
        Create Your Event
      </Heading>
      <FormControl
        isInvalid={
          Boolean(formik.touched.eventName) && Boolean(formik.errors.eventName)
        }
      >
        <FormLabel htmlFor="event-name" fontWeight={"normal"}>
          Event Name
        </FormLabel>
        <Input
          id="title"
          placeholder="Event Name"
          {...formik.getFieldProps("eventName")}
        />
        {formik.errors.eventName && (
          <FormErrorMessage>{formik.errors.eventName}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl
        my={4}
        isInvalid={
          Boolean(formik.touched.eventDescription) &&
          Boolean(formik.errors.eventDescription)
        }
      >
        <FormLabel htmlFor="description" fontWeight={"normal"}>
          Event Description
        </FormLabel>
        <ReactQuill
          id="description"
          placeholder="Describe your event..."
          value={formik.values.eventDescription}
          onChange={(value) => formik.setFieldValue("eventDescription", value)}
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }],
              [{ size: [] }],
              ["bold", "italic", "underline", "blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["link", "image"],
            ],
          }}
        />
        {formik.errors.eventDescription && (
          <FormErrorMessage>{formik.errors.eventDescription}</FormErrorMessage>
        )}
      </FormControl>

      {showCropper && (
        <Modal
          isOpen={showCropper}
          onClose={() => setShowCropper(false)}
          size={"full"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Crop Image</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Cropper
                ref={cropperRef}
                style={{ height: "70vh", width: "100%" }}
                zoomTo={0.5}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
                aspectRatio={1}
                initialAspectRatio={1}
                cropBoxResizable={false}
                highlight={false}
                toggleDragModeOnDblclick={false}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={getCroppedData}>
                Crop Image
              </Button>
              <Button variant="ghost" onClick={onCancelImage}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Box className=" flex w-full items-center justify-center mt-6">
        <AspectRatio width={"52"} ratio={1}>
          <Box
            borderColor={
              formik.touched.eventCoverImage && formik.errors.eventCoverImage
                ? "red.400"
                : "gray.300"
            }
            borderStyle="dashed"
            borderWidth="2px"
            rounded="md"
            shadow="sm"
            position="relative"
          >
            {formik.values["eventCoverImage"] ? (
              <>
                <Image
                  src={formik.values["eventCoverImage"]}
                  className=" w-full h-full object-cover"
                  alt="Cover Art"
                />
                <IconButton
                  icon={<CloseIcon />}
                  aria-label="Cancel Image"
                  position="absolute"
                  top="2"
                  left="2"
                  colorScheme="red"
                  onClick={onCancelImage}
                  size={"sm"}
                />
              </>
            ) : (
              <>
                <Text align={"center"}>Upload Image Cover Art</Text>
                <Input
                  type="file"
                  height="100%"
                  width="100%"
                  position="absolute"
                  top="0"
                  left="0"
                  opacity="0"
                  aria-hidden="true"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e)}
                />
              </>
            )}
          </Box>
        </AspectRatio>
      </Box>
      <HStack justifyContent={"center"} gap={4} mt={6}>
        <Button
          onClick={formik.submitForm}
          variant="solid"
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting}
        >
          <Icon as={MdOutlineArrowForward} />
        </Button>
      </HStack>
    </Box>
  );
};

export default EventDetails1Form;
