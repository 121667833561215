import {
  Box,
  Select,
  Stack,
  StackItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ISection,
  ISectionIdDetailsMap,
} from "../../types/interface";
import React from "react";

interface SectionCardProps {
  section: ISection;
  selectSection: (section: ISection) => void;
}

const SectionItemCard: React.FC<SectionCardProps> = ({
  section,
  selectSection,
}) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={4}
      position="relative"
      display="flex"
      flexDirection="column"
      bgColor="white"
      color="black"
      onClick={() => selectSection(section)}
    >
      <Text color="black">Section Name: {section.name}</Text>
      <Text color="black">Section Price: {section.price}</Text>
      <Text color="black">Section seats left: {section.numSeatsLeft}</Text>
    </Box>
  );
};

interface SectionsListSidebarProps {
  maxNumSeats: number;
  selectSection: (sectionId: string) => void;
  setNumSeatsToBuy: (count: number) => void;
  sectionIdDetailsMap: ISectionIdDetailsMap;
}

const SectionsListSidebar: React.FC<SectionsListSidebarProps> = (props) => {
  // Create an array of 50 dummy grid items
  const handleNumSeatChange = (event) => {
    console.log("This is new num: ", typeof (event.target.value as number));
    props.setNumSeatsToBuy(Number(event.target.value));
  };

  const numSeatsOptions: JSX.Element[] = [];
  for (let i = 1; i <= props.maxNumSeats; i++) {
    numSeatsOptions.push(
      <option key={i} value={i}>
        {i}
      </option>,
    );
  }

  return (
    <VStack borderWidth="2px" borderRadius="lg" p={4} className="max-h-[40vh]">
      <Select
        placeholder="Select Number of Tickets"
        variant="filled"
        onChange={handleNumSeatChange}
      >
        {numSeatsOptions}
      </Select>
      <Stack
        spacing={2}
        gap={4}
        className="min-h-[50vh]"
        overflowY="auto"
        w="100%"
      >
        {Object.entries(props.sectionIdDetailsMap).map(
          ([sectionId, sectionObject]) => (
            <StackItem key={sectionId} backgroundColor="teal.400" padding="4">
              <SectionItemCard
                section={sectionObject}
                selectSection={() => props.selectSection(sectionId)}
              />
            </StackItem>
          ),
        )}
      </Stack>
    </VStack>
  );
};

export default SectionsListSidebar;
