import AWS, { S3 } from "aws-sdk";
import axios from "axios";
import { makeToast } from "./toast";

let _s3: S3;

const initializeS3 = () => {
  _s3 = new S3({
    accessKeyId: "AKIAR37T7SUZ4AKBEJ34",
    secretAccessKey: "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",
    region: "Global",
  });
};

AWS.config.update({
  accessKeyId: "AKIAR37T7SUZ4AKBEJ34",
  secretAccessKey: "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",
  region: "Global",
});

const s3 = new AWS.S3();

const getS3 = () => {
  return s3;
};

const bucket = "mushastore";

const uploadImageToAws = async (url: string): Promise<string> => {
  const s3 = getS3();
  console.log("This is img url: ", url);
  const uploadImg = async (img: Blob) => {
    try {
      let response = await s3
        .upload({
          Bucket: bucket,
          Key: `musha${Date.now() + Date.now()}img.jpg`,
          Body: img,
          ContentType: "image/*",
        })
        .promise()
        .then((res) => {
          console.log("successful", res);
          return res;
        })
        .catch((err) => console.log("there is an error IN UPLOAD", err));
      console.log("fetch success", response);
      return response;
    } catch (error) {
      console.error("catch error here", error);
    }
  };

  let imgResponse = "";

  let response;
  try {
    response = await axios.get(url, {
      responseType: "blob",
    });
  } catch (error) {
    makeToast("error", "Error", "Could not upload Image. Try again later");
    return;
  }

  const imageBlob: Blob = response.data;

  console.log("Image blob brayo: ", imageBlob);
  if (imageBlob instanceof Blob) {
    console.log("imageBlob->>", imageBlob);
    const data: any = await uploadImg(imageBlob);
    console.log(data);
    imgResponse = data.Location;
  }

  return imgResponse;
};

export { bucket, getS3, initializeS3, uploadImageToAws };
