import { EventSeatTypes, ICategory } from "../types/interface";
import meetingImg from "../images/siavosh-hosseini-F5QBwO8X1KE-unsplash.jpg";

export const app_name = "Musha Tickets";

export let API_URL: string;

if (process.env.NODE_ENV === "development") {
  API_URL = "http://localhost:4000/api";
  // API_URL = "https://musha-tickets-6272.vercel.app/api";
} else {
  // API_URL = "https://musha-tickets-backend.onrender.com/api";
  API_URL = "https://musha-tickets-6272.vercel.app/api";
}

export interface ICategoryImages {
  // define the interface for the object that maps item names to image URLsArts & Theatre
  [key: string]: string;
}

export const ACCOUNT_TYPES = [
  {
    value: "paid",
    label: "No",
  },
  {
    value: "free",
    label: "Yes",
  },
];

export const TICKET_PLANS = [
  {
    id: "plan-1",
    value: EventSeatTypes.STANDARD_BASED,
    label: {
      title: "No Venue Design",
      subTitle: `4.6% commission per Ticket (1.6% Musha Tickets + 3.0%
      Payment Platform). A Booking Fee of K5 per ticket is
      charged to Event attendees (ex VAT).`,
    },

    color: "#1E3B5A",
  },
  {
    id: "plan-3",
    value: EventSeatTypes.SEATS_BASED,
    label: {
      title: `Seats Venue Design`,
      subTitle: ` 5.8% commission per Ticket (2.8% Musha Tickets + 3.0%
      Payment Platform). A Booking Fee of K5 per ticket is
      charged to Event attendees (ex VAT).`,
    },
    color: "#71482C",
  },
];

export const CategoryImages = {
  Sports:
    "https://i.pinimg.com/564x/af/8a/c0/af8ac0502240220ab9387ed08ad32d5a.jpg",
  Concerts_and_Parties:
    "https://i.pinimg.com/564x/4c/8e/24/4c8e24335f110f6abdf3f938ffabc0d0.jpg",
  Other_Events:
    "https://i.pinimg.com/736x/8d/6d/36/8d6d362cfd3aaecb464852c962d1b475.jpg",
  Meetings_and_Conferences: meetingImg,
};

// "https://i.pinimg.com/564x/76/ec/11/76ec11611fb1c7a7b43d493922429adc.jpg"

export const getCategoryByName = (
  items: ICategory[],
  name: string,
): ICategory | undefined => {
  const regex = new RegExp(`^${name}$`, "i"); // case-insensitive regex

  //remove all spaces from the name
  const newItems: ICategory[] = items.map((cat) => {
    const _name = cat.name.trim().split(" ")[0];
    return { ...cat, name: _name.toLowerCase() };
  });

  return newItems.find((item) => {
    //console.log('name:-->', item)
    return regex.test(item.name);
  });
};

export const getBooleanString = (bool: string): boolean => {
  let boolVal: boolean = false;

  console.log("Provided bool: ", bool, bool === "true");

  if (bool === "false" || bool === "no") {
    boolVal = false;
  } else if (bool === "true" || bool === "yes") {
    boolVal = true;
  } else {
    throw new Error("Invalid boolean provided");
  }

  return boolVal;
};

export const hasNullableValue = (obj: any): boolean => {
  for (const value of Object.values(obj)) {
    if (value === null || value === undefined || value === "") {
      return true;
    }
  }
  return false;
};
