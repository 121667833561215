import React from "react";
import { Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const TermsOfUseContent = () => (
  <>
    <Heading>TERMS OF USE</Heading>
    <Heading size={"base"}>Introduction</Heading>
    <Text>
      Welcome to Musha Tickets, an e-ticketing platform that provides you with
      the best event experience in the land. These terms of use ("Agreement")
      govern your use of our electronic ticketing platform. These terms and
      conditions of the electronic ticketing platform (hereafter referred to as
      "the Platform") govern this site. The Platform is owned and operated by
      Musha Store & Tickets Services Limited (hereafter referred to as "we,"
      "us," or "our").
    </Text>
    <Text>
      Please read these terms and conditions carefully before using the
      Platform. By accessing or using the Platform, you agree to be bound by
      these terms and conditions. If you do not agree to these terms and
      conditions, you must not use the Platform.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      1. Eligibility and Account Registration:
    </Heading>
    <Text>
      The Platform is available only to individuals who are at least 18 years
      old and can enter into legally binding contracts. By using the Platform,
      you represent and warrant that you are at least 18 years old and can enter
      into legally binding contracts. For certain events that are intended for
      children below the age of 18 years, parents or legal guardians of those
      children are responsible for purchasing their tickets.
    </Text>
    <Text>
      You may browse the Site without registering for an account. However, you
      will be required to register for an account to use certain features of the
      Site, such as purchasing a ticket or creating an event. Your account
      username should not include the name of another person with the intent to
      impersonate that person, or be offensive, vulgar, or obscene. Your account
      username and password are personal to you. You are responsible for the
      confidentiality and use of your username and password, as well as all
      activities (including purchases) conducted through your account. You may
      not transfer or sell access to your account.
    </Text>
    <Text>
      We will not be liable for any harm related to the disclosure of your
      username or password or the use of your password or username by anyone
      else. You may not use another user's account without their permission. If
      you discover any unauthorized use of your account or any other
      account-related security breach, you must immediately notify us in writing
      by sending an email. We may require you to change your username and/or
      password if we believe your account is no longer secure or if we receive a
      complaint that your username violates someone else's rights. You will have
      no ownership in your account or username. We may refuse registration,
      cancel an account, or deny access to the Site for any reason.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      2. Use of the Platform:
    </Heading>
    <Text>
      The Platform allows you to purchase tickets for various events. You agree
      to use the Platform only for lawful purposes and in accordance with these
      terms and conditions. You must not use the Platform in any way that could
      damage or impair the Platform or its functionality.
    </Text>
    <Text>
      These Terms and Conditions govern your use of all products, services,
      content, and information provided by Musha Tickets in respect to the
      Platform. By downloading, registering, accessing, and/or using the
      Platform, you acknowledge that you have read, understood, and agree to be
      legally bound by these Terms and Conditions. If you do not agree with
      these Terms and Conditions and/or our privacy policy, please do not use
      the Platform. By signing up to use the Platform, you agree to comply with
      and be bound by the terms and conditions governing the operations of the
      Platform.
    </Text>
    <Heading size={"xs"}>
      You agree that you will comply with all applicable laws, rules, and
      regulations, and that you will not:
    </Heading>
    <Text pl={4}>
      - Inhibit or restrict any other person from using the Site
      <br />
      - Use the Site for any unlawful purpose. <br />
      - Express or imply that any statements you make are endorsed by us,
      without our prior written consent.
      <br />- Impersonate any person or entity, whether actual or fictitious,
      including any employee or representative of our company.
    </Text>
    <Heading size={"xs"}>You also agree that you will not submit:</Heading>
    <Text>
      (a) any content or information that is unlawful, fraudulent, defamatory,
      libelous, or otherwise objectionable, or infringes our or any third
      party's intellectual property or other rights;
    </Text>
    <Text>
      (b) any non-public information about companies without authorization; or
    </Text>
    <Text>
      (c) any advertisements, solicitations, chain letters, pyramid schemes,
      surveys, contests, investment opportunities, or other unsolicited
      commercial communication.
    </Text>
    <Text>
      (d) or provide links to, any postings containing material that could be
      considered harmful, obscene, pornographic, sexually explicit, indecent,
      lewd, violent, abusive, profane, insulting, threatening, harassing,
      hateful, or otherwise objectionable, including any posting that includes
      the image or likeness of individuals under 18 years of age, encourages or
      otherwise depicts or glamorizes drug use (including alcohol and
      cigarettes), characterizes violence as acceptable, glamorous, or
      desirable, or contains any personal contact information or other personal
      information identifying any third party.
    </Text>
    <Text>
      (e) or provide links to, any postings containing material that harasses,
      victimizes, degrades, or intimidates an individual or group of individuals
      on the basis of religion, race, ethnicity, gender, age, or disability.
    </Text>
    <Text>
      (f) or engage in spamming or flooding.
      <br />
      (g) or harvest and collect information about Site users.
      <br />
      (h) or order, or attempt to order, a number of tickets for an event that
      exceeds the stated limit for that event.
      <br />
      (i) or attempt to enter a private meeting by trying a large number of
      wrong passcodes without being invited.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      3. Ticket Purchases:
    </Heading>
    <Text>
      Our main goal is to make your purchasing experience easy, efficient, and
      fair so we can get you on your way to live events as quickly as possible.
    </Text>
    <Heading size={"xs"}>a. Currency:</Heading>
    <Text>
      Our fees may change from time to time and may vary based on several
      factors, including but not limited to product type, price, country,
      payment method, and currency. Our fees can be viewed on the Musha Tickets
      website under the relevant section. All ticket prices for events that
      occur in Zambia are stated in Zambian Kwacha (ZMW). All ticket prices for
      events that occur outside Zambia have to be communicated to the Musha
      Tickets team via email at{" "}
      <a href="mailto:mushatickets@gmail.com">mushatickets@gmail.com</a> or as
      you are creating your event, and the currency for your particular country
      will be adjusted.
    </Text>
    <Heading size={"xs"}>b. Payment Methods</Heading>
    <Text>
      We accept several methods of payment to accommodate your needs, including
      (among others) Airtel money, MTN money, and Visa. Payments made via the
      platform are deemed to be made by the owner of the Musha Tickets account
      used to make the payment, not by the owner of the mobile money account,
      credit card, or bank account used for payment. We don't store any bank
      card details on our servers; they are stored with the relevant payment
      providers.
    </Text>
    <Text>
      All fee amounts indicated on the booking and checkout page are inclusive
      of value-added and similar taxes. If any charge-back claim occurs, it is
      your responsibility to provide the organizer with all necessary
      documentation to resolve the claim. If we suspect that a transaction may
      be fraudulent, we may request proof (such as proof of identification,
      proof of address, etc.) to determine the validity of the transaction.
    </Text>
    <Text>
      Certain events may not be appropriate for younger attendees. Age
      restrictions are determined by the relevant organizer. If you buy a ticket
      for an age-restricted event, you must ensure that you do not allow any
      person outside the relevant age group to attend the event.
    </Text>
    <Heading size={"xs"}>c. Who You Are Buying From</Heading>
    <Text>
      We act as the agent for those who provide events, such as artists, venues,
      teams, fan clubs, promoters, and leagues (the "Event Organizer"). We
      generally sell tickets on behalf of the Event Organizer. Tickets obtained
      from unauthorized sources, not directly through our site, may be lost,
      stolen, or counterfeit and, if so, are void.
    </Text>
    <Heading size={"xs"}>d. Pricing</Heading>
    <Text>
      We sell tickets on behalf of Event Organizers, which means we do not set
      the ticket prices or determine seating locations. Event Organizers
      typically set the face price of their tickets. A total commission of 4.6%
      to 5% depending on your ticket plan of which 3% goes to the relevant
      payment providers and the remaining 1.6% to 2% goes to Musha Tickets. This
      is collected from the Event Organizers. A booking fee of K5 is charged per
      ticket from the event goer per ticket purchase.
    </Text>
    <Heading size={"xs"}>e. Order Confirmation and Processing</Heading>
    <Text>
      Your order is confirmed when we send you a confirmation, in the form of a
      confirmation page, email ("Order Confirmation"), or WhatsApp ("Order
      Confirmation"), or a direct text message (‘’Order Confirmation’’). If you
      do not receive an Order Confirmation after submitting payment information,
      or if you experience an error message or service interruption after
      submitting payment information, it is your responsibility to confirm via
      your account whether or not your order has been placed. Only you may be
      aware of any problems that may occur during the purchase process. We will
      not be responsible for losses (monetary or otherwise) if you assume that
      an order was not placed because you failed to receive confirmation.
    </Text>
    <Heading size={"xs"}>f. Service Fees</Heading>
    <Text>
      Tickets purchased on our site are typically subject to a per-ticket
      service fee, among other possible fees. In some instances, you may be able
      to purchase tickets directly from the venue box office. We collect tax as
      required by state and local laws. The tax may be displayed separately or
      included in the total service fee amount.
    </Text>
    <Heading size={"xs"}>
      g. Canceled, Postponed, Rescheduled, and Moved Events
    </Heading>
    <Text>
      When purchasing tickets for any event, you are contracting with the
      organizer and not with Musha Tickets. We do not verify whether events,
      fundraisers, or subscription offers are genuine, nor do we verify the
      accuracy of any statements or descriptions made by the organizers.
    </Text>
    <Text>
      Events may be subject to specific terms and conditions set by the event
      organizer. Please read and understand these terms before buying a ticket
      on Musha Tickets. We are not responsible for any cancellation,
      postponement, limitation, or restriction of an event. Any disputes or
      complaints you have about an event, campaign, or subscription should be
      addressed directly with the organizer.
    </Text>
    <Heading size={"xs"}>
      Occasionally, events are canceled, postponed, rescheduled to a different
      date or time, or moved to a different venue:
    </Heading>
    <Text>
      <b>If the event is canceled:</b>
      You do not need to take any action to obtain a refund from us unless
      instructed by the Event Organizer. We will issue a refund to the original
      method of payment used at the time of purchase once we receive funds from
      the Event Organizer. Please note that this refund process may take up to 2
      weeks to complete.
    </Text>
    <Text>
      <b>If the event is postponed, rescheduled, or moved:</b>Your ticket(s)
      (including any additional add-ons or upgrades) are still valid, and no
      further action is required. In this case, you will have the option to
      receive a refund for the ticket amount, excluding tax and service fees, or
      keep your ticket(s) for the postponed, rescheduled, or moved event.
    </Text>
    <Text>
      If your event is canceled, postponed, rescheduled, or moved, we will
      attempt to contact you to provide updates on the status of the event and
      inform you of any refund procedures. For specific instructions regarding
      any particular canceled, postponed, rescheduled, or moved event, please
      check the event information online and in your account, or contact us
      directly.
    </Text>
    <Text>
      We will not be liable for travel expenses or any other expenses incurred
      by you or anyone else in connection with a canceled, postponed,
      rescheduled, or moved event.
    </Text>
    <Heading size={"xs"}>h. Refunds</Heading>
    <Text>
      All sales are final, and refunds are only allowed in limited
      circumstances, as explained in this section and the Canceled, Postponed,
      Rescheduled, and Moved Events section above. Before purchasing tickets,
      please carefully review your event and seat selection. Tickets cannot be
      replaced if they are lost, stolen, or damaged.
    </Text>
    <Text>
      Refunds in cases of cancellation, postponement, rescheduling, or moved
      dates will be issued for the ticket price you paid, excluding tax and
      service fees. The refund process will take approximately one week. If a
      refund is issued, it will be processed to the original method of payment
      used at the time of purchase. We cannot issue a refund to a different
      mobile money number, credit card, or debit card. If your phone number,
      credit card, or debit card number has changed, the refund will still be
      made to the particular phone number or card that you used to purchase your
      ticket.
    </Text>
    <Text>
      Please note that seating maps are representative of a venue's layout, but
      they are subject to change at any time. Refunds are not allowed if the
      seating map is updated, additional seats/rows are added, or if seats
      change due to a venue change after purchase.
    </Text>
    <Text>
      Occasionally, we may offer tickets at a discount after the original
      on-sale date. We will not refund the difference between the original price
      and the sale price. Additionally, there are no refunds or returns for
      adjusted prices set by the Event Organizers.
    </Text>
    <Text>
      <b>Pricing and Other Errors:</b>If the amount you paid for a ticket is
      incorrect (regardless of whether it was due to an error in a posted price
      on the site or other communication), or if you were able to order a ticket
      that was not supposed to be released for sale, we have the right to cancel
      that ticket or order and refund the amount you paid. This applies even if
      the error occurred due to human error or a system malfunction. We will not
      be liable for travel or any other expenses incurred in connection with
      errors of this nature.
    </Text>
    <Text>
      <b>Force Majeure:</b>We will not be held responsible for event
      cancellations due to force majeure, including natural disasters, acts of
      terrorism, or government regulations. Refunds will be issued according to
      our refund policy (please refer to our refund policy for more details).
    </Text>
    <Heading size={"xs"}>
      i. Account, Order, and Billing Information Verification
    </Heading>
    <Text>
      All information on accounts and orders must be valid and subject to
      verification. Orders are subject to credit card approval and are processed
      only after verifying the billing address associated with your credit card
      and other billing information. Orders placed or attempted to be placed
      using false, misleading, incorrect, or incomplete information, or
      information that cannot be verified as belonging to the account holder,
      may be canceled at any time. If your order is canceled for any of these
      reasons, we may sell your tickets to another customer without further
      notice.
    </Text>
    <Heading size={"xs"}>j. Multiple Browser Windows</Heading>
    <Text>
      When ordering tickets online with us, please ensure that you are looking
      for tickets and placing an order using only one browser window. Using
      multiple browser windows to look up tickets may result in losing your
      tickets, errors during the purchase process, or timer expiration.
    </Text>
    <Heading size={"xs"}>k. Limitation of Liability</Heading>
    <Text letterSpacing={2}>
      IN NO EVENT WILL WE OR OUR EVENT ORGANIZERS, SUPPLIERS, ADVERTISERS, AND
      SPONSORS BE RESPONSIBLE OR LIABLE TO YOU OR ANYONE ELSE FOR, AND YOU
      HEREBY KNOWINGLY AND EXPRESSLY WAIVE ALL RIGHTS TO SEEK, DIRECT, INDIRECT,
      INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES OF ANY TYPE, AND ANY RIGHTS
      TO HAVE DAMAGES MULTIPLIED OR OTHERWISE INCREASED, ARISING OUT OF OR IN
      CONNECTION WITH THE SITE, THE CONTENT, OR ANY PRODUCT OR SERVICE PURCHASED
      THROUGH THE SITE. THIS INCLUDES ANY CLAIMS BASED UPON CONTRACT, TORT, OR
      OTHER LEGAL OR EQUITABLE THEORY. EVEN IF WE HAVE BEEN ADVISED OF THE
      POSSIBILITY OF SUCH DAMAGES. THIS ALSO APPLIES REGARDLESS OF WHETHER THE
      CLAIM IS BASED UPON CONTRACT, TORT, OR OTHER LEGAL OR EQUITABLE THEORY.
    </Text>
    <Text>
      WITHOUT LIMITING THE FOREGOING, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT
      WE WILL HAVE NO LIABILITY OR RESPONSIBILITY WHATSOEVER FOR:
    </Text>
    <Text>
      (a) ANY FAILURE OF ANOTHER USER OF THE SITE TO CONFORM TO THE CODES OF
      CONDUCT,
      <br />
      (b) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, WHETHER
      ARISING IN CONTRACT OR IN TORT, RESULTING FROM YOUR ACCESS TO AND USE OF
      OUR SITE,
      <br />
      (c) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
      PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,
      <br />
      (d) ANY BUGS, VIRUSES, WORMS, TROJAN HORSES, DEFECTS, DATE BOMBS, TIME
      BOMBS, OR OTHER ITEMS OF A DESTRUCTIVE NATURE WHICH MAY BE TRANSMITTED TO
      OR THROUGH OUR SITE,
      <br />
      (e) ANY ERRORS, MISTAKES, INACCURACIES, OR OMISSIONS IN ANY CONTENT,
      <br />
      (f) ANY LOST, STOLEN, OR DAMAGED TICKETS, OR THE FAILURE OF A VENUE TO
      HONOR A TICKET.
      <br />
      YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SITE IS TO
      STOP USING THE SITE.
    </Text>
    <Text>
      Balls, bottles, and other objects may fly into the spectator area during
      an event. Despite spectator shielding, injury can occur. Stay alert at all
      times before, during, and after play or performance. If struck,
      immediately ask an usher for directions to a medical station. If any
      personal injury, loss, or death occurs at an event, Musha Tickets is not
      responsible for it, and we will not be held liable for any such claims or
      instances, and all such claims are waived.
    </Text>
    <Text>
      You assume all risks, hazards, and dangers arising from or relating in any
      way to the risk of contracting a communicable disease or illness,
      including exposure to COVID-19 or any other bacteria, virus, or pathogen
      capable of causing a communicable disease or illness. This includes
      exposure before, during, or after the event, regardless of how it was
      caused or contracted. You hereby waive any and all claims and potential
      claims against Musha Tickets.
    </Text>
    <Heading size={"xs"}>
      l. License; Ejection and Cancellation; No Redemption Value
    </Heading>
    <Text>
      You agree to comply with all applicable Event Organizer Rules, including
      rules, policies, terms, and conditions. The Event Organizer reserves the
      right, without refund, to refuse admission to or eject any person whose
      conduct management deems disorderly, uses vulgar or abusive language, or
      fails to comply with Event Organizer Rules. Breach of terms or rules will
      terminate your license to attend the event without refund. A ticket is a
      revocable license, and admission may be refused. A ticket is not
      redeemable for cash.
    </Text>
    <Heading size={"xs"}>m. Recording, Transmission, and Exhibition</Heading>
    <Text>
      You agree that the event for which you purchase tickets is a public event,
      and your appearance and actions inside and outside the venue are
      considered public in nature. You acknowledge that you have no expectation
      of privacy regarding your actions or conduct at the event. By attending
      the event, you grant permission to us, the Event Organizer(s), our
      partners, licensees, and assigns, including our brand and media partners,
      to use your name, image, likeness, acts, poses, plays, appearance,
      movements, and statements in any live or recorded audio, video, or
      photographic display or other transmission, exhibition, publication, or
      reproduction made of or at the event. This permission applies to any
      purpose, manner, medium, or context now known or developed in the future,
      without requiring further authorization or compensation to you or anyone
      acting on your behalf. For virtual events, you may only view the virtual
      event for personal purposes. You are not permitted to record, copy,
      publicly exhibit, transmit, or distribute any virtual event through any
      means, resell views of any virtual event, or allow others to log into your
      account to watch a virtual event.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      4. Claims of Copyright Infringement on the Site
    </Heading>
    <Text>
      If you believe in good faith that any content on the Site infringes your
      copyright, you may send us a notice requesting the removal of the content.
      The notice must include: (a) your (or your agent's) physical or electronic
      signature; (b) identification of the copyrighted work on our Site that is
      claimed to have been infringed (or a representative list if multiple
      copyrighted works are included in one notification); (c) identification of
      the content that is claimed to be infringing or the subject of infringing
      activity, including information reasonably sufficient to locate the
      content on the Site; (d) your name, address, telephone number, and email
      address (if available); (e) a statement that you have a good faith belief
      that the use of the content in the manner complained of is not authorized
      by you, your agent, or the law; and (f) a statement that the information
      in the notification is accurate and, under penalty of perjury, that you or
      your agent is authorized to act on behalf of the copyright owner. If you
      believe in good faith that a notice of copyright infringement has been
      wrongly filed against you, you may send us a counter-notice.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      5. Other Links, Push Notifications, and Mobile, WhatsApp, and Email
      Messaging
    </Heading>
    <Text>
      The Site contains links to other websites that may not be owned or
      operated by us. The inclusion of these links does not indicate approval or
      endorsement of those websites. We have no control over the content or
      privacy practices of those websites. We encourage you to review the terms
      of use and practices of any linked website. Your use of other websites is
      at your own risk and subject to their respective terms. It is your
      responsibility to take precautions to ensure that any links you select or
      software you download, whether from the Site or other sites, are free of
      viruses, worms, Trojan horses, defects, date bombs, time bombs, or other
      destructive items.
    </Text>
    <Text>
      We offer browsing, WhatsApp, email, and mobile messaging services that may
      include tickets, alerts, promotions, and offers for products. By signing
      up, you authorize us to use automated technology to send messages to the
      contact details you provide during the signup process. Your consent to
      receive mobile communications is not required to make a purchase from us.
    </Text>
    <Text>
      Message and data rates may apply according to the rate plan provided by
      your wireless carrier. We will not be responsible for any text messaging
      or wireless charges incurred by you or anyone with access to your wireless
      device or telephone number. Please note that if your carrier does not
      permit text alerts, you may not receive our alerts. Additionally, certain
      carriers may not allow the use of prepaid phones or calling plans to
      receive alerts. You may receive a bounce-back message for each message you
      send to us. Our service may not be compatible with all wireless carriers
      or devices.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      6. Intellectual Property
    </Heading>
    <Text>
      All content on our platform, including text, graphics, and logos, is
      protected by intellectual property laws. You are not permitted to use or
      reproduce this content without our permission.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      7. User Content and Ownership
    </Heading>
    <Text>
      Our platform may enable you to submit content such as reviews, ratings,
      media files, or comments. By submitting such content, you grant us a
      non-exclusive, royalty-free, perpetual, irrevocable, and fully
      sublicensable right to use, reproduce, modify, adapt, publish, translate,
      create derivative works from, distribute, and display the content
      worldwide in any media.
    </Text>
    <Text>
      You retain all rights to your User Content. If you submit User Content to
      the Site, you grant us a worldwide, non-exclusive, transferable,
      sublicensable, royalty-free right and license to use, reproduce, modify,
      create derivative works of, distribute, publicly perform, display,
      archive, and commercialize your User Content at our discretion, in all
      formats and media channels now known or discovered in the future, without
      any compensation or acknowledgment to you or anyone else. This license
      does not affect your ownership rights in your User Content, including the
      right to grant additional licenses to your User Content, except if it
      conflicts with the Terms. We are not obligated to post, display, or use
      any User Content, nor are we required to attribute your User Content to
      you. You will not make any claim or authorize others to claim that our use
      of your User Content infringes any of your rights.
    </Text>
    <Text>
      The Site and all its Content, including data, text, designs, pages,
      screenshots, images, artwork, photographs, audio and video clips, HTML
      code, source code, and software accessible or viewable on the Site, as
      well as all tickets obtained from the Site, are owned by us. We hold
      copyrights, patents, and other intellectual property rights in the Site
      and Content. We reserve the right to change the Content and features of
      the Site at any time.
    </Text>
    <Text>
      We grant you a limited, conditional, non-exclusive, non-transferable,
      non-sublicensable license to view the Site and its Content in accordance
      with the Terms for personal, non-commercial purposes only. However, you
      agree that as a condition of using the Site, you will not:
    </Text>
    <Text pl={2}>
      - Submit any software or materials that contain worms, viruses, Trojan
      horses, defects, date bombs, time bombs, or any other destructive items.
      <br />
      - Manipulate identifiers, including forging headers, to disguise the
      origin of any content you submit.
      <br />
      - Link to any part of the Site other than the URL assigned to the home
      page.
      <br />
      - "Mirror" or "frame" any part of the Site.
      <br />
      - Modify, translate, sublicense, adapt, sell, reverse engineer, decompile,
      or disassemble any part of the Site, or attempt to derive source code,
      underlying ideas, or algorithms from any portion of the Content.
      <br />
      - Remove any copyright, trademark, or other proprietary rights notices
      displayed on the Site.
      <br />
      - Use any computer program, bot, robot, spider, offline reader, site
      search/retrieval application, or any other manual or automatic device,
      process, or tool to retrieve, index, data mine, or circumvent the security
      structure, navigational structure, or presentation of the Content or the
      Site, including any CAPTCHA displayed. Public search engines may use
      spiders to copy materials from the Site solely for creating publicly
      available searchable indices, but not caches or archives of such
      materials. We may revoke this exception at any time and require the
      removal of previously archived materials.
      <br />
      - Take any action that may impose an unreasonable or disproportionately
      large load on our infrastructure, as determined solely by us.
      <br />
      - Access, reload, or refresh transactional event or ticketing pages, or
      make any other request to transactional servers more than once within a
      three-second interval.
      <br />
      - Request more than 1,000 pages of the Site within any 24-hour period,
      whether individually or as part of a group.
      <br />
      - Make more than 800 reserve requests on the Site within any 24-hour
      period, whether individually or as part of a group.
      <br />
      - Reproduce, display, modify, publicly perform, distribute, or create
      derivative works of the Site or the Content.
      <br />
      - Reproduce or scan tickets in a format or medium different from what is
      provided by the Site.
      <br />
      - Decode, decrypt, modify, or reverse engineer any tickets or underlying
      algorithms or barcodes used on the Site or in the production of tickets.
      <br />
      - Use the Site or the Content in an attempt to, or in conjunction with,
      any device, service, or program designed to circumvent any technological
      measure that controls access to, or the rights in, the Site and/or
      Content, whether by manual or automatic means, for any purpose.
      <br />
      - Use ticket bot technology to search for or purchase tickets through the
      Site. This explicitly prohibits the use of automated ticket purchasing
      software on the Site and circumventing any security measures, access
      control systems, or technological controls or measures on the Site that
      enforce posted event ticket purchasing limits or maintain the integrity of
      posted online ticket purchasing order rules.
      <br />
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      8. Prohibited Conduct:
    </Heading>
    <Text>
      You are prohibited from using our platform for any illegal, fraudulent, or
      abusive activities. Posting defamatory, obscene, or offensive content on
      our platform is also not allowed. Interfering with the operation of our
      platform or attempting to gain unauthorized access to our systems is
      strictly prohibited.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      9. Indemnification:
    </Heading>
    <Text>
      You agree to indemnify and hold us harmless from any claims, damages,
      losses, or expenses, including attorneys' fees, arising from your use of
      our platform or any breach of this Agreement. If anyone brings a claim
      against us related to your use of the Site, the Content, your User
      Content, or your violation of the Terms, you agree to indemnify, defend,
      and hold us and our affiliated companies, Event Organizers, suppliers,
      advertisers, and sponsors, as well as our officers, directors, employees,
      and agents, harmless from any and all claims, damages, losses, and
      expenses of any kind (including reasonable legal fees and costs). We
      reserve the right to assume exclusive control and defense of any claim,
      and you will fully cooperate with us in asserting any available defenses.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      10. Modifications:
    </Heading>
    <Text>
      We reserve the right to modify this Agreement at any time without prior
      notice. Your continued use of our platform after any modifications
      constitutes acceptance of the new terms.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      11. Termination:
    </Heading>
    <Text>
      We may terminate this Agreement and your access to our platform at any
      time, without prior notice, for any reason or no reason.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      12. Violation of Terms, Governing Law and Arbitration:
    </Heading>
    <Text>
      This Agreement constitutes the entire agreement between you and us
      regarding the use of our platform. If any provision of this Agreement is
      found to be invalid or unenforceable, the remaining provisions shall
      remain in full force and effect.
    </Text>
    <Text>
      We may investigate any violation of the Terms, including unauthorized use
      of the Site. We may provide law enforcement with information you provide
      to us related to your transactions to assist in any investigation or
      prosecution against you. We reserve the right to take legal action (as
      outlined in Section 17 below) deemed appropriate. You acknowledge that
      monetary damages may not provide us with sufficient remedy, and we may
      seek injunctive or other relief for your violation of the Terms. In the
      event of a violation of the Terms or the law, or for any other reason or
      no reason, we may cancel your account, delete all your User Content, and
      prevent you from accessing the Site at any time without notice. If this
      occurs, you may no longer use the Site or access any Content. You will
      still be bound by your obligations under the Terms. You agree that we will
      not be liable to you or any third party for termination of your access to
      the Site, your account, or any related information, and we will not be
      required to make the Site or your account or any related information
      available to you. We may also cancel any ticket or merchandise order, as
      well as tickets or merchandise acquired through your order. We reserve the
      right to refuse honoring pending and future purchases made from all
      accounts we believe may be associated with you or cancel a ticket or
      ticket order associated with any person we believe to be acting with you,
      or cancel your ticket postings, or exercise any other remedy available to
      us.
    </Text>
    <Text>
      You acknowledge that your abusive use of the Site may cause damage and
      harm to us, including impaired goodwill, lost sales, and increased
      expenses. You also agree that determining monetary damages for your
      abusive use of the Site is difficult, and if you or others acting with you
      request more than 1,000 pages of the Site or make more than 800 reserve
      requests on the Site within any 24-hour period, you and those acting with
      you will be jointly and severally liable for liquidated damages.
    </Text>
    <Text>
      This Agreement is governed by and construed in accordance with the laws of
      Zambia.
    </Text>
    <Text letterSpacing={2}>
      YOU AND WE EACH AGREE THAT, EXCEPT AS PROVIDED BELOW, ANY DISPUTE, CLAIM,
      OR CONTROVERSY RELATING IN ANY WAY TO THE TERMS, YOUR USE OF THE SITE, OR
      PRODUCTS OR SERVICES SOLD, DISTRIBUTED, ISSUED, OR SERVICED BY OR THROUGH
      US—IRRESPECTIVE OF WHEN THAT DISPUTE, CLAIM, OR CONTROVERSY AROSE—WILL BE
      RESOLVED SOLELY BY BINDING, INDIVIDUAL ARBITRATION AS SET FORTH IN THE
      TERMS, RATHER THAN IN COURT. YOU AND WE THEREBY EACH AGREE TO WAIVE ANY
      RIGHT TO A JURY TRIAL, AND AGREE THAT YOU AND WE MAY BRING CLAIMS AGAINST
      EACH OTHER ONLY IN AN INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
      MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
    </Text>
    <Text>
      In the event that the arbitration agreement in the Terms is held to be
      unenforceable for any reason, any litigation against us (except for small
      claims court actions) may only be initiated in a court located within
      Lusaka, Zambia. Both you and we consent to the jurisdiction of those
      courts for such purposes.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      13. Informal Dispute Resolution:
    </Heading>
    <Text>
      Both you and we recognize and agree that good faith, informal efforts to
      resolve disputes often result in prompt, low-cost, and mutually beneficial
      outcomes. Therefore, before either party may commence an arbitration or
      assert a claim in small claims court, both parties will engage in the
      following informal dispute resolution process:
    </Text>
    <Text>
      The party seeking to initiate a claim in arbitration or small claims court
      ("claimant") must provide written notice to the other party
      ("respondent"). To notify us of your intention to initiate informal
      dispute resolution, you must send an email to mushatickets@gmail.com,
      including your full name, the email address and mailing address associated
      with your Musha Tickets account, your counsel's name and contact
      information (if represented by counsel), and a brief description of your
      claim(s) and the relief sought. To notify you of our intention to initiate
      informal dispute resolution, we will email you at the email address
      associated with your Musha Tickets account, providing a brief description
      of our claim(s) and the relief sought, as well as our counsel's name and
      contact information.
    </Text>
    <Text>
      Both parties will then personally meet and confer, via teleconference or
      videoconference, in a good faith effort to informally resolve any claim
      covered by this mutual arbitration agreement. If either party is
      represented by counsel, that counsel may participate in the informal
      dispute resolution conference.
    </Text>
    <Text>
      All offers, promises, conduct, and statements made during the informal
      dispute resolution process by any party, its agents, employees, and
      attorneys are confidential and not admissible for any purpose in any
      subsequent proceeding, except when evidence is otherwise admissible or
      discoverable, which shall not be rendered inadmissible or non-discoverable
      as a result of its use in the informal dispute resolution process.
    </Text>
    <Text>
      The informal dispute resolution conference shall take place within sixty
      (60) days of receiving the written notice described above, unless an
      extension is mutually agreed upon by both parties. If, after participating
      in the conference, the dispute remains unresolved, the claimant may
      proceed with arbitration or assert a claim in small claims court in
      accordance with this arbitration agreement.
    </Text>
    <Text>
      Any statute of limitations will be tolled while both parties engage in the
      informal dispute resolution process described in this section.
    </Text>
    <Text>
      It is our belief that the Terms do not contain any provision contrary to
      law. However, if any part of the Terms is determined to be illegal,
      invalid, or unenforceable, you agree that: (a) that part shall still be
      enforced to the extent permissible to fulfill the intent of the Terms, and
      (b) the remaining parts shall be deemed valid and enforceable.
    </Text>
    <Heading pt={4} pl={3} size={"md"}>
      14. Disclaimer of Warranties:
    </Heading>
    <Text>
      We provide the Site and the Content to you "as is" and "as available." We
      strive to keep the Site up, bug-free, and safe, but you use it at your own
      risk. To the fullest extent permitted by law, and to the extent applicable
      law allows the disclaimer of express or implied warranties, we disclaim
      all warranties, express or implied, including any warranty of title,
      non-infringement, accuracy, merchantability, fitness for a particular
      purpose, or warranties that may arise from course of dealing or
      performance or usage of trade. We do not guarantee that the Site will
      always be safe, secure, or error-free, or that the Site will always
      function without disruptions, delays, or imperfections.
    </Text>
    <Text letterSpacing={2}>
      WE ARE NOT RESPONSIBLE FOR THE ACTIONS OR INFORMATION OF THIRD PARTIES,
      AND YOU RELEASE US FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING
      OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH
      THIRD PARTIES.
    </Text>
    <Text>
      Musha Tickets sends tickets and transactional updates via email to the
      email address provided by the person making the order. Non-delivery of
      emails may occur due to various reasons, including user error in entering
      the email address, issues with upstream providers, or problems with the
      recipient's mailbox (such as a full inbox, company firewalls, or emails
      being filtered to junk or promo folders). Musha Tickets assumes no
      responsibility for non-delivery of emails and ensures that your tickets
      are stored and accessible in your Musha Tickets account for easy access at
      any time. Additionally, Musha Tickets also sends tickets via WhatsApp, and
      if an incorrect WhatsApp number is provided, Musha Tickets will not be
      held responsible for the failure to access your ticket on WhatsApp.
    </Text>
    <Text
      fontWeight={"bold"}
      color={"blue.500"}
      padding={2}
      textAlign={"center"}
    >
      <Link to={"/guidelines"} color={"blue"}>
        CHECK OUR WEBSITE TERMS AND COMMUNITY GUIDELINES
      </Link>
    </Text>
  </>
);

export default TermsOfUseContent;
