import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Index from "./Routes";
import "./index.css";

const App: React.FC = (): JSX.Element => {
  useEffect(() => {
    localStorage.removeItem("chakra-ui-color-mode");
  }, []);
  return (
    <Router>
      <Index />
    </Router>
  );
};

export default App;
