import {
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VStack,
} from "@chakra-ui/react";
import React from "react";

interface Props {
  title: string;
  callback?: (val: string | number) => void;
  value?: string | number;
}

const CustomNumberInput: React.FC<Props> = ({
  title,
  value,
  callback,
}): JSX.Element => {
  return (
    <VStack m={"1"}>
      <Heading fontSize={"xs"} fontWeight={"medium"}>
        {title}
      </Heading>
      <NumberInput
        onChange={(e) => callback(e)}
        size="xs"
        maxW={16}
        defaultValue={value}
        min={0}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </VStack>
  );
};

export default CustomNumberInput;
