import { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { setHours, setMinutes, startOfToday} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import {
  Input,
  InputGroup,
  InputRightElement,
  forwardRef,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

const CustomInput = forwardRef(({ onClick, ...props }, ref) => {
  return (
    <InputGroup className=" cursor-pointer">
      <Input
        {...props}
        ref={ref}
        placeholder="Select date"
        borderRadius="md"
        w={{ base: "70vw", md: 510 }}
        onClick={onClick} // Trigger onClick event
      />
      <InputRightElement>
        <CalendarIcon color="gray.900" onClick={onClick} />
      </InputRightElement>
    </InputGroup>
  );
});

const DateTimePicker = ({ selectedDate, onChange }) => {
  const currentDate = new Date();
  const startDate = setHours(setMinutes(startOfToday(), 0), 9);

  const inputRef = useRef(null);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const filterPassedTime = (time) => {
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <DatePicker
      selected={selectedDate || startDate}
      onChange={onChange}
      showTimeSelect
      filterTime={filterPassedTime}
      minDate={startDate}
      dateFormat="MMMM d, yyyy h:mm aa"
      timeIntervals={15}
      open={isDatePickerOpen}
      onCalendarOpen={() => setDatePickerOpen(true)}
      onCalendarClose={() => setDatePickerOpen(false)}
      customInput={
        <CustomInput
          inputRef={inputRef}
          onClick={() => setDatePickerOpen(!isDatePickerOpen)}
        />
      }
    />
  );
};

export default DateTimePicker;
