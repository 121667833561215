import { Box, Text, Button } from "@chakra-ui/react";
import Header from "./Header";
import PricingCards from "./PricingCards";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AiOutlineCheckCircle,
  AiFillTrademarkCircle,
  AiFillSketchCircle,
} from "react-icons/ai";

const Pricing = () => {
  const navigate = useNavigate();
  return (
    <Box className="min-h-screen w-full flex flex-col overflow-hidden items-center justify-center">
      <Header />
      <Text className="md:text-3xl text-xl font-bold px-4 md:px-12">
        Affordable pricing for all events
      </Text>
      <PricingCards />

      <Text className="md:text-3xl text-xl font-bold md:max-w-md mb-6 text-center">
        Unlock a suite of comprehensive features for your event
      </Text>
      <Box className=" w-full grid lg:grid-cols-2 px-4 md:px-12">
        <Box className=" flex flex-col gap-2">
          <Box className="text-sm text-s flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiOutlineCheckCircle size={24} color="orange" />
            </Box>
            <Text>Manage attendees and track event revenue in real time</Text>
          </Box>

          <Box className="text-sm flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiOutlineCheckCircle size={24} color="orange" />
            </Box>
            <Text>Access our free Android and iOS app to check in guests</Text>
          </Box>
          <Box className="text-sm flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiOutlineCheckCircle size={24} color="orange" />
            </Box>
            <Text>
              Event attendees can pay for tickets through mobile money or debit
              cards
            </Text>
          </Box>
        </Box>
        <Box className=" flex flex-col gap-2">
          <Box className="text-sm flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiOutlineCheckCircle size={24} color="orange" />
            </Box>
            <Text>
              A committed support team at your service, catering to both you and
              your guests.
            </Text>
          </Box>
          <Box className="text-sm flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiOutlineCheckCircle size={24} color="orange" />
            </Box>
            <Text>Host private (invite only) events</Text>
          </Box>
        </Box>
      </Box>
      <Text
        className="md:text-3xl text-xl font-bold md:max-w-md mb-6 text-center"
        marginTop={"8px"}
      >
        Need more features? The following are available at an additional cost
      </Text>
      <Box className=" w-full grid lg:grid-cols-2 px-4 md:px-12">
        <Box className=" flex flex-col gap-2">
          <Box className="text-sm text-s flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiFillSketchCircle size={24} color="red" />
            </Box>
            <Text> Customized ticket appearances</Text>
          </Box>

          <Box className="text-sm flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiFillSketchCircle size={24} color="red" />
            </Box>
            <Text>
              {" "}
              Have Musha employees assist with ticket scanning at your event
            </Text>
          </Box>
          <Box className="text-sm flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiFillSketchCircle size={24} color="red" />
            </Box>
            <Text>Event wrist bands or neck tags</Text>
          </Box>
        </Box>
        <Box className=" flex flex-col gap-2">
          <Box className="text-sm flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiFillSketchCircle size={24} color="red" />
            </Box>
            <Text>Physical tickets</Text>
          </Box>
          <Box className="text-sm flex gap-4 mt-4 text-gray-500">
            <Box>
              <AiFillSketchCircle size={24} color="red" />
            </Box>
            <Text>Search engine optimization to promote event</Text>
          </Box>
        </Box>
      </Box>

      <Text
        className="md:text-1xl  font-semibold md:max-w-md mb-6 text-center"
        marginTop={"8px"}
      >
        Contact us at support@mushatickets.com for more information about
        pricing.
      </Text>
      <Button
        rounded={"none"}
        className=" mt-12 bg-orange-500 "
        borderRadius={"2rem"}
        padding={"2rem"}
        fontSize={"xl"}
        fontWeight={"extrabold"}
        ml={"2rem"}
        mr={"2rem"}
        onClick={() => navigate(`/create_event`)}
      >
        Create an event
      </Button>
    </Box>
  );
};

export default Pricing;
