import React from "react";

import { Heading, HeadingProps } from "@chakra-ui/react";

interface Params extends HeadingProps {
  msg: string;
}

const ErrorHeading: React.FC<Params> = ({
  border,
  borderColor,
  padding,
  fontSize,
  msg,
  fontWeight,
}): JSX.Element => {
  return (
    <Heading
      padding={Boolean(padding) ? padding : 8}
      borderRadius={"lg"}
      border={Boolean(border) ? border : "1px"}
      borderColor={Boolean(borderColor) ? borderColor : "gray.300"}
      fontSize={Boolean(fontSize) ? fontSize : "sm"}
      fontWeight={Boolean(fontWeight) ? fontWeight : "medium"}
      textAlign={"center"}
    >
      {msg}
    </Heading>
  );
};

export default ErrorHeading;
