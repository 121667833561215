import React, { useEffect, useRef } from "react";
import { ReactComponent as SVGImage } from "./music_club.svg";

interface SVGProps {
  // handleSeatClick:(  ) => void;
  handleSeatClick: (id: string, event: MouseEvent) => void;
  handleSeatHover: (id: string, event: MouseEvent) => void;
  handleSeatEndHover: (id: string, event: MouseEvent) => void;
  handleSectionClick: (id: string, event: MouseEvent) => void;
  handleSectionHover: (id: string, event: MouseEvent) => void;
  handleSectionEndHover: (id: string, event: MouseEvent) => void;
  svgText: string;
}

const DiagramSVG: React.FC<SVGProps> = (props: SVGProps) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const allElements = svgRef.current?.querySelectorAll("*");
    // Zoom in on the clicked element.
    // Add a click listener to each circle and path element.
    // console.log("This is all elements: ", allElements);
    if (!allElements) {
      return;
    }
    allElements.forEach((element) => {
      function clickListener(event: MouseEvent) {
        props.handleSeatClick(element.id, event);
      }

      if (
        element.id.includes("seat") &&
        !element.hasAttribute("attached_listener")
      ) {
        console.log("Adding click listener");
        console.log("This is element ID: ", element.id);
        element.setAttribute("attached_listener", "true");
        element.addEventListener("click", clickListener);
        element.addEventListener("mouseenter", (event: MouseEvent) =>
          props.handleSeatHover(element.id, event),
        );
        element.addEventListener("mouseleave", (event: MouseEvent) =>
          props.handleSeatEndHover(element.id, event),
        );
      }

      if (
        element.id.includes("section") &&
        !element.hasAttribute("attached_listener")
      ) {
        element.setAttribute("attached_listener", "true");
        element.addEventListener("click", (event: MouseEvent) =>
          props.handleSectionClick(element.id, event),
        );
        element.addEventListener("mouseenter", (event: MouseEvent) =>
          props.handleSectionHover(element.id, event),
        );
        element.addEventListener("mouseleave", (event: MouseEvent) =>
          props.handleSectionEndHover(element.id, event),
        );
      }
    });
  }, [props]);

  return (
    <svg ref={svgRef} width="100%" height="100%">
      <SVGImage />
    </svg>
  );

  // return (
  //   <svg
  //     ref={svgRef}
  //     width="100%"
  //     height="100%"
  //     dangerouslySetInnerHTML={{ __html: props.svgText }}
  //     className="min-h-[40vh] md:min-h-[86vh] h-full"
  //   />
  // );
};
export default DiagramSVG;
