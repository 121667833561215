import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";

const PricingCards = () => {
  return (
    <Box
      className=" grid md:grid-cols-3 z-10 py-8 w-full items-center px-4 md:px-10 gap-6"
      maxW={"1200px"}
    >
      <Box
        className="flex w-full shadow-md hover:shadow-xl duration-200 p-4 items-start border justify-start text-left rounded-md flex-col"
        maxW={"400px"}
        minH={"400px"}
      >
        <Text className="font-bold">Free events</Text>
        <Text className=" text-sm text-gray-500">
          Free event? Free ticketing! Pay only a one time setup fee and Use
          Musha tickets to distribute tickets and manage attendees at no cost.
        </Text>
        <Text className=" mt-[4.5rem]">
          <span className=" text-2xl font-extrabold">k500</span> Setup fee for
          standard events
        </Text>
        <Text className=" mt-[1rem]">
          <span className=" text-2xl font-extrabold">k1500</span> Setup fee for
          seating chart events
        </Text>
        {/*<Text className="text-sm flex gap-2 mt-4 text-gray-500">*/}
        {/*  <AiOutlineCheckCircle size={24} /> Lorem ipsum dolor sit amet*/}
        {/*  consectetur adipisicing elit.*/}
        {/*</Text>*/}
        {/*<Button rounded={"none"} className=" w-full mt-12">*/}
        {/*  Get started for free*/}
        {/*</Button>*/}
      </Box>
      <Box
        className="flex w-full shadow-md hover:shadow-xl duration-200 p-4 items-start border justify-start text-left rounded-md flex-col"
        maxW={"400px"}
        minH={"400px"}
      >
        <Text className="font-bold">Standard events</Text>
        <Text className=" text-sm text-gray-500">
          Manage your standard events with all the Musha Tickets tools at your
          disposal. We charge a commission and service fee per ticket sold, so
          you always know exactly what you're paying.
        </Text>
        <Text className=" mt-8">
          <span className=" text-2xl font-extrabold">4.6%</span> Commission Fee
          (paid by event organiser)
        </Text>
        <Text>
          <span className=" text-2xl font-extrabold">K5</span> Service Fee (paid
          by event attendees)
        </Text>
        {/*<Text className="text-sm flex gap-2 mt-4 text-gray-500">*/}
        {/*  <AiOutlineCheckCircle size={24} /> Lorem ipsum dolor sit amet*/}
        {/*  consectetur adipisicing elit.*/}
        {/*</Text>*/}
        {/*<Button*/}
        {/*  bg={"orange.500"}*/}
        {/*  rounded={"none"}*/}
        {/*  className=" w-full mt-12 hover:bg-orange-600"*/}
        {/*>*/}
        {/*  Create yout first event*/}
        {/*</Button>*/}
      </Box>
      <Box
        className="flex w-full shadow-md hover:shadow-xl duration-200 p-4 items-start border justify-start text-left rounded-md flex-col"
        maxW={"400px"}
        minH={"400px"}
      >
        <Text className="font-bold">Seating chart events</Text>
        <Text className=" text-sm text-gray-500">
          Bring your events to life with visual seating charts. Let attendees
          choose their perfect seat before they even arrive, creating a more
          personalized and exciting experience.
        </Text>
        <Text className=" mt-12">
          <span className=" text-2xl font-extrabold">5.8%</span> Commission Fee
          (paid by event organiser)
        </Text>
        <Text>
          <span className=" text-2xl font-extrabold">K5</span> Service Fee (paid
          by event attendees)
        </Text>
        {/*<Text className="text-sm flex gap-2 mt-4 text-gray-500">*/}
        {/*  <AiOutlineCheckCircle size={24} /> Lorem ipsum dolor sit amet*/}
        {/*  consectetur adipisicing elit.*/}
        {/*</Text>*/}
        {/*<Button rounded={"none"} className=" w-full mt-12">*/}
        {/*  Get started*/}
        {/*</Button>*/}
      </Box>
    </Box>
  );
};

export default PricingCards;
