const colors = {
  primary: "#002e63",
  border: "#c6c6c6",
  white: "#fff",
  bgWhite: "#f6f6f6",
  gray: "#9ca5ab",
  background: "#eef0f3",
  error: "#b55464",
  newBlue: "rgba(0, 132, 255, 0.527)",
  activeGray: "#808080",
  shadeGray: "#C5C5C5",
  deepGray: "#3B3B3B",
  aliceblue: "aliceblue",
  simpleGray: "#d0d0d0",
  orange: "#F97316",
};

export default colors;
