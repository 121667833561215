import React from "react";

import { Heading, Stack, Text, VStack } from "@chakra-ui/react";

import BackButton from "../components/Buttons/BackButton";
import Footer from "../components/Footer";
import WithSubnavigation from "../components/Nav/Navbar";

function CommunityGuidelines() {
  return (
    <VStack w={"full"}>
      <WithSubnavigation />
      <Stack
        w={{
          base: "full",
          md: "70%",
        }}
        p={4}
      >
        <BackButton />
        <Heading>WEBSITE TERMS AND COMMUNITY GUIDELINES</Heading>
        <Text>
          Welcome to our electronic ticketing platform! Please read these
          website terms and community guidelines carefully before using our
          service.
        </Text>
        <Heading size={"md"}>Website Terms</Heading>
        <Text>
          1. Agreement to Terms By using our service, you agree to be bound by
          these website terms. If you do not agree to these website terms,
          please do not use our service.
          <br />
          2. Service Description - Our service provides electronic ticketing for
          various events and activities. We are not responsible for the events
          or activities themselves, and we do not provide any warranties or
          guarantees regarding the events or activities.
          <br />
          3. Intellectual Property - Our service and its contents, including but
          not limited to text, graphics, images, logos, and software, are the
          property of our company or our licensors and are protected by
          copyright, trademark, and other intellectual property laws. You may
          not use our service or its contents for any commercial purpose without
          our prior written consent.
          <br />
          4. User Conduct - You agree not to use our service for any unlawful
          purpose or in any way that could damage our service or interfere with
          any other party's use of our service. You also agree not to use our
          service to send unsolicited commercial messages or to collect or
          harvest personally identifiable information from other users.
          <br />
          5. Disclaimer of Warranties - Our service is provided on an "as is"
          and "as available" basis, without any warranties of any kind, express
          or implied. We do not warrant that our service will be uninterrupted
          or error-free, or that any defects in our service will be corrected.
          <br />
          6. Limitation of Liability - To the fullest extent permitted by law,
          we will not be liable for any indirect, incidental, special,
          consequential, or punitive damages arising out of or in connection
          with your use of our service. Our liability to you for any direct
          damages shall be limited to the amount paid by you for the ticket
          purchased through our service.
          <br />
          7. Governing Law and Jurisdiction - These website terms shall be
          governed by and construed in accordance with the laws of Zambia. Any
          dispute arising out of or in connection with these website terms shall
          be subject to the exclusive jurisdiction of the courts of Lusaka in
          Zambia. 8. Changes to Website Terms - We may revise these website
          terms at any time by updating this page. Your continued use of our
          service after any such changes constitutes your acceptance of the new
          website terms.
        </Text>
        <Heading size={"md"}>Community Guidelines</Heading>
        <Text>
          1. Introduction Our community guidelines are designed to promote a
          positive and respectful environment for all users of our service. By
          using our service, you agree to abide by these community guidelines.
          <br />
          2. Respect for Others - We expect all users to treat others with
          respect and dignity. Hate speech, harassment, and discrimination will
          not be tolerated.
          <br />
          3. No Illegal Activity - Our service may not be used for any illegal
          activity or to promote or facilitate illegal activity.
          <br />
          4. Intellectual Property - Users may not post or upload content that
          infringes on the intellectual property rights of others. We may remove
          any content that we believe infringes on the intellectual property
          rights of others.
          <br />
          5. No Spam or Advertising - Users may not use our service to send
          unsolicited commercial messages or to promote their own products or
          services.
          <br />
          6. Moderation and Enforcement - We reserve the right to moderate and
          enforce these community guidelines, and to remove any content or user
          that violates these guidelines. We also reserve the right to terminate
          the accounts of users who repeatedly violate these guidelines.
          <br />
          7. Changes to Community Guidelines - We may revise these community
          guidelines at any time by updating this page. Your continued use of
          our service after any such changes constitutes your acceptance of the
          new community guidelines.
          <br />
        </Text>
        <Heading size={"md"}>Anti-Spam Policy</Heading>
        <Text>
          As an electronic ticketing platform, we are committed to complying
          with anti-spam laws and ensuring that our users only receive
          communications that they have explicitly requested or that are related
          to their account on our platform. To achieve this, we have established
          the following anti-spam policy:
        </Text>
        <Text>
          1. Explicit Consent: We will only send communications to users who
          have explicitly opted in to receive them or who have an existing
          business relationship with us.
          <br />
          2. Opt-Out Mechanism: We will provide users with a clear and easy way
          to opt out of receiving communications from us, such as an unsubscribe
          link or email address.
          <br />
          3. Identification: All communications sent by us will clearly identify
          us as the sender and include accurate contact information.
          <br />
          4. Content: All communications sent by us will be relevant and contain
          accurate information.
          <br />
          5. Compliance: We will comply with all applicable anti-spam laws and
          regulations in Zambia.
          <br />
          6. Reporting: Users are encouraged to report any unsolicited
          communications they receive from us or other users on our platform.
          <br />
        </Text>
        <Text>
          By following this anti-spam policy, we can ensure that our users only
          receive relevant and requested communications and that we are in
          compliance with applicable laws and regulations. We reserve the right
          to update this policy as needed to reflect changes in our platform or
          anti-spam laws and regulations.
          <br />
          Thank you for using our electronic ticketing platform!
        </Text>
      </Stack>
      <Footer />
    </VStack>
  );
}

export default CommunityGuidelines;
