import React from "react";

import { Box, Image, Stack, Text } from "@chakra-ui/react";

import { ICategory } from "../../types/interface";

interface Params extends ICategory {
  callback: (val: string) => void;
}

const HeaderCategoryCard: React.FC<Params> = ({
  name,
  img,
  value,
  callback,
}): JSX.Element => {
  return (
    <Box
      onClick={() => callback(name)}
      shadow={"lg"}
      cursor={"pointer"}
      position={"relative"}
      rounded={"lg"}
      overflow={"hidden"}
      height={{ base: "90px", md: "170px" }}
    >
      <Image
        height={"full"}
        width={"full"}
        objectFit={"cover"}
        src={img as string}
      />
      <Stack
        padding={"2"}
        position={"absolute"}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
        zIndex={11}
        top={"0"}
        left={"0"}
        width={"full"}
        height={"full"}
        background={"rgba(0,0,0,0.1)"}
      >
        <Text
          p={1}
          rounded={"md"}
          bg={"rgba(0,0,0,0.9)"}
          className=" md:text-sm text-xs"
          fontWeight={"bold"}
          color={"aliceblue"}
        >
          {name==="Meetings and Conferences" ? "Meetings & Conferences":name}
        </Text>
      </Stack>
    </Box>
  );
};

export default HeaderCategoryCard;
