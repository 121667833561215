import { useEffect, useState } from "react";
import userPool from "../userPool";
import { useSetRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";
import usePostData from "../hooks/usePostData";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { validateEmail } from "../utils/functions";
import { EventSeatTypes, IEvent, IMutateData } from "../types/interface";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import {
  isAuthenticatedAtom,
  refreshAtomState,
  userAccessToken,
  awsUserDetailsAtom,
} from "../recoil/atom";
import colors from "../utils/colors";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

interface Props {
  data: IEvent;
}

const LoginModalBody: React.FC<Props> = ({ data }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedAtom);
  const setUserAccessToken = useSetRecoilState(userAccessToken);
  const setRefreshToken = useSetRecoilState(refreshAtomState);
  const setUserDetails = useSetRecoilState(awsUserDetailsAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const putDBUser = usePostData();

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Boolean(email) || !Boolean(password)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [email, password]);

  const submit = async () => {
    try {
      if (invalid) {
        return toast({
          title: "Please Enter all Fields",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }

      if (!validateEmail(email)) {
        return toast({
          title: "Email is Invalid",
          description: "Please enter a valid email",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }
      setIsLoading(true);
      const response: any = await loginCognitoUser();
      console.log(
        "AWS:LOGIN:RESPONSE: ",
        response.accessToken.jwtToken,
        "  End",
      );
      console.log("AWS Resp: ", response.accessToken);
      const showErrorToast = () => {
        return toast({
          title: "There was an error signing in, please try again",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      };
      if (!response) {
        setIsLoading(false);
        showErrorToast();
        return;
      }
      if (typeof response.accessToken.jwtToken === "string") {
        setUserAccessToken(response.accessToken.jwtToken);
        setRefreshToken(response.refreshToken.token);
        const userData: IMutateData = {
          inputData: {},
          endpoint: "/users",
        };
        const res = await putDBUser.mutateAsync(userData);
        if (res.user !== null || res.user !== undefined) {
          setUserDetails(response.idToken.payload);
          setIsAuthenticated(true);
          setIsLoading(false);
          navigate(
            data.ticketingType === EventSeatTypes.STANDARD_BASED
              ? `/event/${data._id}/choice`
              : `/event/${data._id}/buy_diagram`,
          );
        } else {
          showErrorToast();
        }
      }
    } catch (error: any) {
      toast({
        title: "Error",
        description: `${error.message as string}`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const loginCognitoUser = () => {
    const authenticationData = { Username: email, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const CU = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) =>
      CU.authenticateUser(authenticationDetails, {
        onSuccess: (result: any) => resolve(result),
        onFailure: (err: any) => reject(err),
      }),
    );
  };
  return (
    <Stack
      spacing={4}
      w={"full"}
      maxW={"md"}
      bg={useColorModeValue("white", "gray.700")}
      rounded={"xl"}
      boxShadow={"lg"}
      p={6}
      // py={6}
      my={12}
    >
      <Text textAlign={"center"} fontWeight={"bold"} fontSize={"1.5rem"}>
        You are not signed in
      </Text>
      <Button
        loadingText="Logging In..."
        isLoading={isLoading}
        size="lg"
        bg={colors.activeGray}
        color={"white"}
        _hover={{
          bg: colors.orange,
        }}
        onClick={() =>
          navigate(
            data.ticketingType === EventSeatTypes.STANDARD_BASED
              ? `/event/${data._id}/choice`
              : `/event/${data._id}/buy_diagram`,
          )
        }
      >
        Continue as guest
      </Button>
      {/*<Divider borderWidth="1px" borderColor="black" />*/}
      {/*<Text textAlign={"center"} fontWeight={"bold"}>*/}
      {/*  ----- Or -----*/}
      {/*</Text>*/}
      {/*<Divider borderWidth="1px" borderColor="black" />*/}
      <Flex alignItems="center">
        <Divider flex="1" borderColor={colors.deepGray} />
        <Text mx={2} textColor={colors.deepGray}>
          Or
        </Text>
        <Divider flex="1" borderColor={colors.deepGray} />
      </Flex>

      <FormControl id="email" isRequired>
        <FormLabel>Email address</FormLabel>
        <Input
          placeholder="your-email@example.com"
          _placeholder={{ color: "gray.500" }}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
      </FormControl>
      <FormControl id="password" isRequired>
        <FormLabel>Password</FormLabel>
        <InputGroup>
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="**********"
            _placeholder={{ color: "gray.500" }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputRightElement h={"full"}>
            <Button
              variant={"ghost"}
              onClick={() => setShowPassword((showPassword) => !showPassword)}
            >
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Stack spacing={6}>
        <Button
          loadingText="Logging In..."
          isLoading={isLoading}
          size="lg"
          bg={colors.primary}
          color={"white"}
          _hover={{
            bg: "blue.800",
          }}
          onClick={() => submit()}
        >
          Log In
        </Button>
      </Stack>
      <Stack pt={6}>
        <Text align={"center"}>
          Don't have an account yet?{" "}
          <Link to={"/sign_up"}>
            <Text color={colors.primary} fontWeight={"bold"}>
              Sign Up
            </Text>
          </Link>
        </Text>
      </Stack>
      <Link to="/forgot_password" className=" text-center">
        <Text color={colors.primary} fontWeight={"bold"}>
          Forgot Password?
        </Text>
      </Link>
    </Stack>
  );
};

export default LoginModalBody;
