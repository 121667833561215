import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";

import { useFormik } from "formik";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  allAddTicketsTypeDataAtom,
  eventCreationPathAtom,
  EventDetails2FormAtom,
  formDataAtom,
  formProgressAtom,
  formStepAtom,
  incompleteEventAtom,
  prevCreateEventFormStepAtom,
} from "../../../recoil/atom";
import {
  CreateEventFormSteps,
  EventSeatTypes,
  IAddTicketGroupingData,
  IEvent,
  SeatGroupingData,
} from "../../../types/interface";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { apiClient } from "../../../api";
import { makeToast } from "../../../utils/toast";

const AddSeatsGroupingForm: React.FC = () => {
  const eventDetails2FormAtom = useRecoilValue(EventDetails2FormAtom);
  const [formData, setFormData] = useRecoilState(formDataAtom);

  const setFormStep = useSetRecoilState(formStepAtom);
  const setFormProgress = useSetRecoilState(formProgressAtom);
  const [prevCreateEventFormStep, setPrevCreateEventFormStep] = useRecoilState(
    prevCreateEventFormStepAtom,
  );
  const [, setEventCreationPath] = useRecoilState(eventCreationPathAtom);
  const [salesStartDate, setSalesStartDate] = useState<Date | null>(null);
  const [salesStartTime, setSalesStartTime] = useState<Date | null>(null);
  const [salesEndDate, setSalesEndDate] = useState<Date | null>(null);
  const [salesEndTime, setSalesEndTime] = useState<Date | null>(null);
  const [itemToEdit, setItemToEdit] = useState(-1);
  const [showAdvancedSettings, setShowAdvancedSettingsAdvancedOptions] =
    useState(false);
  const [incompleteEvent, setIncompleteEvent] =
    useRecoilState(incompleteEventAtom);
    const isPaidEvent: boolean = !incompleteEvent.isFree;

  console.log("Incomplete event receeved in choice of ticketing plan: ", incompleteEvent.isFree);

  const handleStartDateChange = (date: Date | null) => {
    setSalesStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setSalesEndDate(date);
  };

  const handleStartTimeChange = (time: Date | null) => {
    setSalesStartTime(time);
  };

  const handleEndTimeChange = (time: Date | null) => {
    setSalesEndTime(time);
  };

  const [allAddTicketsTypeData, setAllAddTicketsTypeData] = useRecoilState(
    allAddTicketsTypeDataAtom,
  );

  const [ticketAddTypeFormErrors, setTicketAddTypeFormErrors] = useState<{
    [key in keyof IAddTicketGroupingData]?: string;
  }>({});

  useEffect(() => {
    setFormProgress(78);
    setPrevCreateEventFormStep(CreateEventFormSteps.SELECT_SEATING_TYPE);
    setEventCreationPath(CreateEventFormSteps.ADD_SEATS_GROUPING_FORM);
  }, [setEventCreationPath, setFormProgress, setPrevCreateEventFormStep]);

  useEffect(() => {
    console.log("All addTypes changed: ", allAddTicketsTypeData);
  }, [allAddTicketsTypeData]);

  useEffect(() => {
    if (incompleteEvent?.seatGroupingMap === undefined) {
      return;
    }
    const seatGroupingArray: IAddTicketGroupingData[] = Object.entries(
      incompleteEvent.seatGroupingMap,
    ).map(([name, data]) => ({
      name,
      availableQuantity: data?.numInitialSeats.toString(),
      price: data?.price.toString(),
      salesStartDate: data?.salesStartDate
        ? new Date(data?.salesStartDate)
        : undefined,
      salesStartTime: data?.salesStartDate
        ? new Date(data?.salesStartDate)
        : undefined,
      salesEndDate: data.salesEndDate ? new Date(data.salesEndDate) : undefined,
      salesEndTime: data.salesEndDate ? new Date(data.salesEndDate) : undefined,
      maxTicketsPerOrder: data.maxTicketsPerOrder
        ? data.maxTicketsPerOrder.toString()
        : undefined,
    }));

    setAllAddTicketsTypeData(seatGroupingArray);
  }, []);

  const combineDateAndTime = (dateObject: Date, timeObject: Date): Date => {
    const newYear = dateObject.getFullYear();
    const newMonth = dateObject.getMonth();
    const newDay = dateObject.getDate();
    const newHours = timeObject.getHours();
    const newMinutes = timeObject.getMinutes();
    const newSeconds = timeObject.getSeconds();
    const newMilliseconds = timeObject.getMilliseconds();

    return new Date(
      newYear,
      newMonth,
      newDay,
      newHours,
      newMinutes,
      newSeconds,
      newMilliseconds,
    );
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      const submissionFormData: Record<string, SeatGroupingData> = {};
      allAddTicketsTypeData.forEach((formData) => {
        const price = isPaidEvent ? parseFloat(formData.price) : 0;
        const salesStartDate =
          (formData.salesStartDate &&
            formData.salesStartTime &&
            combineDateAndTime(
              formData.salesStartDate,
              formData.salesStartTime,
            )) ||
          null;
        const salesEndDate =
          (formData.salesEndDate &&
            formData.salesEndTime &&
            combineDateAndTime(formData.salesEndDate, formData.salesEndTime)) ||
          null;
        submissionFormData[formData.name] = {
          groupName: formData.name,
          numInitialSeats: parseInt(formData.availableQuantity, 0),
          numSeatsSold: 0,
          price,
          maxTicketsPerOrder: parseInt(formData.maxTicketsPerOrder, 0) | 0,
          salesStartDate,
          salesEndDate,
        };
      });

      console.log("Added tickets are", submissionFormData);

      const eventUpdates: IEvent = incompleteEvent;
      eventUpdates.seatGroupingMap = submissionFormData;

      try {
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await apiClient.put(
          `/event/${incompleteEvent._id}`,
          eventUpdates,
          {
            headers,
          },
        );
        console.log("This is response SelectSeatingTypeForm: ", response);
        setIncompleteEvent(response.data as IEvent);
      } catch (err) {
        makeToast(
          "error",
          "Error",
          "Could not update event. Please try again later",
        );
      }
      setFormStep(CreateEventFormSteps.ADD_BANK_DETAILS);
    },
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleAddTicketNumberInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    if (/^\d+$/.test(value) || value.length === 0) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleAddTicketTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddFormData = () => {
    if (validateFormData()) {
      const newGroupData = {
        ...formData,
        salesStartDate,
        salesStartTime,
        salesEndDate,
        salesEndTime,
      };
      if (itemToEdit === -1) {
        newGroupData.id = allAddTicketsTypeData.length;
        setAllAddTicketsTypeData((prevData: IAddTicketGroupingData[]) => [
          ...prevData,
          newGroupData,
        ]);
      } else {
        newGroupData.id = itemToEdit;
        const updatedArray = allAddTicketsTypeData.map((item, index) =>
          index === itemToEdit ? newGroupData : item,
        );
        setAllAddTicketsTypeData(updatedArray);
      }
      setFormData({
        id: allAddTicketsTypeData.length,
        name: "",
        availableQuantity: "",
        price: "",
        maxTicketsPerOrder: "",
      });
      setSalesStartTime(null);
      setSalesStartDate(null);
      setSalesEndTime(null);
      setSalesEndDate(null);
      setItemToEdit(-1);
      onClose();
    }
  };

  const setFormDataToSpecifiedGroupAndOpenModal = (index: number) => {
    const ticketData = allAddTicketsTypeData[index];
    setFormData({
      name: ticketData.name,
      availableQuantity: ticketData.availableQuantity,
      price: ticketData.price,
      maxTicketsPerOrder: ticketData.maxTicketsPerOrder,
    });
    setSalesStartTime(ticketData.salesStartTime);
    setSalesStartDate(ticketData.salesStartDate);
    setSalesEndTime(ticketData.salesEndTime);
    setSalesEndDate(ticketData.salesEndDate);
    setItemToEdit(index);
    onOpen();
  };

  const handleDeleteTicketTypeFormData = (id: number) => {
    setAllAddTicketsTypeData((prevTriplets) =>
      prevTriplets.filter((triplet) => triplet.id !== id),
    );
    onClose();
  };

  const validateFormData = (): boolean => {
    const errors: { [key in keyof IAddTicketGroupingData]?: string } = {};

    if (isPaidEvent && !isValidNumber(formData.price)) {
      errors.price = "Price must be a valid number.";
    }

    if (!isValidNumber(formData.availableQuantity)) {
      errors.availableQuantity = "Quantity must be a valid number.";
    }

    if (formData.name.length === 0) {
      errors.name = "Please provide a ticket name";
    }

    setTicketAddTypeFormErrors(errors);

    if (showAdvancedSettings) {
      if (formData.maxTicketsPerOrder === "") {
        errors.maxTicketsPerOrder = "Select maximum tickets per order";
      } else if (!isValidNumber(formData.maxTicketsPerOrder)) {
        errors.maxTicketsPerOrder = "Must be a number";
      } else if (
        parseInt(formData.maxTicketsPerOrder) >
        parseInt(formData.availableQuantity)
      ) {
        errors.maxTicketsPerOrder =
          "Cannot be more than the available quantity";
      }

      if (salesEndDate < salesStartDate) {
        errors.salesEndDate = "Can't be before start date.";
      }

      if (
        salesEndDate.getTime() === salesStartDate.getTime() &&
        salesEndTime <= salesStartTime
      ) {
        errors.salesEndTime = "Should be greater than start time.";
      }
    }

    return Object.keys(errors).length === 0;
  };

  const isValidNumber = (value: string): boolean => {
    return /^\d+(\.\d+)?$/.test(value);
  };

  console.log("This is allAddTicketsTypeData: ", allAddTicketsTypeData);
  return (
    <Box as="form" onSubmit={formik.handleSubmit}>
      <Stack>
        <Button
          onClick={onOpen}
          variant={"outline"}
          rightIcon={<AiOutlinePlus />}
          className="mt-4 mb-6"
        >
          Add Tickets
        </Button>
      </Stack>
      {allAddTicketsTypeData.map((item, i) => (
        <Flex key={i} className="border rounded-md p-2 mb-2">
          <Box className="w-full">
            <Box>
              <Text>
                <span className="font-bold mr-1">Section Name: </span>
                {item.name}
              </Text>

              <>
                {item.salesStartDate && (
                  <Text>
                    <span className="font-bold mr-1">Sales start: </span>
                    {moment(item.salesStartDate).format("ll")}
                    {item.salesStartTime
                      ? ", at " + item.salesStartTime?.toLocaleTimeString()
                      : ""}
                  </Text>
                )}

                {item.salesEndDate && (
                  <Text>
                    <span className="font-bold mr-1">Sales end: </span>
                    {moment(item.salesEndDate).format("ll")}
                    {item.salesEndTime
                      ? ", at " + item.salesEndTime?.toLocaleTimeString()
                      : ""}
                  </Text>
                )}
              </>

              {parseInt(item.maxTicketsPerOrder) >= 0 && (
                <Text>
                  <span className="font-bold mr-1">Max. Per Order: </span>
                  {item.maxTicketsPerOrder}
                </Text>
              )}
            </Box>
            <Box>
              {isPaidEvent ? (
                <Text>
                  {" "}
                  <span className="font-bold mr-1">Price: </span>K {item.price}
                </Text>
              ) : null}

              <Text>
                <span className="font-bold mr-1">Quantity: </span> x{" "}
                {item.availableQuantity}
              </Text>
            </Box>
          </Box>

          <Popover>
            <PopoverTrigger>
              <IconButton
                aria-label="menu"
                icon={<BsThreeDotsVertical />}
                variant={"ghost"}
                isRound
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent className="w-fit">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody className="w-fit flex flex-col mt-6">
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setFormDataToSpecifiedGroupAndOpenModal(item.id)
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    variant={"ghost"}
                    onClick={() => handleDeleteTicketTypeFormData(item.id)}
                  >
                    Delete
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Flex>
      ))}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Tickets</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              mt={4}
              isInvalid={!!ticketAddTypeFormErrors.name}
              isRequired
            >
              <Input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleAddTicketTextChange}
              />
              <FormErrorMessage>
                {ticketAddTypeFormErrors.name}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              mt={4}
              isInvalid={!!ticketAddTypeFormErrors.availableQuantity}
              isRequired
            >
              <Input
                type="text"
                name="availableQuantity"
                placeholder="Available Quantity"
                value={formData.availableQuantity}
                onChange={handleAddTicketNumberInputChange}
              />
              <FormErrorMessage>
                {ticketAddTypeFormErrors.availableQuantity}
              </FormErrorMessage>
            </FormControl>

            {isPaidEvent ? (
              <FormControl
                mt={4}
                isInvalid={!!ticketAddTypeFormErrors.price}
                isRequired
              >
                <InputGroup>
                  <InputLeftAddon children="K" />
                  <Input
                    placeholder="Price"
                    name="price"
                    value={formData.price}
                    onChange={handleAddTicketNumberInputChange}
                  />
                </InputGroup>
                <FormErrorMessage>
                  {ticketAddTypeFormErrors.price}
                </FormErrorMessage>
              </FormControl>
            ) : null}

            <Flex
              className=" w-full items-center justify-between mt-4 cursor-pointer"
              onClick={() =>
                setShowAdvancedSettingsAdvancedOptions(!showAdvancedSettings)
              }
            >
              <Text className="font-bold">
                {showAdvancedSettings ? "Hide" : "Show"} advanced settings
              </Text>
              <ChevronDownIcon
                className={`${
                  showAdvancedSettings ? "-rotate-180" : ""
                } duration-200`}
              />
            </Flex>
            {showAdvancedSettings && (
              <Box>
                <FormControl
                  mt={4}
                  isInvalid={!!ticketAddTypeFormErrors.maxTicketsPerOrder}
                  isRequired
                >
                  <Input
                    type="text"
                    name="maxTicketsPerOrder"
                    placeholder="Maximum Tickets Per Order"
                    value={formData.maxTicketsPerOrder}
                    onChange={handleAddTicketNumberInputChange}
                  />
                  <FormErrorMessage>
                    {ticketAddTypeFormErrors.maxTicketsPerOrder}
                  </FormErrorMessage>
                </FormControl>
                <Flex className=" items-center gap-4 my-4">
                  <FormControl
                    isInvalid={!!ticketAddTypeFormErrors.salesStartDate}
                    mt={4}
                  >
                    <FormHelperText>Sales Start</FormHelperText>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      selected={salesStartDate}
                      onChange={handleStartDateChange}
                      className="react-datepicker-time__input"
                    />
                    <FormErrorMessage>
                      {ticketAddTypeFormErrors.salesStartDate}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!ticketAddTypeFormErrors.salesStartTime}
                    mt={4}
                  >
                    <FormHelperText>Start time</FormHelperText>
                    <DatePicker
                      selected={salesStartTime}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      onChange={handleStartTimeChange}
                      className="react-datepicker-time__input"
                    />
                    <FormErrorMessage>
                      {ticketAddTypeFormErrors.salesStartTime}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Box className=" items-center gap-4 my-4 grid grid-cols-2">
                  <FormControl
                    isInvalid={!!ticketAddTypeFormErrors.salesEndDate}
                    mt={4}
                  >
                    <FormHelperText>Sales End</FormHelperText>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      selected={salesEndDate}
                      onChange={handleEndDateChange}
                      className={`react-datepicker-time__input ${
                        !!ticketAddTypeFormErrors.salesEndDate
                          ? "border-red-500 border"
                          : ""
                      } `}
                    />
                  </FormControl>

                  <FormControl
                    isInvalid={!!ticketAddTypeFormErrors.salesEndTime}
                    mt={4}
                  >
                    <FormHelperText>End Time</FormHelperText>
                    <DatePicker
                      selected={salesEndTime}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      onChange={handleEndTimeChange}
                      className={`react-datepicker-time__input ${
                        !!ticketAddTypeFormErrors.salesEndTime
                          ? "border-red-500 border"
                          : ""
                      } `}
                    />
                  </FormControl>
                </Box>
              </Box>
            )}
          </ModalBody>

          <ModalFooter className="flex w-full gap-4 justifty-between">
            <Button colorScheme="blue" onClick={onClose} className="w-full">
              Cancel
            </Button>
            <Button
              onClick={handleAddFormData}
              variant="outline"
              className="w-full"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*{isEditModalOpen && (*/}
      {/*  <EditModal*/}
      {/*    isOpen={isEditModalOpen}*/}
      {/*    onClose={() => setIsEditModalOpen(false)}*/}
      {/*    onSave={handleSaveEditedItem}*/}
      {/*    itemToEdit={itemToEdit}*/}
      {/*  />*/}
      {/*)}*/}

      <HStack spacing={4} justifyContent="center" mt={10}>
        <Button
          type="button"
          variant="outline"
          onClick={() => setFormStep(prevCreateEventFormStep)}
        >
          <Icon as={MdOutlineArrowBack} />
        </Button>
        <Button
          isDisabled={allAddTicketsTypeData.length === 0}
          onClick={formik.submitForm}
          variant="solid"
          isLoading={formik.isSubmitting}
          disabled={formik.isSubmitting}
        >
          <Icon as={MdOutlineArrowForward} />
        </Button>
      </HStack>
    </Box>
  );
};

export default AddSeatsGroupingForm;
