import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Heading,
  Text,
} from "@chakra-ui/react";

const TermsAndConditionsModal = ({ isOpen, onClose, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {children}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TermsAndConditionsModal;
