import React from "react";

import { Link } from "react-router-dom";
import { Heading, Stack, VStack } from "@chakra-ui/react";
import TermsOfUseContent from "./TermsOfUseContent";

const Terms: React.FC = (): JSX.Element => {
  return (
    <VStack w={"full"}>
      <Stack
        w={{
          base: "full",
          md: "70%",
        }}
        p={4}
      >
        <TermsOfUseContent />
      </Stack>
    </VStack>
  );
};

export default Terms;
