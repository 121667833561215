import { ColorHues } from "@chakra-ui/react";

interface AppTheme {
  name: string;
  id: string;
  colors: {
    primary: ColorHues;
    secondary: ColorHues;
    action: ColorHues;
  };
}

const customeTheme: AppTheme = {
  id: "custome",
  name: "Custome",

  colors: {
    action: {
      50: "#F5F7FA",
      100: "#E4E8EE",
      200: "#CED5E5",
      300: "#B7C2D9",
      400: "#9FAFCD",
      500: "#2C4971", // Main color (#2C4971)
      600: "#243D63",
      700: "#1C3154",
      800: "#162747",
      900: "#0D1A39",
    },

    primary: {
      50: "#536492",
      100: "#47577D",
      200: "#3B4B66",
      300: "#2F3F50",
      400: "#233339",
      500: "#2C4971", // Custom color 2
      600: "#1E345B",
      700: "#112245",
      800: "#031631",
      900: "#000A1C",
    },
    secondary: {
      50: "#FFEEC7",
      100: "#FDDFA0",
      200: "#FCC178",
      300: "#FBB351",
      400: "#FAA429",
      500: "#FE8007", // Custom color 1
      600: "#D66306",
      700: "#A94405",
      800: "#812303",
      900: "#5B1602",
    },
  },
};

export default customeTheme;
