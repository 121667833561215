import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import { IoArrowBack } from "react-icons/io5";
import { Outlet, useNavigate } from "react-router-dom";

const BuyTicketLayout = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <Box as="main" px={{ base: 4, md: 20 }}>
      <Spacer h={4} />
      <HStack justifyContent={"start"}>
        <IconButton isRound aria-label="back" onClick={handleBackClick}>
          <Icon as={IoArrowBack} fontSize={"2xl"} />
        </IconButton>
      </HStack>
      <Spacer h={4} />
      <Outlet />
    </Box>
  );
};

export default BuyTicketLayout;
