import { Box, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

interface OrderSummaryMobileProps {
  openSummary: boolean;
  setOpenSummary: React.Dispatch<React.SetStateAction<boolean>>;
  totalPrice: number;
  totalQuantity: number;
  totalFees: number;
  priceToPay: number;
}

const CheckoutFormSummaryMobile: React.FC<OrderSummaryMobileProps> = ({
  openSummary,
  setOpenSummary,
  totalPrice,
  totalQuantity,
  totalFees,
  priceToPay,
}) => {
  return (
    <Box className="lg:hidden block">
      <Flex
        onClick={() => setOpenSummary(!openSummary)}
        className="justify-between border-b pb-2"
      >
        <Flex className="items-center gap-2">
          <Text>Order Summary</Text>
          {openSummary ? (
            <ChevronUpIcon fontSize={24} />
          ) : (
            <ChevronDownIcon fontSize={24} />
          )}
        </Flex>
        <Text className="font-bold">K {totalPrice.toFixed(2)}</Text>
      </Flex>
      <Box
        className={`gap-2 grid mt-2 duration-300 ${
          openSummary ? "" : "hidden"
        }`}
      >
        <Box className="gap-2 grid pt-4 w-full border-b">
          <Flex className="justify-between">
            <Text className="col-span-2 font-bold">Ticket Quantity </Text>
            <Text>x {totalQuantity}</Text>
          </Flex>
          <Flex className="justify-between">
            <Text className="col-span-2 font-bold">SubTotal </Text>
            <Text className="font-bold">K {totalPrice.toFixed(2)}</Text>
          </Flex>
          <Flex className="justify-between">
            <Text className="col-span-2 font-bold">Service fee </Text>
            <Text className="font-bold">K {totalFees.toFixed(2)}</Text>
          </Flex>
          <Flex className="justify-between">
            <Text className="col-span-2 font-bold">Total </Text>
            <Text className="font-bold">K {priceToPay.toFixed(2)}</Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutFormSummaryMobile;
