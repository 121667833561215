import { Box, Button, Stack, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import WithSubnavigation from "../components/Nav/Navbar";
import colors from "../utils/colors";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";
import TicketContainer from "../components/Ticket";

const Ticket = () => {
  const { ticket_id } = useParams();


  const createPDF = async () => {
    // const pdf = new jsPDF("portrait", "pt", "a4");
    // const data = await html2canvas(document.querySelector("#pdf") as HTMLElement);
    // const img = data.toDataURL("image/png");
    // const imgProperties = pdf.getImageProperties(img);
    // const pdfWidth = pdf.internal.pageSize.getWidth();
    // //console.log("imgProperties", imgProperties);
    // //console.log("pdf.internal.pageSize", pdf.internal.pageSize.getWidth())
    // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    // pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    // pdf.save("Musha-ticke.pdf");
  };

  return (
    <VStack h={"full"} width={"full"} position={"relative"}>
      <WithSubnavigation />
      <Text>Ticket: {ticket_id}</Text>
      <TicketContainer />
      <Box
        w={"full"}
        position={"fixed"}
        bottom={"0"}
        left={"0"}
        zIndex={"999"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack
          width={{
            base: "100%",
            md: "40%",
          }}
          direction={"row"}
          padding={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            flex={1}
            fontSize={"sm"}
            rounded={"md"}
            bg={colors.primary}
            color={"white"}
            boxShadow={
              "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
            }
            _hover={{
              bg: "blue.500",
            }}
            _focus={{
              bg: "blue.500",
            }}
            onClick={() => {
              createPDF();
              console.log("Clicked Download");
            }}
          >
            Download Ticket
          </Button>
        </Stack>
      </Box>
      <Box width={"full"} h={{ base: "90px", md: "150px" }} />
    </VStack>
  );
};

export default Ticket;
