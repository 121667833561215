export const faq = [
  {
    question: "What is Musha Tickets?",
    answer:
      "Musha Tickets is an electronic ticketing platform that allows users to browse and purchase tickets for a wide range of events, including sports, concerts, arts and theatres, and meetings and events. It also offers event organizers the ability to create, manage, and sell tickets for their events.",
  },
  {
    question: "What is the refund policy for Musha Tickets?",
    answer: `Musha Tickets offers a flexible refund policy depending on the event organizer's terms and conditions. To request a refund, please contact our customer support team with your ticket details. Refunds are processed within 5-7 business days. Note that some events may have a no-refund policy, so please check the event details before purchasing.`,
  },
  {
    question: "How do I purchase tickets on Musha Tickets?",
    answer: `To purchase tickets, follow these steps:
      Go to the Musha Tickets website.
      Browse or search for your desired event.
      Click on the event to view event details.
      Select the number of tickets you want and choose your preferred seating (if applicable).
      Proceed to checkout and provide payment information.
      After successful payment, you'll receive a confirmation email with your electronic tickets, a PDF document on your tickets tab on your Account and a WhatsApp message which has your ticket.`,
  },
  {
    question: "What is the diagrammatic interactive user interface for events?",
    answer:
      "The diagrammatic interactive user interface is a feature on Musha Tickets that provides a visual representation of the event venue, including seat or section layouts. Users can click on the interface to select specific seats or sections when purchasing tickets for events with assigned seating. This feature enhances the ticket-buying experience by allowing users to choose their preferred seats interactively.",
  },
  {
    question: "Can I purchase tickets without creating an account?",
    answer: `Yes, you can purchase tickets without creating an account. Musha Tickets offers a convenient "Buy Now" option where you only need to provide your full name, WhatsApp phone number, and email address for the ticket purchase. Your details will be saved for future purchases, but creating an account is not mandatory for buying tickets.`,
  },
  {
    question: "How do I create an event on Musha Tickets?",
    answer: `
        Log in to your Musha Tickets account.
        Click on "Create Event" and fill in the details of your required event.
        Decide as to whether you want your event to be a Private Event or a Public Event.
        Choose your event type:
            - Diagram Presentation with Seat Selection
            - Diagram Presentation with Section Selection
            - Normal QR Code Scanning
        Provide event details, seating arrangements, and pricing information.
        Set up ticket options and pricing.
        Submit the event for review. Your event will be live within 48 hours.
    `,
  },
  {
    question: "What happens after I create an event?",
    answer:
      "After successfully creating an event, you'll receive a confirmation message, and your event will be listed on the Musha Tickets platform within 48 hours. Event attendees can purchase tickets for your event, and you can access event management tools through your account.",
  },
  {
    question: "Can I track ticket sales and earnings for my event?",
    answer: `Yes, you can track ticket sales and earnings. Musha Tickets provides event organizers with a "Ticket Sales and Commission" section in their account. You can monitor the number of tickets sold and view the commission earned from ticket sales. You can also withdraw your earnings from this section.`,
  },
  {
    question: "How do I use the Musha Tickets Validator App for event entry?",
    answer:
      "The Musha Tickets Scanner App is designed for event creators to scan tickets at the entry point. To use the app, log in with your Musha Tickets Event Pass Phrase, select the event you want to manage, and scan attendees' tickets using the app's camera feature. The app provides real-time status updates for scanned tickets.",
  },
  {
    question:
      "What should I do if I encounter an issue with a ticket or the app at the event entry point?",
    answer: `If you encounter issues with a ticket or the app, please contact Musha Tickets customer support. They can assist with resolving problems related to ticket scanning, event entry, or account management.
      You can reach us on <a style="text-decoration:underline" href="mailto:mushatickets@gmail.com">mushatickets@gmail.com</a> or contact us on 
      <a style="text-decoration:underline" href="tel:+260979958895">+260 979 958 895</a>.`,
  },
  {
    question: "How can I share an event with friends or on social media?",
    answer:
      "Each event on Musha Tickets has a shareable link. You can access this link from the event details page. You can easily share this link with friends, family, or on your social media platforms to promote your event and boost ticket sales.",
  },
];
