import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Heading,
  Stack,
  StackItem,
  IconButton,
  Flex,
} from "@chakra-ui/react";

import { CloseIcon } from "@chakra-ui/icons";

import { ISeat, ISeatIdMap } from "../../types/interface";

interface BoughtTicketsListProps {
  seats: ISeatIdMap;
  removeSelectedSeat: (string) => void;
  navigateToPurchase: () => void;
}

interface CardProps {
  seat: ISeat;
  removeSelectedSeatIconClick: () => void;
}

const BoughtItemCard: React.FC<CardProps> = ({
  seat,
  removeSelectedSeatIconClick,
}) => {
  return (
    <Box
      borderWidth="2px"
      borderRadius="lg"
      p={2}
      position="relative"
      display="flex"
      bg={"white"}
      _dark={{ bg: "transparent" }}
      mb={{ md: 2, base: 1 }}
      className="w-full flex-col"
    >
      <Flex className=" w-full justify-end items-center">
        <IconButton
          icon={<CloseIcon />}
          aria-label="Close"
          onClick={removeSelectedSeatIconClick}
          size={"xs"}
          isRound
        />
      </Flex>
      <Box className="flex items-center justify-between gap-4 pb-2">
        <Box className="flex flex-col items-center">
          <Text color={"gray"}>SEC</Text>{" "}
          <Text className="text-lg font-bold">{seat.section}</Text>
        </Box>
        <Box className="flex flex-col items-center">
          <Text color={"gray"}>ROW</Text>{" "}
          <Text className="text-lg font-bold">{seat.row}</Text>
        </Box>
        <Box className="flex flex-col items-center">
          <Text color={"gray"}>SEAT</Text>{" "}
          <Text className="text-lg font-bold">{seat.seatNumber}</Text>
        </Box>
      </Box>
      <Box className="border-t w-full text-sm flex md:pt-2 pt-1 justify-between">
        <Text>Price :</Text>
        <Text>K{seat.price} + Fees</Text>
      </Box>
    </Box>
  );
};

const TicketList: React.FC<BoughtTicketsListProps> = ({
  seats,
  removeSelectedSeat,
  navigateToPurchase,
}) => {
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [numSeatsSelected, setNumSeatsSelected] = useState<number>(0);
  useEffect(() => {
    const newTotalPrice = Object.values(seats).reduce(
      (accumulator, seat) => accumulator + parseInt(seat.price),
      0,
    );
    setTotalPrice(newTotalPrice);
    setNumSeatsSelected(Object.values(seats).length);
  }, [seats]);
  return (
    <Box className="border-2 rounded-md p-2 h-full w-full flex flex-col max-h-[49vh] md:min-h-[80vh] lg:min-h-[86vh]">
      <Heading
        as="h2"
        _dark={{ color: "white" }}
        className="pb-2 text-center md:text-lg text-base"
      >
        Tickets
      </Heading>
      <Stack className="flex-grow overflow-y-auto">
        {Object.entries(seats).map(([id, seat]: [string, ISeat]) => (
          <StackItem>
            <BoughtItemCard
              seat={seat}
              removeSelectedSeatIconClick={() => removeSelectedSeat(id)}
            />
          </StackItem>
        ))}
      </Stack>
      <Box className="pt-4 border-t">
        <Flex className=" justify-between items-center w-full">
          <Text className="font-bold">Total Price:</Text>
          <Text>K{totalPrice} + Fees</Text>
        </Flex>

        <Text className=" text-left w-full">{numSeatsSelected} Ticktet(s)</Text>
        <Button
          mt={4}
          colorScheme="blue"
          className="w-full"
          onClick={navigateToPurchase}
        >
          Purchase Ticket(s)
        </Button>
      </Box>
    </Box>
  );
};

export default TicketList;
