import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../../utils/functions";

function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const toast = useToast();

  const handleForgotPassword = async () => {
    try {
      const userPool = new CognitoUserPool({
        UserPoolId: "us-east-1_spapTUiQA",
        ClientId: "4f868k9j14hkmr3hetocp2bi3m",
      });

      const userData = {
        Username: email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);
      if (!validateEmail(email)) {
        return toast({
          title: "Email is Invalid",
          description: "Please enter a valid email",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }

      cognitoUser.forgotPassword({
        onSuccess: () => {
          setSuccess("Password reset initiated successfully");
          setError("");
          navigate("/confirm_password_reset");
        },
        onFailure: (err) => {
          if (err) {
            setError("User not found. Please check your email and try again.");
            return toast({
              title: "An error ocurred",
              description:
                "User not found. Please check your email and try again.",
              status: "warning",
              duration: 9000,
              isClosable: true,
            });
          } else {
            setError(`Error initiating password reset: ${err.message}`);
          }
          setSuccess("");
        },
      });
    } catch (error) {
      setError("An unexpected error occurred");
      setSuccess("");
      return toast({
        title: "An error ocurred",
        description: "Error resetting Password",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box className="min-h-screen items-center justify-center px-4 grid">
      <form className="shadow rounded-lg border p-4 max-w-md">
        <FormControl id="email" isRequired>
          <FormLabel>Email address</FormLabel>
          <Input
            placeholder="your-email@example.com"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            mb={4}
          />
        </FormControl>
        <Button onClick={handleForgotPassword} className="w-full">
          Reset Password
        </Button>

        {error && (
          <Text color="red.500" mt={4}>
            {error}
          </Text>
        )}

        {success && (
          <Text color="green.500" mt={4}>
            {success}
          </Text>
        )}
      </form>
    </Box>
  );
}

export default ForgotPasswordForm;
