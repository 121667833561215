import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { SeatGroupingData } from "../../types/interface";
import { sumPricesAndQuantities } from "../../utils/functions";

interface Ticket {
  quantity: number;
  amount: number;
}

interface FormikValues {
  [key: string]: Ticket;
}

interface OrderSummaryMobileProps {
  openSummary: boolean;
  setOpenSummary: React.Dispatch<React.SetStateAction<boolean>>;
  priceAndQuantityMap: Record<string, PriceAndQuantity>;
}

interface PriceAndQuantity {
  price: number;
  quantity: number;
  fees?: number;
}

const OrderSummaryMobile: React.FC<OrderSummaryMobileProps> = ({
  openSummary,
  setOpenSummary,
  priceAndQuantityMap,
}) => {
  const { totalPrice, totalQuantity, totalFees, priceToPay } =
    sumPricesAndQuantities(priceAndQuantityMap);
  return (
    <Box className="lg:hidden block">
      <Flex
        onClick={() => setOpenSummary(!openSummary)}
        className="justify-between border-b pb-2"
      >
        <Flex className="items-center gap-2">
          <Text>Order Summary</Text>
          {openSummary ? (
            <ChevronUpIcon fontSize={24} />
          ) : (
            <ChevronDownIcon fontSize={24} />
          )}
        </Flex>
        <Text className="font-bold">K {priceToPay.toFixed(2)}</Text>
      </Flex>
      <Box
        className={`gap-2 grid mt-2 duration-300 ${
          openSummary ? "" : "hidden"
        }`}
      >
        <Box className="w-full md:text-base text-sm justify-center border-b pb-4">
          {priceAndQuantityMap !== undefined ? (
            Object.entries(priceAndQuantityMap)
              .filter(
                ([type, priceAndQuantity]) => priceAndQuantity.quantity > 0,
              )
              .map(([type, priceAndQuantity]) => (
                <Flex className="justify-between mt-2 items-center" key={type}>
                  <Flex className="gap-5 items-center">
                    <Text
                      className={`rounded-full border border-[#fd7f07] px-2`}
                    >
                      {priceAndQuantity.quantity}
                    </Text>
                    <Text className="text-gray-400">{type}</Text>
                  </Flex>
                  <Text>K {priceAndQuantity.price.toFixed(2)}</Text>
                </Flex>
              ))
          ) : (
            <Text>This event doesn't have any price types.</Text>
          )}
        </Box>
        <Box className="gap-2 grid pt-4 w-full border-b">
          <Flex className="justify-between">
            <Text className="col-span-2 font-bold">Ticket Quantity </Text>
            <Text>x {totalQuantity}</Text>
          </Flex>
          <Flex className="justify-between">
            <Text className="col-span-2 font-bold">SubTotal </Text>
            <Text className="font-bold">K {totalPrice.toFixed(2)}</Text>
          </Flex>
          <Flex className="justify-between">
            <Text className="col-span-2 font-bold">Service fee </Text>
            <Text className="font-bold">K {totalFees.toFixed(2)}</Text>
          </Flex>
          <Flex className="justify-between">
            <Text className="col-span-2 font-bold">Total </Text>
            <Text className="font-bold">K {priceToPay.toFixed(2)}</Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSummaryMobile;
