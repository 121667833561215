import { useRecoilValue } from "recoil";
import { selectedEventState} from "../../../recoil/atom";
import useGetData from "../../../hooks/useGetData";
import { API_URL } from "../../../utils/constants";
import Loading from "../../EventComponent/Event/Loading";
import EventDetails from "./EventDetails";
import { Text } from "@chakra-ui/react";

const UpcomingEventInfo = () => {
  const selectedEvent = useRecoilValue(selectedEventState);
  const eventId = selectedEvent._id;

  const { data, isLoading, isSuccess, error } = useGetData(
    ["seatData", eventId],
    `${API_URL}/seat/userSeats?eventId=${eventId}`,
    300000,
  );

  if (isLoading) {
    return <Loading count={1} />;
  }

  if (error && !isSuccess) {
    return (
      <Text className="text-center text-red-400 my-16">
        Error getting tickets
      </Text>
    );
  }

  return (
    <EventDetails
      selectedEvent={selectedEvent}
      data={data}
      isLoading={isLoading}
      isSuccess={isSuccess}
      error={error}
    />
  );
};

export default UpcomingEventInfo;
