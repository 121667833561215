import { createStandaloneToast } from "@chakra-ui/react";

const { toast } = createStandaloneToast();

export const makeToast = (status, title, message, id = "my-toast") => {
  if (!toast.isActive(id)) {
    toast({
      id,
      title,
      description: message,
      isClosable: true,
      duration: 6000,
      status,
    });
  }
};
