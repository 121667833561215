import React, { ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { isAuthenticatedAtom } from "../recoil/atom";
import { Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { apiClient } from "../api";
import { API_URL } from "../utils/constants";
import Loading from "../components/EventComponent/Event/Loading";

interface Props {
  children: ReactNode;
  loginPath: string;
}

const RequireAuth: React.FC<Props> = ({ children, loginPath }) => {
  const { data, error, isLoading, isSuccess } = useQuery(
    "authQuery",
    async () => {
      const res = await apiClient.get(API_URL + "/users/whoami");
      console.log("This is response: ", res);
    },
    {
      retry: 0,
    },
  );

  if (isLoading) return <Loading count={10} />;

  if (error) return <Navigate to={loginPath} replace={true} />;
  return <>{children}</>;
};

export default RequireAuth;
