import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
const TicketsLayout = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box px={{ base: 4, md: 10 }} py={4}>
      <HStack>
        <IconButton isRound aria-label="back" onClick={handleBackClick}>
          <Icon as={IoArrowBack} fontSize={"2xl"} />
        </IconButton>
      </HStack>
      <Spacer h={4} />
      <Outlet />
    </Box>
  );
};

export default TicketsLayout;
