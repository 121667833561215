import { useState } from "react";
import { Button, useToast, Box, Text, Flex, Image } from "@chakra-ui/react";
import axios from "axios";
import EditEventModal from "./EditEventModal";
import { useRecoilValue } from "recoil";
import { selectedEventState } from "../../recoil/atom";
import moment from "moment";
import { API_URL } from "../../utils/constants";
import parse from "html-react-parser";

const EditEvent = () => {
  const selectedEvent = useRecoilValue(selectedEventState);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const handleEditSave = async (editedEvent) => {
    try {
      setIsLoading(true);
      // Make a PUT request to update the event
      const response = await axios.put(
        `${API_URL}/event/${selectedEvent._id}`,
        editedEvent
      );

      // Handle the response as needed
      console.log("Updated Event:", response.data.event);

      toast({
        title: response.data.message,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating event:", error);
      toast({
        title: "Error updating event",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      setIsEditModalOpen(false);
    }
  };

  return (
    <Box className=" w-full items-center justify-center grid md:grid-cols-2 gap-2 pt-8">
      <Box className=" flex flex-col gap-2">
        <Text>{selectedEvent.title}</Text>
        <Flex>
          <Text>{moment(selectedEvent.eventStart).format("lll")}</Text>-
          <Text>{moment(selectedEvent.eventEnd).format("lll")}</Text>
        </Flex>
        <Box>{parse(selectedEvent.description)}</Box>
        <Button
          onClick={handleEditClick}
          isLoading={isLoading}
          className=" w-fit"
        >
          Edit
        </Button>
        <EditEventModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          selectedEvent={selectedEvent}
          onSave={handleEditSave}
        />
      </Box>
      <Image
        src={selectedEvent.imgBanner}
        className=" w-full h-full object-cover"
      />
    </Box>
  );
};

export default EditEvent;
