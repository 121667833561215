import { Box, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { IEvent, PriceAndQuantity } from "../../types/interface";
import { sumPricesAndQuantities } from "../../utils/functions";

// import orderBg from "../../assets/img/orderBg.jpg";

interface OrderSummaryProps {
  event: IEvent;
  priceAndQuantityMap: Record<string, PriceAndQuantity>;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  event,
  priceAndQuantityMap,
}) => {
  const { totalPrice, totalQuantity, totalFees, priceToPay } =
    sumPricesAndQuantities(priceAndQuantityMap);

  // console.log("This is price and quantity map: ", priceAndQuantityMap);

  return (
    <Box className="lg:block hidden">
      <Box>
        <Box
          className="w-full h-64 flex items-center justify-center"
          backgroundColor="#e9eff2"
          // backgroundImage={orderBg}
        >
          <Image
            src={event.imgBanner}
            loading="lazy"
            alt={event.title}
            className="w-60 h-60 object-center rounded-md object-cover"
          />
        </Box>
        <Box className="gap-2 mt-4 grid">
          <Text className="text-lg font-semibold">{event.title}</Text>
          <Text className="text-gray-500">{event.locationName}</Text>
          <SimpleGrid
            columns={3}
            spacing={4}
            className="w-full md:text-base text-sm justify-center border-b py-4"
          >
            <Text className="font-bold">Ticket type</Text>
            <Text className="font-bold">Quantity</Text>
            <Text className="font-bold">Cost</Text>

            {priceAndQuantityMap !== undefined &&
              Object.entries(priceAndQuantityMap)
                .filter(
                  ([type, priceAndQuantity]) => priceAndQuantity.price > -1,
                )
                .map(([type, priceAndQuantity]) => (
                  <React.Fragment key={type}>
                    <Text className="text-gray-400">{type}</Text>
                    <Text>x {priceAndQuantity.quantity}</Text>
                    <Text>K {priceAndQuantity.price.toFixed(2)}</Text>
                  </React.Fragment>
                ))}
          </SimpleGrid>
          <Box className="gap-2 grid pt-4">
            <Box className="grid grid-cols-3 gap-6">
              <Text className="col-span-2 font-bold">Ticket Quantity </Text>
              <Text>x {totalQuantity}</Text>
            </Box>
            <Box className="grid grid-cols-3 gap-6">
              <Text className="col-span-2 font-bold">SubTotal </Text>
              <Text className="font-bold">K {totalPrice.toFixed(2)}</Text>
            </Box>
            <Box className="grid grid-cols-3 gap-6">
              <Text className="col-span-2 font-bold">Service fee </Text>
              <Text className="font-bold">K {totalFees.toFixed(2)}</Text>
            </Box>
            <Box className="grid grid-cols-3 gap-6">
              <Text className="col-span-2 font-bold">Total</Text>
              <Text className="font-bold">K {priceToPay.toFixed(2)}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSummary;
