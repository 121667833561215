import React, { useEffect, useState } from "react";

import GooglePlacesAutocomplete from "chakra-ui-google-places-autocomplete";
import { useRecoilState } from "recoil";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Fade,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
  useBoolean,
} from "@chakra-ui/react";

import { useFormik } from "formik";
import { MdOutlineArrowBack, MdOutlineArrowForward } from "react-icons/md";
import {
  DescribeDiagramFormatFormAtom,
  formProgressAtom,
  formStepAtom,
  prevCreateEventFormStepAtom,
} from "../../../recoil/atom";
import {
  chooseDiagramTypeFormSchema,
  form2Schema,
} from "../../../utils/validation";
import { CreateEventFormSteps } from "../../../types/interface";

const DescribeDiagramFormatForm: React.FC = (): JSX.Element => {
  const [formState, setFormState] = useRecoilState(
    DescribeDiagramFormatFormAtom,
  );
  const [formStep, setFormStep] = useRecoilState(formStepAtom);
  const [formProgress, setFormProgress] = useRecoilState(formProgressAtom);
  const [prevCreateEventFormStep, setPrevCreateEventFormStep] = useRecoilState(
    prevCreateEventFormStepAtom,
  );

  useEffect(() => {
    setFormProgress(70);
    setPrevCreateEventFormStep(CreateEventFormSteps.SELECT_SEATING_TYPE);
  }, []);
  const formik = useFormik({
    initialValues: {
      hasSections: formState.hasSections,
      hasRows: formState.hasRows,
      hasTables: formState.hasTables,
      hasAssignedSeats: formState.hasAssignedSeats,
    },
    enableReinitialize: true,
    validationSchema: chooseDiagramTypeFormSchema,
    onSubmit: (values) => {
      setFormState({
        hasSections: values.hasSections,
        hasRows: values.hasRows,
        hasTables: values.hasTables,
        hasAssignedSeats: values.hasAssignedSeats,
      });
      setFormStep(CreateEventFormSteps.ADD_SEATING_CHART);
    },
  });

  return (
    <Box as="form" onSubmit={formik.handleSubmit}>
      <Heading
        fontSize={"lg"}
        fontWeight="bold"
        color="primary.500"
        my={6}
        textAlign={"center"}
      >
        Venue Seating Information
      </Heading>

      <FormControl as="fieldset" my={"4"}>
        <FormLabel
          as="legend"
          htmlFor="postal_code"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Does your venue have sections?
        </FormLabel>
        <RadioGroup
          id="hasSections"
          defaultValue="no"
          onChange={(value) =>
            value === "yes"
              ? setFormState((currVal) => {
                  return { ...currVal, hasSections: true };
                })
              : setFormState((currVal) => {
                  return { ...currVal, hasSections: false };
                })
          }
        >
          <Stack spacing={4} direction="row">
            <Radio value={"no"}>No</Radio>
            <Radio value={"yes"}>Yes</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl as="fieldset" my={"4"}>
        <FormLabel
          as="legend"
          htmlFor="postal_code"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Does your venue have rows?
        </FormLabel>
        <RadioGroup
          id="hasRows"
          defaultValue="no"
          onChange={(value) =>
            value === "yes"
              ? setFormState((currVal) => {
                  return { ...currVal, hasRows: true };
                })
              : setFormState((currVal) => {
                  return { ...currVal, hasRows: false };
                })
          }
        >
          <Stack spacing={4} direction="row">
            <Radio value={"no"}>No</Radio>
            <Radio value={"yes"}>Yes</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl as="fieldset" my={"4"}>
        <FormLabel
          as="legend"
          htmlFor="postal_code"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Does your venue have tables?
        </FormLabel>
        <RadioGroup
          id="hasTables"
          defaultValue="no"
          onChange={(value) =>
            value === "yes"
              ? setFormState((currVal) => {
                  return { ...currVal, hasTables: true };
                })
              : setFormState((currVal) => {
                  return { ...currVal, hasTables: false };
                })
          }
        >
          <Stack spacing={4} direction="row">
            <Radio value={"no"}>No</Radio>
            <Radio value={"yes"}>Yes</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl as="fieldset" my={"4"}>
        <FormLabel
          as="legend"
          htmlFor="postal_code"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          Does your venue have assigned seats?
        </FormLabel>
        <RadioGroup
          id="hasAssignedSeats"
          defaultValue="no"
          onChange={(value) =>
            value === "yes"
              ? setFormState((currVal) => {
                  return { ...currVal, hasAssignedSeats: true };
                })
              : setFormState((currVal) => {
                  return { ...currVal, hasAssignedSeats: false };
                })
          }
        >
          <Stack spacing={4} direction="row">
            <Radio value={"no"}>No</Radio>
            <Radio value={"yes"}>Yes</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <HStack justifyContent={"center"} gap={4} mt={10}>
        <Button
          type="button"
          onClick={() => setFormStep(prevCreateEventFormStep)}
          variant="outline"
        >
          <Icon as={MdOutlineArrowBack} />
        </Button>

        <Button onClick={formik.submitForm} variant="solid">
          <Icon as={MdOutlineArrowForward} />
        </Button>
      </HStack>
    </Box>
  );
};

export default DescribeDiagramFormatForm;
