import { Navigate, Outlet } from "react-router-dom";
import React, { ComponentType } from "react";
import { useRecoilValue } from "recoil";
import { isAuthenticatedAtom } from "../recoil/atom";

export default function WithoutAuthentication() {
  const isAuthenticated = useRecoilValue(isAuthenticatedAtom);

  return !isAuthenticated ? <Outlet /> : <Navigate to={"/"} replace={true} />;
}
