import { Box, Button, Select, Stack, StackItem, Text } from "@chakra-ui/react";
import { rtDisplayData } from "../../types/interface";
import React, { useState } from "react";
import { rtDiagramDSContainer } from "../../utils/buildRtDiagramDataStructure";

interface GroupCardProps {
  selectGroupTicket: () => void;
  displayText: string;
  price: number;
}

const GroupItemCard: React.FC<GroupCardProps> = ({
  displayText,
  selectGroupTicket,
  price,
}) => {
  return (
    <Box
      className="flex justify-between w-full bg-gray-100 py-4 px-2 cursor-pointer hover:bg-gray-200"
      onClick={selectGroupTicket}
    >
      <Box>
        <Text color="black">{displayText}</Text>
      </Box>
      <Text color="black" className=" font-bold">
        K {price}
      </Text>
    </Box>
  );
};

interface GroupTicketsListProps {
  rtDSContainer: rtDiagramDSContainer;
  selectGroupTickets: (rtIdentifier: string) => void;
  maxNumSeats: number;
  numSeatsToBuy: number;
  setNumSeatsToBuy: (number) => void;
}

const GroupTicketsList: React.FC<GroupTicketsListProps> = (props) => {
  // Create an array of 50 dummy grid items
  const [activeOption, setActiveOption] = useState("cheapest");

  const handleOptionClick = (option: string) => {
    setActiveOption(option);
  };

  console.log(
    "Rendering group tickets list. Count: ",
    props.rtDSContainer.getRowCount()
  );
  if (props.rtDSContainer.getRowCount() === 0) {
    return <Text>No row data</Text>;
  }

  const numSeatsButtons: JSX.Element[] = [];
  for (let i = 1; i < 5; i++) {
    numSeatsButtons.push(
      <Button
        key={i}
        variant={props.numSeatsToBuy === i ? "solid" : "outline"}
        colorScheme="orange"
        onClick={() => props.setNumSeatsToBuy(i)}
      >
        {i}
      </Button>
    );
  }

  const numSeatsOptions: JSX.Element[] = [];
  for (let i = 5; i <= props.maxNumSeats; i++) {
    numSeatsOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  const getGroupText = (displayData: rtDisplayData): string => {
    //For semantic purposes to determine where section text appears
    const sectionName = displayData.section;
    const isNumber =
      !isNaN(parseFloat(sectionName)) && isFinite(Number(sectionName));
    const isShortString = sectionName.length <= 2;

    if (isNumber || isShortString) {
      return `Section ${sectionName}, Row ${displayData.row}`;
    }
    return `${sectionName} Section , Row ${displayData.row}`;
    // Check if the string has two or fewer characters
  };

  const displayRowData = (identifiers: string[]) => {
    return identifiers.map((rtIdentifier, index) => (
      <StackItem key={index} className="w-full">
        <GroupItemCard
          selectGroupTicket={() => props.selectGroupTickets(rtIdentifier)}
          displayText={getGroupText(
            props.rtDSContainer.getRtDisplayData(rtIdentifier)
          )}
          price={props.rtDSContainer.getPrice(rtIdentifier)}
        />
      </StackItem>
    ));
  };

  return (
    <Box className="border rounded-md p-2 h-full flex flex-col max-h-[40vh] md:min-h-[80vh] lg:min-h-[86vh]">
      <Stack direction="row" spacing={4} mt={4}>
        {numSeatsButtons}
        <Select
          borderColor={"orange"}
          placeholder="5+"
          onChange={(event) =>
            props.setNumSeatsToBuy(parseInt(event.target.value, 10))
          }
        >
          {numSeatsOptions}
        </Select>
      </Stack>
      <Stack direction="row" spacing={4} mt={4}>
        <Button
          variant={activeOption === "cheapest" ? "solid" : "outline"}
          colorScheme="orange"
          onClick={() => handleOptionClick("cheapest")}
          className="w-full"
        >
          Cheapest seats
        </Button>
        <Button
          variant={activeOption === "best" ? "solid" : "outline"}
          colorScheme="orange"
          onClick={() => handleOptionClick("best")}
          className="w-full"
        >
          Best seats
        </Button>
      </Stack>
      {activeOption === "cheapest" && (
        <Stack spacing={2} mt={4} className="flex-grow overflow-y-auto">
          {displayRowData(
            props.rtDSContainer.getLowestToHighestPriceRtIdentifiers(
              props.numSeatsToBuy
            )
          ).length !== 0 ? (
            <>
              {displayRowData(
                props.rtDSContainer.getLowestToHighestPriceRtIdentifiers(
                  props.numSeatsToBuy
                )
              )}
            </>
          ) : (
            <Text textAlign={"center"} className=" font-bold">
              No tickets available
            </Text>
          )}
        </Stack>
      )}
      {activeOption === "best" && (
        <Stack spacing={2} mt={4} className="flex-grow overflow-y-auto">
          {displayRowData(
            props.rtDSContainer.getSortedBestRtIdentifiers(props.numSeatsToBuy)
          ).length !== 0 ? (
            <>
              {displayRowData(
                props.rtDSContainer.getSortedBestRtIdentifiers(
                  props.numSeatsToBuy
                )
              )}
            </>
          ) : (
            <Text textAlign={"center"} className=" font-bold">
              No tickets available
            </Text>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default GroupTicketsList;
