import axios, { AxiosError } from "axios";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { Box, Center, Progress, Spacer, useToast } from "@chakra-ui/react";
import EventDetails1Form from "../components/CreateEvent/Forms/EventDetails1Form";
import EventDetails2Form from "../components/CreateEvent/Forms/EventDetails2Form";
import AddBankDetailsForm from "../components/CreateEvent/Forms/AddBankDetailsForm";
import {
  AddBankDetailsFormAtom,
  AddSeatingChartFormAtom,
  AddSeatsGroupingFormAtom,
  allAddTicketsTypeDataAtom,
  eventDescriptionAtom,
  EventDetails1FormAtom,
  EventDetails2FormAtom,
  eventNameAtom,
  formProgressAtom,
  formStepAtom,
  SelectSeatingTypeAtom,
  awsUserDetailsAtom,
  backendUserDetailsAtom,
  incompleteEventAtom,
} from "../recoil/atom";
import {
  CreateEventFormSteps,
  EventSeatTypes,
  IAddBankDetailsForm,
  IEvent,
  RowOrTableType,
} from "../types/interface";
import { bucket, getS3, uploadImageToAws } from "../utils/aws";
// import {useErrorBoundary} from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../api";
import AddSeatsGroupingForm from "../components/CreateEvent/Forms/AddSeatsGroupingForm";
import SelectSeatingTypeForm from "../components/CreateEvent/Forms/SelectSeatingTypeForm";
import DescribeDiagramFormatForm from "../components/CreateEvent/Forms/DescribeDiagramFormatForm";
import AddSeatingChartForm from "../components/CreateEvent/Forms/AddSeatingChartForm";
import { generateStringsFromMap } from "../utils/sortedNumericMap";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../utils/constants";
import { makeToast } from "../utils/toast";
import LoadingEventDetails from "../components/EventComponent/Event/LoadingEventDetails";
import Loading from "../components/EventComponent/Event/Loading";

const CreateEvent: React.FC = (): JSX.Element => {
  const formStep = useRecoilValue(formStepAtom);
  const progress = useRecoilValue(formProgressAtom);
  const addSeatingChartFormState = useRecoilValue(AddSeatingChartFormAtom);

  const [incompleteEvent, setIncompleteEvent] =
    useRecoilState(incompleteEventAtom);

  const navigate = useNavigate();
  const toast = useToast();

  const { mutate, isLoading: checkAuthLoading } = useMutation(
    async () => {
      const res = await apiClient.get(API_URL + "/users/whoami");
      return res.data;
    },
    {
      onSuccess(data) {
        console.log(data);
      },
      onError(error) {
        if (error instanceof AxiosError) {
          if (
            (error.response && error.response.status === 401) ||
            error.response.status === 403
          ) {
            navigate("/sign_in");
          }
        }
        console.log(error);
      },
    },
  );

  const {
    data: getUserIncompleteEventData,
    isLoading: getUserIncompleteEventIsLoading,
    isSuccess: getUserIncompleteEventIsSuccess,
    isError: getUserIncompleteEventIsError,
  } = useQuery("event_get_user_incomplete_event", async () => {
    const response = await apiClient.get("/event/get_user_incomplete_event");
    return response.data;
  });

  if (getUserIncompleteEventIsLoading) {
    return <Loading count={5} />;
  }
  if (!getUserIncompleteEventIsLoading && getUserIncompleteEventIsError) {
    makeToast("error", "Error", "Failed to initialize create event");
    navigate("/");
  }
  if (getUserIncompleteEventIsSuccess) {
    console.log("Use query response: ", getUserIncompleteEventData);
    if (getUserIncompleteEventData.isPresent) {
      setIncompleteEvent(getUserIncompleteEventData.event as IEvent);
    } else {
      setIncompleteEvent(null);
    }
  }

  const convertAddSeatingChartFormStateToApiFormat = (): any => {
    console.log(
      "This is addSeatingChartFormState object ",
      addSeatingChartFormState,
    );
    const result: any[] = [];
    Object.entries(addSeatingChartFormState).forEach(
      ([sectionName, sectionObject]) => {
        const apiSectionObject: any = {};
        apiSectionObject["name"] = sectionObject.name;
        apiSectionObject["rowsOrTables"] = [];
        console.log("entered section foreach");
        Object.entries(sectionObject.rowOrTableMap).forEach(
          ([rowOrTableName, rowOrTableObject]) => {
            console.log("entered row foreach");
            const apiRowObject: any = {};
            apiRowObject["rowOrTableType"] = RowOrTableType.ROW;
            apiRowObject["name"] = rowOrTableObject.name;
            apiRowObject["seats"] = generateStringsFromMap(
              rowOrTableObject.seats,
            );
            apiSectionObject["rowsOrTables"].push(apiRowObject);
          },
        );
        result.push(apiSectionObject);
      },
    );
    return result;
  };

  //This function takes in form4 data because recoil state does not update immediately.
  const handleSubmit = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    // console.log("Set headers");

    try {
      const response = await apiClient.post("/event/submit_incomplete_event", {
        headers,
      });
      navigate(`/create_event/success/${response.data._id}`);
      setIncompleteEvent(null);
      toast({
        title: "Successfully created an event",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log("This is error: ", error.response.data);
      makeToast(
        "error",
        "Error",
        "Could not create event. Please try again later",
      );
    }
  };

  let currentFormComponent: React.ReactNode;
  switch (formStep) {
    case CreateEventFormSteps.EVENT_DETAILS_1:
      currentFormComponent = (
        <EventDetails1Form
          isUserEventInitialized={getUserIncompleteEventData?.isPresent}
        />
      );
      break;
    case CreateEventFormSteps.EVENT_DETAILS_2:
      currentFormComponent = <EventDetails2Form />;
      break;
    case CreateEventFormSteps.ADD_SEATS_GROUPING_FORM:
      currentFormComponent = <AddSeatsGroupingForm />;
      break;
    case CreateEventFormSteps.SELECT_SEATING_TYPE:
      currentFormComponent = <SelectSeatingTypeForm />;
      break;
    case CreateEventFormSteps.DESCRIBE_DIAGRAM_FORMAT:
      currentFormComponent = <DescribeDiagramFormatForm />;
      break;
    case CreateEventFormSteps.ADD_SEATING_CHART:
      currentFormComponent = <AddSeatingChartForm />;
      break;
    case CreateEventFormSteps.ADD_BANK_DETAILS:
      currentFormComponent = <AddBankDetailsForm submitEvent={handleSubmit} />;
      break;
    default:
      throw new Error("Invalid form component");
  }
  return (
    <>
      <Spacer height={10} />
      <Center mx={{ base: 4, md: 10 }}>
        <Box
          borderWidth="1px"
          rounded="lg"
          className="max-w-xl p-4 md:p-8 w-full"
        >
          <Progress
            hasStripe
            value={progress}
            isAnimated
            mb={4}
            colorScheme="secondary"
            rounded={"sm"}
          />
          {currentFormComponent}
        </Box>
      </Center>
      <Spacer height={10} />
    </>
  );
};

export default CreateEvent;
