import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";

export const refreshAccessToken = async (): Promise<boolean> => {
  const userPool = new CognitoUserPool({
    UserPoolId: "us-east-1_spapTUiQA",
    ClientId: "4f868k9j14hkmr3hetocp2bi3m",
  });
  const cognitoUser = userPool.getCurrentUser();

  if (!cognitoUser) {
    console.log("User is not signed in.");
    cleanupSession();
    return false;
  }

  try {
    const session = await getSession(cognitoUser);
    if (session && session.isValid()) {
      console.log("Session is valid.");
      await refreshSession(cognitoUser, session);
      return true;
    } else {
      console.log("Session is not valid. User needs to sign in again.");
      cleanupSession();
      return false;
    }
  } catch (error) {
    console.error("Error refreshing access token:", error);
    cleanupSession();
    return false;
  }
};

function getSession(cognitoUser: CognitoUser): Promise<any> {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  });
}

function refreshSession(cognitoUser: CognitoUser, session: any): Promise<void> {
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(session.getRefreshToken(), (err, result) => {
      if (err) {
        reject(err);
      } else {
        const newAccessToken = result.getAccessToken().getJwtToken();
        console.log("New Access token fetched");
        localStorage.setItem("userAccessToken", newAccessToken);
        localStorage.setItem("isAuthenticatedAtom", "true");
        resolve();
      }
    });
  });
}

function cleanupSession() {
  localStorage.setItem("isAuthenticatedAtom", "false");
  localStorage.setItem("awsUserDetailsAtom", null);
  localStorage.clear();
  window.location.href = "/sign_in";
}
