import { Box, Button, Flex, useColorMode, Icon, Text } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import moment from "moment";
import {
  buyerEmailAtom,
  currentEventAtom,
  numSeatsInSectionToBuyAtom,
  pdfBuffersAtom,
  awsUserDetailsAtom,
  selectedDiagramSeatsMapAtom,
} from "../../recoil/atom";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { EventSeatTypes } from "../../types/interface";

const SuccessPage = () => {
  const userDetails = useRecoilValue(awsUserDetailsAtom);
  const currentEvent = useRecoilValue(currentEventAtom);
  const numSeatsInSectionToBuy = useRecoilValue(numSeatsInSectionToBuyAtom);
  const buyerEmail = useRecoilValue(buyerEmailAtom);
  const pdfBuffersObject = useRecoilValue(pdfBuffersAtom);
  const selectedDiagramSeatsMap = useRecoilValue(selectedDiagramSeatsMapAtom);
  const totalSeatsCount = Object.values(selectedDiagramSeatsMap).length;

  const eventType = currentEvent?.ticketingType;

  let countToDisplay;
  if (EventSeatTypes.STANDARD_BASED) {
    countToDisplay = Object.values(numSeatsInSectionToBuy).reduce(
      (acc, count) => acc + count,
      0,
    );
  } else {
    countToDisplay = Object.values(selectedDiagramSeatsMap).length;
  }

  const handleDownload = () => {
    try {

      const blob = new Blob([pdfBuffersObject], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "MushaTickets.pdf";

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
    } catch (error) {
      console.error("Error handling compressed PDF buffer:", error);
    }
  };

  return (
    <Box className=" w-full px-4 md:px-12 flex items-center justify-center">
      <Box
        p={6}
        borderWidth={1}
        borderRadius="md"
        boxShadow="md"
        maxW="100%"
        mx="auto"
        my={12}
      >
        <Flex
          borderBottom="1px solid gray"
          className="w-full flex md:flex-row items-center pb-6 justify-between flex-col gap-4"
        >
          <Flex className="items-center">
            <Icon as={CheckCircleIcon} color="orange" boxSize={6} mr={2} />
            <Text fontWeight="bold" className="md:text-2xl text-xl">
              Thanks for your order!
            </Text>
          </Flex>
          <Button
            colorScheme="orange"
            onClick={handleDownload}
            className=" md:block hidden"
          >
            Download Tickets
          </Button>
        </Flex>
        <Flex flexDirection="column" pt="4">
          <Text fontSize="14px">YOU'RE GOING TO</Text>
          <Text fontSize="20px" fontWeight="bold" pt="2">
            {currentEvent?.title}
          </Text>
        </Flex>
        <Flex flexDirection="column" pt="4">
          <Text fontSize="14px" fontWeight="bold">
            ORGANISER MESSAGE
          </Text>
          <Text pt="1">
            Thanks for registering for {currentEvent?.title}! We can't wait to
            see you there for what we believe will be a very special event! Keep
            your ticket(s) handy and we shall see you soon
          </Text>
        </Flex>
        <Flex
          pt="5"
          justifyContent="space-between"
          gap="4"
          className="w-full flex md:flex-row flex-col"
        >
          <Flex flexDirection="column">
            <Text
              pt="1"
              fontWeight="bold"
            >{`${countToDisplay} TICKET(S) SENT TO`}</Text>
            <Text>{buyerEmail}</Text>
          </Flex>
          <Flex flexDirection="column">
            <Flex fontWeight="bold">DATE</Flex>
            <Text>
              {moment(currentEvent?.eventStart).format("MMMM Do YYYY, h:mm a")}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Flex fontWeight="bold">LOCATION</Flex>
            <Text>{currentEvent?.locationName}</Text>
          </Flex>
        </Flex>
        <Button
          colorScheme="orange"
          onClick={handleDownload}
          className=" md:hidden block w-full mt-6"
        >
          Download Tickets
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessPage;
