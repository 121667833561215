import RootLayout from "./RootLayout";

const RootLayoutStandard: React.FC = () => {
  return <RootLayout maxWidth={"1340px"} />;
};

const RootLayoutWide: React.FC = () => {
  return <RootLayout maxWidth={"1700px"} />;
};
export { RootLayoutStandard, RootLayoutWide };
