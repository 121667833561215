import { useQuery, QueryKey } from "react-query";
import { apiClient } from "../api";

const useGetData = (queryKey: QueryKey, url: string, cacheTime?: number) => {
  const options = {};
  if (cacheTime !== undefined) {
    options["cacheTime"] = cacheTime;
  }
  const { data, error, isLoading, isSuccess } = useQuery(
    queryKey,
    async () => {
      try {
        const response = await apiClient.get(url);

        return response.data;
      } catch (error) {
        throw new Error("Network response was not ok");
      }
    },
    options,
  );

  return Boolean(data)
    ? { data, error, isLoading, isSuccess }
    : { data: [], error, isLoading, isSuccess };
};

export default useGetData;
